import React from "react";

// mui components
import { Stack } from "@mui/material";

// custom components
import OutlinedTextInput from "../../custom-input/outlined-text-input";
import CustomCheckbox from "../../custom-checkbox";
import CustomDivider from "../../custom-divider";
import Heading5 from "../../typography/heading-05";
import Body3 from "../../typography/body-03";
import Body2 from "../../typography/body-02";

// custom styles
import styles from "./index.module.scss";

const DemographicInfo = ({
	demographicIndex,
	coBorrower,
	demographicData,
	data,
	setData,
	disabled,
}) => {
	return (
		<Stack direction="column" className={styles.cardContainer}>
			<Stack direction="column" className={styles.cardHeaderContainer}>
				<Heading5
					text={`Demographic Information - ${
						coBorrower ? "Co Borrower " : "Borrower "
					}`}
					fontType="semibold"
				/>
				<Body3
					text={`Provide details about the ${
						coBorrower ? "co-borrower" : "borrower"
					}`}
				/>
			</Stack>

			{/* ethnicity */}
			<Stack direction="column" className={styles.sectionContainer}>
				<Stack direction="column" className={styles.rowContainer}>
					<Stack direction="row" className={styles.questionContainer}>
						<Body2 text="A." />
						<Body2
							text={`Provide information regarding  ${
								coBorrower ? "co-borrower" : "borrower"
							}’s ethnicity. Select all that apply.`}
						/>
					</Stack>

					<Stack direction="column" className={styles.optionsContainer}>
						<CustomCheckbox
							checked={
								demographicData?.hmdaEthnicityHispanicLatinoIndicator || false
							}
							handleChange={() =>
								setData(
									data.map((data, index) => {
										if (demographicIndex === index) {
											return {
												...data,
												hmdaEthnicityHispanicLatinoIndicator:
													!demographicData?.hmdaEthnicityHispanicLatinoIndicator,
												hmdaEthnicityNotHispanicLatinoIndicator: false,
												hmdaEthnicityDoNotWishIndicator: false,
											};
										} else {
											return data;
										}
									})
								)
							}
							label="Hispanic or Latino"
							disabled={disabled}
						/>

						<Stack direction="row" className={styles.subRowContainer}>
							<Stack direction="column" className={styles.subColumnContainer}>
								<CustomCheckbox
									checked={demographicData?.hmdaMexicanIndicator || false}
									handleChange={() =>
										setData(
											data.map((data, index) => {
												if (demographicIndex === index) {
													return {
														...data,
														hmdaMexicanIndicator:
															!demographicData?.hmdaMexicanIndicator,
													};
												} else {
													return data;
												}
											})
										)
									}
									label="Hispanic or Latino"
									disabled={disabled}
								/>
							</Stack>

							<Stack direction="column" className={styles.subColumnContainer}>
								<CustomCheckbox
									checked={demographicData?.hmdaMexicanIndicator || false}
									handleChange={() =>
										setData(
											data.map((data, index) => {
												if (demographicIndex === index) {
													return {
														...data,
														hmdaMexicanIndicator:
															!demographicData?.hmdaMexicanIndicator,
													};
												} else {
													return data;
												}
											})
										)
									}
									label="Mexican"
									disabled={disabled}
								/>
							</Stack>

							<Stack direction="column" className={styles.subColumnContainer}>
								<CustomCheckbox
									checked={demographicData?.hmdaPuertoRicanIndicator || false}
									handleChange={() =>
										setData(
											data.map((data, index) => {
												if (demographicIndex === index) {
													return {
														...data,
														hmdaPuertoRicanIndicator:
															!demographicData?.hmdaPuertoRicanIndicator,
													};
												} else {
													return data;
												}
											})
										)
									}
									label="Puerto Rican"
									disabled={disabled}
								/>
							</Stack>

							<Stack direction="column" className={styles.subColumnContainer}>
								<CustomCheckbox
									checked={demographicData?.hmdaCubanIndicator || false}
									handleChange={() =>
										setData(
											data.map((data, index) => {
												if (demographicIndex === index) {
													return {
														...data,
														hmdaCubanIndicator:
															!demographicData?.hmdaCubanIndicator,
													};
												} else {
													return data;
												}
											})
										)
									}
									label="Cuban"
									disabled={disabled}
								/>
							</Stack>

							<Stack direction="column" className={styles.subColumnContainer}>
								<CustomCheckbox
									checked={
										demographicData?.hmdaHispanicLatinoOtherOriginIndicator ||
										false
									}
									handleChange={() =>
										setData(
											data.map((data, index) => {
												if (demographicIndex === index) {
													return {
														...data,
														hmdaHispanicLatinoOtherOriginIndicator:
															!demographicData?.hmdaHispanicLatinoOtherOriginIndicator,
													};
												} else {
													return data;
												}
											})
										)
									}
									label="Other Hispanic or Latino:"
									disabled={disabled}
								/>

								<Stack className={styles.otherInputContainer}>
									<OutlinedTextInput
										type="text"
										placeholder="Enter your ethnicity"
										value={demographicData?.hmdaOtherHispanicLatinoOrigin}
										handleChange={(event) =>
											setData(
												data.map((data, index) => {
													if (demographicIndex === index) {
														return {
															...data,
															hmdaOtherHispanicLatinoOrigin: event.target.value,
														};
													} else {
														return data;
													}
												})
											)
										}
										disabled={disabled}
									/>
								</Stack>
							</Stack>
						</Stack>
					</Stack>

					<Stack direction="column" className={styles.optionsContainer}>
						<CustomCheckbox
							checked={
								demographicData?.hmdaEthnicityNotHispanicLatinoIndicator ||
								false
							}
							handleChange={() =>
								setData(
									data.map((data, index) => {
										if (demographicIndex === index) {
											return {
												...data,
												hmdaEthnicityHispanicLatinoIndicator: false,
												hmdaMexicanIndicator: false,
												hmdaPuertoRicanIndicator: false,
												hmdaCubanIndicator: false,
												hmdaHispanicLatinoOtherOriginIndicator: false,
												hmdaOtherHispanicLatinoOrigin: "",
												hmdaEthnicityNotHispanicLatinoIndicator:
													!demographicData?.hmdaEthnicityNotHispanicLatinoIndicator,
												hmdaEthnicityDoNotWishIndicator: false,
											};
										} else {
											return data;
										}
									})
								)
							}
							label="Not Hispanic or Latino"
							disabled={disabled}
						/>
					</Stack>

					{/* do not wish to furnish */}
					<Stack direction="column" className={styles.optionsContainer}>
						<CustomCheckbox
							checked={
								demographicData?.hmdaEthnicityDoNotWishIndicator || false
							}
							handleChange={() =>
								setData(
									data.map((data, index) => {
										if (demographicIndex === index) {
											if (!demographicData?.hmdaEthnicityDoNotWishIndicator) {
												return {
													...data,
													hmdaEthnicityHispanicLatinoIndicator: false,
													hmdaMexicanIndicator: false,
													hmdaPuertoRicanIndicator: false,
													hmdaCubanIndicator: false,
													hmdaHispanicLatinoOtherOriginIndicator: false,
													hmdaOtherHispanicLatinoOrigin: "",
													hmdaEthnicityNotHispanicLatinoIndicator: false,
													hmdaEthnicityDoNotWishIndicator:
														!demographicData?.hmdaEthnicityDoNotWishIndicator,
												};
											} else {
												return {
													...data,
													hmdaEthnicityDoNotWishIndicator:
														!demographicData?.hmdaEthnicityDoNotWishIndicator,
												};
											}
										} else {
											return data;
										}
									})
								)
							}
							label="I do not wish to furnish this information."
							disabled={disabled}
						/>
					</Stack>
				</Stack>
			</Stack>

			{/* race */}
			<Stack direction="column" className={styles.sectionContainer}>
				<Stack direction="column" className={styles.rowContainer}>
					<Stack direction="row" className={styles.questionContainer}>
						<Body2 text="B." />
						<Body2
							text={`Provide information regarding  ${
								coBorrower ? "co-borrower" : "borrower"
							}’s race. Select all that apply.`}
						/>
					</Stack>

					{/* american indian or alaska native */}
					<Stack direction="column" className={styles.optionsContainer}>
						<CustomCheckbox
							checked={demographicData?.hmdaAmericanIndianIndicator || false}
							handleChange={() =>
								setData(
									data.map((data, index) => {
										if (demographicIndex === index) {
											return {
												...data,
												hmdaAmericanIndianIndicator:
													!demographicData?.hmdaAmericanIndianIndicator,
												hmdaRaceDoNotWishProvideIndicator: false,
											};
										} else {
											return data;
										}
									})
								)
							}
							label="American Indian or Alaska Native"
							disabled={disabled}
						/>

						<Stack direction="column" className={styles.subRowContainer}>
							<Stack direction="row" className={styles.inlineContainer}>
								<Body2 text="Enter name of enrolled or principal tribe:" />
								<Stack className={styles.otherInputContainer}>
									<OutlinedTextInput
										type="text"
										placeholder="Enter your American Indian race"
										value={
											demographicData?.hmdaAmericanIndianTribe === undefined
												? demographicData?.americanIndianOtherValue
												: demographicData?.hmdaAmericanIndianTribe
										}
										handleChange={(event) =>
											setData(
												data.map((data, index) => {
													if (demographicIndex === index) {
														return {
															...data,
															americanIndianOtherValue: event.target.value,
															hmdaAmericanIndianTribe: event.target.value,
														};
													} else {
														return data;
													}
												})
											)
										}
										disabled={disabled}
									/>
								</Stack>
							</Stack>
						</Stack>
					</Stack>

					{/* asian */}
					<Stack direction="column" className={styles.optionsContainer}>
						<CustomCheckbox
							checked={demographicData?.hmdaAsianIndicator || false}
							handleChange={() =>
								setData(
									data.map((data, index) => {
										if (demographicIndex === index) {
											return {
												...data,
												hmdaAsianIndicator: !data?.hmdaAsianIndicator,
												hmdaRaceDoNotWishProvideIndicator: false,
											};
										} else {
											return data;
										}
									})
								)
							}
							label="Asian"
							disabled={disabled}
						/>

						<Stack direction="row" className={styles.subRowContainer}>
							<Stack direction="column" className={styles.subColumnContainer}>
								<CustomCheckbox
									checked={demographicData?.hmdaAsianIndianIndicator || false}
									handleChange={() =>
										setData(
											data.map((data, index) => {
												if (demographicIndex === index) {
													return {
														...data,
														hmdaAsianIndianIndicator:
															!data?.hmdaAsianIndianIndicator,
													};
												} else {
													return data;
												}
											})
										)
									}
									label="Asian Indian"
									disabled={disabled}
								/>

								<CustomCheckbox
									checked={demographicData?.hmdaChineseIndicator || false}
									handleChange={() =>
										setData(
											data.map((data, index) => {
												if (demographicIndex === index) {
													return {
														...data,
														hmdaChineseIndicator:
															!demographicData?.hmdaChineseIndicator,
													};
												} else {
													return data;
												}
											})
										)
									}
									label="Chinese"
									disabled={disabled}
								/>
							</Stack>

							<Stack direction="column" className={styles.subColumnContainer}>
								<CustomCheckbox
									checked={demographicData?.hmdaFilipinoIndicator || false}
									handleChange={() =>
										setData(
											data.map((data, index) => {
												if (demographicIndex === index) {
													return {
														...data,
														hmdaFilipinoIndicator:
															!demographicData?.hmdaFilipinoIndicator,
													};
												} else {
													return data;
												}
											})
										)
									}
									label="Filipino"
									disabled={disabled}
								/>

								<CustomCheckbox
									checked={demographicData?.hmdaJapaneseIndicator || false}
									handleChange={() =>
										setData(
											data.map((data, index) => {
												if (demographicIndex === index) {
													return {
														...data,
														hmdaJapaneseIndicator:
															!demographicData?.hmdaJapaneseIndicator,
													};
												} else {
													return data;
												}
											})
										)
									}
									label="Japanese"
									disabled={disabled}
								/>
							</Stack>

							<Stack direction="column" className={styles.subColumnContainer}>
								<CustomCheckbox
									checked={demographicData?.hmdaKoreanIndicator || false}
									handleChange={() =>
										setData(
											data.map((data, index) => {
												if (demographicIndex === index) {
													return {
														...data,
														hmdaKoreanIndicator:
															!demographicData?.hmdaKoreanIndicator,
													};
												} else {
													return data;
												}
											})
										)
									}
									label="Korean"
									disabled={disabled}
								/>

								<CustomCheckbox
									checked={demographicData?.hmdaVietnameseIndicator || false}
									handleChange={() =>
										setData(
											data.map((data, index) => {
												if (demographicIndex === index) {
													return {
														...data,
														hmdaVietnameseIndicator:
															!demographicData?.hmdaVietnameseIndicator,
													};
												} else {
													return data;
												}
											})
										)
									}
									label="Vietnamese"
									disabled={disabled}
								/>
							</Stack>

							{/* other */}
							<Stack direction="column" className={styles.subColumnContainer}>
								<CustomCheckbox
									checked={
										demographicData?.hmdaAsianOtherRaceIndicator || false
									}
									handleChange={() =>
										setData(
											data.map((data, index) => {
												if (demographicIndex === index) {
													return {
														...data,
														hmdaAsianOtherRaceIndicator:
															!demographicData?.hmdaAsianOtherRaceIndicator,
													};
												} else {
													return data;
												}
											})
										)
									}
									label="Other Asian:"
									disabled={disabled}
								/>

								<Stack className={styles.otherInputContainer}>
									<OutlinedTextInput
										type="text"
										placeholder="Enter your ethnicity"
										value={demographicData?.hmdaOtherAsianRace}
										handleChange={(event) =>
											setData(
												data.map((data, index) => {
													if (demographicIndex === index) {
														return {
															...data,
															hmdaOtherAsianRace: event.target.value,
														};
													} else {
														return data;
													}
												})
											)
										}
										disabled={disabled}
									/>
								</Stack>
							</Stack>
						</Stack>
					</Stack>

					{/* black */}
					<Stack direction="column" className={styles.optionsContainer}>
						<CustomCheckbox
							checked={demographicData?.hmdaAfricanAmericanIndicator || false}
							handleChange={() =>
								setData(
									data.map((data, index) => {
										if (demographicIndex === index) {
											return {
												...data,
												hmdaAfricanAmericanIndicator:
													!data?.hmdaAfricanAmericanIndicator,
												hmdaRaceDoNotWishProvideIndicator: false,
											};
										} else {
											return data;
										}
									})
								)
							}
							label="Black or African American"
							disabled={disabled}
						/>
					</Stack>

					{/* native hawaiian */}
					<Stack direction="column" className={styles.optionsContainer}>
						<CustomCheckbox
							checked={demographicData?.hmdaPacificIslanderIndicator || false}
							handleChange={() =>
								setData(
									data.map((data, index) => {
										if (demographicIndex === index) {
											return {
												...data,
												hmdaPacificIslanderIndicator:
													!data?.hmdaPacificIslanderIndicator,
												hmdaRaceDoNotWishProvideIndicator: false,
											};
										} else {
											return data;
										}
									})
								)
							}
							label="Native Hawaiian or Other Pacific Islander"
							disabled={disabled}
						/>

						<Stack direction="row" className={styles.subRowContainer}>
							<Stack direction="column" className={styles.subColumnContainer}>
								<CustomCheckbox
									checked={
										demographicData?.hmdaNativeHawaiianIndicator || false
									}
									handleChange={() =>
										setData(
											data.map((data, index) => {
												if (demographicIndex === index) {
													return {
														...data,
														hmdaNativeHawaiianIndicator:
															!data?.hmdaNativeHawaiianIndicator,
													};
												} else {
													return data;
												}
											})
										)
									}
									label="Native Hawaiian"
									disabled={disabled}
								/>
							</Stack>

							<Stack direction="column" className={styles.subColumnContainer}>
								<CustomCheckbox
									checked={
										demographicData?.hmdaGuamanianOrChamorroIndicator || false
									}
									handleChange={() =>
										setData(
											data.map((data, index) => {
												if (demographicIndex === index) {
													return {
														...data,
														hmdaGuamanianOrChamorroIndicator:
															!demographicData?.hmdaGuamanianOrChamorroIndicator,
													};
												} else {
													return data;
												}
											})
										)
									}
									label="Guamanian or Chamorro"
									disabled={disabled}
								/>
							</Stack>

							<Stack direction="column" className={styles.subColumnContainer}>
								<CustomCheckbox
									checked={demographicData?.hmdaSamoanIndicator || false}
									handleChange={() =>
										setData(
											data.map((data, index) => {
												if (demographicIndex === index) {
													return {
														...data,
														hmdaSamoanIndicator:
															!demographicData?.hmdaSamoanIndicator,
													};
												} else {
													return data;
												}
											})
										)
									}
									label="Samoan"
									disabled={disabled}
								/>
							</Stack>

							{/* other */}
							<Stack direction="column" className={styles.subColumnContainer}>
								<CustomCheckbox
									checked={
										demographicData?.hmdaPacificIslanderOtherIndicator || false
									}
									handleChange={() =>
										setData(
											data.map((data, index) => {
												if (demographicIndex === index) {
													return {
														...data,
														hmdaPacificIslanderOtherIndicator:
															!demographicData?.hmdaPacificIslanderOtherIndicator,
													};
												} else {
													return data;
												}
											})
										)
									}
									label="Other Pacific Islander:"
									disabled={disabled}
								/>

								<Stack className={styles.otherInputContainer}>
									<OutlinedTextInput
										type="text"
										placeholder="Enter your race"
										value={demographicData?.hmdaOtherPacificIslanderRace}
										handleChange={(event) =>
											setData(
												data.map((data, index) => {
													if (demographicIndex === index) {
														return {
															...data,
															hmdaOtherPacificIslanderRace: event.target.value,
														};
													} else {
														return data;
													}
												})
											)
										}
										disabled={disabled}
									/>
								</Stack>
							</Stack>
						</Stack>
					</Stack>

					{/* white */}
					<Stack direction="column" className={styles.optionsContainer}>
						<CustomCheckbox
							checked={demographicData?.hmdaWhiteIndicator || false}
							handleChange={() =>
								setData(
									data.map((data, index) => {
										if (demographicIndex === index) {
											return {
												...data,
												hmdaWhiteIndicator: !data?.hmdaWhiteIndicator,
												hmdaRaceDoNotWishProvideIndicator: false,
											};
										} else {
											return data;
										}
									})
								)
							}
							label="White"
							disabled={disabled}
						/>
					</Stack>

					{/* do not wish to furnish */}
					<Stack direction="column" className={styles.optionsContainer}>
						<CustomCheckbox
							checked={
								demographicData?.hmdaRaceDoNotWishProvideIndicator || false
							}
							handleChange={() =>
								setData(
									data.map((data, index) => {
										if (demographicIndex === index) {
											if (!demographicData?.hmdaRaceDoNotWishProvideIndicator) {
												return {
													...data,
													hmdaAmericanIndianIndicator: false,
													hmdaAmericanIndianTribe: "",
													americanIndianOtherValue: "",
													hmdaAsianIndicator: false,
													hmdaAsianIndianIndicator: false,
													hmdaChineseIndicator: false,
													hmdaFilipinoIndicator: false,
													hmdaJapaneseIndicator: false,
													hmdaKoreanIndicator: false,
													hmdaVietnameseIndicator: false,
													hmdaAsianOtherRaceIndicator: false,
													hmdaOtherAsianRace: "",
													hmdaAfricanAmericanIndicator: false,
													hmdaPacificIslanderIndicator: false,
													hmdaNativeHawaiianIndicator: false,
													hmdaGuamanianOrChamorroIndicator: false,
													hmdaSamoanIndicator: false,
													hmdaPacificIslanderOtherIndicator: false,
													hmdaOtherPacificIslanderRace: "",
													hmdaWhiteIndicator: false,
													hmdaRaceDoNotWishProvideIndicator:
														!demographicData?.hmdaRaceDoNotWishProvideIndicator,
												};
											} else {
												return {
													...data,
													hmdaRaceDoNotWishProvideIndicator:
														!data?.hmdaRaceDoNotWishProvideIndicator,
												};
											}
										} else {
											return data;
										}
									})
								)
							}
							label="I do not wish to furnish this information."
							disabled={disabled}
						/>
					</Stack>
				</Stack>
			</Stack>

			{/* sex */}
			<Stack direction="column" className={styles.sectionContainer}>
				<Stack direction="column" className={styles.rowContainer}>
					<Stack direction="row" className={styles.questionContainer}>
						<Body2 text="C." />
						<Body2
							text={`Provide information regarding  ${
								coBorrower ? "co-borrower" : "borrower"
							}’s sex. Select all that apply.`}
						/>
					</Stack>

					<Stack direction="column" className={styles.optionsContainer}>
						<CustomCheckbox
							checked={
								demographicData?.hmdaGendertypeFemaleIndicator === undefined
									? demographicData?.HmdaGendertypeFemaleIndicator || false
									: demographicData?.hmdaGendertypeFemaleIndicator || false
							}
							handleChange={() =>
								setData(
									data.map((data, index) => {
										if (demographicIndex === index) {
											return {
												...data,
												HmdaGendertypeFemaleIndicator:
													!data?.HmdaGendertypeFemaleIndicator,
												hmdaGendertypeFemaleIndicator:
													!data?.HmdaGendertypeFemaleIndicator,
												hmdaGendertypeMaleIndicator: false,
												HmdaGendertypeMaleIndicator: false,
												hmdaGendertypeDoNotWishIndicator: false,
												HmdaGendertypeDoNotWishIndicator: false,
											};
										} else {
											return data;
										}
									})
								)
							}
							label="Female"
							disabled={disabled}
						/>
					</Stack>

					<Stack direction="column" className={styles.optionsContainer}>
						<CustomCheckbox
							checked={
								demographicData?.HmdaGendertypeMaleIndicator === undefined
									? demographicData?.hmdaGendertypeMaleIndicator || false
									: demographicData?.HmdaGendertypeMaleIndicator || false
							}
							handleChange={() =>
								setData(
									data.map((data, index) => {
										if (demographicIndex === index) {
											return {
												...data,
												HmdaGendertypeMaleIndicator:
													!data?.HmdaGendertypeMaleIndicator,
												hmdaGendertypeMaleIndicator:
													!data?.HmdaGendertypeMaleIndicator,
												hmdaGendertypeFemaleIndicator: false,
												HmdaGendertypeFemaleIndicator: false,
												hmdaGendertypeDoNotWishIndicator: false,
												HmdaGendertypeDoNotWishIndicator: false,
											};
										} else {
											return data;
										}
									})
								)
							}
							label="Male"
							disabled={disabled}
						/>
					</Stack>

					{/* do not wish to furnish */}
					<Stack direction="column" className={styles.optionsContainer}>
						<CustomCheckbox
							checked={
								demographicData?.HmdaGendertypeDoNotWishIndicator === undefined
									? demographicData?.hmdaGendertypeDoNotWishIndicator || false
									: demographicData?.HmdaGendertypeDoNotWishIndicator || false
							}
							handleChange={() =>
								setData(
									data.map((data, index) => {
										if (demographicIndex === index) {
											if (!demographicData?.HmdaGendertypeDoNotWishIndicator) {
												return {
													...data,
													HmdaGendertypeDoNotWishIndicator:
														!demographicData?.HmdaGendertypeDoNotWishIndicator,
													hmdaGendertypeDoNotWishIndicator:
														!demographicData?.HmdaGendertypeDoNotWishIndicator,
													hmdaGendertypeFemaleIndicator: false,
													HmdaGendertypeFemaleIndicator: false,
													HmdaGendertypeMaleIndicator: false,
													hmdaGendertypeMaleIndicator: false,
												};
											} else {
												return {
													...data,
													HmdaGendertypeDoNotWishIndicator:
														!data?.HmdaGendertypeDoNotWishIndicator,
												};
											}
										} else {
											return data;
										}
									})
								)
							}
							label="I do not wish to furnish this information."
							disabled={disabled}
						/>
					</Stack>
				</Stack>
			</Stack>

			{!coBorrower && <CustomDivider />}
		</Stack>
	);
};

export default DemographicInfo;
