import React from "react";

// mui components
import { MenuItem, Select } from "@mui/material";

// custom components
import Heading5 from "../../typography/heading-05";

// mui icons
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// custom styles
import styles from "./index.module.scss";

const SingleSelect = ({
	id,
	label,
	displayEmpty,
	value,
	handleChange,
	options,
	extraClass,
}) => {
	const customClass = `${styles.singleSelect} ${extraClass}`;

	return (
		<Select
			labelId={`simple-select-${id}-label`}
			id={`simple-select-${id}`}
			label={label}
			displayEmpty={displayEmpty}
			IconComponent={ExpandMoreIcon}
			value={value}
			onChange={handleChange}
			className={customClass}
		>
			<MenuItem value="" disabled>
				<Heading5 text="Select" />
			</MenuItem>
			{options.map((item, index) => (
				<MenuItem value={item.value} key={index}>
					<Heading5 text={item.label} />
				</MenuItem>
			))}
		</Select>
	);
};

export default SingleSelect;
