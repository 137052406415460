import React from "react";

// mui components
import { IconButton, TableCell, TableRow } from "@mui/material";

// custom components
import OutlinedTextInput from "../../custom-input/outlined-text-input";
import SecondarySelect from "../../custom-select/secondary-select";

// helper utils
import {
	ownerOptions,
	liabilityTypeOptions,
} from "../../../utils/select-options";

// mui icons
import { DeleteOutlined } from "@mui/icons-material";

// custom styles
import styles from "../index.module.scss";
import MaskedInput from "../../custom-input/masked-input";
import CustomCheckbox from "../../custom-checkbox";

const LiabilityInformationRow = ({
	row,
	index,
	handleRemoveRow,
	handleUpdate,
	disableRow,
}) => {
	return (
		<TableRow hover tabIndex={-1} key={row.label}>
			<TableCell>
				<IconButton
					className={styles.deleteIconBtn}
					onClick={() => handleRemoveRow(index, row?.vodIndex)}
					disabled={disableRow}
				>
					<DeleteOutlined className={styles.deleteIcon} />
				</IconButton>
			</TableCell>
			<TableCell>
				<SecondarySelect
					value={row?.owner}
					handleChange={(event) =>
						handleUpdate(index, "owner", event.target.value)
					}
					options={ownerOptions}
					disabled={disableRow}
				/>
			</TableCell>
			<TableCell>
				<SecondarySelect
					value={row?.liabilityType}
					handleChange={(event) =>
						handleUpdate(index, "liabilityType", event.target.value)
					}
					options={liabilityTypeOptions}
					disabled={disableRow}
				/>
			</TableCell>
			<TableCell>
				<OutlinedTextInput
					type="text"
					value={row?.holderName}
					handleChange={(event) =>
						handleUpdate(index, "holderName", event.target.value)
					}
					disabled={disableRow}
				/>
			</TableCell>
			<TableCell>
				<OutlinedTextInput
					type="text"
					value={row?.accountIdentifier}
					handleChange={(event) =>
						handleUpdate(index, "accountIdentifier", event.target.value)
					}
					disabled={disableRow}
				/>
			</TableCell>
			<TableCell>
				<MaskedInput
					placeholder="$"
					type="currency"
					value={row?.unpaidBalanceAmount}
					onInputChange={(value) =>
						disableRow !== true &&
						handleUpdate(index, "unpaidBalanceAmount", value)
					}
					disabled={disableRow}
				/>
			</TableCell>
			<TableCell align="center">
				<CustomCheckbox
					checked={row?.payoffStatusIndicator || false}
					handleChange={() =>
						handleUpdate(
							index,
							"payoffStatusIndicator",
							!row?.payoffStatusIndicator
						)
					}
					disabled={disableRow}
					extraClass={styles.checkboxInput}
				/>
			</TableCell>
			<TableCell>
				<MaskedInput
					placeholder="$"
					type="currency"
					value={row?.monthlyPaymentAmount?.toString()}
					onInputChange={(value) =>
						disableRow !== true &&
						handleUpdate(index, "monthlyPaymentAmount", value)
					}
					disabled={disableRow}
				/>
			</TableCell>
			<TableCell align="center">
				<CustomCheckbox
					checked={row?.reoId || false}
					handleChange={() => console.log("120 reo id:", row?.reoId)}
					disabled={true}
					extraClass={styles.checkboxInput}
				/>
			</TableCell>
		</TableRow>
	);
};

export default LiabilityInformationRow;
