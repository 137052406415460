import React, { useState, useEffect } from "react";

// mui components
import { Box, Stack } from "@mui/material";

// custom components
import CustomCheckbox from "../../custom-checkbox";
import CustomRadio from "../../custom-radio";
import CustomDivider from "../../custom-divider";
import Heading5 from "../../typography/heading-05";
import Body3 from "../../typography/body-03";

// helper utils
import { YNOptions } from "../../../utils/select-options";

// custom styles
import styles from "./index.module.scss";

const DemographicProvidedInfo = ({
	demographicIndex,
	coBorrower,
	demographicData,
	data,
	setData,
	disabled,
}) => {
	const [accordionClosed, setAccordionClosed] = useState(false);

	const [faceToFace, setFaceToFace] = useState(false);
	const [telephone, setTelephone] = useState(false);
	const [fax, setFax] = useState(false);
	const [email, setEmail] = useState(false);

	const [ethnicity, setEthnicity] = useState("");
	const [race, setRace] = useState("");
	const [sex, setSex] = useState("");

	useEffect(() => {
		console.log("25 demographicData:", demographicData);

		if (demographicData?.applicationTakenMethodType === "FaceToFace") {
			setFaceToFace(true);
		} else if (demographicData?.applicationTakenMethodType === "Telephone") {
			setTelephone(true);
		} else if (demographicData?.applicationTakenMethodType === "Mail") {
			setFax(true);
		} else if (demographicData?.applicationTakenMethodType === "Internet") {
			setEmail(true);
		}

		if (demographicData?.applicationTakenMethodType === "FaceToFace") {
			setEthnicity(demographicData?.isEthnicityBasedOnVisual);
			setRace(demographicData?.isRaceBasedOnVisual);
			setSex(demographicData?.isSexBasedOnVisual);
		}
	}, [demographicData]);

	const handleChangeFaceToFace = (value) => {
		setFaceToFace(value);
		if (value) {
			setTelephone(false);
			setFax(false);
			setEmail(false);
			setData(
				data.map((data, index) => {
					if (demographicIndex === index) {
						return { ...data, applicationTakenMethodType: "FaceToFace" };
					} else {
						return data;
					}
				})
			);
		}
	};

	const handleChangeTelephone = (value) => {
		setTelephone(value);
		if (value) {
			setFaceToFace(false);
			setFax(false);
			setEmail(false);
			setData(
				data.map((data, index) => {
					if (demographicIndex === index) {
						return { ...data, applicationTakenMethodType: "Telephone" };
					} else {
						return data;
					}
				})
			);
		}
	};

	const handleChangeFax = (value) => {
		setFaceToFace(value);
		if (value) {
			setFaceToFace(false);
			setTelephone(false);
			setEmail(false);
			setData(
				data.map((data, index) => {
					if (demographicIndex === index) {
						return { ...data, applicationTakenMethodType: "Mail" };
					} else {
						return data;
					}
				})
			);
		}
	};

	const handleChangeEmail = (value) => {
		setFaceToFace(value);
		if (value) {
			setFaceToFace(false);
			setTelephone(false);
			setFax(false);
			setData(
				data.map((data, index) => {
					if (demographicIndex === index) {
						return { ...data, applicationTakenMethodType: "Internet" };
					} else {
						return data;
					}
				})
			);
		}
	};

	return (
		<Box className={styles.cardContainer}>
			<Stack direction="column" className={styles.cardHeaderContainer}>
				<Heading5
					text={`The ${
						coBorrower ? " Co Borrower " : " Borrower "
					} Demographic Information was provided through`}
					fontType="semibold"
				/>
				<Body3
					text={`Provide details about the ${
						coBorrower ? " Co Borrower" : " Borrower"
					}`}
				/>
			</Stack>
			<Stack direction="column" className={styles.formSectionContainer}>
				<Stack direction="column" className={styles.inputSectionContainer}>
					{/* face to face */}
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.fullWidthContainer}
					>
						<CustomCheckbox
							checked={faceToFace}
							handleChange={() => handleChangeFaceToFace(!faceToFace)}
							label="Face-to-Face Interview"
							subLabel="(includes electronic media with video component)"
							disabled={disabled}
						/>
					</Stack>

					{/* ethnicity */}
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.subInputContainer}
					>
						<CustomRadio
							id={`radio-ethnicity-${coBorrower ? "co-borrower" : "borrower"}`}
							value={ethnicity}
							handleChange={(event) =>
								setData(
									data.map((data, index) => {
										if (demographicIndex === index) {
											return {
												...data,
												applicationTakenMethodType: "FaceToFace",
												isEthnicityBasedOnVisual: event.target.value,
											};
										} else {
											return data;
										}
									})
								)
							}
							label={`Was the ethnicity of the ${
								coBorrower ? "co-borrower" : "borrower"
							} collected on the basis of visual observation or surname?`}
							extraClass={styles.radioContainer}
							extraLabelClass={styles.radioLabelText}
							disabled={disabled}
							data={YNOptions}
						/>
					</Stack>

					{/* race */}
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.subInputContainer}
					>
						<CustomRadio
							id={`radio-race-${coBorrower ? "co-borrower" : "borrower"}`}
							value={race}
							handleChange={(event) =>
								setData(
									data.map((data, index) => {
										if (demographicIndex === index) {
											return {
												...data,
												applicationTakenMethodType: "FaceToFace",
												isRaceBasedOnVisual: event.target.value,
											};
										} else {
											return data;
										}
									})
								)
							}
							label={`Was the race of the ${
								coBorrower ? "co-borrower" : "borrower"
							} collected on the basis of visual observation or surname?`}
							extraClass={styles.radioContainer}
							extraLabelClass={styles.radioLabelText}
							disabled={disabled}
							data={YNOptions}
						/>
					</Stack>

					{/* sex */}
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.subInputContainer}
					>
						<CustomRadio
							id={`radio-sex-${coBorrower ? "co-borrower" : "borrower"}`}
							value={sex}
							handleChange={(event) =>
								setData(
									data.map((data, index) => {
										if (demographicIndex === index) {
											return {
												...data,
												applicationTakenMethodType: "FaceToFace",
												isSexBasedOnVisual: event.target.value,
											};
										} else {
											return data;
										}
									})
								)
							}
							label={`Was the sex of the ${
								coBorrower ? "co-borrower" : "borrower"
							} collected on the basis of visual observation or surname?`}
							extraClass={styles.radioContainer}
							extraLabelClass={styles.radioLabelText}
							disabled={disabled}
							data={YNOptions}
						/>
					</Stack>
				</Stack>

				{/* telephone interview */}
				<Stack
					direction="column"
					className={styles.inputContainer + " " + styles.fullWidthContainer}
				>
					<CustomCheckbox
						checked={telephone}
						handleChange={() => handleChangeTelephone(!telephone)}
						label="Telephone Interview"
						disabled={disabled}
					/>
				</Stack>

				{/* fax or mail */}
				<Stack
					direction="column"
					className={styles.inputContainer + " " + styles.fullWidthContainer}
				>
					<CustomCheckbox
						checked={fax}
						handleChange={() => handleChangeFax(!fax)}
						label="Fax or Mail"
						disabled={disabled}
					/>
				</Stack>

				{/* email or internet */}
				<Stack
					direction="column"
					className={styles.inputContainer + " " + styles.fullWidthContainer}
				>
					<CustomCheckbox
						checked={email}
						handleChange={() => handleChangeEmail(!email)}
						label="Email or Internet"
						disabled={disabled}
					/>
				</Stack>
			</Stack>

			<CustomDivider />
		</Box>
	);
};

export default DemographicProvidedInfo;
