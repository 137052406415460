import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// redux slice
import { logOut, selectUserDetails } from "../../features/auth/authSlice";

// local storage utils
import { storageDelete } from "../../utils/localStorage";

// mui components
import {
	Box,
	Divider,
	Drawer,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
} from "@mui/material";

// custom components
import Heading5 from "../typography/heading-05";

// mui icons
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";

// custom icons
import LoganLogo from "../../assets/icons/logo";

// drawer data
import { drawerTopData, drawerBottomData } from "./drawer-data";

// custom styles
import styles from "./index.module.scss";

const CustomDrawer = ({
	drawerWidth,
	handleDrawerItemClick,
	mobileOpen,
	handleDrawerToggle,
}) => {
	const { pathname } = useLocation();
	const dispatch = useDispatch();
	const [currentPath, setCurrentPath] = useState("");
	const userDetails = useSelector(selectUserDetails);

	useEffect(() => {
		let location = pathname.split("/");
		console.log("92 pathname", location[1]);
		setCurrentPath(location[1]);
	}, [pathname]);

	const isCurrentPath = (loanUrl) => {
		if (typeof loanUrl === "string") {
			return currentPath === loanUrl?.toLowerCase();
		} else {
			return loanUrl.some((url) => pathname.includes(url));
		}
	};

	const handleLogout = () => {
		dispatch(logOut());
		storageDelete("access-token");
		storageDelete("refresh-token");
	};

	const drawer = (
		<Box className={styles.drawerDiv}>
			<Box className={styles.logoContainer}>
				<LoganLogo extraStyles={{ width: "90%" }} />
			</Box>

			<List className={styles.drawerList}>
				{drawerTopData?.map((item, index) => (
					<ListItem
						key={index}
						disablePadding
						className={
							isCurrentPath(item.url)
								? styles.drawerSelectedItem
								: styles.drawerItem
						}
					>
						<ListItemButton onClick={() => handleDrawerItemClick(item.path)}>
							<ListItemIcon>{item.icon}</ListItemIcon>
							<Heading5
								text={item.title}
								fontType="semibold"
								extraClass={
									isCurrentPath(item.url)
										? styles.drawerSelectedText
										: styles.drawerText
								}
							/>
						</ListItemButton>
					</ListItem>
				))}
			</List>
			<Divider className={styles.dividerLine} />
			<List className={styles.drawerList}>
				<ListItem disablePadding className={styles.drawerItem}>
					<ListItemButton onClick={handleLogout}>
						<ListItemIcon>
							<LogoutOutlinedIcon sx={{ fontSize: "32px" }} />
						</ListItemIcon>
						<Heading5
							text="Log Out"
							fontType="semibold"
							extraClass={styles.drawerText}
						/>
					</ListItemButton>
				</ListItem>
				{userDetails?.user_roles?.includes("admin") &&
					drawerBottomData?.map((item, index) => (
						<ListItem
							key={index}
							disablePadding
							className={
								isCurrentPath(item.url)
									? styles.drawerSelectedItem
									: styles.drawerItem
							}
						>
							<ListItemButton onClick={() => handleDrawerItemClick(item.path)}>
								<ListItemIcon>{item.icon}</ListItemIcon>
								<Heading5
									text={item.title}
									fontType="semibold"
									extraClass={
										isCurrentPath(item.url)
											? styles.drawerSelectedText
											: styles.drawerText
									}
								/>
							</ListItemButton>
						</ListItem>
					))}
			</List>
		</Box>
	);

	return (
		<Box
			component="nav"
			sx={{
				width: { sm: drawerWidth },
				flexShrink: { sm: 0 },
			}}
			aria-label="mailbox folders"
		>
			<Drawer
				variant="temporary"
				open={mobileOpen}
				onClose={handleDrawerToggle}
				ModalProps={{
					keepMounted: true, // Better open performance on mobile.
				}}
				sx={{
					display: { xs: "block", sm: "none" },
					"& .MuiDrawer-paper": {
						boxSizing: "border-box",
						width: drawerWidth,
					},
				}}
				className={styles.drawerMobileContainer}
			>
				{drawer}
			</Drawer>
			<Drawer
				variant="permanent"
				sx={{
					display: { xs: "none", sm: "block" },
					"& .MuiDrawer-paper": {
						boxSizing: "border-box",
						width: drawerWidth,
					},
				}}
				open
				className={styles.drawerContainer}
			>
				{drawer}
			</Drawer>
		</Box>
	);
};

export default CustomDrawer;
