import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { DateTime } from "luxon";

// mui icons
import CloseIcon from "@mui/icons-material/Close";
import UploadFileIcon from "@mui/icons-material/UploadFile";

// mui components
import { Box, Stack } from "@mui/material";

// api slice
import { useUpdateFieldsMutation } from "../../../../features/update-fields/updateFieldsApiSlice";
import { setLoanDocsReducer } from "../../../../features/loan-docs/loanDocsSlice";
import { useLoanDocsMutation } from "../../../../features/loan-docs/loanDocsApiSlice";
import { useBrokerUploadsMutation } from "../../../../features/broker-uploads/brokerUploadsApiSlice";
import { getBrokerUploads } from "../../../../app/services/broker-upload";

// custom components
import Heading3 from "../../../typography/heading-03";
import SupportingDocs from "../../../supporting-docs";
import PrimaryButton from "../../../buttons/primary-button";
import SecondaryButton from "../../../buttons/secondary-button";
import Body2 from "../../../typography/body-02";
import Body3 from "../../../typography/body-03";

// helper utils
import { formatBytes } from "../../../../utils/format-bytes";

// custom styles
import styles from "./index.module.scss";

const DocumentsDrawer = ({
	handleCloseDrawer,
	handleOpenProcessingModal,
	handleCloseProcessingModal,
	handleOpenSuccessModal,
	setProcessingText,
	setSuccessText,
}) => {
	const fileTypes = ["PDF", "PNG", "JPEG", "JPG", "HTML", "TXT"];
	const params = useParams();
	const dispatch = useDispatch();

	const [loanDocs] = useLoanDocsMutation();
	const [updateFields] = useUpdateFieldsMutation();
	const [brokerUploads] = useBrokerUploadsMutation();

	const [attachedFiles, setAttachedFiles] = useState([]);

	const handleFileAttach = async (file) => {
		let newArr = [];
		Array.from(file).forEach((file) => newArr.push(file));
		setAttachedFiles(newArr);
	};

	const deleteFile = (event, index) => {
		console.log("60 files:", attachedFiles);
		console.log("60 index:", index);

		let newArr = attachedFiles;
		newArr.splice(index, 1);

		setAttachedFiles([...newArr]);
	};

	const handleCancel = () => {
		console.log("50 cancel clicked.");
		setAttachedFiles([]);
	};

	// upload documents
	const handleSubmitDocuments = async () => {
		console.log("50 submit click!!!", attachedFiles);

		if (attachedFiles?.length === 0) return;

		console.log("315 running ");

		handleCloseDrawer();
		handleOpenProcessingModal();
		setProcessingText("Uploading new documents");

		for (let index = 0; index < attachedFiles.length; index++) {
			const element = attachedFiles[index];

			var formData = new FormData();
			formData.append("loan_guid", params?.id);
			formData.append("file", element);

			// dispatch API and open modal if successful
			try {
				const fileUpload = await loanDocs({ formData }).unwrap();
				console.log("99 file data:", fileUpload);
				dispatch(setLoanDocsReducer(fileUpload));
				// uploaded successfully
				// setProgress(Math.ceil((index / attachedFiles.length) * 100));

				if (index === attachedFiles.length - 1) {
					// run at the end of the loop
					// setProgress(100);

					// call api to fetch updated list
					getBrokerUploads({
						loanGuid: params?.id,
						brokerUploads: brokerUploads,
					});

					let valuesArr = [
						{ id: "TPO.X4", value: DateTime.now().toFormat("MM-dd-yyyy") },
					];

					const updateFieldValues = await updateFields({
						loan_guid: params?.id,
						field_values: valuesArr,
					}).unwrap();
					console.log("90 updated values:", updateFieldValues);

					handleCloseProcessingModal();
					handleOpenSuccessModal();
					setSuccessText("Documents have been uploaded");

					console.log("70 all files attached");
				}
			} catch (err) {
				console.log("95 err:", err);
				// TODO aqib open error modal
				handleCloseProcessingModal();
			}
		}
	};

	return (
		<Stack direction="column" className={styles.stackContainer}>
			<Stack
				direction="row"
				alignItems="center"
				justifyContent="space-between"
				className={styles.drawerHeaderContainer}
			>
				<Heading3
					text="Upload Documents"
					extraClass={styles.headerText}
					fontType="semibold"
				/>
				<SecondaryButton
					startIcon={<CloseIcon />}
					text="Close"
					onClick={handleCloseDrawer}
				/>
			</Stack>
			<Stack direction="column" className={styles.drawerBodyContainer}>
				<Heading3
					text="Upload Documentation:"
					extraClass={styles.labelText}
					fontType="semibold"
				/>

				<SupportingDocs
					fileCount={attachedFiles?.length}
					fileTypes={fileTypes}
					text="or drag to upload supporting files"
					handleFileAttach={handleFileAttach}
					multiple={true}
				/>
			</Stack>

			{attachedFiles !== undefined && attachedFiles?.length > 0 && (
				<Stack direction="column" className={styles.uploadedFileContainer}>
					{attachedFiles?.map((item, index) => (
						<Stack
							direction="row"
							key={index}
							className={styles.fileListContainer}
						>
							<Box className={styles.fileIconContainer}>
								<UploadFileIcon className={styles.fileIcon} />
							</Box>
							<Stack direction="column" className={styles.fileNameContainer}>
								<Body2 text={item?.name} />
								<Body3 text={formatBytes(item?.size)} />
							</Stack>

							<Box
								sx={{ cursor: "pointer" }}
								onClick={(event) => deleteFile(event, index)}
								className={styles.deleteIconContainer}
							>
								<CloseIcon className={styles.deleteIcon} />
							</Box>
						</Stack>
					))}
				</Stack>
			)}

			<Stack
				direction="row"
				alignItems="center"
				justifyContent="flex-end"
				className={styles.drawerFooterContainer}
			>
				<SecondaryButton
					text="Reset"
					extraClass={styles.cancelBtn}
					extraTextClass={styles.cancelText}
					onClick={handleCancel}
					disabled={attachedFiles?.length === 0}
				/>
				<PrimaryButton
					text="Upload"
					onClick={handleSubmitDocuments}
					disabled={attachedFiles?.length === 0}
				/>
			</Stack>
		</Stack>
	);
};

export default DocumentsDrawer;
