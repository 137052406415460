import React from "react";

// mui components
import { Checkbox, TableCell, TableRow } from "@mui/material";

// custom components
import SecondarySelect from "../../custom-select/secondary-select";
import OutlinedTextInput from "../../custom-input/outlined-text-input";
import MaskedInput from "../../custom-input/masked-input";
import CustomCheckbox from "../../custom-checkbox";

// helper utils
import {
	liabilityTypeOptions,
	ownerOptions,
} from "../../../utils/select-options";

// custom styles
import styles from "../index.module.scss";

const LinkLiabilityRow = ({
	row,
	index,
	selectedLiability,
	realEstate,
	selectedRealEstate,
	handleSelectLiability,
}) => {
	const labelId = `enhanced-table-checkbox-${row.id}`;

	return (
		<TableRow
			hover
			tabIndex={-1}
			key={row.id}
			onClick={() => handleSelectLiability(row)}
			role="checkbox"
			selected={
				selectedLiability.includes(row?.volIndex) ||
				row?.reoId === realEstate[selectedRealEstate]?.reoId
			}
			className={
				selectedLiability.includes(row?.volIndex) ||
				row?.reoId === realEstate[selectedRealEstate]?.reoId
					? styles.highlightRow
					: ""
			}
		>
			<TableCell padding="checkbox">
				<Checkbox
					color="primary"
					checked={
						selectedLiability.includes(row?.volIndex) ||
						row?.reoId === realEstate[selectedRealEstate]?.reoId
					}
					inputProps={{
						"aria-labelledby": labelId,
					}}
				/>
			</TableCell>
			<TableCell>
				<SecondarySelect
					value={row?.owner}
					handleChange={() => console.log("50 liability owner")}
					options={ownerOptions}
					disabled={true}
				/>
			</TableCell>
			<TableCell>
				<SecondarySelect
					value={row?.liabilityType}
					handleChange={() => console.log("50 liability type")}
					options={liabilityTypeOptions}
					disabled={true}
				/>
			</TableCell>
			<TableCell>
				<OutlinedTextInput
					type="text"
					value={row?.holderName}
					handleChange={() => console.log("50 liability holder")}
					disabled={true}
				/>
			</TableCell>
			<TableCell>
				<OutlinedTextInput
					type="text"
					value={row?.accountIdentifier}
					handleChange={() => console.log("50 liability account number")}
					disabled={true}
				/>
			</TableCell>
			<TableCell>
				<MaskedInput
					placeholder="$"
					type="currency"
					value={row?.unpaidBalanceAmount}
					onInputChange={() => console.log("50 liability account number")}
					disabled={true}
				/>
			</TableCell>
			<TableCell align="center">
				<CustomCheckbox
					checked={row?.payoffStatusIndicator || false}
					handleChange={() => console.log("50 liability account number")}
					disabled={true}
					extraClass={styles.checkboxInput}
				/>
			</TableCell>
			<TableCell>
				<MaskedInput
					placeholder="$"
					type="currency"
					value={row?.monthlyPaymentAmount?.toString()}
					onInputChange={() => console.log("50 liability account number")}
					disabled={true}
				/>
			</TableCell>
		</TableRow>
	);
};

export default LinkLiabilityRow;
