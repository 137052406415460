import React from "react";
import { useNavigate, useParams } from "react-router-dom";

// mui components
import { Box, Divider, Stack } from "@mui/material";

// mui icons
import LaunchIcon from "@mui/icons-material/Launch";

// custom components
import Heading3 from "../../typography/heading-03";
import SecondaryButton from "../../buttons/secondary-button";
import Body2 from "../../typography/body-02";
import Heading4 from "../../typography/heading-04";

// common functions
import { filterValue } from "../../../utils/filter-value";

// custom styles
import styles from "./index.module.scss";

const LoanSummaryCard = ({ loanData, fieldData }) => {
	const navigate = useNavigate();
	let { id: loanGuid } = useParams();

	const milestoneStatus = (status) => {
		console.log("25 value:", status);

		if (status === "Started") return "Loan Submitted";
		if (status === "Loan Submitted") return "Loan Submitted";
		if (status === "Review Submission") return "Review Submission";
		if (status === "Incomplete Credit Pkg") return "Incomplete Credit Pkg";
		if (status === "Recd Cred Pkg") return "Recd Cred Pkg";
		if (status === "Review Cred Pkg") return "Recd Cred Pkg";
		if (status === "File Setup") return "File Setup";
		if (status === "Submit to UW") return "In Underwriting";
		if (status === "In UWing Review") return "In Underwriting";
		if (status === "Suspended") return "Suspended";
		if (status === "Cond'l Approval") return "Conditional Approval";
		if (status === "Recd Conditions") return "Recd Conditions";
		if (status === "UW Cond Review") return "UW Cond Review";
		if (status === "QC Requested") return "In Underwriting";
		if (status === "QC Complete") return "In Underwriting";
		if (status === "CTC") return "Clear to Close";
		if (status === "Closing Prep") return "Closing Docs";
		if (status === "Closing") return "Closing";
		if (status === "Funding Prep") return "Wire Sent";
		if (status === "Funding") return "Funded";
		return "NA";
	};

	return (
		<Stack
			direction="column"
			alignItems="flex-start"
			className={styles.cardContainer}
		>
			<Stack direction="column" className={styles.topContainer}>
				<Heading3
					text={`${filterValue(fieldData, "URLA.X73")} ${filterValue(
						fieldData,
						"URLA.X75"
					)} ${filterValue(fieldData, "12")} ${filterValue(fieldData, "14")}`}
					extraClass={styles.addressText}
					fontType="semibold"
				/>

				<Stack
					direction="row"
					className={styles.completeLoanContainer}
					onClick={() =>
						loanData?.loanNumber !== undefined &&
						navigate(`/view-loan/${loanGuid}/${loanData?.loanNumber}`)
					}
				>
					<Body2
						text="Go to Complete Application"
						extraClass={styles.completeLoanLink}
					/>
					<LaunchIcon className={styles.completeLoanIcon} />
				</Stack>
			</Stack>

			<Stack direction="row" className={styles.bottomContainer}>
				<Stack direction="row" className={styles.detailContainer}>
					<Stack direction="column" className={styles.detailDiv}>
						<Body2 text="Loan Amount" fontType="semibold" />
						<Heading4
							text={`$ ${Number(
								loanData?.borrowerRequestedLoanAmount
							)?.toLocaleString()}`}
							extraClass={styles.detailsValue}
							fontType="semibold"
						/>
					</Stack>
					<Box className={styles.dividerContainer}>
						<Divider className={styles.dividerLine} />
					</Box>
				</Stack>

				<Stack direction="row" className={styles.detailContainer}>
					<Stack direction="column" className={styles.detailDiv}>
						<Body2 text="Borrower Name" fontType="semibold" />
						<Heading4
							text={`${filterValue(fieldData, "4000")} ${filterValue(
								fieldData,
								"4001"
							)} ${filterValue(fieldData, "4002")}`}
							extraClass={styles.detailsValue}
							fontType="semibold"
						/>
					</Stack>
					<Box className={styles.dividerContainer}>
						<Divider className={styles.dividerLine} />
					</Box>
				</Stack>
				<Stack direction="row" className={styles.detailContainer}>
					<Stack direction="column" className={styles.detailDiv}>
						<Body2 text="Co Borrower Name" fontType="semibold" />
						<Heading4
							text={`${filterValue(fieldData, "4004")} ${filterValue(
								fieldData,
								"4005"
							)} ${filterValue(fieldData, "4006")}`}
							extraClass={styles.detailsValue}
							fontType="semibold"
						/>
					</Stack>
					<Box className={styles.dividerContainer}>
						<Divider className={styles.dividerLine} />
					</Box>
				</Stack>
				<Stack direction="row" className={styles.detailContainer}>
					<Stack direction="column" className={styles.detailDiv}>
						<Body2 text="Loan Number" fontType="semibold" />
						<Heading4
							text={loanData?.loanNumber}
							extraClass={styles.detailsValue}
							fontType="semibold"
						/>
					</Stack>
					<Box className={styles.dividerContainer}>
						<Divider className={styles.dividerLine} />
					</Box>
				</Stack>
				<Stack direction="row" className={styles.detailContainer}>
					<Stack direction="column" className={styles.detailDiv}>
						<Body2 text="FICO" fontType="semibold" />
						<Heading4
							text={filterValue(fieldData, "VASUMM.X23")}
							extraClass={styles.detailsValue}
							fontType="semibold"
						/>
					</Stack>
					<Box className={styles.dividerContainer}>
						<Divider className={styles.dividerLine} />
					</Box>
				</Stack>
				<Stack direction="row" className={styles.detailContainer}>
					<Stack direction="column" className={styles.detailDiv}>
						<Body2 text="Milestone Status" fontType="semibold" />
						<Heading4
							text={milestoneStatus(
								filterValue(fieldData, "Log.MS.CurrentMilestone")
							)}
							extraClass={styles.detailsValue}
							fontType="semibold"
						/>
					</Stack>
					<Box className={styles.dividerContainer}>
						<Divider className={styles.dividerLine} />
					</Box>
				</Stack>
				<Stack direction="row" className={styles.detailContainer}>
					<Stack direction="column" className={styles.detailDiv}>
						<Body2 text="Rate and Lock" fontType="semibold" />
						<Heading4
							text={`${Number(filterValue(fieldData, "2160")).toFixed(
								3
							)}% ${filterValue(fieldData, "2400")}`}
							extraClass={styles.detailsValue}
							fontType="semibold"
						/>
					</Stack>
				</Stack>
			</Stack>
		</Stack>
	);
};

export default LoanSummaryCard;
