import { createSlice } from "@reduxjs/toolkit";
import { storageSave } from "../../utils/localStorage";

const authSlice = createSlice({
	name: "auth",
	initialState: {
		token: null,
		refresh: null,
		userDetails: null,
		organizationName: null,
		loEmail: null,
		lpEmail: null,
	},
	reducers: {
		setCredentials: (state, action) => {
			const {
				access,
				refresh,
				username,
				email,
				first_name,
				last_name,
				user_roles,
				organization_name,
			} = action.payload;
			state.refresh = refresh;
			state.token = access;
			state.userDetails = {
				username,
				email,
				first_name,
				last_name,
				user_roles,
			};
			state.organizationName = organization_name;
			if (refresh !== undefined) {
				storageSave("access-token", access);
				storageSave("refresh-token", refresh);
			}
		},
		logOut: (state, action) => {
			state.token = null;
			state.refresh = null;
			state.userDetails = null;
			state.organizationName = null;
			state = null;
		},
		setUserDetails: (state, action) => {
			state.userDetails = action.payload;
		},
		setOrganizationName: (state, action) => {
			state.organizationName = action.payload;
		},
		setLoEmail: (state, action) => {
			state.loEmail = action.payload;
		},
		setLpEmail: (state, action) => {
			state.lpEmail = action.payload;
		},
		setAccessToken: (state, action) => {
			state.token = action.payload;
		},
		setRefreshToken: (state, action) => {
			state.refresh = action.payload;
		},
	},
});

export const {
	setCredentials,
	logOut,
	setUserDetails,
	setOrganizationName,
	setLoEmail,
	setLpEmail,
	setAccessToken,
	setRefreshToken,
} = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentToken = (state) => state.auth.token;
export const selectRefreshToken = (state) => state.auth.refresh;
export const selectOrganizationName = (state) => state.organizationName;
export const selectLoEmail = (state) => state.auth.loEmail;
export const selectLpEmail = (state) => state.auth.lpEmail;
export const selectUserDetails = (state) => state.auth.userDetails;
