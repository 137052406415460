import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { utils, writeFileXLSX } from "xlsx";

// mui components
import { Box, Stack } from "@mui/material";

// custom components
import CustomTable from "../../custom-table";
import SingleSelect from "../../custom-select/single-select";
import PrimaryButton from "../../buttons/primary-button";
import SecondaryButton from "../../buttons/secondary-button";
import Heading5 from "../../typography/heading-05";
import Heading3 from "../../typography/heading-03";

// mui icons
import CloseIcon from "@mui/icons-material/Close";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

// api slice
import { useUnderwritingConditionMutation } from "../../../features/underwriting-conditions/underwritingApiSlice";
import { usePreliminaryConditionMutation } from "../../../features/preliminary-conditions/preliminaryApiSlice";
import { usePostClosingConditionMutation } from "../../../features/post-closing-conditions/postClosingApiSlice";

// reducer slice
import { setCategoryCondition } from "../../../features/category-condition/categoryConditonSlice";
import { selectUnderwritingConditions } from "../../../features/underwriting-conditions/underwritingSlice";
import { selectPreliminaryConditions } from "../../../features/preliminary-conditions/preliminarySlice";
import { selectPostClosingConditions } from "../../../features/post-closing-conditions/postClosingSlice";

// redux functions
import { getUnderwritingConditions } from "../../../app/services/underwriting-conditions";
import { getPreliminaryConditions } from "../../../app/services/preliminary-conditions";
import { getPostClosingConditions } from "../../../app/services/post-closing-conditions";

// custom styles
import styles from "./index.module.scss";
import { store } from "../../../app/store";

const ConditionTrackingCard = ({
	toggleDrawer,
	handleTabChange,
	// loading modal
	handleOpenLoadingDataModal,
	handleCloseLoadingDataModal,
	setLoadingDataText,
	// error modal
	handleOpenErrorModal,
	setErrorModalText,
	// custom functions
	setSelectedCondition,
}) => {
	const params = useParams();
	const [conditionCategory, setConditionCategory] = useState("");

	const conditionsOptions = [
		{
			value: "underwriting",
			label: "Underwriting Conditions",
		},
		{
			value: "preliminary",
			label: "Preliminary Conditions",
		},
		{
			value: "post-closing",
			label: "Post Closing Conditions",
		},
	];

	const [underwritingCondition] = useUnderwritingConditionMutation();
	const [preliminaryCondition] = usePreliminaryConditionMutation();
	const [postClosingCondition] = usePostClosingConditionMutation();

	const brokerUploadColumns = [
		{ id: "upload-date", label: "Date", minWidth: 165 },
		{ id: "name", label: "Name", minWidth: 180 },
		{ id: "description", label: "Description", minWidth: 320 },
		{ id: "category", label: "Category", minWidth: 165 },
		{ id: "status", label: "Status", minWidth: 165 },
		{ id: "sub-status", label: "Sub Status", minWidth: 165 },
		{ id: "remedy", label: "Remedy", minWidth: 120, align: "center" },
		{ id: "", label: "", minWidth: 250 },
	];

	const [documentsList, setDocumentsList] = useState([]);

	const handleErrorModal = (data) => {
		console.log("60 data:", data);

		handleCloseLoadingDataModal();
		handleOpenErrorModal();
		setErrorModalText("There was an error getting conditions");
	};

	// get underwriting conditions data from redux store
	const underwritingConditionData = useSelector(selectUnderwritingConditions);
	console.log("100 underwriting:", underwritingConditionData);

	// get preliminary conditions data from redux store
	const preliminaryConditionData = useSelector(selectPreliminaryConditions);
	console.log("105 preliminary:", preliminaryConditionData);

	// get post closing conditions data from redux store
	const postClosingConditionData = useSelector(selectPostClosingConditions);
	console.log("110 post closing:", postClosingConditionData);

	// update state underwriting conditions data from redux store
	useEffect(() => {
		console.log("80 underwriting:", underwritingConditionData);

		if (
			conditionCategory === "underwriting" &&
			underwritingConditionData !== undefined
		) {
			setDocumentsList(underwritingConditionData);
			handleCloseLoadingDataModal();
		}
	}, [underwritingConditionData]);

	// update state preliminary conditions data from redux store
	useEffect(() => {
		console.log("80 preliminary:", preliminaryConditionData);

		if (
			conditionCategory === "preliminary" &&
			preliminaryConditionData !== undefined
		) {
			setDocumentsList(preliminaryConditionData);
			handleCloseLoadingDataModal();
		}
	}, [preliminaryConditionData]);

	// update state post closing conditions data from redux store
	useEffect(() => {
		console.log("80 post closing:", postClosingConditionData);

		if (
			conditionCategory === "post-closing" &&
			postClosingConditionData !== undefined
		) {
			setDocumentsList(postClosingConditionData);
			handleCloseLoadingDataModal();
		}
	}, [postClosingConditionData]);

	// run api and update state based on selected condition category
	useEffect(() => {
		console.log("75 use effect:", params?.id);

		if (conditionCategory === "underwriting") {
			getUnderwritingConditions({
				loanGuid: params?.id,
				apiRequest: underwritingCondition,
				handleErrorModal: handleErrorModal,
			});

			handleOpenLoadingDataModal();
			setLoadingDataText("Setting up the lock info");
		} else if (conditionCategory === "preliminary") {
			getPreliminaryConditions({
				loanGuid: params?.id,
				apiRequest: preliminaryCondition,
				handleErrorModal: handleErrorModal,
			});

			handleOpenLoadingDataModal();
			setLoadingDataText("Setting up the lock info");
		} else if (conditionCategory === "post-closing") {
			getPostClosingConditions({
				loanGuid: params?.id,
				apiRequest: postClosingCondition,
				handleErrorModal: handleErrorModal,
			});

			handleOpenLoadingDataModal();
			setLoadingDataText("Setting up the lock info");
		}
	}, [conditionCategory]);

	// TODO export to excel function
	const handleExportExcelClick = () => {
		// uncomment next section to export
		// const loanData = loans.map(({ loanGuid, fields }) => ({
		// 	loanId: loanGuid,
		// 	loanFolder: fields["Loan.LoanFolder"],
		// 	loanNumber: fields["Loan.LoanNumber"],
		// 	loanAmount: fields["Loan.LoanAmount"],
		// 	borrowerName: fields["Loan.BorrowerName"],
		// 	propertyAddress:
		// 		fields["Fields.URLA.X73"] +
		// 		"," +
		// 		fields["Fields.URLA.X75"] +
		// 		"," +
		// 		fields["Fields.12"] +
		// 		"," +
		// 		fields["Fields.14"],
		// 	loanStatus: fields["Fields.Log.MS.CurrentMilestone"],
		// 	// salesRep: fields[''],
		// 	lockExpirationDate: fields["Fields.762"].slice(0, 10),
		// 	loanProgram: fields["Fields.1401"],
		// }));

		const loanData = "";

		const ws = utils.json_to_sheet(loanData);
		const wb = utils.book_new();
		utils.book_append_sheet(wb, ws, "Data");
		writeFileXLSX(wb, "Exported Excel.xlsx");
	};

	// update condition category drodpown state
	const handleConditionCategory = (event) => {
		setConditionCategory(event.target.value);
		store.dispatch(setCategoryCondition(event.target.value));
	};

	const handleConditionUploadClick = (event, id) => {
		console.log("180 id:", id);

		// Create a copy of the existing array and add the new string
		const newArray = [id];
		// Update the state with the new array
		setSelectedCondition(newArray);

		toggleDrawer(event, "condition-tracking");
	};

	return (
		<Stack direction="column" className={styles.stackContainer}>
			<Stack
				direction="row"
				alignItems="center"
				justifyContent="space-between"
				className={styles.topContainer}
			>
				<Heading3
					text="Condition Tracking"
					extraClass={styles.tabTitle}
					fontType="semibold"
				/>

				<Stack
					direction="row"
					alignItems="center"
					justifyContent="flex-end"
					className={styles.topBtnContainer}
				>
					<SecondaryButton
						text="Close"
						startIcon={<CloseIcon />}
						onClick={(e) => handleTabChange(e, false)}
					/>
					<SecondaryButton
						text="Export to Excel"
						startIcon={<DownloadOutlinedIcon className={styles.downloadIcon} />}
						// TODO export to excel
						onClick={handleExportExcelClick}
						disabled={conditionCategory === ""}
					/>
					<PrimaryButton
						text="Upload Remedy to Multiple Conditions"
						startIcon={<AddOutlinedIcon className={styles.primaryBtnIcon} />}
						onClick={(e) => toggleDrawer(e, "condition-tracking")}
						disabled={conditionCategory === ""}
					/>
				</Stack>
			</Stack>

			<Stack direction="column" className={styles.categoryContainer}>
				<Heading5
					text="Conditions Category"
					fontType="semibold"
					extraClass={styles.categoryHeader}
				/>

				<SingleSelect
					id="condition-category"
					displayEmpty={true}
					value={conditionCategory}
					handleChange={handleConditionCategory}
					options={conditionsOptions}
					extraClass={styles.selectContainer}
				/>
			</Stack>

			{conditionCategory !== "" && (
				<Stack direction="column" className={styles.bottomContainer}>
					{conditionCategory === "post-closing" ? (
						<Stack
							key={Math.floor(Math.random() * 100)}
							direction="column"
							className={styles.conditionTableStack}
						>
							<Box>
								{/* condition tracking post closing table */}
								<CustomTable
									enableSort={true}
									tableType="condition-tracking"
									squaredBorder={true}
									columnData={brokerUploadColumns}
									rowData={documentsList}
									setSortedData={setDocumentsList}
									handleConditionUploadClick={handleConditionUploadClick}
								/>
							</Box>
						</Stack>
					) : (
						<Stack
							key={Math.floor(Math.random() * 100)}
							direction="column"
							className={styles.bottomContainer}
						>
							<Stack direction="column" className={styles.conditionTableStack}>
								<Heading3
									text="Prior To Approval"
									extraClass={styles.approvalHeading}
									fontType="semibold"
								/>

								<Box className={styles.approvalTableContainer}>
									{/* condition tracking approval table */}
									<CustomTable
										enableSort={true}
										tableType="condition-tracking"
										squaredBorder={true}
										columnData={brokerUploadColumns}
										rowData={documentsList?.filter(
											(item) => item?.priorTo?.toLowerCase() === "approval"
										)}
										setSortedData={setDocumentsList}
										handleConditionUploadClick={handleConditionUploadClick}
									/>
								</Box>
							</Stack>

							<Stack direction="column" className={styles.conditionTableStack}>
								<Heading3
									text="Prior To Docs"
									extraClass={styles.docsHeading}
									fontType="semibold"
								/>

								<Box className={styles.docsTableContainer}>
									{/* condition tracking docs table */}
									<CustomTable
										enableSort={true}
										tableType="condition-tracking"
										squaredBorder={true}
										columnData={brokerUploadColumns}
										rowData={documentsList?.filter(
											(item) => item?.priorTo?.toLowerCase() === "docs"
										)}
										setSortedData={setDocumentsList}
										handleConditionUploadClick={handleConditionUploadClick}
									/>
								</Box>
							</Stack>

							<Stack direction="column" className={styles.conditionTableStack}>
								<Heading3
									text="Prior To Funding"
									extraClass={styles.fundingHeading}
									fontType="semibold"
								/>

								<Box className={styles.fundingTableContainer}>
									{/* condition tracking funding table */}
									<CustomTable
										enableSort={true}
										tableType="condition-tracking"
										squaredBorder={true}
										columnData={brokerUploadColumns}
										rowData={documentsList?.filter(
											(item) => item?.priorTo?.toLowerCase() === "funding"
										)}
										setSortedData={setDocumentsList}
										handleConditionUploadClick={handleConditionUploadClick}
									/>
								</Box>
							</Stack>

							<Stack direction="column" className={styles.conditionTableStack}>
								<Heading3
									text="Prior To Closing"
									extraClass={styles.closingHeading}
									fontType="semibold"
								/>

								<Box className={styles.closingTableContainer}>
									{/* condition tracking closing table */}
									<CustomTable
										enableSort={true}
										tableType="condition-tracking"
										squaredBorder={true}
										columnData={brokerUploadColumns}
										rowData={documentsList?.filter(
											(item) => item?.priorTo?.toLowerCase() === "closing"
										)}
										setSortedData={setDocumentsList}
										handleConditionUploadClick={handleConditionUploadClick}
									/>
								</Box>
							</Stack>

							<Stack direction="column" className={styles.conditionTableStack}>
								<Heading3
									text="Prior To Purchase"
									extraClass={styles.purchaseHeading}
									fontType="semibold"
								/>

								<Box className={styles.purchaseTableContainer}>
									{/* condition tracking purchase table */}
									<CustomTable
										enableSort={true}
										tableType="condition-tracking"
										squaredBorder={true}
										columnData={brokerUploadColumns}
										rowData={documentsList?.filter(
											(item) => item?.priorTo?.toLowerCase() === "purchase"
										)}
										setSortedData={setDocumentsList}
										handleConditionUploadClick={handleConditionUploadClick}
									/>
								</Box>
							</Stack>
						</Stack>
					)}
				</Stack>
			)}
		</Stack>
	);
};

export default ConditionTrackingCard;
