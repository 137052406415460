import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// mui components
import { Stack } from "@mui/material";

// mui icons
import CloseIcon from "@mui/icons-material/Close";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

// api slice
import { useDownloadAttachmentMutation } from "../../../features/download-attachment/downloadAttachmentApiSlice";
import { useBrokerUploadsMutation } from "../../../features/broker-uploads/brokerUploadsApiSlice";

// reducer selector
import { selectBrokerUploadedDocs } from "../../../features/broker-uploads/brokerUploadSlice";

// api services
import { getBrokerUploads } from "../../../app/services/broker-upload";

// custom components
import Heading3 from "../../typography/heading-03";
import PrimaryButton from "../../buttons/primary-button";
import SecondaryButton from "../../buttons/secondary-button";
import CustomTable from "../../custom-table";

// custom styles
import styles from "./index.module.scss";

const DocumentsCard = ({
	toggleDrawer,
	handleTabChange,
	handleOpenLoadingDataModal,
	handleCloseLoadingDataModal,
	setLoadingDataText,
}) => {
	useEffect(() => {
		handleOpenLoadingDataModal();
		setLoadingDataText("Setting up the documents list");
	}, []);

	const params = useParams();

	const [downloadAttachment] = useDownloadAttachmentMutation();
	const [brokerUploads] = useBrokerUploadsMutation();

	const brokerUploadedDocs = useSelector(selectBrokerUploadedDocs);

	const brokerUploadColumns = [
		{ id: "label", label: "Document Name", minWidth: 310 },
		{ id: "upload-date", label: "Upload Date", minWidth: 165 },
	];

	const [selectedRow, setSelectedRow] = useState([]);

	console.log("45 selected row:", selectedRow);

	const [documentsList, setDocumentsList] = useState([]);

	useEffect(() => {
		getBrokerUploads({ loanGuid: params?.id, brokerUploads: brokerUploads });
	}, [params]);

	useEffect(() => {
		if (brokerUploadedDocs !== undefined && brokerUploadedDocs?.length > 0) {
			setDocumentsList(brokerUploadedDocs);
			handleCloseLoadingDataModal();
		}
	}, [brokerUploadedDocs]);

	const handleDownloadClick = async () => {
		let attachmendId = selectedRow.toString();

		var formData = new FormData();
		formData.append("loan_guid", params?.id);
		formData.append("attachment_ids", attachmendId);

		try {
			const downloadAttachmentData = await downloadAttachment({
				formData,
			}).unwrap();

			let attachmentArr = downloadAttachmentData?.response?.attachments;

			for (let index = 0; index < attachmentArr.length; index++) {
				window.open(attachmentArr[index]?.originalUrls[0], "_blank").focus();
			}
		} catch (err) {
			console.log("95 err:", err);
		}
	};

	return (
		<Stack direction="column" className={styles.stackContainer}>
			<Stack
				direction="row"
				alignItems="center"
				justifyContent="space-between"
				className={styles.topContainer}
			>
				<Heading3
					text="Broker Uploads"
					extraClass={styles.tabTitle}
					fontType="semibold"
				/>

				<Stack
					direction="row"
					alignItems="center"
					justifyContent="flex-end"
					className={styles.topBtnContainer}
				>
					<SecondaryButton
						text="Close"
						startIcon={<CloseIcon />}
						onClick={(e) => handleTabChange(e, false)}
					/>
					<SecondaryButton
						text="Download"
						startIcon={<DownloadOutlinedIcon className={styles.downloadIcon} />}
						onClick={handleDownloadClick}
						disabled={selectedRow.length === 0}
					/>
					<PrimaryButton
						text="Upload Documents"
						startIcon={<AddOutlinedIcon className={styles.primaryBtnIcon} />}
						onClick={(e) => toggleDrawer(e, "upload-documents")}
						disabled={selectedRow.length > 0}
					/>
				</Stack>
			</Stack>

			<Stack direction="row" className={styles.bottomContainer}>
				{/* lock status table  */}
				<CustomTable
					enableSelect={true}
					columnData={brokerUploadColumns}
					rowData={documentsList}
					tableType="broker-documents"
					setSortedData={setDocumentsList}
					selectedRow={selectedRow}
					setSelectedRow={setSelectedRow}
				/>
			</Stack>
		</Stack>
	);
};

export default DocumentsCard;
