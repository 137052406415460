import { TableCell, TableRow } from "@mui/material";
import React from "react";
import Body2 from "../../typography/body-02";

const LoanStatusRow = ({ row }) => {
	return (
		<TableRow hover tabIndex={-1} key={row.id}>
			<TableCell>
				<Body2 text={row.label} />
			</TableCell>
			<TableCell>
				<Body2 text={row.count} />
			</TableCell>
		</TableRow>
	);
};

export default LoanStatusRow;
