import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// mui components
import { Box, Stack, Tab, Tabs } from "@mui/material";

// api slices
import { useGetBorrowerPairMutation } from "../../../../features/get-borrower-pair/getBorrowerPairApiSlice";
import { useGetLoanSummaryQuery } from "../../../../features/loanSummary/loanSummaryApiSlice";
import { useFieldMutation } from "../../../../features/field-values/fieldValueApiSlice";

// mui icons
import { ArrowBackOutlined, ArrowForwardOutlined } from "@mui/icons-material";

// custom components
import TabPanel from "../../../../components/tab-panel";
import ViewLoanInfoCard from "../info-card";
import ContactInformation from "../../../../components/loan-form-card/contact-information";
import EmploymentInfo from "../../../../components/loan-form-card/employment-information";
import AssetsInformation from "../../../../components/loan-form-card/assets-information";
import LiabilitesInformation from "../../../../components/loan-form-card/liabilities-information";
import RealEstate from "../../../../components/loan-form-card/real-estate";
import DeclarationInfo from "../../../../components/loan-form-card/declaration-information";
import MilitaryInfo from "../../../../components/loan-form-card/military-information";
import DemographicProvidedInfo from "../../../../components/loan-form-card/demographic-provided-information";
import DemographicInfo from "../../../../components/loan-form-card/demographic-information";
import PrimaryButton from "../../../../components/buttons/primary-button";
import SecondaryButton from "../../../../components/buttons/secondary-button";
import Heading6 from "../../../../components/typography/heading-06";

// custom styles
import styles from "./index.module.scss";

export const ViewLoanInfo = ({
	setLoanApplicationStage,
	loanGuid,
	handleOpenLoaderModal,
	handleCloseLoaderModal,
}) => {
	console.log("45 loan id:", loanGuid);

	const params = useParams();
	const loanNumber = params?.loanId;

	const { data: loanData } = useGetLoanSummaryQuery(params?.id);

	const [field, { isLoading: loadingValues }] = useFieldMutation();

	const [data, setData] = useState({ applications: [] });

	useEffect(() => {
		console.log("258 loan data:", loanData);
		setData(loanData?.data);
	}, [loanData]);

	const [propertyInfo, setPropertyInfo] = useState({});

	useEffect(() => {
		setPropertyInfo(data?.property);
	}, [data]);

	const [getBorrowerPair, { isLoading: loadingBorrowerPairData }] =
		useGetBorrowerPairMutation();

	const [borrowerPairData, setBorrowerPairData] = useState([]);

	const [borrowerResidences, setBorrowerResidences] = useState([]);
	const [borrowerFinances, setBorrowerFinances] = useState([]);
	const [borrowerAsset, setBorrowerAsset] = useState([]);
	const [borrowerLiability, setBorrowerLiability] = useState([]);
	const [borrowerRealEstate, setBorrowerRealEstate] = useState([]);

	const [borrowerVesting, setBorrowerVesting] = useState([]);

	const [sameAddress, setSameAddress] = useState([]);

	const [tabValue, setTabValue] = useState(0);

	const [error, setError] = useState("");

	const modelArr = [
		{
			id: "Residence/0",
			applicantType: "Borrower",
			mailingAddressIndicator: false,
			residencyType: "Current",
		},
		{
			id: "Residence/1",
			applicantType: "Borrower",
			mailingAddressIndicator: true,
		},
		{
			id: "Residence/2",
			applicantType: "CoBorrower",
			mailingAddressIndicator: false,
			residencyType: "Current",
		},
		{
			id: "Residence/3",
			applicantType: "CoBorrower",
			mailingAddressIndicator: true,
		},
	];

	const reorderArray = (
		arr,
		borrowerMailingAddress,
		coborrowerMailingAddress,
		borrowerPairIndex
	) => {
		// Separate the objects into two groups: borrowers and co-borrowers
		const borrowers = arr
			.filter((obj) => obj.applicantType === "Borrower")
			.sort((a, b) => {
				const numA = parseInt(a.id.split("Residence/")[1]);
				const numB = parseInt(b.id.split("Residence/")[1]);
				return numA - numB;
			});

		const coBorrowers = arr
			.filter((obj) => obj.applicantType === "CoBorrower")
			.sort((a, b) => {
				const numA = parseInt(a.id.split("Residence/")[1]);
				const numB = parseInt(b.id.split("Residence/")[1]);
				return numA - numB;
			});

		// Sort the residency objects (the first two objects) by residencyType
		const residencyObjects = [borrowers[0], coBorrowers[0]].filter(Boolean);
		residencyObjects.sort((a, b) => {
			const aIndex = a.residencyType === "Current" ? 0 : 1;
			const bIndex = b.residencyType === "Current" ? 0 : 1;
			return aIndex - bIndex;
		});

		// Sort the mailing objects (the next two objects) by mailingAddressIndicator
		const mailingObjects = [borrowers[1], coBorrowers[1]].filter(Boolean);
		mailingObjects.sort((a, b) => {
			const aIndex = a.mailingAddressIndicator ? 1 : 0;
			const bIndex = b.mailingAddressIndicator ? 1 : 0;
			return aIndex - bIndex;
		});

		if (borrowerPairIndex === 0 && borrowerMailingAddress) {
			// borrower at first borrower pair
			setSameAddress((prevArr) => [...prevArr, 0]);
		} else if (borrowerPairIndex === 1 && borrowerMailingAddress) {
			// borrower at second borrower pair
			setSameAddress((prevArr) => [...prevArr, 4]);
		} else if (borrowerPairIndex === 2 && borrowerMailingAddress) {
			// borrower at third borrower pair
			setSameAddress((prevArr) => [...prevArr, 8]);
		}

		if (borrowerPairIndex === 0 && coborrowerMailingAddress) {
			// co-borrower at first borrower pair
			setSameAddress((prevArr) => [...prevArr, 2]);
		} else if (borrowerPairIndex === 1 && coborrowerMailingAddress) {
			// co-borrower at second borrower pair
			setSameAddress((prevArr) => [...prevArr, 6]);
		} else if (borrowerPairIndex === 2 && coborrowerMailingAddress) {
			// co-borrower at third borrower pair
			setSameAddress((prevArr) => [...prevArr, 10]);
		}

		console.log("60 residency:", residencyObjects);
		console.log("60 mailing:", mailingObjects);

		const mergeArrays = (a, b) =>
			(a.length > b.length ? a : b).reduce(
				(acc, cur, i) => (a[i] && b[i] ? [...acc, a[i], b[i]] : [...acc, cur]),
				[]
			);

		// Combine the sorted residency and mailing objects into a new array
		const reorderedArray = mergeArrays(residencyObjects, mailingObjects);

		/* let l = Math.min(residencyObjects.length, mailingObjects.length);
		
			for (i = 0; i < l; i++) {
			reorderedArray.push(residencyObjects[i], mailingObjects[i]);
			} */

		console.log("60 reorder:", reorderedArray);

		// Assign the correct id value to each object based on its index in the new array
		const finalArray = reorderedArray.map((obj, index) => {
			const id = `Residence/${
				obj.mailingAddressIndicator === true && (index % 2 === 1) === false
					? index + 1
					: index
			}`;
			return { ...obj, id };
		});

		console.log("70 finalArray:", finalArray);

		const sortedArr = finalArray.sort((a, b) => {
			const aIndex = parseInt(a.id.split("/")[1]);
			const bIndex = parseInt(b.id.split("/")[1]);
			return aIndex - bIndex;
		});

		console.log("70 sorted:", sortedArr);

		const missingId = modelArr.filter((obj) => {
			return !sortedArr.find((o) => {
				return o.id === obj.id;
			});
		});

		console.log("80 missing:", missingId);

		if (missingId.length > 0) {
			missingId.forEach((missingObj) => {
				const missingIndex = parseInt(missingObj.id.split("/")[1]);
				sortedArr.splice(missingIndex, 0, missingObj);
			});
		}

		console.log("179 sortedArr:", sortedArr);
		return sortedArr;
	};

	const updateField = (fieldValues) => {
		console.log("88 field values:", fieldValues);

		let borrowerVesting = fieldValues?.find(
			(o) => o.fieldId === "IRS4506.X4"
		)?.value;
		let coborrowerVesting = fieldValues?.find(
			(o) => o.fieldId === "Vesting.Trst2TaxID"
		)?.value;

		let vestingArr = {};

		vestingArr.borrowerTaxIdentificationNumberIdentifier1 = borrowerVesting;

		vestingArr.borrowerTaxIdentificationNumberIdentifier2 = coborrowerVesting;

		setBorrowerVesting(vestingArr);
	};

	console.log("370 borrower vesting:", borrowerVesting);

	const getFieldValues = async (loanId) => {
		console.log("175 loan id:", loanId);
		try {
			const getValues = await field({
				loan_guid: loanId,
				fields: "IRS4506.X4,Vesting.Trst2TaxID",
			}).unwrap();
			console.log("175 all fields:", getValues?.response);
			if (getValues?.response?.length > 0) {
				updateField(getValues?.response);
			}
		} catch (err) {
			console.log("584 error:", err);
		}
	};

	useEffect(() => {
		const getBorrowerPairData = async () => {
			const borrowerPairData = await getBorrowerPair({
				loanGuid,
			}).unwrap();

			console.log("190 borrower pair data:", borrowerPairData?.response);

			let residenceArr = [];
			let financeArr = [];
			let assetArr = [];
			let liabilityArr = [];
			let realEstateArr = [];

			for (let index = 0; index < borrowerPairData?.response?.length; index++) {
				console.log(
					"80 borrower res data:",
					borrowerPairData?.response[index]?.residences
				);

				residenceArr.push(
					...reorderArray(
						borrowerPairData?.response[index]?.residences,
						borrowerPairData?.response[index]?.borrower
							?.mailingAddressSameAsPresentIndicator,
						borrowerPairData?.response[index]?.coborrower
							?.mailingAddressSameAsPresentIndicator,
						index
					)
				);

				borrowerPairData?.response[index]?.employment !== undefined
					? financeArr.push(...borrowerPairData?.response[index]?.employment)
					: financeArr.push([]);

				assetArr.push(
					borrowerPairData?.response[index]?.assets !== undefined
						? borrowerPairData?.response[index]?.assets
						: []
				);

				liabilityArr.push(
					borrowerPairData?.response[index]?.liabilities !== undefined
						? borrowerPairData?.response[index]?.liabilities
						: []
				);

				realEstateArr.push(
					borrowerPairData?.response[index]?.reoProperties !== undefined
						? borrowerPairData?.response[index]?.reoProperties
						: []
				);
			}

			setBorrowerResidences(residenceArr);
			setBorrowerFinances(financeArr);
			setBorrowerAsset(assetArr);
			setBorrowerLiability(liabilityArr);
			setBorrowerRealEstate(realEstateArr);

			let pairArr = [];

			borrowerPairData?.response?.map((item) =>
				pairArr.push(item.borrower, item.coborrower)
			);

			setBorrowerPairData(pairArr);
			handleCloseLoaderModal();
		};

		if (loanGuid !== null && loanGuid !== undefined && loanGuid !== "") {
			handleOpenLoaderModal();
			getBorrowerPairData();
			getFieldValues(loanGuid);
		}
	}, [loanGuid]);

	const handlePrev = () => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});
		setLoanApplicationStage(0);
	};

	const handleNext = async () => {
		setError("");
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});
		setLoanApplicationStage(2);
	};

	function a11yProps(index) {
		return {
			id: `simple-tab-${index}`,
			"aria-controls": `simple-tabpanel-${index}`,
		};
	}

	const handleChangeTab = (event, newValue) => {
		setTabValue(newValue);
	};

	return (
		<Stack direction="column" className={styles.stackContainer}>
			<Stack className={styles.bodyContainer}>
				{/* default view no tabs */}
				{borrowerPairData?.length <= 2 && (
					<Box className={styles.tabPanelContainer}>
						<ViewLoanInfoCard
							loanNumber={loanNumber}
							borrower={borrowerPairData[0]}
							coBorrower={borrowerPairData[1]}
							propertyInfo={propertyInfo}
						/>
						{borrowerResidences?.slice(0, 4)?.map((residence, index) => (
							<ContactInformation
								key={index}
								residenceIndex={index}
								coBorrower={index >= 2}
								residenceData={residence}
								data={borrowerResidences}
								setData={setBorrowerResidences}
								addressType={
									residence?.residencyType === "Current" ? "current" : "mail"
								}
								sameAddress={sameAddress}
								setSameAddress={setSameAddress}
								disabled={true}
							/>
						))}

						{borrowerFinances?.slice(0, 2)?.map((finance, index) => (
							<EmploymentInfo
								key={index}
								financeIndex={index}
								coBorrower={index % 2 !== 0}
								financeData={finance}
								data={borrowerFinances}
								setData={setBorrowerFinances}
								vestingData={borrowerVesting}
								setVestingData={setBorrowerVesting}
								error={error.includes("provide a value")}
								disabled={true}
								viewLoan={true}
							/>
						))}

						{/* assets */}
						{borrowerAsset?.slice(0, 1)?.map((asset, index) => (
							<AssetsInformation
								key={index}
								assetIndex={index}
								assetData={asset}
								data={borrowerAsset}
								setData={setBorrowerAsset}
								disabled={true}
							/>
						))}

						{/* liabilities */}
						{borrowerLiability?.slice(0, 1)?.map((liability, index) => (
							<LiabilitesInformation
								key={index}
								liabilityIndex={index}
								liabilityData={liability}
								data={borrowerLiability}
								setData={setBorrowerLiability}
								disabled={true}
							/>
						))}

						{/* real estate */}
						{borrowerRealEstate?.slice(0, 1)?.map((estate, index) => (
							<RealEstate
								key={index}
								estateIndex={index}
								estateData={estate}
								data={borrowerRealEstate}
								setData={setBorrowerRealEstate}
								liabilityCount={borrowerLiability?.length}
								disabled={true}
							/>
						))}

						{/* declaration info */}
						{borrowerPairData
							?.slice(0, 2)
							?.map(
								(declaration, index) =>
									index === borrowerPairData?.slice(0, 2)?.length - 1 && (
										<DeclarationInfo
											key={index}
											declarationIndex={index}
											coBorrower={index % 2 !== 0}
											declarationData={declaration}
											data={borrowerPairData}
											setData={setBorrowerPairData}
											disabled={true}
										/>
									)
							)}

						{/* military */}
						{borrowerPairData?.slice(0, 2)?.map((military, index) => (
							<MilitaryInfo
								key={index}
								militaryIndex={index}
								coBorrower={index % 2 !== 0}
								militaryData={military}
								data={borrowerPairData}
								setData={setBorrowerPairData}
								error={error.includes("provide a value")}
								disabled={true}
								viewLoan={true}
							/>
						))}

						{/* demographic provided info */}
						{borrowerPairData?.slice(0, 2)?.map((demographic, index) => (
							<DemographicProvidedInfo
								key={index}
								demographicIndex={index}
								coBorrower={index % 2 !== 0}
								demographicData={demographic}
								data={borrowerPairData}
								setData={setBorrowerPairData}
								error={error.includes("provide a value")}
								disabled={true}
							/>
						))}

						{/* demographic info */}
						{borrowerPairData?.slice(0, 2)?.map((demographic, index) => (
							<DemographicInfo
								key={index}
								demographicIndex={index}
								coBorrower={index % 2 !== 0}
								demographicData={demographic}
								data={borrowerPairData}
								setData={setBorrowerPairData}
								error={error.includes("provide a value")}
								disabled={true}
								viewLoan={true}
							/>
						))}
					</Box>
				)}

				{/* tabs section */}
				{borrowerPairData?.length > 2 && (
					<Tabs
						value={tabValue}
						onChange={handleChangeTab}
						aria-label="borrower tabs"
						TabIndicatorProps={{
							style: {
								backgroundColor: "#76D99E",
							},
						}}
					>
						{borrowerPairData?.length >= 3 && (
							<Tab
								className={styles.tabBtnContainer}
								label={
									<Stack direction="row" className={styles.tabLabelContainer}>
										<Heading6
											text="Borrower Pair 1"
											fontType="semibold"
											extraClass={
												tabValue === 0 ? styles.activeTabText : styles.tabText
											}
										/>
									</Stack>
								}
								{...a11yProps(0)}
							/>
						)}
						{borrowerPairData?.length >= 4 && (
							<Tab
								className={styles.tabBtnContainer}
								label={
									<Stack direction="row" className={styles.tabLabelContainer}>
										<Heading6
											text="Borrower Pair 2"
											fontType="semibold"
											extraClass={
												tabValue === 1 ? styles.activeTabText : styles.tabText
											}
										/>
									</Stack>
								}
								{...a11yProps(1)}
							/>
						)}
						{borrowerPairData?.length >= 6 && (
							<Tab
								className={styles.tabBtnContainer}
								label={
									<Stack direction="row" className={styles.tabLabelContainer}>
										<Heading6
											text="Borrower Pair 3"
											fontType="semibold"
											extraClass={
												tabValue === 2 ? styles.activeTabText : styles.tabText
											}
										/>
									</Stack>
								}
								{...a11yProps(2)}
							/>
						)}
					</Tabs>
				)}

				{/* borrower pair 1 */}
				{borrowerPairData?.length >= 3 && (
					<TabPanel
						value={tabValue}
						index={0}
						className={tabValue === 0 ? styles.tabPanelContainer : ""}
					>
						<ViewLoanInfoCard
							loanNumber={loanNumber}
							borrower={borrowerPairData[0]}
							coBorrower={borrowerPairData[1]}
							propertyInfo={propertyInfo}
						/>
						{borrowerResidences?.slice(0, 4)?.map((residence, index) => (
							<ContactInformation
								key={index}
								residenceIndex={index}
								coBorrower={index >= 2}
								residenceData={residence}
								data={borrowerResidences}
								setData={setBorrowerResidences}
								addressType={
									residence?.residencyType === "Current" ? "current" : "mail"
								}
								sameAddress={sameAddress}
								setSameAddress={setSameAddress}
								disabled={true}
							/>
						))}

						{borrowerFinances?.slice(0, 2)?.map((finance, index) => (
							<EmploymentInfo
								key={index}
								financeIndex={index}
								coBorrower={index % 2 !== 0}
								financeData={finance}
								data={borrowerFinances}
								setData={setBorrowerFinances}
								vestingData={borrowerVesting}
								setVestingData={setBorrowerVesting}
								error={error.includes("provide a value")}
								disabled={true}
								viewLoan={true}
							/>
						))}

						{/* assets */}
						{borrowerAsset?.slice(0, 1)?.map((asset, index) => (
							<AssetsInformation
								key={index}
								assetIndex={index}
								assetData={asset}
								data={borrowerAsset}
								setData={setBorrowerAsset}
								disabled={true}
							/>
						))}

						{/* liabilities */}
						{borrowerLiability?.slice(0, 1)?.map((liability, index) => (
							<LiabilitesInformation
								key={index}
								liabilityIndex={index}
								liabilityData={liability}
								data={borrowerLiability}
								setData={setBorrowerLiability}
								disabled={true}
							/>
						))}

						{/* real estate */}
						{borrowerRealEstate?.slice(0, 1)?.map((estate, index) => (
							<RealEstate
								key={index}
								estateIndex={index}
								estateData={estate}
								data={borrowerRealEstate}
								setData={setBorrowerRealEstate}
								liabilityCount={borrowerLiability?.length}
								disabled={true}
							/>
						))}

						{/* declaration info */}
						{borrowerPairData
							?.slice(0, 2)
							?.map(
								(declaration, index) =>
									index === borrowerPairData?.slice(0, 2)?.length - 1 && (
										<DeclarationInfo
											key={index}
											declarationIndex={index}
											coBorrower={index % 2 !== 0}
											declarationData={declaration}
											data={borrowerPairData}
											setData={setBorrowerPairData}
											disabled={true}
										/>
									)
							)}

						{/* military */}
						{borrowerPairData?.slice(0, 2)?.map((military, index) => (
							<MilitaryInfo
								key={index}
								militaryIndex={index}
								coBorrower={index % 2 !== 0}
								militaryData={military}
								data={borrowerPairData}
								setData={setBorrowerPairData}
								error={error.includes("provide a value")}
								disabled={true}
								viewLoan={true}
							/>
						))}

						{/* demographic provided info */}
						{borrowerPairData?.slice(0, 2)?.map((demographic, index) => (
							<DemographicProvidedInfo
								key={index}
								demographicIndex={index}
								coBorrower={index % 2 !== 0}
								demographicData={demographic}
								data={borrowerPairData}
								setData={setBorrowerPairData}
								error={error.includes("provide a value")}
								disabled={true}
							/>
						))}

						{/* demographic info */}
						{borrowerPairData?.slice(0, 2)?.map((demographic, index) => (
							<DemographicInfo
								key={index}
								demographicIndex={index}
								coBorrower={index % 2 !== 0}
								demographicData={demographic}
								data={borrowerPairData}
								setData={setBorrowerPairData}
								error={error.includes("provide a value")}
								disabled={true}
								viewLoan={true}
							/>
						))}
					</TabPanel>
				)}

				{/* borrower pair 2 */}
				{borrowerPairData?.length >= 4 && (
					<TabPanel
						value={tabValue}
						index={1}
						className={tabValue === 1 ? styles.tabPanelContainer : ""}
					>
						<ViewLoanInfoCard
							loanNumber={loanNumber}
							borrower={borrowerPairData[0]}
							coBorrower={borrowerPairData[1]}
							propertyInfo={propertyInfo}
						/>
						{borrowerResidences?.slice(4, 8)?.map((residence, index) => (
							<ContactInformation
								key={index}
								residenceIndex={index + 4}
								coBorrower={index >= 2}
								residenceData={residence}
								data={borrowerResidences}
								setData={setBorrowerResidences}
								addressType={
									residence?.residencyType === "Current" ? "current" : "mail"
								}
								sameAddress={sameAddress}
								setSameAddress={setSameAddress}
								disabled={true}
							/>
						))}

						{borrowerFinances?.slice(2, 4)?.map((finance, index) => (
							<EmploymentInfo
								key={index}
								financeIndex={index + 2}
								coBorrower={index % 2 !== 0}
								financeData={finance}
								data={borrowerFinances}
								setData={setBorrowerFinances}
								vestingData={borrowerVesting}
								setVestingData={setBorrowerVesting}
								error={error.includes("provide a value")}
								disabled={true}
								viewLoan={true}
							/>
						))}

						{/* assets */}
						{borrowerAsset?.slice(1, 2)?.map((asset, index) => (
							<AssetsInformation
								key={index}
								assetIndex={index + 1}
								assetData={asset}
								data={borrowerAsset}
								setData={setBorrowerAsset}
								disabled={true}
							/>
						))}

						{/* liabilities */}
						{borrowerLiability?.slice(1, 2)?.map((liability, index) => (
							<LiabilitesInformation
								key={index}
								liabilityIndex={index + 1}
								liabilityData={liability}
								data={borrowerLiability}
								setData={setBorrowerLiability}
								disabled={true}
							/>
						))}

						{/* real estate */}
						{borrowerRealEstate?.slice(1, 2)?.map((estate, index) => (
							<RealEstate
								key={index}
								estateIndex={index + 1}
								estateData={estate}
								data={borrowerRealEstate}
								setData={setBorrowerRealEstate}
								liabilityCount={borrowerLiability?.length}
								disabled={true}
							/>
						))}

						{/* declaration info */}
						{borrowerPairData
							?.slice(2, 4)
							?.map(
								(declaration, index) =>
									index === borrowerPairData?.slice(2, 4)?.length - 1 && (
										<DeclarationInfo
											key={index}
											declarationIndex={index + 2}
											coBorrower={index % 2 !== 0}
											declarationData={declaration}
											data={borrowerPairData}
											setData={setBorrowerPairData}
											disabled={true}
										/>
									)
							)}

						{/* military */}
						{borrowerPairData?.slice(2, 4)?.map((military, index) => (
							<MilitaryInfo
								key={index}
								militaryIndex={index + 2}
								coBorrower={index % 2 !== 0}
								militaryData={military}
								data={borrowerPairData}
								setData={setBorrowerPairData}
								error={error.includes("provide a value")}
								disabled={true}
								viewLoan={true}
							/>
						))}

						{/* demographic provided info */}
						{borrowerPairData?.slice(2, 4)?.map((demographic, index) => (
							<DemographicProvidedInfo
								key={index}
								demographicIndex={index + 2}
								coBorrower={index % 2 !== 0}
								demographicData={demographic}
								data={borrowerPairData}
								setData={setBorrowerPairData}
								error={error.includes("provide a value")}
								disabled={true}
							/>
						))}

						{/* demographic info */}
						{borrowerPairData?.slice(2, 4)?.map((demographic, index) => (
							<DemographicInfo
								key={index}
								demographicIndex={index + 2}
								coBorrower={index % 2 !== 0}
								demographicData={demographic}
								data={borrowerPairData}
								setData={setBorrowerPairData}
								error={error.includes("provide a value")}
								disabled={true}
								viewLoan={true}
							/>
						))}
					</TabPanel>
				)}

				{/* borrower pair 3 */}
				{borrowerPairData?.length >= 6 && (
					<TabPanel
						value={tabValue}
						index={2}
						className={tabValue === 2 ? styles.tabPanelContainer : ""}
					>
						<ViewLoanInfoCard
							loanNumber={loanNumber}
							borrower={borrowerPairData[0]}
							coBorrower={borrowerPairData[1]}
							propertyInfo={propertyInfo}
						/>
						{borrowerResidences?.slice(8, 12)?.map((residence, index) => (
							<ContactInformation
								key={index}
								residenceIndex={index + 8}
								coBorrower={index >= 2}
								residenceData={residence}
								data={borrowerResidences}
								setData={setBorrowerResidences}
								addressType={
									residence?.residencyType === "Current" ? "current" : "mail"
								}
								sameAddress={sameAddress}
								setSameAddress={setSameAddress}
								disabled={true}
							/>
						))}

						{borrowerFinances?.slice(4, 6)?.map((finance, index) => (
							<EmploymentInfo
								key={index}
								financeIndex={index + 4}
								coBorrower={index % 2 !== 0}
								financeData={finance}
								data={borrowerFinances}
								setData={setBorrowerFinances}
								vestingData={borrowerVesting}
								setVestingData={setBorrowerVesting}
								error={error.includes("provide a value")}
								disabled={true}
								viewLoan={true}
							/>
						))}

						{/* assets */}
						{borrowerAsset?.slice(2, 3)?.map((asset, index) => (
							<AssetsInformation
								key={index}
								assetIndex={index + 2}
								assetData={asset}
								data={borrowerAsset}
								setData={setBorrowerAsset}
								disabled={true}
							/>
						))}

						{/* liabilities */}
						{borrowerLiability?.slice(2, 3)?.map((liability, index) => (
							<LiabilitesInformation
								key={index}
								liabilityIndex={index + 2}
								liabilityData={liability}
								data={borrowerLiability}
								setData={setBorrowerLiability}
								disabled={true}
							/>
						))}

						{/* real estate */}
						{borrowerRealEstate?.slice(2, 3)?.map((estate, index) => (
							<RealEstate
								key={index}
								estateIndex={index + 2}
								estateData={estate}
								data={borrowerRealEstate}
								setData={setBorrowerRealEstate}
								liabilityCount={borrowerLiability?.length}
								disabled={true}
							/>
						))}

						{/* declaration info */}
						{borrowerPairData
							?.slice(4, 6)
							?.map(
								(declaration, index) =>
									index === borrowerPairData?.slice(4, 6)?.length - 1 && (
										<DeclarationInfo
											key={index}
											declarationIndex={index + 4}
											coBorrower={index % 2 !== 0}
											declarationData={declaration}
											data={borrowerPairData}
											setData={setBorrowerPairData}
											disabled={true}
										/>
									)
							)}

						{/* military */}
						{borrowerPairData?.slice(4, 6)?.map((military, index) => (
							<MilitaryInfo
								key={index}
								militaryIndex={index + 4}
								coBorrower={index % 2 !== 0}
								militaryData={military}
								data={borrowerPairData}
								setData={setBorrowerPairData}
								error={error.includes("provide a value")}
								disabled={true}
								viewLoan={true}
							/>
						))}

						{/* demographic provided info */}
						{borrowerPairData?.slice(4, 6)?.map((demographic, index) => (
							<DemographicProvidedInfo
								key={index}
								demographicIndex={index + 4}
								coBorrower={index % 2 !== 0}
								demographicData={demographic}
								data={borrowerPairData}
								setData={setBorrowerPairData}
								error={error.includes("provide a value")}
								disabled={true}
							/>
						))}

						{/* demographic info */}
						{borrowerPairData?.slice(4, 6)?.map((demographic, index) => (
							<DemographicInfo
								key={index}
								demographicIndex={index + 4}
								coBorrower={index % 2 !== 0}
								demographicData={demographic}
								data={borrowerPairData}
								setData={setBorrowerPairData}
								error={error.includes("provide a value")}
								disabled={true}
								viewLoan={true}
							/>
						))}
					</TabPanel>
				)}

				<Stack direction="row" className={styles.footerContainer}>
					<SecondaryButton
						text="Previous"
						startIcon={<ArrowBackOutlined className={styles.prevIcon} />}
						onClick={handlePrev}
					/>
					<PrimaryButton
						text="Continue"
						endIcon={<ArrowForwardOutlined className={styles.nextIcon} />}
						onClick={handleNext}
					/>
				</Stack>
			</Stack>
		</Stack>
	);
};

export default ViewLoanInfo;
