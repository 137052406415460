import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// mui components
import { Box, Stack } from "@mui/material";

// custom components
import CustomLayout from "../../components/layout";
import LoanProgress from "../../components/loan-progress";
import LoanApplicationForm from "../../components/loan-form/create-xml-loan-application";
import EditLoanApplication from "../../components/loan-form/edit-loan-application";
import UpdateLoanApplication from "../../components/loan-form/update-loan-application";
import LoanPricingApplication from "../../components/loan-form/create-loan-pricing";
import ShowMyFees from "../../components/loan-form/create-loan-fee";
import LoanDocuments from "../../components/loan-form/create-loan-documents";
import SupportingDocs from "../../components/supporting-docs";
import CustomDivider from "../../components/custom-divider";
import PrimaryButton from "../../components/buttons/primary-button";
import SecondaryButton from "../../components/buttons/secondary-button";
import Heading5 from "../../components/typography/heading-05";
import Body3 from "../../components/typography/body-03";
import Body2 from "../../components/typography/body-02";

// helper utils
import { storageLoad } from "../../utils/localStorage";
import { formatBytes } from "../../utils/format-bytes";

// api slices
import { useConvertMismoMutation } from "../../features/convert-mismo/convertMismoApiSlice";
import { useLoanMutation } from "../../features/loan/loanApiSlice";
import { useUserDetailsMutation } from "../../features/user-details/userDetailsApiSlice";

// reducer slices
import { setFileReducer } from "../../features/loan/loanSlice";
import {
	selectCurrentToken,
	selectRefreshToken,
	selectUserDetails,
	setUserDetails,
} from "../../features/auth/authSlice";
import { setOrganizationNameReducer } from "../../features/organization-detail/organizationDetailsUserSlice";
import { selectOrganizationName } from "../../features/organization-detail/organizationDetailsUserSlice";
import { selectImpersonatedOrgName } from "../../features/admin/impersonated-organization-name/impersonatedOrganizationNameSlice";

// custom modal
import LoaderModal from "../../components/modal/loader-modal";
import ProcessingModal from "../../components/modal/processing-modal";
import ConfirmationModal from "../../components/modal/confirmation-modal";
import SuccessModal from "../../components/modal/success-modal";

// mui icons
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import UploadOutlined from "@mui/icons-material/UploadOutlined";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import CloseIcon from "@mui/icons-material/Close";
import { CloseOutlined } from "@mui/icons-material";

// custom styles
import styles from "./index.module.scss";

const fileTypes = ["XML"];

export default function NewLoan() {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const loanSteps = [
		"Borrower Information",
		"Additional Borrower Information",
		"Loan Product and Terms",
		"Fees",
		"Supporting Documents",
	];

	const userDetails = useSelector(selectUserDetails);

	const [userDetailsAPI] = useUserDetailsMutation();
	const [loan, { isLoading }] = useLoanMutation();
	const [convertMismo] = useConvertMismoMutation();

	const [confirmModalText, setConfirmModalText] = useState("");
	const [confirmModalVisible, setConfirmModalVisible] = useState(false);

	const handleOpenConfirmModal = () => {
		setConfirmModalVisible(true);
	};

	const handleCloseConfirmModal = () => {
		setConfirmModalVisible(false);
	};

	const handleSubmitConfirmModal = () => {
		navigate("/admin");
	};

	const userOrganizationName = useSelector(selectOrganizationName);
	const impersonatedOrganizationName = useSelector(selectImpersonatedOrgName);

	const [organizationName, setOrganizationName] = useState("");

	useEffect(() => {
		console.log("130 userOrganizationName:", userOrganizationName);
		console.log(
			"130 impersonatedOrganizationName:",
			impersonatedOrganizationName
		);

		if (userDetails?.user_roles?.includes("admin")) {
			setOrganizationName(impersonatedOrganizationName);
		} else {
			setOrganizationName(userOrganizationName);
		}
	}, [userOrganizationName, impersonatedOrganizationName]);

	useEffect(() => {
		// check if admin signed in as another user has an organization
		console.log("150 user roles:", userDetails?.user_roles);
		console.log("150 organization name:", organizationName);

		if (
			userDetails?.user_roles?.includes("admin") &&
			(impersonatedOrganizationName === undefined ||
				impersonatedOrganizationName === null ||
				impersonatedOrganizationName === "")
		) {
			console.log("140 No organization");
			setConfirmModalText("Sign in as a broker to gain access.");
			handleOpenConfirmModal();
		}
	}, [impersonatedOrganizationName, userDetails]);

	const [file, setFile] = useState("");

	const [fileUploadModal, setFileUploadModal] = useState(false);

	const [loaderModal, setLoaderModal] = useState(false);

	const handleOpenLoaderModal = () => {
		setLoaderModal(true);
	};

	const handleCloseLoaderModal = () => {
		setLoaderModal(false);
	};

	const [xmlUploaded, setXmlUploaded] = useState(false);
	const [xmlSubmitted, setXmlSubmitted] = useState(false);

	const [xmlType, setXmlType] = useState("");

	const [fileUploadedModal, setFileUploadedModal] = useState(false);
	const [loanData, setLoanData] = useState({});

	const [loanGuid, setLoanGuid] = useState("");

	const [loanApplicationStage, setLoanApplicationStage] = useState(0);

	const token = useSelector(selectCurrentToken);
	const refreshToken = useSelector(selectRefreshToken);
	const localAccessToken = storageLoad("access-token");
	const localRefreshToken = storageLoad("refresh-token");

	const getUserDetails = async () => {
		const userDetailData = await userDetailsAPI().unwrap();
		dispatch(setUserDetails({ ...userDetailData?.data }));
		dispatch(
			setOrganizationNameReducer(userDetailData?.data?.organization_name)
		);
	};

	useEffect(() => {
		if (token || refreshToken || localAccessToken || localRefreshToken) {
			getUserDetails();
		}
	}, []);

	const handleOpenFileUploadModal = () => {
		setFileUploadedModal(true);
	};

	const handleCloseFileUploadModal = () => {
		setFileUploadedModal(false);
		setXmlSubmitted(true);
	};

	const showUploadFile = (xmlType) => {
		// clear previous file
		setXmlUploaded(false);
		setFile("");
		dispatch(setFileReducer({}));

		// set new type
		setXmlType(xmlType);
	};

	const finalCase = () => {
		setLoanData({});
		navigate("/create-loan");
	};

	const handleFileUpload = async (file) => {
		setFileUploadModal(true);
		setFile(file);
		console.log("87 file:", file);

		var formData = new FormData();
		var xmlFile = file;
		formData.append("file", xmlFile);

		// Display the key/value pairs
		for (var pair of formData.entries()) {
			console.log(pair[0] + ", " + pair[1]);
		}

		try {
			const fileUpload = await loan({ formData }).unwrap();
			console.log("90 uploaded file data:", fileUpload);
			// uploaded successfully
			dispatch(setFileReducer(fileUpload));
			setXmlUploaded(true);
			setLoanData(fileUpload?.Data);
			setFileUploadModal(false);
		} catch (err) {
			console.log("95 err:", err);
			setFileUploadModal(false);
			if (!err?.originalStatus) {
				// isLoading: true until timeout occurs
				// setError("No Server Response");
			} else if (err.originalStatus === 400) {
				// setError("Missing Username or Password");
			} else if (err.originalStatus === 401) {
				// setError("Unauthorized");
			} else {
				// setError("File upload failed");
			}
		}
	};

	const handleUploadCalyx = async (file) => {
		setFileUploadModal(true);
		setFile(file);
		console.log("125 file:", file);

		var formData = new FormData();
		var xmlFile = file;
		formData.append("Calyx_XML", xmlFile);

		// Display the key/value pairs
		for (var pair of formData.entries()) {
			console.log(pair[0] + ", " + pair[1]);
		}

		try {
			const fileUpload = await convertMismo({ formData }).unwrap();
			console.log("140 uploaded file data:", fileUpload);
			// uploaded successfully
			dispatch(setFileReducer(fileUpload));
			setXmlUploaded(true);
			setLoanData(fileUpload?.data);
			setFileUploadModal(false);
		} catch (err) {
			console.log("150 err:", err);
			setFileUploadModal(false);
			if (!err?.originalStatus) {
				// isLoading: true until timeout occurs
				// setError("No Server Response");
			} else if (err.originalStatus === 400) {
				// setError("Missing Username or Password");
			} else if (err.originalStatus === 401) {
				// setError("Unauthorized");
			} else {
				// setError("File upload failed");
			}
		}
	};

	const deleteFile = (event) => {
		event.stopPropagation();
		event.preventDefault();

		setXmlUploaded(false);
		setFile("");
		dispatch(setFileReducer({}));

		console.log("deelte file");
	};

	const handleConfirmExit = () => {
		navigate(`/home`);
	};

	const breadcrumbData = [
		{
			label: "Home",
			path: "/home",
		},
		{
			label: "New Loan",
			path: "",
		},
	];

	return (
		<Box>
			<CustomLayout breadcrumb={breadcrumbData} disabledOverflow={true}>
				<Stack
					direction="row"
					className={styles.stackContainer}
					sx={{
						overflow: "hidden",
					}}
				>
					{/* loan status */}
					<Stack
						direction={xmlSubmitted ? "row" : "column"}
						className={
							xmlSubmitted
								? styles.bodyContainer
								: styles.bodyContainer + " " + styles.bodySpacing
						}
					>
						{!xmlSubmitted && <Heading5 text="New Loan" fontType="semibold" />}

						{!xmlSubmitted && (
							<Stack
								direction="row"
								className={styles.applicationTypeContainer}
							>
								<Stack
									direction="column"
									className={styles.applicationTypeHeader}
								>
									<Heading5 text="Application Type" fontType="semibold" />
									<Body3 text="How would you like to fill the application?" />
								</Stack>

								<Stack direction="row" className={styles.btnContainer}>
									<SecondaryButton
										startIcon={<UploadOutlined sx={{ fontSize: "20px" }} />}
										text="Upload 3.4 XML"
										extraClass={
											xmlType === "ULAD/ILAD MISMO"
												? styles.selectedApplicationTypeBtn
												: styles.applicationTypeBtn
										}
										onClick={() => {
											showUploadFile("ULAD/ILAD MISMO");
										}}
									/>
									<SecondaryButton
										startIcon={<UploadOutlined sx={{ fontSize: "20px" }} />}
										text="Upload Calyx XML"
										extraClass={
											xmlType === "CALYX MISMO"
												? styles.selectedApplicationTypeBtn
												: styles.applicationTypeBtn
										}
										onClick={() => {
											showUploadFile("CALYX MISMO");
										}}
									/>

									<SecondaryButton
										startIcon={<EditOutlinedIcon sx={{ fontSize: "20px" }} />}
										text="Fill Application Manually"
										extraClass={styles.applicationTypeBtn}
										onClick={finalCase}
									/>
								</Stack>
							</Stack>
						)}

						{!xmlSubmitted && xmlType !== "" && (
							<CustomDivider extraClass={styles.dividerContainer} />
						)}

						{!xmlSubmitted && xmlType !== "" && (
							<SupportingDocs
								fileTypes={fileTypes}
								text={`or drag to upload ${
									xmlType === "ULAD/ILAD MISMO" ? "3.4 XML" : "Calyx XML"
								} file`}
								handleFileAttach={
									xmlType === "ULAD/ILAD MISMO"
										? handleFileUpload
										: handleUploadCalyx
								}
								multiple={false}
							/>
						)}

						{/* xml uploaded but not submitted */}
						{xmlUploaded && !xmlSubmitted && (
							<Stack
								direction="column"
								className={styles.uploadedFileContainer}
							>
								<Stack direction="row" className={styles.fileListContainer}>
									<Box className={styles.fileIconContainer}>
										<UploadFileIcon className={styles.fileIcon} />
									</Box>
									<Stack
										direction="column"
										className={styles.fileNameContainer}
									>
										<Body2 text={file?.name} />
										<Body3 text={formatBytes(file?.size)} />
									</Stack>

									<Box
										sx={{ cursor: "pointer" }}
										onClick={(event) => deleteFile(event)}
										className={styles.deleteIconContainer}
									>
										<CloseIcon className={styles.deleteIcon} />
									</Box>
								</Stack>

								<Stack direction="row" className={styles.footerBtnContainer}>
									<SecondaryButton
										text="Cancel"
										onClick={(event) => deleteFile(event)}
									/>
									<PrimaryButton
										text={
											xmlType === "CALYX MISMO" ? "Submit" : "Submit Request"
										}
										onClick={handleOpenFileUploadModal}
									/>
								</Stack>
							</Stack>
						)}

						{/* xml submitted */}
						{xmlSubmitted && (
							<Stack direction="column" className={styles.loanContainer}>
								{/* page title and close btn container */}
								<Box direction="row" className={styles.headerContainer}>
									<Stack className={styles.headerTextContainer}>
										<Heading5
											text={`Step ${
												loanApplicationStage === 0
													? loanApplicationStage + 1
													: loanApplicationStage
											}: ${
												loanSteps[
													loanApplicationStage === 0
														? loanApplicationStage
														: loanApplicationStage - 1
												]
											}`}
											fontType="semibold"
											className={styles.headerText}
										/>
									</Stack>

									<Stack
										className={styles.closeIconContainer}
										onClick={() => {
											setConfirmModalText("Are you sure you want to exit?");
											handleOpenConfirmModal();
										}}
									>
										<CloseOutlined className={styles.closeIcon} />
									</Stack>
								</Box>
								{loanApplicationStage === 0 ? (
									<LoanApplicationForm
										setLoanApplicationStage={setLoanApplicationStage}
										loanGuid={loanGuid}
										setLoanGuid={setLoanGuid}
										xmlType={xmlType}
										applicationData={loanData}
										setApplicationData={setLoanData}
										handleOpenLoaderModal={handleOpenLoaderModal}
										handleCloseLoaderModal={handleCloseLoaderModal}
									/>
								) : loanApplicationStage === 1 ? (
									<EditLoanApplication
										setLoanApplicationStage={setLoanApplicationStage}
										applicationData={loanData}
										setApplicationData={setLoanData}
										loanGuid={loanGuid}
										setLoanGuid={setLoanGuid}
									/>
								) : loanApplicationStage === 2 ? (
									<UpdateLoanApplication
										setLoanApplicationStage={setLoanApplicationStage}
										loanGuid={loanGuid}
									/>
								) : loanApplicationStage === 3 ? (
									<LoanPricingApplication
										data={loanData}
										setLoanApplicationStage={setLoanApplicationStage}
										loanGuid={loanGuid}
									/>
								) : loanApplicationStage === 4 ? (
									<ShowMyFees
										setLoanApplicationStage={setLoanApplicationStage}
										loanGuid={loanGuid}
									/>
								) : (
									loanApplicationStage === 5 && (
										<LoanDocuments
											setLoanApplicationStage={setLoanApplicationStage}
											loanGuid={loanGuid}
										/>
									)
								)}
							</Stack>
						)}

						{/* application progress */}
						{xmlSubmitted && (
							<LoanProgress
								loanSteps={loanSteps}
								currentStep={
									loanApplicationStage === 0
										? loanApplicationStage
										: loanApplicationStage - 1
								}
							/>
						)}
					</Stack>
				</Stack>
			</CustomLayout>

			{/* modal */}
			<LoaderModal open={loaderModal} />
			<ProcessingModal
				open={fileUploadModal}
				text="Your file is being uploaded and processed"
			/>
			<SuccessModal
				open={fileUploadedModal}
				handleClose={handleCloseFileUploadModal}
				text={`Your ${
					xmlType === "CALYX MISMO" ? "CALYX POINT XML" : "3.4"
				} file has been accepted. Review and submit the
				application.`}
				confirmText={"Review"}
			/>
			<ConfirmationModal
				open={confirmModalVisible}
				text={confirmModalText}
				confirmText={
					confirmModalText?.includes("exit") ? "Exit Application" : "Sign In"
				}
				handleConfirm={
					confirmModalText?.includes("exit")
						? handleConfirmExit
						: handleSubmitConfirmModal
				}
				closeText={
					confirmModalText?.includes("exit") ? "Return to Application" : "Close"
				}
				handleClose={handleCloseConfirmModal}
			/>
		</Box>
	);
}
