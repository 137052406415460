import React from "react";
import { Button } from "@mui/material";

import Heading5 from "../../typography/heading-05";

import styles from "./index.module.scss";

const SecondaryButton = ({
	text,
	extraClass,
	extraTextClass,
	startIcon,
	endIcon,
	onClick,
	fullWidth,
	disabled,
	fontType,
}) => {
	const customClass = `${styles.secondaryBtn} ${extraClass}`;
	const customTextClass = `${styles.secondaryBtnText} ${extraTextClass}`;

	return (
		<Button
			variant="outlined"
			startIcon={startIcon}
			endIcon={endIcon}
			onClick={onClick}
			className={customClass}
			fullWidth={fullWidth}
			disabled={disabled}
		>
			<Heading5 text={text} fontType={fontType} extraClass={customTextClass} />
		</Button>
	);
};

export default SecondaryButton;
