import React from "react";
import { DateTime } from "luxon";

// mui components
import { Box, TableCell, TableRow } from "@mui/material";

// custom components
import Body1 from "../../typography/body-01";

// mui icons
import { FileUploadOutlined } from "@mui/icons-material";

// custom styles
import styles from "../index.module.scss";

const ConditionTrackingRow = ({ row, handleUploadClick }) => {
	return (
		<TableRow hover tabIndex={-1} key={row.label}>
			<TableCell>
				<Body1
					text={DateTime.fromISO(row.createdDate, {
						zone: "America/New_York",
					}).toFormat("MM/dd/yyyy")}
				/>
			</TableCell>
			<TableCell>
				<Body1 text={row.title} extraClass={styles.truncatedText} />
			</TableCell>
			<TableCell>
				<Body1 text={row.description} extraClass={styles.truncatedText} />
			</TableCell>
			<TableCell>
				<Body1 text={row.category} />
			</TableCell>
			<TableCell>
				<Body1 text={row.status} />
			</TableCell>
			<TableCell>
				<Body1 text={row.sub_status} />
			</TableCell>
			<TableCell
				onClick={(event) => handleUploadClick(event, row.id)}
				className={styles.textCenter}
			>
				<Box className={styles.uploadIconContainer}>
					<FileUploadOutlined className={styles.uploadIcon} />
				</Box>
			</TableCell>
			<TableCell>
				<Body1
					text={
						row?.attachment_upload_timestamp !== undefined
							? DateTime.fromISO(row?.attachment_upload_timestamp, {
									zone: "America/New_York",
							  }).toFormat("MM/dd/yyyy")
							: "NA"
					}
					fontType="semibold"
					extraClass={styles.conditionTimestamp}
				/>
			</TableCell>
		</TableRow>
	);
};

export default ConditionTrackingRow;
