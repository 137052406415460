import { createSlice } from "@reduxjs/toolkit";

const resetSlice = createSlice({
	name: "uploadXml",
	initialState: { user: null },
	reducers: {
		setResetReducer: (state, action) => {
			console.log("17 action:", action);
			const data = action.payload;
			console.log("10 data:", data);
			state.user = data;
		},
	},
});

export const { setResetReducer } = resetSlice.actions;

export default resetSlice.reducer;

export const selectCurrentXml = (state) => state.resetPassword.user;
