import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import DatePicker from "react-date-picker";

// mui components
import { Box, InputLabel, Stack } from "@mui/material";

// custom components
import ViewLoanInfoCard from "../info-card";
import OutlinedTextInput from "../../../../components/custom-input/outlined-text-input";
import MaskedInput from "../../../../components/custom-input/masked-input";
import SecondarySelect from "../../../../components/custom-select/secondary-select";
import CustomRadio from "../../../../components/custom-radio";
import PrimaryButton from "../../../../components/buttons/primary-button";
import SecondaryButton from "../../../../components/buttons/secondary-button";
import CustomDivider from "../../../../components/custom-divider";
import Heading5 from "../../../../components/typography/heading-05";
import Heading6 from "../../../../components/typography/heading-06";
import Body3 from "../../../../components/typography/body-03";
import Body2 from "../../../../components/typography/body-02";

// mui icons
import { ArrowBackOutlined, ArrowForwardOutlined } from "@mui/icons-material";

// api slices
import { useFieldMutation } from "../../../../features/field-values/fieldValueApiSlice";

// helper utils
import {
	YNOptions,
	bpcValueOptions,
	lpcOptions,
	lpcTypeOptions,
	stateOptions,
	vestingMethodOptions,
} from "../../../../utils/select-options";

// custom styles
import styles from "./index.module.scss";

const ViewLoanFee = ({
	setLoanApplicationStage,
	loanGuid,
	handleOpenLoaderModal,
	handleCloseLoaderModal,
	disabled,
}) => {
	const params = useParams();
	const loanNumber = params?.loanId;
	const navigate = useNavigate();

	const [field, { isLoading: loadingValues }] = useFieldMutation();

	const [borrowerFirstName, setBorrowerFirstName] = useState("");
	const [borrowerMiddleName, setBorrowerMiddleName] = useState("");
	const [borrowerLastName, setBorrowerLastName] = useState("");

	const [coBorrowerFirstName, setCoBorrowerFirstName] = useState("");
	const [coBorrowerMiddleName, setCoBorrowerMiddleName] = useState("");
	const [coBorrowerLastName, setCoBorrowerLastName] = useState("");

	const [street, setStreet] = useState("");
	const [city, setCity] = useState("");
	const [state, setState] = useState("");
	const [postalCode, setPostalCode] = useState("");

	const [closingDate, setClosingDate] = useState("");
	const [vestingMethod, setVestingMethod] = useState("");
	const [vestingFull, setVestingFull] = useState("");

	// const [companyTitle, setCompanyTitle] = useState("");
	// const [officerName, setOfficerName] = useState("");
	// const [officerEmail, setOfficerEmail] = useState("");
	// const [officerPhone, setOfficerPhone] = useState("");
	// const [companyAddress, setCompanyAddress] = useState("");
	// const [companyZipcode, setCompanyZipcode] = useState("");
	// const [companyCity, setCompanyCity] = useState("");
	// const [companyState, setCompanyState] = useState("");

	const [escrowCompanyName, setEscrowCompanyName] = useState("");
	const [escrowOfficerName, setEscrowOfficerName] = useState("");
	const [escrowOfficerEmail, setEscrowOfficerEmail] = useState("");
	const [escrowOfficerPhone, setEscrowOfficerPhone] = useState("");
	const [escrowCompanyAddress, setEscrowCompanyAddress] = useState("");
	const [escrowCompanyZipcode, setEscrowCompanyZipcode] = useState("");
	const [escrowCompanyCity, setEscrowCompanyCity] = useState("");
	const [escrowCompanyState, setEscrowCompanyState] = useState("");

	const [borrowerPoa, setBorrowerPoa] = useState("");
	const [coBorrowerPoa, setCoBorrowerPoa] = useState("");

	const [lpcType, setLpcType] = useState("");
	const [lpcValue, setLpcValue] = useState("");
	const [bpcValue, setBpcValue] = useState("");
	const [bpcAmount, setBpcAmount] = useState("");

	const [creditReport, setCreditReport] = useState("");
	const [appraisalFee, setAppraisalFee] = useState("");
	const [secondAppraisalFee, setSecondAppraisalFee] = useState("");
	const [processingFee, setProcessingFee] = useState("");
	const [thirdPartyProcessingFee, setThirdPartyProcessingFee] = useState("");

	const [serviceCreditReport, setServiceCreditReport] = useState("");
	const [serviceAppraisalFee, setServiceAppraisalFee] = useState("");
	const [serviceSecondAppraisalFee, setServiceSecondAppraisalFee] =
		useState("");
	const [serviceProcessingFee, setServiceProcessingFee] = useState("");
	const [serviceThirdPartyProcessingFee, setServiceThirdPartyProcessingFee] =
		useState("");

	const handlePrev = () => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});
		setLoanApplicationStage(2);
	};

	const getFieldValues = async (loanId) => {
		console.log("175 loan id:", loanId);
		try {
			const getValues = await field({
				loan_guid: loanId,
				fields: "4000,4001,4002,4004,4005,4006,URLA.X73,12,14,15",
			}).unwrap();
			console.log("175 all fields:", getValues?.response);

			setBorrowerFirstName(
				getValues?.response?.find((o) => o.fieldId === "4000")?.value
			);
			setBorrowerMiddleName(
				getValues?.response?.find((o) => o.fieldId === "4001")?.value
			);
			setBorrowerLastName(
				getValues?.response?.find((o) => o.fieldId === "4002")?.value
			);

			setCoBorrowerFirstName(
				getValues?.response?.find((o) => o.fieldId === "4004")?.value
			);
			setCoBorrowerMiddleName(
				getValues?.response?.find((o) => o.fieldId === "4005")?.value
			);
			setCoBorrowerLastName(
				getValues?.response?.find((o) => o.fieldId === "4006")?.value
			);

			setStreet(
				getValues?.response?.find((o) => o.fieldId === "URLA.X73")?.value
			);
			setCity(getValues?.response?.find((o) => o.fieldId === "12")?.value);
			setState(getValues?.response?.find((o) => o.fieldId === "14")?.value);
			setPostalCode(
				getValues?.response?.find((o) => o.fieldId === "15")?.value
			);
		} catch (err) {}
	};

	useEffect(() => {
		if (loanGuid !== undefined && loanGuid !== "") {
			console.log("180 params:", loanGuid);
			getFieldValues(loanGuid);
		}
	}, [loanGuid]);

	const handleNext = async () => {
		console.log("502 params id:", loanGuid);

		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});
		navigate(`/loan-summary/${loanGuid}`);
	};

	const updateField = (fieldValues) => {
		console.log("88 field values:", fieldValues);

		setClosingDate(fieldValues?.find((o) => o.fieldId === "2966")?.value);

		let vestingMethodValue1 = fieldValues?.find(
			(o) => o.fieldId === "CX.PORTAL.CORPTRUSTORGTYP"
		)?.value;
		let vestingMethodValue2 = fieldValues?.find(
			(o) => o.fieldId === "33"
		)?.value;

		if (vestingMethodValue1 !== "") {
			setVestingMethod(vestingMethodValue1);
		} else if (vestingMethodValue2 !== "") {
			setVestingMethod(vestingMethodValue2);
		}

		let finalVestingField = fieldValues?.find(
			(o) => o.fieldId === "CX.PORTAL.FINAL.VESTING"
		)?.value;
		let trustName = fieldValues?.find(
			(o) => o.fieldId === "CX.PORTAL.CORPTRUSTNAME"
		)?.value;

		if (finalVestingField !== "") {
			setVestingFull(finalVestingField);
		} else if (trustName !== "") {
			setVestingFull(trustName);
		}

		// setCompanyTitle(fieldValues?.find((o) => o.fieldId === "411")?.value);

		// setOfficerName(fieldValues?.find((o) => o.fieldId === "416")?.value);

		// setOfficerEmail(fieldValues?.find((o) => o.fieldId === "88")?.value);

		// setOfficerPhone(fieldValues?.find((o) => o.fieldId === "417")?.value);

		// setCompanyAddress(fieldValues?.find((o) => o.fieldId === "412")?.value);

		// setCompanyCity(fieldValues?.find((o) => o.fieldId === "413")?.value);

		// setCompanyState(fieldValues?.find((o) => o.fieldId === "1174")?.value);

		// setCompanyZipcode(fieldValues?.find((o) => o.fieldId === "414")?.value);

		let escrowCompanyName = fieldValues?.find(
			(o) => o.fieldId === "610"
		)?.value;

		let escrowOfficerName = fieldValues?.find(
			(o) => o.fieldId === "611"
		)?.value;

		let escrowOfficerEmail = fieldValues?.find(
			(o) => o.fieldId === "87"
		)?.value;

		let escrowOfficerPhone = fieldValues?.find(
			(o) => o.fieldId === "615"
		)?.value;

		let escrowCompanyAddress = fieldValues?.find(
			(o) => o.fieldId === "612"
		)?.value;

		let escrowCompanyCity = fieldValues?.find(
			(o) => o.fieldId === "613"
		)?.value;

		let escrowCompanyState = fieldValues?.find(
			(o) => o.fieldId === "1175"
		)?.value;

		let escrowCompanyZipcode = fieldValues?.find(
			(o) => o.fieldId === "614"
		)?.value;

		if (
			escrowCompanyName !== "" ||
			escrowOfficerName !== "" ||
			escrowOfficerEmail !== "" ||
			escrowOfficerPhone !== "" ||
			escrowCompanyAddress !== "" ||
			escrowCompanyCity !== "" ||
			escrowCompanyState !== "" ||
			escrowCompanyZipcode !== ""
		) {
			setEscrowCompanyName(escrowCompanyName);
			setEscrowOfficerName(escrowOfficerName);
			setEscrowOfficerEmail(escrowOfficerEmail);
			setEscrowOfficerPhone(escrowOfficerPhone);
			setEscrowCompanyAddress(escrowCompanyAddress);
			setEscrowCompanyCity(escrowCompanyCity);
			setEscrowCompanyState(escrowCompanyState);
			setEscrowCompanyZipcode(escrowCompanyZipcode);
		}

		setBorrowerPoa(
			fieldValues?.find((o) => o.fieldId === "CX.CPL.USINGPOA.BORROWER")?.value
		);

		setCoBorrowerPoa(
			fieldValues?.find((o) => o.fieldId === "CX.CPL.USINGPOA.COBORROWER")
				?.value
		);

		setCreditReport(fieldValues?.find((o) => o.fieldId === "640")?.value);
		setAppraisalFee(fieldValues?.find((o) => o.fieldId === "641")?.value);
		setSecondAppraisalFee(
			fieldValues?.find((o) => o.fieldId === "NEWHUD.X138")?.value
		);
		setProcessingFee(
			fieldValues?.find((o) => o.fieldId === "NEWHUD.X136")?.value
		);
		setThirdPartyProcessingFee(
			fieldValues?.find((o) => o.fieldId === "NEWHUD.X1285")?.value
		);

		setServiceCreditReport(
			fieldValues?.find((o) => o.fieldId === "624")?.value
		);
		setServiceAppraisalFee(
			fieldValues?.find((o) => o.fieldId === "617")?.value
		);
		setServiceSecondAppraisalFee(
			fieldValues?.find((o) => o.fieldId === "NEWHUD.X1052")?.value
		);
		setServiceProcessingFee(
			fieldValues?.find((o) => o.fieldId === "NEWHUD.X1050")?.value
		);
		setServiceThirdPartyProcessingFee(
			fieldValues?.find((o) => o.fieldId === "NEWHUD.X1284")?.value
		);

		let lpcTypePercentage = fieldValues?.find(
			(o) => o.fieldId === "NEWHUD.X1141"
		)?.value;

		let lpcTypeAmount = fieldValues?.find(
			(o) => o.fieldId === "NEWHUD.X1225"
		)?.value;

		let bpcTypePercentage = fieldValues?.find(
			(o) => o.fieldId === "NEWHUD.X223"
		)?.value;

		let bpcTypeAmount = fieldValues?.find(
			(o) => o.fieldId === "NEWHUD.X224"
		)?.value;

		if (lpcTypePercentage !== "") {
			setLpcType("%");
			setLpcValue(lpcTypePercentage);
		} else if (lpcTypeAmount !== "") {
			setLpcType("$");
			setLpcValue(lpcTypeAmount);
		}

		setBpcValue(bpcTypePercentage);
		setBpcAmount(bpcTypeAmount);

		handleCloseLoaderModal();
	};

	const getLoanFieldValue = async (loanId) => {
		console.log("175 loan id:", loanId);
		try {
			const getValues = await field({
				loan_guid: loanId,
				fields:
					"763,2966,33,CX.PORTAL.CORPTRUSTORGTYP,CX.PORTAL.FINAL.VESTING,CX.PORTAL.CORPTRUSTNAME,411,416,88,417,412,413,1174,414,610,611,87,615,612,613,1175,614,CX.CPL.USINGPOA.BORROWER,CX.CPL.USINGPOA.COBORROWER,NEWHUD.X251,NEWHUD.X254,650,644,651,645,40,41,43,44,1782,1783,1787,1788,1792,1793,NEWHUD.X1141,NEWHUD.X1225,NEWHUD.X223,NEWHUD.X224,640,641,NEWHUD.X138,NEWHUD.X136,NEWHUD.X1285,624,617,NEWHUD.X1052,NEWHUD.X1050,NEWHUD.X1284",
			}).unwrap();
			console.log("175 all fields:", getValues?.response);
			if (getValues?.response?.length > 0) {
				updateField(getValues?.response);
			}
		} catch (err) {
			console.log("584 error:", err);
		}
	};

	useEffect(() => {
		if (loanGuid !== undefined && loanGuid !== "") {
			handleOpenLoaderModal();
			getLoanFieldValue(loanGuid);
		}
	}, [loanGuid]);

	return (
		<Stack direction="column" className={styles.stackContainer}>
			<Stack className={styles.bodyContainer}>
				{console.log("3070 first name:", borrowerFirstName)}
				<ViewLoanInfoCard
					loanNumber={loanNumber}
					borrower={{
						firstName: borrowerFirstName,
						middleName: borrowerMiddleName,
						lastName: borrowerLastName,
					}}
					coBorrower={{
						firstName: coBorrowerFirstName,
						middleName: coBorrowerMiddleName,
						lastName: coBorrowerLastName,
					}}
					propertyInfo={{
						addressLineText: street,
						city,
						state,
						postalCode,
					}}
				/>

				<Stack direction="column" className={styles.cardHeaderContainer}>
					<Heading5 text="Closing Date" fontType="semibold" />
					<Body3 text="Provide details about the loan" />
				</Stack>

				<Stack direction="row" className={styles.formContainer}>
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor="closing-date-picker">
							<Heading6
								text="Closing Date"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<DatePicker
							id="closing-date-picker"
							className={
								disabled
									? styles.datepicker + " " + styles.disabledDatepicker
									: styles.datepicker
							}
							value={closingDate || ""}
							onChange={setClosingDate}
							disabled={disabled}
						/>
					</Stack>
				</Stack>

				<CustomDivider />

				<Stack direction="column" className={styles.cardHeaderContainer}>
					<Heading5 text="Fee reimbursement" fontType="semibold" />
					<Body3 text="Do you need any fees reimbursed back to you that you incurred on behalf of the borrower(s)?" />
				</Stack>

				<Stack
					direction="column"
					className={styles.formContainer + " " + styles.feeFormContainer}
				>
					{/* credit report */}
					<Stack direction="row" className={styles.formSectionContainer}>
						<Body2 text="Credit Report:" extraClass={styles.sectionHeader} />

						<Stack direction="row" className={styles.formContainer}>
							<Stack
								direction="column"
								className={styles.inputContainer + " " + styles.inlineContainer}
							>
								<InputLabel htmlFor="credit-report-fee">
									<Heading6
										text="Fees"
										fontType="semibold"
										extraClass={styles.inputHeader}
									/>
								</InputLabel>

								<MaskedInput
									id="credit-report-fee"
									placeholder="$"
									type="currency"
									fullWidth={true}
									value={creditReport}
									onInputChange={setCreditReport}
									disabled={disabled}
								/>
							</Stack>

							<Stack
								direction="column"
								className={styles.inputContainer + " " + styles.inlineContainer}
							>
								<InputLabel htmlFor="credit-report-service-provider">
									<Heading6
										text="Service Provider"
										fontType="semibold"
										extraClass={styles.inputHeader}
									/>
								</InputLabel>

								<OutlinedTextInput
									id="credit-report-service-provider"
									placeholder="Enter service provider"
									fullWidth={true}
									value={serviceCreditReport}
									handleChange={(event) =>
										setServiceCreditReport(event.target.value)
									}
									disabled={disabled}
								/>
							</Stack>
						</Stack>
					</Stack>

					{/* appraisal Fee: */}
					<Stack direction="row" className={styles.formSectionContainer}>
						<Body2 text="Appraisal Fee:" extraClass={styles.sectionHeader} />

						<Stack direction="row" className={styles.formContainer}>
							<Stack
								direction="column"
								className={styles.inputContainer + " " + styles.inlineContainer}
							>
								<InputLabel htmlFor="appraisal-fee">
									<Heading6
										text="Fees"
										fontType="semibold"
										extraClass={styles.inputHeader}
									/>
								</InputLabel>

								<MaskedInput
									id="appraisal-fee"
									placeholder="$"
									type="currency"
									fullWidth={true}
									value={appraisalFee}
									onInputChange={setAppraisalFee}
									disabled={disabled}
								/>
							</Stack>

							<Stack
								direction="column"
								className={styles.inputContainer + " " + styles.inlineContainer}
							>
								<InputLabel htmlFor="appraisal-fee-service-provider">
									<Heading6
										text="Service Provider"
										fontType="semibold"
										extraClass={styles.inputHeader}
									/>
								</InputLabel>

								<OutlinedTextInput
									id="appraisal-fee-service-provider"
									placeholder="Enter service provider"
									fullWidth={true}
									value={serviceAppraisalFee}
									handleChange={(event) =>
										setServiceAppraisalFee(event.target.value)
									}
									disabled={disabled}
								/>
							</Stack>
						</Stack>
					</Stack>

					{/* Second Appraisal Fee: */}
					<Stack direction="row" className={styles.formSectionContainer}>
						<Body2
							text="Second Appraisal Fee:"
							extraClass={styles.sectionHeader}
						/>

						<Stack direction="row" className={styles.formContainer}>
							<Stack
								direction="column"
								className={styles.inputContainer + " " + styles.inlineContainer}
							>
								<InputLabel htmlFor="second-appraisal-fee">
									<Heading6
										text="Fees"
										fontType="semibold"
										extraClass={styles.inputHeader}
									/>
								</InputLabel>
								<MaskedInput
									id="second-appraisal-fee"
									placeholder="$"
									type="currency"
									fullWidth={true}
									value={secondAppraisalFee}
									onInputChange={setSecondAppraisalFee}
									disabled={disabled}
								/>
							</Stack>

							<Stack
								direction="column"
								className={styles.inputContainer + " " + styles.inlineContainer}
							>
								<InputLabel htmlFor="second-appraisal-fee-service-provider">
									<Heading6
										text="Service Provider"
										fontType="semibold"
										extraClass={styles.inputHeader}
									/>
								</InputLabel>

								<OutlinedTextInput
									id="second-appraisal-fee-service-provider"
									placeholder="Enter service provider"
									fullWidth={true}
									value={serviceSecondAppraisalFee}
									handleChange={(event) =>
										setServiceSecondAppraisalFee(event.target.value)
									}
									disabled={disabled}
								/>
							</Stack>
						</Stack>
					</Stack>

					{/* processing Fee: */}
					<Stack direction="row" className={styles.formSectionContainer}>
						<Body2 text="Processing Fee:" extraClass={styles.sectionHeader} />

						<Stack direction="row" className={styles.formContainer}>
							<Stack
								direction="column"
								className={styles.inputContainer + " " + styles.inlineContainer}
							>
								<InputLabel htmlFor="processing-fee">
									<Heading6
										text="Fees"
										fontType="semibold"
										extraClass={styles.inputHeader}
									/>
								</InputLabel>

								<MaskedInput
									id="processing-fee"
									placeholder="$"
									type="currency"
									fullWidth={true}
									value={processingFee}
									onInputChange={setProcessingFee}
									disabled={disabled}
								/>
							</Stack>

							<Stack
								direction="column"
								className={styles.inputContainer + " " + styles.inlineContainer}
							>
								<InputLabel htmlFor="processing-fee-service-provider">
									<Heading6
										text="Service Provider"
										fontType="semibold"
										extraClass={styles.inputHeader}
									/>
								</InputLabel>

								<OutlinedTextInput
									id="processing-fee-service-provider"
									placeholder="Enter service provider"
									fullWidth={true}
									value={serviceProcessingFee}
									handleChange={(event) =>
										setServiceProcessingFee(event.target.value)
									}
									disabled={disabled}
								/>
							</Stack>
						</Stack>
					</Stack>

					{/* third Party Processing Fee: */}
					<Stack direction="row" className={styles.formSectionContainer}>
						<Body2
							text="Third Party Processing Fee:"
							extraClass={styles.sectionHeader}
						/>

						<Stack direction="row" className={styles.formContainer}>
							<Stack
								direction="column"
								className={styles.inputContainer + " " + styles.inlineContainer}
							>
								<InputLabel htmlFor="third-party-processing-fee">
									<Heading6
										text="Fees"
										fontType="semibold"
										extraClass={styles.inputHeader}
									/>
								</InputLabel>

								<MaskedInput
									id="third-party-processing-fee"
									placeholder="$"
									type="currency"
									fullWidth={true}
									value={thirdPartyProcessingFee}
									onInputChange={setThirdPartyProcessingFee}
									disabled={disabled}
								/>
							</Stack>

							<Stack
								direction="column"
								className={styles.inputContainer + " " + styles.inlineContainer}
							>
								<InputLabel htmlFor="third-party-processing-service-provider">
									<Heading6
										text="Service Provider"
										fontType="semibold"
										extraClass={styles.inputHeader}
									/>
								</InputLabel>

								<OutlinedTextInput
									id="third-party-processing-service-provider"
									placeholder="Enter service provider"
									fullWidth={true}
									value={serviceThirdPartyProcessingFee}
									handleChange={(event) =>
										setServiceThirdPartyProcessingFee(event.target.value)
									}
									disabled={disabled}
								/>
							</Stack>
						</Stack>
					</Stack>
				</Stack>

				<CustomDivider />

				<Stack direction="column" className={styles.cardHeaderContainer}>
					<Heading5 text="Vesting" fontType="semibold" />
					<Body3 text="Provide details about the loan" />
				</Stack>

				<Stack direction="row" className={styles.formContainer}>
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.inlineContainer}
					>
						<InputLabel htmlFor="select-vesting-method">
							<Heading6
								text="How will the transaction be vested?"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<SecondarySelect
							id="select-vesting-method"
							fullWidth={true}
							value={vestingMethod}
							handleChange={(event) => setVestingMethod(event.target.value)}
							options={vestingMethodOptions}
							disabled={disabled}
						/>
					</Stack>

					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.inlineContainer}
					>
						<InputLabel htmlFor="input-vesting-wrriten">
							<Heading6
								text="Vesting fully written as:"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<OutlinedTextInput
							id="input-vesting-wrriten"
							placeholder="Type here"
							fullWidth={true}
							value={vestingFull}
							handleChange={(event) => setVestingFull(event.target.value)}
							disabled={disabled}
						/>
					</Stack>
				</Stack>

				<CustomDivider />

				<Stack direction="column" className={styles.cardHeaderContainer}>
					<Heading5 text="Compensation" fontType="semibold" />
					<Body3 text="Provide details about the loan" />
				</Stack>

				<Stack
					direction="column"
					className={styles.formContainer + " " + styles.feeFormContainer}
				>
					{/* LPC */}
					<Stack direction="row" className={styles.formSectionContainer}>
						<Body2 text="LPC:" extraClass={styles.compensationHeader} />

						<Stack direction="row" className={styles.formContainer}>
							<Stack
								direction="column"
								className={
									styles.inputContainer + " " + styles.fullWidthContainer
								}
							>
								<CustomRadio
									fullWidth={true}
									value={lpcType}
									handleChange={(event) => setLpcType(event.target.value)}
									data={lpcOptions}
									disabled={disabled}
								/>

								<Box className={styles.lpcInputContainer}>
									{lpcType === "%" ? (
										<SecondarySelect
											fullWidth={true}
											value={lpcValue || ""}
											handleChange={(event) => setLpcValue(event.target.value)}
											options={lpcTypeOptions}
											disabled={disabled}
										/>
									) : (
										<MaskedInput
											placeholder="LPC Amount ($)"
											type="currency"
											value={lpcValue || ""}
											onInputChange={setLpcValue}
											disabled={disabled}
										/>
									)}
								</Box>
							</Stack>
						</Stack>
					</Stack>

					{/* BPC */}
					<Stack direction="row" className={styles.formSectionContainer}>
						<Body2 text="BPC:" extraClass={styles.compensationHeader} />

						<Stack direction="row" className={styles.formContainer}>
							<Stack
								direction="column"
								className={styles.inputContainer + " " + styles.bpcContainer}
							>
								<InputLabel htmlFor="select-bpc-percentage">
									<Heading6
										text="BPC in percentage"
										fontType="semibold"
										extraClass={styles.inputHeader}
									/>
								</InputLabel>

								<SecondarySelect
									id="select-bpc-percentage"
									value={bpcValue}
									handleChange={(event) => setBpcValue(event.target.value)}
									options={bpcValueOptions}
									disabled={disabled}
								/>
							</Stack>
						</Stack>

						<Stack direction="row" className={styles.formContainer}>
							<Stack
								direction="column"
								className={styles.inputContainer + " " + styles.bpcContainer}
							>
								<InputLabel htmlFor="input-bpc-amount">
									<Heading6
										text="BPC in dollars"
										fontType="semibold"
										extraClass={styles.inputHeader}
									/>
								</InputLabel>

								<MaskedInput
									id="input-bpc-amount"
									placeholder="$"
									type="currency"
									value={bpcAmount}
									onInputChange={setBpcAmount}
									disabled={disabled}
								/>
							</Stack>
						</Stack>
					</Stack>
				</Stack>

				<CustomDivider />

				<Stack direction="column" className={styles.cardHeaderContainer}>
					<Heading5 text="Escrow" fontType="semibold" />
					<Body3 text="Provide details about the escrow" />
				</Stack>

				<Stack direction="row" className={styles.formContainer}>
					{/* escrow company name */}
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.fullWidthContainer}
					>
						<InputLabel htmlFor="input-company-name">
							<Heading6
								text="Company Name"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<OutlinedTextInput
							id="input-company-name"
							fullWidth={true}
							value={escrowCompanyName}
							handleChange={(event) => setEscrowCompanyName(event.target.value)}
							disabled={disabled}
						/>
					</Stack>

					{/* escrow company address */}
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.fullWidthContainer}
					>
						<InputLabel htmlFor="input-company-address-line">
							<Heading6
								text="escrowCompanyAddress"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<OutlinedTextInput
							id="input-company-address-line"
							fullWidth={true}
							value={escrowCompanyAddress}
							handleChange={(event) =>
								setEscrowCompanyAddress(event.target.value)
							}
							disabled={disabled}
						/>
					</Stack>

					{/* escrow company city */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor="input-company-city">
							<Heading6
								text="City"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<OutlinedTextInput
							id="input-company-city"
							fullWidth={true}
							value={escrowCompanyCity}
							handleChange={(event) => setEscrowCompanyCity(event.target.value)}
							disabled={disabled}
						/>
					</Stack>

					{/* escrow company state */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor="select-state">
							<Heading6
								text="State"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<SecondarySelect
							id="select-state"
							displayEmpty={true}
							value={escrowCompanyState || ""}
							handleChange={(event) =>
								setEscrowCompanyState(event.target.value)
							}
							options={stateOptions}
							disabled={disabled}
						/>
					</Stack>

					{/* escrow company zipcode */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor="input-company-zipcode">
							<Heading6
								text="Zipcode"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<OutlinedTextInput
							id="input-company-zipcode"
							fullWidth={true}
							value={escrowCompanyZipcode}
							handleChange={(event) =>
								setEscrowCompanyZipcode(event.target.value)
							}
							disabled={disabled}
						/>
					</Stack>

					{/* escrow officer name */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor="input-officer-name">
							<Heading6
								text="Escrow Officer Name"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<OutlinedTextInput
							id="input-officer-name"
							fullWidth={true}
							value={escrowOfficerName}
							handleChange={(event) => setEscrowOfficerName(event.target.value)}
							disabled={disabled}
						/>
					</Stack>

					{/* escrow officer phone */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor="input-officer-phone">
							<Heading6
								text="Escrow Officer Cell Phone Number"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<MaskedInput
							id="input-officer-phone"
							placeholder="(XXX) XXX-XXXX"
							type="phone"
							value={escrowOfficerPhone}
							onInputChange={setEscrowOfficerPhone}
							disabled={disabled}
						/>
					</Stack>

					{/* escrow officer name */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor="input-officer-email">
							<Heading6
								text="Escrow Officer Email ID"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<OutlinedTextInput
							id="input-officer-email"
							fullWidth={true}
							value={escrowOfficerEmail}
							handleChange={(event) =>
								setEscrowOfficerEmail(event.target.value)
							}
							disabled={disabled}
						/>
					</Stack>
				</Stack>

				<CustomDivider />

				<Stack direction="column" className={styles.cardHeaderContainer}>
					<Heading5 text="POA" fontType="semibold" />
					<Body3 text="Provide details about the loan" />
				</Stack>

				<Stack direction="row" className={styles.formContainer}>
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.inlineContainer}
					>
						<InputLabel htmlFor="select-poa-borrower">
							<Heading6
								text="Will POA be used for Borrower?"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<SecondarySelect
							id="select-poa-borrower"
							fullWidth={true}
							value={borrowerPoa}
							handleChange={(event) => setBorrowerPoa(event.target.value)}
							options={YNOptions}
							disabled={disabled}
						/>
					</Stack>

					{((coBorrowerFirstName !== undefined && coBorrowerFirstName !== "") ||
						(coBorrowerMiddleName !== undefined &&
							coBorrowerMiddleName !== "") ||
						(coBorrowerLastName !== undefined &&
							coBorrowerLastName !== "")) && (
						<Stack
							direction="column"
							className={styles.inputContainer + " " + styles.inlineContainer}
						>
							<InputLabel htmlFor="select-poa-co-borrower">
								<Heading6
									text="Will POA be used for Co Borrower?"
									fontType="semibold"
									extraClass={styles.inputHeader}
								/>
							</InputLabel>

							<SecondarySelect
								id="select-poa-co-borrower"
								fullWidth={true}
								value={coBorrowerPoa}
								handleChange={(event) => setCoBorrowerPoa(event.target.value)}
								options={YNOptions}
								disabled={disabled}
							/>
						</Stack>
					)}
				</Stack>
			</Stack>

			{/* footer buttons */}
			<Stack direction="row" className={styles.footerContainer}>
				<SecondaryButton
					text="Previous"
					startIcon={<ArrowBackOutlined className={styles.prevIcon} />}
					onClick={handlePrev}
				/>
				<PrimaryButton
					text="Back to Loan"
					endIcon={<ArrowForwardOutlined className={styles.nextIcon} />}
					onClick={handleNext}
				/>
			</Stack>
		</Stack>
	);
};

export default ViewLoanFee;
