import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DateTime } from "luxon";

// mui components
import { Box, Stack } from "@mui/material";

// custom components
import RequiredError from "../../required-error";
import SecondaryButton from "../../buttons/secondary-button";
import PrimaryButton from "../../buttons/primary-button";
import SupportingDocs from "../../supporting-docs";
import Heading5 from "../../typography/heading-05";
import Body3 from "../../typography/body-03";
import Body2 from "../../typography/body-02";

// helper utils
import { formatBytes } from "../../../utils/format-bytes";

// custom modal
import LoadingDataModal from "../../modal/loading-data-modal";
import SuccessModal from "../../modal/success-modal";
import ErrorModal from "../../modal/error-modal";

// mui icons
import { ArrowBackOutlined, DoneOutlined } from "@mui/icons-material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import CloseIcon from "@mui/icons-material/Close";

// api slices
import { useLoanDocsMutation } from "../../../features/loan-docs/loanDocsApiSlice";
import { setLoanDocsReducer } from "../../../features/loan-docs/loanDocsSlice";
import { useUpdateFieldsMutation } from "../../../features/update-fields/updateFieldsApiSlice";
import { useMoveLoanMutation } from "../../../features/move-loan/moveLoanApiSlice";
import { usePopulateFieldsMutation } from "../../../features/populate-fields/populateFieldsApiSlice";

// reducer slices
import {
	selectLoEmail,
	selectUserDetails,
} from "../../../features/auth/authSlice";

// custom styles
import styles from "./index.module.scss";

const fileTypes = ["PDF", "PNG", "JPEG", "JPG", "HTML", "TXT"];

export const LoanDocuments = ({ setLoanApplicationStage, loanGuid }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	let { id } = useParams();
	const loEmail = useSelector(selectLoEmail);
	const userDetails = useSelector(selectUserDetails);

	console.log("30 user details:", userDetails);
	console.log("30 lo email:", loEmail);

	console.log("23 id:", id);

	const [uploadedModalVisible, setUploadedModalVisible] = useState(false);
	const [loanDocsUploadVisible, setLoanDocsUploadVisible] = useState(false);
	const [progressbarVisible, setProgressbarVisible] = useState(false);
	const [progress, setProgress] = useState(0);

	const handleOpenLoanDocsUpload = () => {
		setLoanDocsUploadVisible(true);
	};

	const handleCloseLoanDocsUpload = () => {
		setLoanDocsUploadVisible(false);
		navigate("/pipeline");
	};

	const handleOpenUploadedModal = () => {
		setUploadedModalVisible(true);
	};

	const handleCloseUploadedModal = () => {
		setUploadedModalVisible(false);
		navigate("/pipeline");
	};

	const [loanDocs, { isLoading }] = useLoanDocsMutation();

	const [moveLoan] = useMoveLoanMutation();
	const [populateFields] = usePopulateFieldsMutation();

	const [file, setFile] = useState("");
	const [error, setError] = useState("");
	const [uploadedFiles, setUploadedFiles] = useState([]);

	const [updateFields, { isLoading: isUpdatingFields }] =
		useUpdateFieldsMutation();

	const handleFileUpload = async (file) => {
		setFile(file);
		console.log("34 file:", file);
		console.log("34 file length:", file?.length);
		let newArr = [];
		Array.from(file).forEach((file) => newArr.push(file));
		setUploadedFiles(newArr);
	};

	const deleteFile = (event, index) => {
		console.log("43 files:", uploadedFiles, index);

		let newArr = uploadedFiles;
		newArr.splice(index, 1);

		setProgress(0);
		setProgressbarVisible(false);

		setUploadedFiles([...newArr]);
	};

	const handlePrevClick = () => {
		console.log("67 prev click");
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});
		setLoanApplicationStage(4);
	};

	const handleSubmitFiles = async () => {
		console.log("50 submit click!!!", file, uploadedFiles);

		setProgressbarVisible(true);
		handleOpenLoanDocsUpload(true);

		for (let index = 0; index < uploadedFiles.length; index++) {
			const element = uploadedFiles[index];

			var formData = new FormData();
			if (id !== undefined && id !== "") {
				formData.append("loan_guid", id);
			} else if (loanGuid !== undefined && loanGuid !== "") {
				formData.append("loan_guid", loanGuid);
			}
			formData.append("file", element);

			// dispatch API and open modal if successful
			try {
				const fileUpload = await loanDocs({ formData }).unwrap();
				console.log("99 file data:", fileUpload);
				dispatch(setLoanDocsReducer(fileUpload));
				// uploaded successfully
				setProgress(Math.ceil((index / uploadedFiles.length) * 100));

				if (index === uploadedFiles.length - 1) {
					// run at the end of the loop
					// navigate("/pipeline");
					setProgress(100);

					let valuesArr = [
						{ id: "TPO.X4", value: DateTime.now().toFormat("MM-dd-yyyy") },
						{ id: "TPO.X90", value: DateTime.now().toFormat("MM-dd-yyyy") },
					];

					console.log("191 loan guid:", loanGuid);

					const updateFieldValues = await updateFields({
						loan_guid: loanGuid,
						field_values: valuesArr,
					}).unwrap();
					console.log("90 updated values:", updateFieldValues);

					if (
						updateFieldValues?.response === "Field values updated successfully."
					) {
						setError("");
						console.log("70 all files uploaded");

						const moveLoanValue = await moveLoan({
							loan_guid: loanGuid,
						}).unwrap();

						console.log("90 updated values:", moveLoanValue);

						if (
							moveLoanValue?.message === "Loan moved to the Pipeline folder"
						) {
							setError("");
							console.log("70 all files uploaded");

							let userEmail;

							if (loEmail !== null && loEmail !== undefined && loEmail !== "") {
								userEmail = loEmail;
							} else {
								userEmail = userDetails?.email;
							}

							const populateCustomFields = await populateFields({
								loan_guid: loanGuid,
								email: userEmail,
							}).unwrap();

							console.log("150 populateCustomFields:", populateCustomFields);

							handleCloseLoanDocsUpload();
							handleOpenUploadedModal();
						} else {
							console.log("170 updateFieldValues:", updateFieldValues);
							setError(updateFieldValues?.message?.details);
						}
					} else {
						console.log("175 updateFieldValues:", updateFieldValues);
						setError(updateFieldValues?.response);
					}
				}
			} catch (err) {
				console.log("95 err:", err);

				if (err?.data?.message?.details !== undefined) {
					console.log("180 updateFieldValues details:", err);
					setError(err?.data?.message?.details);
				} else {
					console.log("180 updateFieldValues message:", err);
					setError(err?.data?.message);
				}
			}
		}
	};

	return (
		<Stack direction="column" className={styles.stackContainer}>
			<Stack className={styles.bodyContainer}>
				<Stack direction="column" className={styles.cardHeaderContainer}>
					<Stack direction="row" className={styles.headerLinkContainer}>
						<Heading5 text="Upload Supporting Documents" fontType="semibold" />

						{/* <Body3
							text="Commercial Broker Submission Form"
							isLink={true}
							handleClick={() =>
								window.open(
									"https://s3.amazonaws.com/logantpoportal.com/Commercial_Broker_Quick_Reference_05-24-2023_-_New_86.pdf",
									"_blank"
								)
							}
						/> */}
					</Stack>

					<Body3 text="Please refer to the Resources Tab for our minimum required submission docs per loan product/program when uploading" />
				</Stack>

				{/* loan flow error */}
				{error !== undefined && error !== "" && (
					<RequiredError
						text={
							error.includes("already moved")
								? "Already moved to 'Pipeline'."
								: error
						}
					/>
				)}

				<SupportingDocs
					fileCount={uploadedFiles?.length}
					fileTypes={fileTypes}
					text="or drag to upload supporting files"
					handleFileAttach={handleFileUpload}
					multiple={true}
				/>

				{uploadedFiles !== undefined && uploadedFiles?.length > 0 && (
					<Stack direction="column" className={styles.uploadedFileContainer}>
						{uploadedFiles?.map((file, index) => (
							<Stack
								direction="row"
								key={index}
								className={styles.fileListContainer}
							>
								<Box className={styles.fileIconContainer}>
									<UploadFileIcon className={styles.fileIcon} />
								</Box>
								<Stack direction="column" className={styles.fileNameContainer}>
									<Body2 text={file?.name} />
									<Body3 text={formatBytes(file?.size)} />
								</Stack>

								<Box
									sx={{ cursor: "pointer" }}
									onClick={(event) => deleteFile(event, index)}
									className={styles.deleteIconContainer}
								>
									<CloseIcon className={styles.deleteIcon} />
								</Box>
							</Stack>
						))}
					</Stack>
				)}
			</Stack>

			{/* footer buttons */}
			{uploadedFiles !== undefined && uploadedFiles?.length > 0 && (
				<Stack direction="row" className={styles.footerContainer}>
					<SecondaryButton
						text="Previous"
						startIcon={<ArrowBackOutlined className={styles.prevIcon} />}
						onClick={handlePrevClick}
					/>
					<PrimaryButton
						// TODO loader isUpdatingFields
						text="Submit Application"
						endIcon={<DoneOutlined className={styles.nextIcon} />}
						onClick={handleSubmitFiles}
					/>
				</Stack>
			)}

			{/* modals */}
			<LoadingDataModal
				open={loanDocsUploadVisible && isLoading}
				text="Please wait while loan is being created"
			/>
			<SuccessModal
				open={loanDocsUploadVisible && !isLoading}
				text="Loan Created Successfully"
				handleClose={handleCloseLoanDocsUpload}
			/>
			<ErrorModal
				open={uploadedModalVisible}
				text="This loan has been successfully submitted to the Lender already"
				handleClose={handleCloseUploadedModal}
			/>
		</Stack>
	);
};

export default LoanDocuments;
