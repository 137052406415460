import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

// mui components
import { Box, Stack, Tab, Tabs } from "@mui/material";

// api slices
import { useGetBorrowerPairMutation } from "../../../../features/get-borrower-pair/getBorrowerPairApiSlice";
import { useFieldMutation } from "../../../../features/field-values/fieldValueApiSlice";
import { useGetLoanSummaryQuery } from "../../../../features/loanSummary/loanSummaryApiSlice";
import { useGetOrganizationDetailsMutation } from "../../../../features/organization-detail/organizationDetailApiSlice";

// reducer slices
import { setOrganizationNameReducer } from "../../../../features/organization-detail/organizationDetailsUserSlice";

// mui icons
import { ArrowForwardOutlined } from "@mui/icons-material";

// custom components
import { TabPanel } from "../../../../components/tab-panel";
import ViewLoanInfoCard from "../info-card";
import BorrowerInformation from "../../../../components/loan-form-card/borrower-information";
import PropertyDetails from "../../../../components/loan-form-card/property-details";
import LoanOriginator from "../../../../components/loan-form-card/loan-originator";
import LoanProcessor from "../../../../components/loan-form-card/loan-processor";
import PrimaryButton from "../../../../components/buttons/primary-button";
import Heading6 from "../../../../components/typography/heading-06";

// custom styles
import styles from "./index.module.scss";

const ViewLoanApplication = ({
	setLoanApplicationStage,
	loanGuid,
	handleOpenLoaderModal,
	handleCloseLoaderModal,
}) => {
	console.log("27 loan id:", loanGuid);
	const dispatch = useDispatch();
	const params = useParams();
	const loanNumber = params?.loanId;

	const [field, { isLoading: loadingValues }] = useFieldMutation();
	const { data: loanData } = useGetLoanSummaryQuery(loanGuid);

	const [getBorrowerPair] = useGetBorrowerPairMutation();

	const [data, setData] = useState({ applications: [] });
	const [fieldValues, setFieldValues] = useState([]);

	useEffect(() => {
		console.log("258 loan data:", loanData);
		setData(loanData?.data);
	}, [loanData]);

	const [loanApplicationData, setLoanApplicationData] = useState([{}]);

	const [tabValue, setTabValue] = useState(0);

	const [error, setError] = useState("");

	const [propertyInfo, setPropertyInfo] = useState({});

	useEffect(() => {
		setPropertyInfo(data?.property);
	}, [data]);

	const [organizationName, setOrganizationName] = useState("");
	const [organizationData, setOrganizationData] = useState({});
	const [organizationDetails, setOrganizationDetails] = useState({});
	const [organizationLicense, setOrganizationLicense] = useState("");

	const [loanOriginatorEmail, setLoanOriginatorEmail] = useState("");
	const [organizationUsers, setOrganizationUsers] = useState([]);
	const [loanOriginator, setLoanOriginator] = useState({});

	const [loanProcessorEmail, setLoanProcessorEmail] = useState("");
	const [organizationProcessor, setOrganizationProcessor] = useState([]);
	const [loanProcessor, setLoanProcessor] = useState({});

	const [getOrganizationDetails, { isLoading: organizationDetailsLoading }] =
		useGetOrganizationDetailsMutation();

	const getFieldValues = async (loanId) => {
		console.log("175 loan id:", loanId);
		try {
			const getValues = await field({
				loan_guid: loanId,
				// 3629: origanization state license
				// 2306: originator state license
				// TPO.X67: originator cell phone
				// TPO.X80: processor cell phone
				fields: "2306,TPO.X67,TPO.X80",
			}).unwrap();
			console.log("175 all fields:", getValues?.response);
			setFieldValues(getValues?.response);
		} catch (err) {}
	};

	useEffect(() => {
		console.log("160 loan guid:", loanGuid);
		if (loanGuid !== undefined && loanGuid !== "") {
			getFieldValues(loanGuid);
		}
	}, [loanGuid]);

	useEffect(() => {
		console.log("111 data:", data?.tPO);
		if (data?.tPO !== undefined && fieldValues?.length > 0) {
			setOrganizationName(data?.tPO?.companyName);
			dispatch(setOrganizationNameReducer(data?.tPO?.companyName));

			console.log("110 field values:", fieldValues);
			console.log("110 org data:", organizationData);

			setLoanOriginatorEmail(data?.tPO?.lOEmail);
			let user = {};
			user.email = data?.tPO?.lOEmail;
			user.phone = data?.tPO?.lOBusinessPhone;
			let organizationUserInfo = { user };

			console.log("120 organizationUserInfo:", [organizationUserInfo]);
			setOrganizationUsers([{ label: user.email, value: user.email }]);

			let userInfo = {};
			let userChild = {};
			userChild.email = data?.tPO?.lOEmail;
			userChild.name = data?.tPO.lOName;
			userInfo.phone = data?.tPO?.lOBusinessPhone;

			userInfo.stateLicense = fieldValues?.find(
				(o) => o.fieldId === "2306"
			)?.value;
			userInfo.cell_phone = fieldValues?.find(
				(o) => o.fieldId === "TPO.X67"
			)?.value;

			userInfo["nmls_id"] = data?.nmlsLoanOriginatorId;
			userInfo = { ...userInfo, user: userChild };
			console.log("95 user info:", userInfo);
			setLoanOriginator(userInfo);

			setLoanProcessorEmail(data?.tPO?.lPEmail);
			let processorUser = {};
			processorUser.email = data?.tPO?.lPEmail;
			processorUser.phone = data?.tPO?.lPBusinessPhone;
			let organizationProcessorUserInfo = { user: processorUser };

			console.log("120 processorUser:", [organizationProcessorUserInfo]);
			setOrganizationProcessor([
				{ label: processorUser.email, value: processorUser.email },
			]);

			let processorUserInfo = {};
			let processorUserChild = {};
			processorUserChild.email = data?.tPO?.lPEmail;
			processorUserChild.name = data?.tPO.lPName;
			processorUserInfo.phone = data?.tPO?.lPBusinessPhone;
			processorUserInfo["nmls_id"] = data?.nmlsLoanOriginatorId;
			processorUserInfo.cell_phone = fieldValues?.find(
				(o) => o.fieldId === "TPO.X80"
			)?.value;
			processorUserInfo = { ...processorUserInfo, user: processorUserChild };
			setLoanProcessor(processorUserInfo);
		}
	}, [data, fieldValues]);

	useEffect(() => {
		const getOrganizationDetailsFunc = async () => {
			const searchResults = await getOrganizationDetails({
				organizationName,
			}).unwrap();
			setOrganizationDetails(searchResults);
		};

		if (organizationName !== undefined && organizationName !== "") {
			getOrganizationDetailsFunc();
		}
	}, [organizationName]);

	useEffect(() => {
		const getAllBorrowerPair = async () => {
			const borrowerPairData = await getBorrowerPair({
				loanGuid,
			}).unwrap();

			let pairArr = [];

			borrowerPairData?.response?.map((item) =>
				pairArr.push(item.borrower, item.coborrower)
			);

			console.log("135 pair arr:", pairArr);
			setLoanApplicationData(pairArr);
			handleCloseLoaderModal();
		};

		if (loanGuid !== null && loanGuid !== undefined && loanGuid !== "") {
			handleOpenLoaderModal();
			getAllBorrowerPair();
		}
	}, [loanGuid]);

	const handleNext = async () => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});
		setLoanApplicationStage(1);
	};

	function a11yProps(index) {
		return {
			id: `simple-tab-${index}`,
			"aria-controls": `simple-tabpanel-${index}`,
		};
	}

	const handleChangeTab = (event, newValue) => {
		setTabValue(newValue);
	};

	return (
		<Stack direction="column" className={styles.stackContainer}>
			<Stack className={styles.bodyContainer}>
				{/* tabs section */}
				{loanApplicationData?.length > 2 && (
					<Tabs
						value={tabValue}
						onChange={handleChangeTab}
						aria-label="borrower tabs"
						TabIndicatorProps={{
							style: {
								backgroundColor: "#76D99E",
							},
						}}
					>
						<Tab
							className={styles.tabBtnContainer}
							label={
								<Stack direction="row" className={styles.tabLabelContainer}>
									<Heading6
										text="Borrower Pair 1"
										fontType="semibold"
										extraClass={
											tabValue === 0 ? styles.activeTabText : styles.tabText
										}
									/>
								</Stack>
							}
							{...a11yProps(0)}
						/>
						<Tab
							className={styles.tabBtnContainer}
							label={
								<Stack direction="row" className={styles.tabLabelContainer}>
									<Heading6
										text="Borrower Pair 2"
										fontType="semibold"
										extraClass={
											tabValue === 1 ? styles.activeTabText : styles.tabText
										}
									/>
								</Stack>
							}
							{...a11yProps(1)}
						/>
						{loanApplicationData?.length > 4 && (
							<Tab
								className={styles.tabBtnContainer}
								label={
									<Stack direction="row" className={styles.tabLabelContainer}>
										<Heading6
											text="Borrower Pair 3"
											fontType="semibold"
											extraClass={
												tabValue === 2 ? styles.activeTabText : styles.tabText
											}
										/>
									</Stack>
								}
								{...a11yProps(2)}
							/>
						)}
					</Tabs>
				)}

				{/* borrower pair 1 */}
				{loanApplicationData?.length > 2 && (
					<TabPanel
						index={0}
						value={tabValue}
						className={tabValue === 0 ? styles.tabPanelContainer : ""}
					>
						<ViewLoanInfoCard
							loanNumber={loanNumber}
							borrower={loanApplicationData[0]}
							coBorrower={loanApplicationData[1]}
							propertyInfo={propertyInfo}
						/>
						{loanApplicationData?.slice(0, 2)?.map((borrower, index) => (
							<BorrowerInformation
								key={index}
								borrowerIndex={index}
								coBorrower={index % 2 !== 0}
								borrowerData={borrower}
								data={loanApplicationData}
								setData={setLoanApplicationData}
								error={error.includes("provide a value")}
								disabled={true}
								viewLoan={true}
							/>
						))}

						<PropertyDetails
							data={propertyInfo}
							setData={setPropertyInfo}
							loanData={loanApplicationData}
							error={error.includes("provide a value")}
							disabled={true}
						/>

						<LoanOriginator
							organizationName={organizationName}
							organizationDetails={organizationDetails}
							loanOriginatorEmail={loanOriginatorEmail}
							setLoanOriginatorEmail={setLoanOriginatorEmail}
							organizationUsers={organizationUsers}
							loanOriginator={loanOriginator}
							setOrganizationLicense={setOrganizationLicense}
							propertyState={propertyInfo?.state}
							disabled={true}
							viewLoan={true}
						/>

						<LoanProcessor
							organizationName={organizationName}
							organizationDetails={organizationDetails}
							loanProcessorEmail={loanProcessorEmail}
							setLoanProcessorEmail={setLoanProcessorEmail}
							organizationProcessor={organizationProcessor}
							loanProcessor={loanProcessor}
							propertyState={propertyInfo?.state}
							error={error.includes("provide a value")}
							disabled={true}
							viewLoan={true}
						/>
					</TabPanel>
				)}

				{/* borrower pair 2 */}
				{loanApplicationData?.length > 2 && (
					<TabPanel
						index={1}
						value={tabValue}
						className={tabValue === 1 ? styles.tabPanelContainer : ""}
					>
						<ViewLoanInfoCard
							loanNumber={loanNumber}
							borrower={loanApplicationData[2]}
							coBorrower={loanApplicationData[3]}
							propertyInfo={propertyInfo}
						/>
						{loanApplicationData?.slice(2, 4)?.map((borrower, index) => (
							<BorrowerInformation
								key={index}
								borrowerIndex={index + 2}
								coBorrower={index % 2 !== 0}
								borrowerData={borrower}
								data={loanApplicationData}
								setData={setLoanApplicationData}
								error={error.includes("provide a value")}
								disabled={true}
								viewLoan={true}
							/>
						))}

						<PropertyDetails
							data={propertyInfo}
							setData={setPropertyInfo}
							loanData={loanApplicationData}
							error={error.includes("provide a value")}
							disabled={true}
						/>

						<LoanOriginator
							organizationName={organizationName}
							organizationDetails={organizationDetails}
							loanOriginatorEmail={loanOriginatorEmail}
							setLoanOriginatorEmail={setLoanOriginatorEmail}
							organizationUsers={organizationUsers}
							loanOriginator={loanOriginator}
							setOrganizationLicense={setOrganizationLicense}
							propertyState={propertyInfo?.state}
							disabled={true}
							viewLoan={true}
						/>

						<LoanProcessor
							organizationName={organizationName}
							organizationDetails={organizationDetails}
							loanProcessorEmail={loanProcessorEmail}
							setLoanProcessorEmail={setLoanProcessorEmail}
							organizationProcessor={organizationProcessor}
							loanProcessor={loanProcessor}
							propertyState={propertyInfo?.state}
							error={error.includes("provide a value")}
							disabled={true}
							viewLoan={true}
						/>
					</TabPanel>
				)}

				{/* borrower pair 3 */}
				{loanApplicationData?.length > 2 && (
					<TabPanel
						index={2}
						value={tabValue}
						className={tabValue === 2 ? styles.tabPanelContainer : ""}
					>
						<ViewLoanInfoCard
							loanNumber={loanNumber}
							borrower={loanApplicationData[4]}
							coBorrower={loanApplicationData[5]}
							propertyInfo={propertyInfo}
						/>
						{loanApplicationData?.slice(4, 6)?.map((borrower, index = 4) => (
							<BorrowerInformation
								key={index}
								borrowerIndex={index + 4}
								coBorrower={index % 2 !== 0}
								borrowerData={borrower}
								data={loanApplicationData}
								setData={setLoanApplicationData}
								error={error.includes("provide a value")}
								disabled={true}
								viewLoan={true}
							/>
						))}

						<PropertyDetails
							data={propertyInfo}
							setData={setPropertyInfo}
							loanData={loanApplicationData}
							error={error.includes("provide a value")}
							disabled={true}
						/>

						<LoanOriginator
							organizationName={organizationName}
							organizationDetails={organizationDetails}
							loanOriginatorEmail={loanOriginatorEmail}
							setLoanOriginatorEmail={setLoanOriginatorEmail}
							organizationUsers={organizationUsers}
							loanOriginator={loanOriginator}
							setOrganizationLicense={setOrganizationLicense}
							propertyState={propertyInfo?.state}
							disabled={true}
							viewLoan={true}
						/>

						<LoanProcessor
							organizationName={organizationName}
							organizationDetails={organizationDetails}
							loanProcessorEmail={loanProcessorEmail}
							setLoanProcessorEmail={setLoanProcessorEmail}
							organizationProcessor={organizationProcessor}
							loanProcessor={loanProcessor}
							propertyState={propertyInfo?.state}
							error={error.includes("provide a value")}
							disabled={true}
							viewLoan={true}
						/>
					</TabPanel>
				)}

				{/* default view no tabs */}
				{loanApplicationData?.length <= 2 && (
					<Box className={styles.tabPanelContainer}>
						<ViewLoanInfoCard
							loanNumber={loanNumber}
							borrower={loanApplicationData[0]}
							coBorrower={loanApplicationData[1]}
							propertyInfo={propertyInfo}
						/>

						{loanApplicationData?.map((borrower, index) => (
							<BorrowerInformation
								key={index}
								borrowerIndex={index}
								coBorrower={index % 2 !== 0}
								borrowerData={borrower}
								data={loanApplicationData}
								setData={setLoanApplicationData}
								error={error.includes("provide a value")}
								disabled={true}
								viewLoan={true}
							/>
						))}

						<PropertyDetails
							data={propertyInfo}
							setData={setPropertyInfo}
							loanData={loanApplicationData}
							error={error.includes("provide a value")}
							disabled={true}
						/>

						<LoanOriginator
							organizationName={organizationName}
							organizationDetails={organizationDetails}
							loanOriginatorEmail={loanOriginatorEmail}
							setLoanOriginatorEmail={setLoanOriginatorEmail}
							organizationUsers={organizationUsers}
							loanOriginator={loanOriginator}
							setOrganizationLicense={setOrganizationLicense}
							propertyState={propertyInfo?.state}
							disabled={true}
							viewLoan={true}
						/>

						<LoanProcessor
							organizationName={organizationName}
							organizationDetails={organizationDetails}
							loanProcessorEmail={loanProcessorEmail}
							setLoanProcessorEmail={setLoanProcessorEmail}
							organizationProcessor={organizationProcessor}
							loanProcessor={loanProcessor}
							propertyState={propertyInfo?.state}
							error={error.includes("provide a value")}
							disabled={true}
							viewLoan={true}
						/>
					</Box>
				)}
			</Stack>

			<Stack direction="row" className={styles.footerContainer}>
				<PrimaryButton
					text="Continue"
					endIcon={<ArrowForwardOutlined className={styles.nextIcon} />}
					onClick={handleNext}
				/>
			</Stack>
		</Stack>
	);
};

export default ViewLoanApplication;
