import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// mui components
import { Box, Stack, Tab, Tabs } from "@mui/material";

// custom components
import SecondaryButton from "../../components/buttons/secondary-button";
import CustomLayout from "../../components/layout";
import LoanSummaryCard from "../../components/card/loan-summary";
import TabPanel from "../../components/tab-panel";

import LockInfoCard from "../../components/card/lock-info";
import ConditionTrackingCard from "../../components/card/condition-tracking";
import ChangeRequestCard from "../../components/card/change-request";
import DocumentsCard from "../../components/card/documents";

import TemporaryDrawer from "../../components/temporary-drawer";

// custom modal
import LoadingDataModal from "../../components/modal/loading-data-modal";
import ProcessingModal from "../../components/modal/processing-modal";
import SuccessModal from "../../components/modal/success-modal";
import ErrorModal from "../../components/modal/error-modal";

// api slice
import { useGetLoanSummaryQuery } from "../../features/loanSummary/loanSummaryApiSlice";
import { useFieldMutation } from "../../features/field-values/fieldValueApiSlice";

// custom styles
import styles from "./index.module.scss";

// custom mui styles
import "./mui-styles.scss";

const LoanSummary = () => {
	const params = useParams();

	const [tabValue, setTabValue] = useState(false);

	const [selectedCondition, setSelectedCondition] = useState("");

	const [processingText, setProcessingText] = useState("");
	const [successText, setSuccessText] = useState("");

	const [drawerType, setDrawerType] = useState("");
	const [drawerOpen, setDrawerOpen] = useState({});

	const handleOpenDrawer = () => {
		setDrawerOpen({
			right: true,
		});
	};

	const handleCloseDrawer = () => {
		setDrawerOpen({
			right: false,
		});
	};

	const [processingModalVisible, setProcessingModalVisible] = useState(false);

	const handleOpenProcessingModal = () => {
		setProcessingModalVisible(true);
	};

	const handleCloseProcessingModal = () => {
		setProcessingModalVisible(false);
	};

	const [successModalVisible, setSuccessModalVisible] = useState(false);

	const handleOpenSuccessModal = () => {
		setSuccessModalVisible(true);
	};

	const handleCloseSuccessModal = () => {
		setSuccessModalVisible(false);
	};

	const [errorModalText, setErrorModalText] = useState("");
	const [errorModalVisible, setErrorModalVisible] = useState(false);
	const handleOpenErrorModal = () => {
		setErrorModalVisible(true);
	};

	const handleCloseErrorModal = () => {
		setErrorModalVisible(false);
	};

	const { data: loanSummaryData, isLoading: loadingLoanData } =
		useGetLoanSummaryQuery(params?.id);

	const [loanData, setLoanData] = useState({});

	useEffect(() => {
		if (loanSummaryData !== undefined) {
			setLoanData(loanSummaryData?.data);
		}
	}, [loanSummaryData]);

	const [loadingDataText, setLoadingDataText] = useState("");
	const [loadingDataModal, setLoadingDataModal] = useState(false);

	const handleOpenLoadingDataModal = () => {
		setLoadingDataModal(true);
	};

	const handleCloseLoadingDataModal = () => {
		setLoadingDataModal(false);
	};

	useEffect(() => {
		if (loadingLoanData) {
			handleOpenLoadingDataModal();
			setLoadingDataText("Setting up the loan details");
		} else {
			handleCloseLoadingDataModal();
		}
	}, [loadingLoanData]);

	const [field] = useFieldMutation();

	const [fieldValues, setFieldValues] = useState([]);

	useEffect(() => {
		const getFieldValues = async () => {
			try {
				const getValues = await field({
					loan_guid: params?.id,
					fields:
						"2,URLA.X73,URLA.X75,12,14,4000,4001,4002,4004,4005,4006,3,2400,VASUMM.X23,Log.MS.CurrentMilestone,1401,CX.LOCKREQUESTTIMESTAMP,2149,2151,3420,2202,2203,2160",
				}).unwrap();
				setFieldValues(getValues?.response);
			} catch (err) {
				// setError(err?.data?.message);
			}
		};

		getFieldValues();
	}, []);

	function a11yProps(index) {
		return {
			id: `simple-tab-${index}`,
			"aria-controls": `simple-tabpanel-${index}`,
		};
	}

	const handleTabChange = (event, value) => {
		setTabValue(value);
	};

	const toggleDrawer = (event, drawerType) => {
		if (drawerType !== undefined && drawerType !== "") {
			setDrawerType(drawerType);
			handleOpenDrawer();
		} else {
			setDrawerType("");
			handleCloseDrawer();
		}
	};

	const breadcrumbData = [
		{
			label: "Home",
			path: "/home",
		},
		{
			label: "Loan Pipeline",
			path: "/pipeline",
		},
		{
			label: loanData?.loanNumber,
			path: "",
			handleClick: (e) => handleTabChange(e, false),
		},
	];

	return (
		<Box>
			<CustomLayout breadcrumb={breadcrumbData} disabledOverflow={true}>
				<Stack
					direction="column"
					className={styles.stackContainer}
					sx={{
						overflow: "hidden",
					}}
				>
					<LoanSummaryCard loanData={loanData} fieldData={fieldValues} />

					<Stack direction="column" className={styles.bodyContainer}>
						<Stack
							direction="row"
							className={`${styles.tabsContainer} tabStackContainer`}
						>
							<Tabs
								value={tabValue}
								onChange={handleTabChange}
								className={styles.tabContainer}
								TabIndicatorProps={{
									style: { display: "none" },
								}}
							>
								<Tab
									className={styles.tabBtnContainer}
									component="div"
									label={
										<SecondaryButton
											text="Lock Information and Activities"
											extraClass={
												tabValue === 0 ? styles.selectedTabBtn : styles.tabBtn
											}
											disabled={tabValue !== false && tabValue !== 0}
										/>
									}
									disabled={tabValue !== false && tabValue !== 0}
									{...a11yProps(0)}
								/>
								<Tab
									className={styles.tabBtnContainer}
									component="div"
									label={
										<SecondaryButton
											text="Condition Tracking"
											extraClass={
												tabValue === 1 ? styles.selectedTabBtn : styles.tabBtn
											}
											disabled={tabValue !== false && tabValue !== 1}
										/>
									}
									disabled={tabValue !== false && tabValue !== 1}
									{...a11yProps(1)}
								/>
								<Tab
									className={styles.tabBtnContainer}
									component="div"
									label={
										<SecondaryButton
											text="Change Requests"
											extraClass={
												tabValue === 2 ? styles.selectedTabBtn : styles.tabBtn
											}
											disabled={tabValue !== false && tabValue !== 2}
										/>
									}
									disabled={tabValue !== false && tabValue !== 2}
									{...a11yProps(2)}
								/>
								<Tab
									className={styles.tabBtnContainer}
									component="div"
									label={
										<SecondaryButton
											text="Documents"
											extraClass={
												tabValue === 3 ? styles.selectedTabBtn : styles.tabBtn
											}
											disabled={tabValue !== false && tabValue !== 3}
										/>
									}
									disabled={tabValue !== false && tabValue !== 3}
									{...a11yProps(3)}
								/>
							</Tabs>
						</Stack>

						<Stack direction="column" className={styles.tabPanelContainer}>
							<TabPanel value={tabValue} index={0}>
								<LockInfoCard
									loanData={loanData}
									fieldData={fieldValues}
									toggleDrawer={toggleDrawer}
									handleTabChange={handleTabChange}
									// loading data modal
									handleOpenLoadingDataModal={handleOpenLoadingDataModal}
									handleCloseLoadingDataModal={handleCloseLoadingDataModal}
									setLoadingDataText={setLoadingDataText}
									// error modal
									handleOpenErrorModal={handleOpenErrorModal}
									setErrorModalText={setErrorModalText}
								/>
							</TabPanel>

							<TabPanel value={tabValue} index={1}>
								<ConditionTrackingCard
									toggleDrawer={toggleDrawer}
									handleTabChange={handleTabChange}
									// loading data modal
									handleOpenLoadingDataModal={handleOpenLoadingDataModal}
									handleCloseLoadingDataModal={handleCloseLoadingDataModal}
									setLoadingDataText={setLoadingDataText}
									// error modal
									handleOpenErrorModal={handleOpenErrorModal}
									setErrorModalText={setErrorModalText}
									// custom functions
									setSelectedCondition={setSelectedCondition}
								/>
							</TabPanel>

							<TabPanel value={tabValue} index={2}>
								<ChangeRequestCard
									toggleDrawer={toggleDrawer}
									handleTabChange={handleTabChange}
									// loading data modal
									handleOpenLoadingDataModal={handleOpenLoadingDataModal}
									handleCloseLoadingDataModal={handleCloseLoadingDataModal}
									// error modal
									handleOpenErrorModal={handleOpenErrorModal}
									setErrorModalText={setErrorModalText}
								/>
							</TabPanel>

							<TabPanel value={tabValue} index={3}>
								<DocumentsCard
									toggleDrawer={toggleDrawer}
									handleTabChange={handleTabChange}
									// loading data modal
									handleOpenLoadingDataModal={handleOpenLoadingDataModal}
									handleCloseLoadingDataModal={handleCloseLoadingDataModal}
									setLoadingDataText={setLoadingDataText}
								/>
							</TabPanel>
						</Stack>
					</Stack>
				</Stack>

				<TemporaryDrawer
					// drawer props
					drawerOpen={drawerOpen}
					drawerDirection="right"
					drawerType={drawerType}
					toggleDrawer={toggleDrawer}
					handleCloseDrawer={handleCloseDrawer}
					handleOpenProcessingModal={handleOpenProcessingModal}
					handleCloseProcessingModal={handleCloseProcessingModal}
					setProcessingText={setProcessingText}
					handleOpenSuccessModal={handleOpenSuccessModal}
					setSuccessText={setSuccessText}
					// request-lock props
					loanData={loanData}
					fieldData={fieldValues}
					// condition tracking props
					selectedCondition={selectedCondition}
					setSelectedCondition={setSelectedCondition}
				/>
			</CustomLayout>

			{/* modals */}
			<LoadingDataModal open={loadingDataModal} text={loadingDataText} />
			<ProcessingModal open={processingModalVisible} text={processingText} />
			<SuccessModal
				open={successModalVisible}
				handleClose={handleCloseSuccessModal}
				text={successText}
			/>
			<ErrorModal
				open={errorModalVisible}
				handleClose={handleCloseErrorModal}
				text={errorModalText}
			/>
		</Box>
	);
};

export default LoanSummary;
