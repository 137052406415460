const { DateTime } = require("luxon");

export const stableSort = (array, comparator) => {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) {
			return order;
		}
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
};

export const descendingComparator = (a, b, orderBy) => {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
};

export const getComparator = (order, orderBy) => {
	return order === "desc"
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
};

const getFieldValue = (obj, property) => {
	if (property in obj) {
		return obj[property];
	} else {
		const propertyParts = property
			.split(/[\[\]]+/)
			.filter((part) => part.length > 0);
		let value = obj;
		for (let part of propertyParts) {
			if (value && typeof value === "object") {
				const nestedProperty = part.replace(/['"]/g, ""); // Remove quotes if present
				if (nestedProperty in value) {
					value = value[nestedProperty];
				} else {
					return undefined;
				}
			} else {
				return undefined;
			}
		}
		return value;
	}
};

export const sortAmount = (array, sortOrder, fieldId) => {
	var sortedArr = [...array];

	return sortOrder === "desc"
		? sortedArr.sort(
				(a, b) =>
					getFieldValue(b, fieldId).localeCompare(getFieldValue(a, fieldId)) ||
					!getFieldValue(b, fieldId) - !getFieldValue(a, fieldId)
		  )
		: sortedArr.sort(
				(a, b) =>
					!getFieldValue(a, fieldId) - !getFieldValue(b, fieldId) ||
					getFieldValue(a, fieldId).localeCompare(getFieldValue(b, fieldId))
		  );
};

const recognizeLuxonFormat = (dateString) => {
	const potentialFormats = [
		"M/d/yyyy hh:mm:ss a",
		"MM/dd/yyyy h:mm a",
		"MMMM dd, yyyy HH:mm:ss",
	];

	for (const format of potentialFormats) {
		const parsedDate = DateTime.fromFormat(dateString, format);
		if (parsedDate.isValid) {
			return format;
		}
	}

	return false; // Format not recognized
};

const convertTimezone = (dateString) => {
	const createdOnLuxon = DateTime.fromISO(dateString, { zone: "utc" });

	return createdOnLuxon;
};

export const sortDate = (array, sortOrder, fieldId) => {
	var sortedArr = [...array];

	sortedArr.sort((a, b) => {
		const dateA =
			getFieldValue(a, fieldId)?.includes("T") ||
			getFieldValue(a, fieldId)?.includes("Z")
				? convertTimezone(a[fieldId])
				: getFieldValue(a, fieldId)
				? DateTime.fromFormat(
						getFieldValue(a, fieldId),
						recognizeLuxonFormat(getFieldValue(a, fieldId))
				  )
				: null;
		const dateB =
			getFieldValue(b, fieldId)?.includes("T") ||
			getFieldValue(b, fieldId)?.includes("Z")
				? convertTimezone(b[fieldId])
				: getFieldValue(b, fieldId)
				? DateTime.fromFormat(
						getFieldValue(b, fieldId),
						recognizeLuxonFormat(getFieldValue(b, fieldId))
				  )
				: null;

		if (dateA && dateB) {
			if (sortOrder === "asc") {
				return dateA - dateB;
			} else {
				return dateB - dateA;
			}
		} else if (dateA) {
			return -1;
		} else if (dateB) {
			return 1;
		} else {
			return 0;
		}
	});

	return sortedArr;
};
