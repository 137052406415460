import React, { useState, useEffect } from "react";
import {
	Autocomplete,
	Box,
	Checkbox,
	Stack,
	TableCell,
	TableHead,
	TableRow,
	TableSortLabel,
	TextField,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";

// helper functions
import { sortAmount, sortDate } from "../../../utils/sort-methods";

// mui components
import OutlinedTextInput from "../../custom-input/outlined-text-input";
import Heading4 from "../../typography/heading-04";

// custom styles
import styles from "./index.module.scss";

const TableHeadSearchSort = (props) => {
	const {
		data,
		rowData,
		// sort
		order,
		orderBy,
		onRequestSort,
		filteredLoans,
		setFilteredLoans,
	} = props;

	const [firstRender, setFirstRender] = useState(true);
	const [selectedLoanFolder, setSelectedLoanFolder] = useState([]);
	const [searchValue, setSearchValue] = useState({
		propertyAddress: "",
		startDate: "",
		borrowerName: "",
		loanAmount: "",
		loanStatus: "",
		salesRep: "",
		loanNumber: "",
		lockExpirationDate: "",
		loanProgram: "",
		loanFolder: "",
	});

	const createSortHandler = (property) => (event) => {
		let fieldId;

		if (property === "number") {
			fieldId = "fields[Loan.LoanNumber]";
		} else if (property === "expiration-date") {
			fieldId = "fields[Fields.762]";
		} else if (property === "days-to-expiry") {
			fieldId = "fields[Fields.432]";
		} else if (property === "program") {
			fieldId = "fields[Fields.1401]";
		} else if (property === "name") {
			fieldId = "fields[Loan.BorrowerName]";
		} else if (property === "folder") {
			fieldId = "fields[Loan.LoanFolder]";
		} else if (property === "status") {
			fieldId = "fields[Fields.Log.MS.CurrentMilestone]";
		} else if (property === "start-date") {
			fieldId = "fields[Fields.2025]";
		} else if (property === "address") {
			fieldId = "fields[Fields.URLA.X73]";
		} else if (property === "label") {
			fieldId = "title";
		} else if (property === "upload-date") {
			fieldId = "createdDate";
		}

		let sortedData;

		if (property.includes("date")) {
			sortedData = sortDate(filteredLoans, order, fieldId);
		} else {
			sortedData = sortAmount(filteredLoans, order, fieldId);
		}

		setFilteredLoans(sortedData);
		onRequestSort(event, property);
	};

	const handleSearchTable = (value, name) => {
		console.log("value:", value);
		console.log("name:", name);

		let updatedSearchValue = { ...searchValue, [name]: value };
		setSearchValue(updatedSearchValue);

		console.log("100 updated search value:", updatedSearchValue);

		let searchedLoans = [];

		searchedLoans = rowData.filter((item) => {
			let loanStatusName = "";

			if (updatedSearchValue?.loanStatus !== "") {
				if (item?.fields["Loan.LoanFolder"] === "Portal - Not Submitted") {
					loanStatusName = "Started - Not Submitted";
				} else if (
					item?.fields["Fields.Log.MS.CurrentMilestone"] === "Started"
				) {
					loanStatusName = "Loan Submitted";
				} else if (
					item?.fields["Fields.Log.MS.CurrentMilestone"] === "Loan Submitted"
				) {
					loanStatusName = "Loan Submitted";
				} else if (
					item?.fields["Fields.Log.MS.CurrentMilestone"] === "Review Submission"
				) {
					loanStatusName = "Review Submission";
				} else if (
					item?.fields["Fields.Log.MS.CurrentMilestone"] ===
					"Incomplete Credit Pkg"
				) {
					loanStatusName = "Incomplete Credit Pkg";
				} else if (
					item?.fields["Fields.Log.MS.CurrentMilestone"] === "Recd Cred Pkg"
				) {
					loanStatusName = "Recd Cred Pkg";
				} else if (
					item?.fields["Fields.Log.MS.CurrentMilestone"] === "Review Cred Pkg"
				) {
					loanStatusName = "Recd Cred Pkg";
				} else if (
					item?.fields["Fields.Log.MS.CurrentMilestone"] === "File Setup"
				) {
					loanStatusName = "File Setup";
				} else if (
					item?.fields["Fields.Log.MS.CurrentMilestone"] === "Submit to UW"
				) {
					loanStatusName = "In Underwriting";
				} else if (
					item?.fields["Fields.Log.MS.CurrentMilestone"] === "In UWing Review"
				) {
					loanStatusName = "In Underwriting";
				} else if (
					item?.fields["Fields.Log.MS.CurrentMilestone"] === "Suspended"
				) {
					loanStatusName = "Suspended";
				} else if (
					item?.fields["Fields.Log.MS.CurrentMilestone"] === "Cond'l Approval"
				) {
					loanStatusName = "Conditional Approval";
				} else if (
					item?.fields["Fields.Log.MS.CurrentMilestone"] === "Recd Conditions"
				) {
					loanStatusName = "Recd Conditions";
				} else if (
					item?.fields["Fields.Log.MS.CurrentMilestone"] === "UW Cond Review"
				) {
					loanStatusName = "UW Cond Review";
				} else if (
					item?.fields["Fields.Log.MS.CurrentMilestone"] === "QC Requested"
				) {
					loanStatusName = "In Underwriting";
				} else if (
					item?.fields["Fields.Log.MS.CurrentMilestone"] === "QC Complete"
				) {
					loanStatusName = "In Underwriting";
				} else if (item?.fields["Fields.Log.MS.CurrentMilestone"] === "CTC") {
					loanStatusName = "Clear to Close";
				} else if (
					item?.fields["Fields.Log.MS.CurrentMilestone"] === "Closing Prep"
				) {
					loanStatusName = "Closing Docs";
				} else if (
					item?.fields["Fields.Log.MS.CurrentMilestone"] === "Closing"
				) {
					loanStatusName = "Closing";
				} else if (
					item?.fields["Fields.Log.MS.CurrentMilestone"] === "Funding Prep"
				) {
					loanStatusName = "Wire Sent";
				} else if (
					item?.fields["Fields.Log.MS.CurrentMilestone"] === "Funding"
				) {
					loanStatusName = "Funded";
				}
			}

			let propertyAddressValue =
				item?.fields["Fields.URLA.X73"]?.toLowerCase() +
				"," +
				item?.fields["Fields.URLA.X75"]?.toLowerCase() +
				"," +
				item?.fields["Fields.12"]?.toLowerCase() +
				"," +
				item?.fields["Fields.14"]?.toLowerCase();

			return (
				propertyAddressValue
					.toString()
					?.includes(updatedSearchValue?.propertyAddress?.toLowerCase()) &&
				item?.fields["Fields.2025"]
					?.split(" ")[0]
					?.toString()
					?.includes(updatedSearchValue?.startDate) &&
				item.fields["Loan.BorrowerName"]
					?.toLowerCase()
					?.includes(updatedSearchValue?.borrowerName?.toLowerCase()) &&
				item.fields["Loan.LoanAmount"]?.includes(
					updatedSearchValue?.loanAmount
				) &&
				loanStatusName
					?.toLowerCase()
					?.includes(updatedSearchValue?.loanStatus?.toLowerCase()) &&
				item.fields["Loan.LoanAmount"]?.includes(
					updatedSearchValue?.salesRep?.toLowerCase()
				) &&
				(item?.fields["Loan.LoanFolder"] !== "Portal - Not Submitted"
					? // loan number
					  item.fields["Loan.LoanNumber"]?.includes(
							updatedSearchValue?.loanNumber
					  )
					: // incomplete loans
					  "Incomplete"
							?.toLowerCase()
							?.includes(updatedSearchValue?.loanNumber?.toLowerCase())) &&
				item.fields["Fields.1401"]
					?.toLowerCase()
					?.includes(updatedSearchValue?.loanProgram?.toLowerCase()) &&
				item?.fields["Fields.762"]
					?.split(" ")[0]
					?.toString()
					?.includes(updatedSearchValue?.lockExpirationDate) &&
				(updatedSearchValue?.loanFolder?.length > 0
					? updatedSearchValue?.loanFolder?.includes(
							item.fields["Loan.LoanFolder"]
					  )
					: true)
			);
		});

		setFilteredLoans(searchedLoans);
	};

	const handleSelectFolder = (selectedList) => {
		handleSearchTable(selectedList, "loanFolder", "Loan.LoanFolder");
		setSelectedLoanFolder(selectedList);
	};

	useEffect(() => {
		if (firstRender && rowData?.length > 0) {
			handleSelectFolder(["Pipeline", "Portal - Not Submitted"]);
			setFirstRender(false);
		}
	}, [rowData]);

	return (
		<TableHead>
			<TableRow>
				{data.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={headCell.numeric ? "right" : "left"}
						padding={headCell.disablePadding ? "none" : "normal"}
						sortDirection={orderBy === headCell.id ? order : false}
						style={{ minWidth: headCell.minWidth }}
					>
						<Stack direction="column" className={styles.headerStack}>
							<TableSortLabel
								active={orderBy === headCell.id}
								direction={orderBy === headCell.id ? order : "asc"}
								onClick={createSortHandler(headCell.id)}
								className={styles.tableLabel}
							>
								<Heading4
									text={headCell.label}
									extraClass={styles.thText}
									fontType="semibold"
								/>
								{orderBy === headCell.id ? (
									<Box component="span" sx={visuallyHidden}>
										{order === "desc"
											? "sorted descending"
											: "sorted ascending"}
									</Box>
								) : null}
							</TableSortLabel>
							{headCell.label === "Loan Folder" ? (
								<Autocomplete
									sx={{
										"& .MuiOutlinedInput-root": {
											paddingTop: "0px",
											paddingLeft: "0px",
											paddingBottom: "0px",
										},
									}}
									multiple
									limitTags={1}
									id="checkboxes-tags"
									className={styles.inputField}
									options={[
										"Pipeline",
										"Portal - Not Submitted",
										"Funded",
										"Adverse Loans",
									]}
									value={selectedLoanFolder}
									onChange={(event, newValue) => {
										handleSelectFolder(newValue);
									}}
									getOptionLabel={(option) => option}
									renderOption={(props, option, { selected }) => (
										<li {...props}>
											<Checkbox
												// TODO aqib uncomment if needed
												// icon={icon}
												// checkedIcon={checkedIcon}
												style={{ marginRight: 8 }}
												checked={selected}
											/>
											{option}
										</li>
									)}
									style={{ width: 300 }}
									renderInput={(params) => (
										<TextField
											{...params}
											sx={{
												"& .MuiOutlinedInput-notchedOutline": {
													borderWidth: "1px !important",
													borderColor: "#000 !important",
													borderRadius: "2px",
												},
											}}
											InputLabelProps={{ shrink: false }}
											placeholder="Search here..."
										/>
									)}
								/>
							) : (
								<OutlinedTextInput
									extraClass={styles.inputField}
									placeholder="Search here..."
									name={
										headCell.label === "Property Address"
											? "propertyAddress"
											: headCell.label === "Start Date"
											? "startDate"
											: headCell.label === "Borrower Name"
											? "borrowerName"
											: headCell.label === "Loan Amount"
											? "loanAmount"
											: headCell.label === "Loan Status"
											? "loanStatus"
											: headCell.label === "Sales Rep."
											? "salesRep"
											: headCell.label === "Loan Number"
											? "loanNumber"
											: headCell.label === "Lock Expiration Date"
											? "lockExpirationDate"
											: headCell.label === "Loan Program" && "loanProgram"
									}
									value={
										headCell.label === "Property Address"
											? searchValue?.propertyAddress || ""
											: headCell.label === "Start Date"
											? searchValue?.startDate || ""
											: headCell.label === "Borrower Name"
											? searchValue?.borrowerName || ""
											: headCell.label === "Loan Amount"
											? searchValue?.loanAmount || ""
											: headCell.label === "Loan Status"
											? searchValue?.loanStatus || ""
											: headCell.label === "Sales Rep."
											? searchValue?.salesRep || ""
											: headCell.label === "Loan Number"
											? searchValue?.loanNumber || ""
											: headCell.label === "Lock Expiration Date"
											? searchValue?.lockExpirationDate
											: headCell.label === "Loan Program"
											? searchValue?.loanProgram
											: ""
									}
									handleChange={(event) =>
										headCell.label === "Loan Number"
											? handleSearchTable(
													event.target.value,
													event.target.name,
													"Loan.LoanNumber"
											  )
											: headCell.label === "Property Address"
											? handleSearchTable(
													event.target.value,
													event.target.name,
													"Fields.URLA.X73"
											  )
											: headCell.label === "Start Date"
											? handleSearchTable(
													event.target.value,
													event.target.name,
													"Fields.2025"
											  )
											: headCell.label === "Borrower Name"
											? handleSearchTable(
													event.target.value,
													event.target.name,
													"Loan.BorrowerName"
											  )
											: headCell.label === "Loan Amount"
											? handleSearchTable(
													event.target.value,
													event.target.name,
													"Loan.LoanAmount"
											  )
											: headCell.label === "Loan Status"
											? handleSearchTable(
													event.target.value,
													event.target.name,
													"Fields.Log.MS.CurrentMilestone"
											  )
											: headCell.label === "Sales Rep."
											? handleSearchTable(
													event.target.value,
													event.target.name,
													"Loan.LoanAmount"
											  )
											: headCell.label === "Lock Expiration Date"
											? handleSearchTable(
													event.target.value,
													event.target.name,
													"Fields.762"
											  )
											: headCell.label === "Loan Program" &&
											  handleSearchTable(
													event.target.value,
													event.target.name,
													"Fields.1401"
											  )
									}
								/>
							)}
						</Stack>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
};

export default TableHeadSearchSort;
