import { createSlice } from "@reduxjs/toolkit";

const organizationNameSlice = createSlice({
	name: "organizationName",
	initialState: { organizationName: null },
	reducers: {
		setOrganizationNameReducer: (state, action) => {
			const data = action.payload;
			state.organizationName = data;
		},
	},
});

export const { setOrganizationNameReducer } = organizationNameSlice.actions;

export default organizationNameSlice.reducer;

export const selectOrganizationName = (state) =>
	state.organizationName.organizationName;
