import React from "react";

import { TableCell, TableRow } from "@mui/material";

import Body1 from "../../typography/body-01";

const ChangeRequestRow = ({ row }) => {
	return (
		<TableRow hover tabIndex={-1} key={row.id}>
			<TableCell>
				<Body1 text={row?.date} />
			</TableCell>
			<TableCell>
				<Body1 text={row.comment} />
			</TableCell>
			<TableCell>
				<Body1 text={row.status} />
			</TableCell>
			<TableCell>
				<Body1 text={row.requestor} />
			</TableCell>
		</TableRow>
	);
};

export default ChangeRequestRow;
