import React from "react";

// mui components
import { Autocomplete, Checkbox, TextField } from "@mui/material";

// mui icons
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// custom styles
import styles from "./index.module.scss";
import "./mui-styles.scss";

const MultiSelect = ({
	multiple,
	limitTags,
	id,
	options,
	value,
	handleChange,
}) => {
	return (
		<Autocomplete
			sx={{
				"& .MuiOutlinedInput-root": {
					paddingTop: "0px",
					paddingLeft: "0px",
					paddingBottom: "0px",
				},

				"& .MuiOutlinedInput-notchedOutline": {
					height: "61px",
					borderWidth: "1.5px !important",
					borderColor: "rgba(0, 0, 0, 0.23) !important",
					borderRadius: "4px !important",
				},

				"&:hover .MuiOutlinedInput-root": {
					"& .MuiOutlinedInput-notchedOutline": {
						borderWidth: "1.5px !important",
						borderColor: "rgba(0, 0, 0, 0.87) !important",
					},
				},
			}}
			multiple={multiple}
			limitTags={limitTags}
			id={id}
			className={styles.multiSelect}
			options={options}
			value={value}
			onChange={(event, newValue) => {
				handleChange(event, newValue);
			}}
			popupIcon={<ExpandMoreIcon />}
			getOptionLabel={(option) => option}
			renderOption={(props, option, { selected }) => (
				<li {...props}>
					<Checkbox style={{ marginRight: 8 }} checked={selected} />
					{option}
				</li>
			)}
			style={{ width: 300 }}
			renderInput={(params) => (
				<TextField
					{...params}
					sx={{
						"& .MuiOutlinedInput-notchedOutline": {
							borderWidth: "1px !important",
							borderColor: "#000 !important",
							borderRadius: "2px",
						},
					}}
					InputLabelProps={{ shrink: false }}
					placeholder="Search here..."
				/>
			)}
		/>
	);
};

export default MultiSelect;
