import { createSlice } from "@reduxjs/toolkit";

const impersonatedOrgNameSlice = createSlice({
	name: "impersonatedOrgName",
	initialState: { impersonatedOrgName: null },
	reducers: {
		setImpersonatedOrgNameReducer: (state, action) => {
			const data = action.payload;
			state.impersonatedOrgName = data;
		},
	},
});

export const { setImpersonatedOrgNameReducer } =
	impersonatedOrgNameSlice.actions;

export default impersonatedOrgNameSlice.reducer;

export const selectImpersonatedOrgName = (state) =>
	state.impersonatedOrgName.impersonatedOrgName;
