import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

// mui components
import { Box, Stack } from "@mui/material";

// custom components
import CustomLayout from "../../components/layout";
import LoanProgress from "../../components/loan-progress";
import ViewLoanApplication from "./components/borrower-info-step";
import ViewLoanInfo from "./components/additional-info-step";
import ViewLoanTerms from "./components/product-terms";
import ViewLoanFee from "./components/fee";
import Heading5 from "../../components/typography/heading-05";

// mui icons
import { CloseOutlined } from "@mui/icons-material";

// custom modal
import LoaderModal from "../../components/modal/loader-modal";
import ConfirmationModal from "../../components/modal/confirmation-modal";

// custom styles
import styles from "./index.module.scss";

const ViewLoan = () => {
	const params = useParams();
	const navigate = useNavigate();

	const loanSteps = [
		"Borrower Information",
		"Additional Borrower Information",
		"Loan Product and Terms",
		"Fees",
	];

	const [loaderModal, setLoaderModal] = useState(false);

	const handleOpenLoaderModal = () => {
		setLoaderModal(true);
	};

	const handleCloseLoaderModal = () => {
		setLoaderModal(false);
	};

	const [exitModal, setExitModal] = useState(false);

	const handleOpenExitModal = () => {
		setExitModal(true);
	};

	const handleCloseExitModal = () => {
		setExitModal(false);
	};

	const handleConfirmExit = () => {
		navigate(`/loan-summary/${params?.id}`);
	};

	const [loanGuid, setLoanGuid] = useState("");
	const [loanApplicationStage, setLoanApplicationStage] = useState(0);

	useEffect(() => {
		console.log("52 params id:", params?.id);

		if (params?.id !== undefined && params?.id !== "") {
			setLoanGuid(params?.id);
		}
	}, [params]);

	const breadcrumbData = [
		{
			label: "Home",
			path: "/home",
		},
		{
			label: "Loans",
			path: "/pipeline",
		},
		{
			label: params?.loanId,
			path: "",
		},
	];

	return (
		<Box>
			<CustomLayout breadcrumb={breadcrumbData} disabledOverflow={true}>
				<Stack direction="row" className={styles.stackContainer}>
					<Stack direction="row" className={styles.bodyContainer}>
						<Stack direction="column" className={styles.loanContainer}>
							{/* page title and close btn container */}
							<Box direction="row" className={styles.headerContainer}>
								<Stack className={styles.headerTextContainer}>
									<Heading5
										text={`Step ${loanApplicationStage + 1}: ${
											loanSteps[loanApplicationStage]
										}`}
										fontType="semibold"
										className={styles.headerText}
									/>
								</Stack>

								<Stack
									className={styles.closeIconContainer}
									onClick={handleOpenExitModal}
								>
									<CloseOutlined className={styles.closeIcon} />
								</Stack>
							</Box>

							{/* loan form */}
							{loanApplicationStage === 0 ? (
								<ViewLoanApplication
									setLoanApplicationStage={setLoanApplicationStage}
									loanGuid={loanGuid || params?.id}
									handleOpenLoaderModal={handleOpenLoaderModal}
									handleCloseLoaderModal={handleCloseLoaderModal}
								/>
							) : loanApplicationStage === 1 ? (
								<ViewLoanInfo
									setLoanApplicationStage={setLoanApplicationStage}
									loanGuid={loanGuid || params?.id}
									handleOpenLoaderModal={handleOpenLoaderModal}
									handleCloseLoaderModal={handleCloseLoaderModal}
									disabled={true}
								/>
							) : loanApplicationStage === 2 ? (
								<ViewLoanTerms
									setLoanApplicationStage={setLoanApplicationStage}
									loanGuid={loanGuid}
									handleOpenLoaderModal={handleOpenLoaderModal}
									handleCloseLoaderModal={handleCloseLoaderModal}
									disabled={true}
								/>
							) : (
								loanApplicationStage === 3 && (
									<ViewLoanFee
										setLoanApplicationStage={setLoanApplicationStage}
										loanGuid={loanGuid}
										handleOpenLoaderModal={handleOpenLoaderModal}
										handleCloseLoaderModal={handleCloseLoaderModal}
										disabled={true}
									/>
								)
							)}
						</Stack>

						{/* application progress */}
						<LoanProgress
							loanSteps={loanSteps}
							currentStep={loanApplicationStage}
						/>
					</Stack>
				</Stack>
			</CustomLayout>

			{/* modals */}
			<LoaderModal open={loaderModal} />
			<ConfirmationModal
				open={exitModal}
				text="Are you sure you want to exit?"
				confirmText="Exit Application"
				handleConfirm={handleConfirmExit}
				closeText="Return to Application"
				handleClose={handleCloseExitModal}
			/>
		</Box>
	);
};

export default ViewLoan;
