import React from "react";

// mui components
import { Box, InputLabel, Stack } from "@mui/material";

// custom components
import OutlinedTextInput from "../../custom-input/outlined-text-input";
import SecondarySelect from "../../custom-select/secondary-select";
import Heading5 from "../../typography/heading-05";
import Heading6 from "../../typography/heading-06";
import Body3 from "../../typography/body-03";

// helper utils
import { stateOptions } from "../../../utils/select-options";

// custom styles
import styles from "./index.module.scss";

const LoanProcessor = ({
	organizationName,
	organizationDetails,
	loanProcessorEmail,
	setLoanProcessorEmail,
	organizationProcessor,
	loanProcessor,
	viewLoan,
	disabled,
	error,
}) => {
	return (
		<Box className={styles.cardContainer}>
			<Stack direction="column" className={styles.cardHeaderContainer}>
				<Heading5 text="Loan Processor Information" fontType="semibold" />
				<Body3 text="Provide details about the loan processor" />
			</Stack>
			<Stack direction="column" className={styles.formSectionContainer}>
				{/* organization information */}
				<Stack direction="row" className={styles.inputSectionContainer}>
					{/* organization name */}
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.fullWidthContainer}
					>
						<InputLabel htmlFor="outlined-input-organization-name">
							<Heading6
								text="Organization Name"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<OutlinedTextInput
							id="outlined-input-organization-name"
							type="text"
							placeholder="Organization Name"
							fullWidth={true}
							value={organizationName}
							disabled={true}
						/>
					</Stack>

					{/* address line */}
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.fullWidthContainer}
					>
						<InputLabel htmlFor="outlined-input-address-line">
							<Heading6
								text="Address Line 1"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<OutlinedTextInput
							id="outlined-input-address-line"
							type="text"
							placeholder="Address Line"
							fullWidth={true}
							value={organizationDetails?.address}
							disabled={true}
						/>
					</Stack>

					{/* unit number */}
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.fullWidthContainer}
					>
						<InputLabel htmlFor="outlined-input-unit-number">
							<Heading6
								text="Address Line 2 / Unit Number"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<OutlinedTextInput
							id="outlined-input-unit-number"
							type="text"
							placeholder="Unit Number"
							fullWidth={true}
							// value={data?.addressUnitIdentifier}
							disabled={true}
						/>
					</Stack>

					{/* city */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor="outlined-input-city">
							<Heading6
								text="City"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<OutlinedTextInput
							id="outlined-input-city"
							type="text"
							placeholder="City"
							value={organizationDetails?.city}
							disabled={true}
						/>
					</Stack>

					{/* state */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor="select-state">
							<Heading6
								text="State"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<SecondarySelect
							id="select-state"
							displayEmpty={true}
							value={organizationDetails?.state || ""}
							options={stateOptions}
							disabled={true}
						/>
					</Stack>

					{/* postal code */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor="outlined-input-postal-code">
							<Heading6
								text="Postal Code"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<OutlinedTextInput
							id="outlined-input-postal-code"
							type="text"
							placeholder="123456"
							value={organizationDetails?.zip}
							disabled={true}
						/>
					</Stack>
				</Stack>

				{/* loan processor information */}
				<Stack direction="row" className={styles.inputSectionContainer}>
					{/* LP email */}
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.fullWidthContainer}
					>
						<InputLabel htmlFor="select-lp-email">
							<Heading6
								text="Loan Processor"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<SecondarySelect
							id="select-lp-email"
							displayEmpty={true}
							fullWidth={true}
							value={loanProcessorEmail || ""}
							handleChange={(event) =>
								setLoanProcessorEmail(event.target.value)
							}
							options={organizationProcessor}
							disabled={disabled}
							error={
								error &&
								(loanProcessorEmail === undefined || loanProcessorEmail === "")
							}
						/>
					</Stack>
				</Stack>

				<Stack direction="row" className={styles.inputSectionContainer}>
					{/* LP Name */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor="outlined-input-lp-name">
							<Heading6
								text="Loan Processor Name"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<OutlinedTextInput
							id="outlined-input-lp-name"
							type="text"
							placeholder="LP Name"
							value={
								viewLoan
									? loanProcessor?.user?.name
									: loanProcessor?.user?.first_name +
									  " " +
									  loanProcessor?.user?.last_name
							}
							disabled={true}
						/>
					</Stack>

					{/* LO NMLS ID */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor="outlined-input-lp-nmls">
							<Heading6
								text="NMLS ID"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<OutlinedTextInput
							id="outlined-input-lp-nmls"
							type="text"
							placeholder="NMLS ID"
							value={loanProcessor?.nmls_id}
							disabled={true}
						/>
					</Stack>

					{/* LO phone */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor="outlined-input-phone">
							<Heading6
								text="Phone Number"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<OutlinedTextInput
							id="outlined-input-phone"
							type="text"
							placeholder="[XXX] XXX-XXXX"
							value={loanProcessor?.phone}
							disabled={true}
						/>
					</Stack>

					{/* LO cell phone */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor="outlined-input-cell-phone">
							<Heading6
								text="Cell Phone Number"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<OutlinedTextInput
							id="outlined-input-cell-phone"
							type="text"
							placeholder="[XXX] XXX-XXXX"
							value={
								loanProcessor?.cell_phone === null ||
								loanProcessor?.cell_phone === undefined
									? ""
									: loanProcessor?.cell_phone
							}
							disabled={true}
						/>
					</Stack>

					{/* LO email */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor="outlined-input-email">
							<Heading6
								text="Email ID"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<OutlinedTextInput
							id="outlined-input-email"
							type="email"
							placeholder="email@mail.com"
							value={loanProcessor?.user?.email}
							disabled={true}
						/>
					</Stack>
				</Stack>
			</Stack>
		</Box>
	);
};

export default LoanProcessor;
