import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { utils, writeFileXLSX } from "xlsx";

// mui components
import { Box, Stack } from "@mui/material";

// mui icons
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";

// api slice
import { selectUserDetails } from "../../features/auth/authSlice";
import { useGetPipelineMutation } from "../../features/pipeline/pipelineApiSlice";
import { useGetMortgageCountMutation } from "../../features/mortgage-count/mortgageCountApiSlice";
import { useGetAdminPipelineMutation } from "../../features/admin/pipeline/pipelineApiSlice";
import { useGetAdminUnfilteredPipelineMutation } from "../../features/admin/pipeline/unfilteredLoanApiSlice";
import { useGetUnfilteredMortgageCountMutation } from "../../features/admin/unfiltered-milestone/unfilteredMilestoneApiSlice";
import { useGetFilteredMortgageCountMutation } from "../../features/admin/filtered-milestone/filteredMilestoneApiSlice";
import { selectImpersonatedUser } from "../../features/admin/impersonated-user/impersonatedUserSlice";

// custom components
import CustomLayout from "../../components/layout";
import CustomTable from "../../components/custom-table";
import Heading4 from "../../components/typography/heading-04";
import SecondaryButton from "../../components/buttons/secondary-button";

// custom modal
import LoaderModal from "../../components/modal/loader-modal";

// custom styles
import styles from "./index.module.scss";

export default function HomePage() {
	const [getPipeline, { isLoading: brokerLoading }] = useGetPipelineMutation();

	const [getMortgageCount] = useGetMortgageCountMutation();

	const [getFilteredMortgageCount] = useGetFilteredMortgageCountMutation();

	const [getUnfilteredMortgageCount] = useGetUnfilteredMortgageCountMutation();

	const [getAdminPipeline, { isLoading: adminLoading }] =
		useGetAdminPipelineMutation();

	const [getAdminUnfilteredPipeline, { isLoading: adminUnfilteredLoading }] =
		useGetAdminUnfilteredPipelineMutation();

	const navigate = useNavigate();
	const [loans, setLoans] = useState([]);
	const [rateLockLoading, setRateLockLoading] = useState(true);

	const [loanStatusLoading, setLoanStatusLoading] = useState(true);
	const [mortgageCountData, setMortgageCountData] = useState({});

	const [loanStatusData, setLoanStatusData] = useState([
		{ label: "Loan Submitted", count: 0 },
		{ label: "Review Submission", count: 0 },
		{ label: "Incomplete Credit Pkg", count: 0 },
		{ label: "Recd Cred Pkg", count: 0 },
		{ label: "File Setup", count: 0 },
		{ label: "UW Cond Review", count: 0 },
		{ label: "In Underwriting", count: 0 },
		{ label: "Suspended", count: 0 },
		{ label: "Cond's Approval", count: 0 },
		{ label: "Recd Conditions", count: 0 },
		{ label: "Clear to Close", count: 0 },
		{ label: "Closing Docs", count: 0 },
		{ label: "Closing", count: 0 },
		{ label: "Wire Sent", count: 0 },
		{ label: "Funded", count: 0 },
	]);

	const userDetails = useSelector(selectUserDetails);
	const impersonatedUser = useSelector(selectImpersonatedUser);

	useEffect(() => {
		async function getAdminPipelineData() {
			console.log("67 user roles:", userDetails?.user_roles);
			console.log("130 impersonated user:", impersonatedUser);

			if (
				userDetails?.user_roles?.includes("admin") === true &&
				impersonatedUser !== undefined &&
				impersonatedUser !== null &&
				impersonatedUser !== ""
			) {
				console.log("28 org name:", impersonatedUser);
				const pipelineAdminData = await getAdminPipeline({
					brokerEmail: impersonatedUser,
				}).unwrap();
				console.log("65 impersonated pipeline data:", pipelineAdminData?.data);
				setRateLockLoading(false);
				setLoans(pipelineAdminData?.data);

				const filteredMortgageData = await getFilteredMortgageCount({
					brokerEmail: impersonatedUser,
				}).unwrap();
				console.log(
					"65 impersonated pipeline data:",
					filteredMortgageData?.data
				);
				setMortgageCountData(filteredMortgageData?.data);
			} else if (
				(impersonatedUser === undefined || impersonatedUser === null) &&
				userDetails?.user_roles?.includes("admin") === true
			) {
				const pipelineAdminLoanData =
					await getAdminUnfilteredPipeline().unwrap();
				console.log("65 admin pipeline data:", pipelineAdminLoanData?.data);
				setRateLockLoading(false);
				setLoans(pipelineAdminLoanData?.data);

				const unfilteredMortgageData =
					await getUnfilteredMortgageCount().unwrap();
				console.log("65 admin mortgage data:", unfilteredMortgageData?.data);
				setMortgageCountData(unfilteredMortgageData?.data);
			} else if (userDetails?.user_roles?.includes("admin") === false) {
				const brokerPipelineData = await getPipeline().unwrap();
				console.log("65 broker mortgage data:", brokerPipelineData?.data);
				setRateLockLoading(false);
				setLoans(brokerPipelineData?.data);

				const mortgagePipelineData = await getMortgageCount().unwrap();
				console.log("65 broker mortgage data:", mortgagePipelineData?.data);
				setMortgageCountData(mortgagePipelineData?.data);
			}
		}

		getAdminPipelineData();
	}, [userDetails, impersonatedUser]);

	useEffect(() => {
		if (
			userDetails?.user_roles?.includes("admin") === true &&
			impersonatedUser?.length <= 0
		) {
			navigate("/admin");
		}
	}, [userDetails, impersonatedUser]);

	useEffect(() => {
		let newArr = [
			{ label: "Loan Submitted", count: 0 },
			{ label: "Review Submission", count: 0 },
			{ label: "Incomplete Credit Pkg", count: 0 },
			{ label: "Recd Cred Pkg", count: 0 },
			{ label: "File Setup", count: 0 },
			{ label: "UW Cond Review", count: 0 },
			{ label: "In Underwriting", count: 0 },
			{ label: "Suspended", count: 0 },
			{ label: "Cond's Approval", count: 0 },
			{ label: "Recd Conditions", count: 0 },
			{ label: "Clear to Close", count: 0 },
			{ label: "Closing Docs", count: 0 },
			{ label: "Closing", count: 0 },
			{ label: "Wire Sent", count: 0 },
			{ label: "Funded", count: 0 },
		];

		if (mortgageCountData !== undefined) {
			console.log("130 mortgageCountData:", mortgageCountData);

			const mortgageCountArr = Object.entries(mortgageCountData).map((e) => ({
				label: e[0],
				count: e[1],
			}));

			for (let index = 0; index < mortgageCountArr.length; index++) {
				const element = mortgageCountArr[index];

				let objIndex;
				if (element.label === "Started") {
					objIndex = newArr.findIndex((obj) => obj?.label === "Loan Submitted");
				} else if (element.label === "Loan Submitted") {
					objIndex = newArr.findIndex((obj) => obj?.label === "Loan Submitted");
				} else if (element.label === "Review Submission") {
					objIndex = newArr.findIndex(
						(obj) => obj?.label === "Review Submission"
					);
				} else if (element.label === "Incomplete Credit Pkg") {
					objIndex = newArr.findIndex(
						(obj) => obj?.label === "Incomplete Credit Pkg"
					);
				} else if (element.label === "Recd Cred Pkg") {
					objIndex = newArr.findIndex((obj) => obj?.label === "Recd Cred Pkg");
				} else if (element.label === "Review Cred Pkg") {
					objIndex = newArr.findIndex((obj) => obj?.label === "Recd Cred Pkg");
				} else if (element.label === "File Setup") {
					objIndex = newArr.findIndex((obj) => obj?.label === "File Setup");
				} else if (element.label === "Submit to UW") {
					objIndex = newArr.findIndex(
						(obj) => obj?.label === "In Underwriting"
					);
				} else if (element.label === "In UWing Review") {
					objIndex = newArr.findIndex(
						(obj) => obj?.label === "In Underwriting"
					);
				} else if (element.label === "Suspended") {
					objIndex = newArr.findIndex((obj) => obj?.label === "Suspended");
				} else if (element.label === "Cond'l Approval") {
					objIndex = newArr.findIndex(
						(obj) => obj?.label === "Cond's Approval"
					);
				} else if (element.label === "Recd Conditions") {
					objIndex = newArr.findIndex(
						(obj) => obj?.label === "Recd Conditions"
					);
				} else if (element.label === "UW Cond Review") {
					objIndex = newArr.findIndex((obj) => obj?.label === "UW Cond Review");
				} else if (element.label === "QC Requested") {
					objIndex = newArr.findIndex(
						(obj) => obj?.label === "In Underwriting"
					);
				} else if (element.label === "QC Complete") {
					objIndex = newArr.findIndex(
						(obj) => obj?.label === "In Underwriting"
					);
				} else if (element.label === "CTC") {
					objIndex = newArr.findIndex((obj) => obj?.label === "Clear to Close");
				} else if (element.label === "Closing Prep") {
					objIndex = newArr.findIndex((obj) => obj?.label === "Closing Docs");
				} else if (element.label === "Closing") {
					objIndex = newArr.findIndex((obj) => obj?.label === "Closing");
				} else if (element.label === "Funding Prep") {
					objIndex = newArr.findIndex((obj) => obj?.label === "Wire Sent");
				} else if (element.label === "Funding") {
					objIndex = newArr.findIndex((obj) => obj?.label === "Funded");
				}

				if (newArr[objIndex] !== undefined) {
					// Update object's name property.
					newArr[objIndex].count += element.count;
				}
			}

			setLoanStatusLoading(false);
			setLoanStatusData(newArr);
		}
	}, [mortgageCountData]);

	const loanStatusColumns = [
		{ id: "label", label: "Loan Status", minWidth: 180 },
		{ id: "count", label: "Count", minWidth: 60 },
	];

	const rateLockColumns = [
		{ id: "number", label: "Loan Number", minWidth: 170 },
		{ id: "expiration-date", label: "Expiration Date", minWidth: 170 },
		{ id: "days-to-expiry", label: "Days to Expire", minWidth: 170 },
		{ id: "program", label: "Loan Program", minWidth: 525 },
		{ id: "name", label: "Borrower Name", minWidth: 200 },
		{ id: "folder", label: "Loan Folder", minWidth: 180 },
	];

	const handleExportExcelClick = () => {
		const loanData = loans.map(({ loanGuid, fields }) => ({
			loanId: loanGuid,
			loanFolder: fields["Loan.LoanFolder"],
			loanNumber: fields["Loan.LoanNumber"],
			loanAmount: fields["Loan.LoanAmount"],
			borrowerName: fields["Loan.BorrowerName"],
			propertyAddress:
				fields["Fields.URLA.X73"] +
				"," +
				fields["Fields.URLA.X75"] +
				"," +
				fields["Fields.12"] +
				"," +
				fields["Fields.14"],
			loanStatus: fields["Fields.Log.MS.CurrentMilestone"],
			// salesRep: fields[''],
			lockExpirationDate: fields["Fields.762"].slice(0, 10),
			loanProgram: fields["Fields.1401"],
		}));

		const ws = utils.json_to_sheet(loanData);
		const wb = utils.book_new();
		utils.book_append_sheet(wb, ws, "Data");
		writeFileXLSX(wb, "Exported Excel.xlsx");
	};

	const breadcrumbData = [
		{
			label: "Home",
			path: "/home",
		},
	];

	return (
		<Box>
			<CustomLayout breadcrumb={breadcrumbData} disabledOverflow={true}>
				<Stack
					direction="row"
					className={styles.stackContainer}
					sx={{
						overflow: "hidden",
					}}
				>
					{/* loan status */}
					<Stack direction="column" className={styles.tableContainer}>
						<Box className={styles.loanTableHeader}>
							<Heading4 text="Loan Status - Active Loans" fontType="semibold" />
						</Box>

						<CustomTable
							columnData={loanStatusColumns}
							rowData={loanStatusData}
							tableType="loan-status"
						/>
					</Stack>
					{/* rate lock status */}
					<Stack direction="column" className={styles.tableContainer}>
						<Stack
							direction="row"
							alignItems="center"
							justifyContent="space-between"
							className={styles.tableTitleContainer}
						>
							<Heading4 text="Rate Lock Status" fontType="semibold" />

							<SecondaryButton
								text="Export to Excel"
								startIcon={
									<DownloadOutlinedIcon className={styles.downloadIcon} />
								}
								onClick={handleExportExcelClick}
							/>
						</Stack>

						<CustomTable
							columnData={rateLockColumns}
							rowData={loans?.filter(
								(loan) =>
									loan?.fields["Loan.LoanFolder"] === "Pipeline" ||
									loan?.fields["Loan.LoanFolder"] === "Funded" ||
									loan?.fields["Loan.LoanFolder"] === "Adverse Loans"
							)}
							enableSort={true}
							tableType="rate-lock"
							isLoading={
								brokerLoading || adminLoading || adminUnfilteredLoading
							}
							setSortedData={setLoans}
						/>
					</Stack>
				</Stack>
			</CustomLayout>

			<LoaderModal open={rateLockLoading || loanStatusLoading} />
		</Box>
	);
}
