import React from "react";
import { useNavigate } from "react-router-dom";

// mui components
import { TableCell, TableRow } from "@mui/material";

// custom components
import Body2 from "../../typography/body-02";

const RateLockRow = ({ row }) => {
	const navigate = useNavigate();

	return (
		<TableRow
			hover
			tabIndex={-1}
			key={row.id}
			onClick={() => navigate(`/loan-summary/${row?.loanGuid}`)}
		>
			{/* loan number */}
			<TableCell key={row.id}>
				<Body2
					text={row?.fields["Loan.LoanNumber"]}
					error={row?.fields["Loan.LoanFolder"] === "Portal - Not Submitted"}
					isLink={true}
				/>
			</TableCell>

			{/* expiration date */}
			<TableCell key={row.id}>
				<Body2
					text={
						row?.fields["Fields.762"] !== ""
							? row?.fields["Fields.762"]?.split(" ")[0]
							: ""
					}
				/>
			</TableCell>

			{/* days to expiry */}
			<TableCell key={row.id}>
				<Body2
					text={
						row?.fields["Fields.432"] !== ""
							? row?.fields["Fields.432"]?.split(".")[0]
							: ""
					}
				/>
			</TableCell>

			{/* loan program */}
			<TableCell key={row.id}>
				<Body2 text={row?.fields["Fields.1401"]} />
			</TableCell>

			{/* borrower name */}
			<TableCell key={row.id}>
				<Body2 text={row?.fields["Loan.BorrowerName"]} />
			</TableCell>

			{/* loan folder */}
			<TableCell key={row.id}>
				<Body2 text={row?.fields["Loan.LoanFolder"]} />
			</TableCell>
		</TableRow>
	);
};

export default RateLockRow;
