import React, { useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import { DateTime } from "luxon";

// mui components
import { Box, InputLabel, Stack } from "@mui/material";

// custom components
import OutlinedTextInput from "../../custom-input/outlined-text-input";
import MaskedInput from "../../custom-input/masked-input";
import SecondarySelect from "../../custom-select/secondary-select";
import CustomRadio from "../../custom-radio";
import CustomDivider from "../../custom-divider";
import Heading5 from "../../typography/heading-05";
import Heading6 from "../../typography/heading-06";
import Body3 from "../../typography/body-03";

// helper utils
import {
	ownershipShareOptions,
	selfEmployedOptions,
	stateOptions,
} from "../../../utils/select-options";

// custom styles
import styles from "./index.module.scss";

const EmploymentInfo = ({
	financeIndex,
	coBorrower,
	financeData,
	data,
	setData,
	vestingData,
	setVestingData,
	disabled,
}) => {
	const [employmentStartDate, setEmploymentStartDate] = useState("");

	useEffect(() => {
		if (
			financeData?.employmentStartDate !== null &&
			financeData?.employmentStartDate !== undefined
		) {
			let year = financeData?.employmentStartDate?.slice(-4);
			let month = financeData?.employmentStartDate?.slice(0, 2);
			let date = financeData?.employmentStartDate?.slice(3, 5);

			let formattedJSDate = new Date(year, month - 1, date);
			console.log("27 formatted JS date:", formattedJSDate);
			const isValidDate = (dateObject) =>
				new Date(dateObject).toString() !== "Invalid Date";

			if (isValidDate(formattedJSDate)) {
				setEmploymentStartDate(formattedJSDate);
			} else {
				let newYear = financeData?.employmentStartDate?.slice(0, 4);
				let newMonth = financeData?.employmentStartDate?.slice(5, 7);
				let newDate = financeData?.employmentStartDate?.slice(8, 10);

				console.log("44 newYear:", newYear);
				console.log("44 newMonth:", newMonth);
				console.log("44 newDate:", newDate);

				let formattedDate = new Date(newYear, newMonth - 1, newDate);
				console.log("27 view loan formatted JS date:", formattedDate);

				if (isValidDate(formattedDate)) {
					setEmploymentStartDate(formattedDate);
				} else {
					setEmploymentStartDate(null);
				}
			}
		} else {
			setEmploymentStartDate(null);
		}
	}, []);

	const [phoneNumber, setPhoneNumber] = useState("");
	const [baseIncome, setBaseIncome] = useState("");

	useEffect(() => {
		setPhoneNumber(financeData?.phoneNumber);
		setBaseIncome(financeData?.basePayAmount);
	}, [financeData]);

	const handlePhoneNumber = (value) => {
		setPhoneNumber(value);

		setData(
			data.map((data, index) => {
				if (financeIndex === index) {
					return { ...data, phoneNumber: value };
				} else {
					return data;
				}
			})
		);
	};

	const handleBaseIncome = (value) => {
		setBaseIncome(value);

		setData(
			data.map((data, index) => {
				if (financeIndex === index) {
					return { ...data, basePayAmount: value };
				} else {
					return data;
				}
			})
		);
	};

	useEffect(() => {
		console.log("99 employmentStartDate:", employmentStartDate);

		let formattedDate =
			DateTime.fromJSDate(employmentStartDate).toFormat("MM-dd-yyyy");

		console.log("105 format date:", formattedDate);

		if (
			employmentStartDate !== null &&
			employmentStartDate !== undefined &&
			employmentStartDate !== ""
		) {
			setData(
				data.map((data, index) => {
					if (financeIndex === index) {
						return { ...data, employmentStartDate: formattedDate };
					} else {
						return data;
					}
				})
			);
		} else {
			setData(
				data.map((data, index) => {
					if (financeIndex === index) {
						return { ...data, employmentStartDate: "" };
					} else {
						return data;
					}
				})
			);
		}
	}, [employmentStartDate]);

	useEffect(() => {
		setData(
			data.map((data, index) => {
				if (financeIndex === index) {
					return {
						...data,
						owner: coBorrower ? "CoBorrower" : "Borrower",
						currentEmploymentIndicator: true,
					};
				} else {
					return data;
				}
			})
		);
	}, [coBorrower]);

	return (
		<Box className={styles.cardContainer}>
			<Stack direction="column" className={styles.cardHeaderContainer}>
				<Heading5
					text={`Employment and Income - ${
						coBorrower ? "Co Borrower " : "Borrower "
					}`}
					fontType="semibold"
				/>

				<Body3 text="Not applicable for DSCR" />
			</Stack>
			<Stack direction="column" className={styles.formSectionContainer}>
				{/* basic information */}
				<Stack direction="row" className={styles.inputSectionContainer}>
					{/* employment status */}
					<Stack direction="column" className={styles.inputContainer}>
						<CustomRadio
							id="employment-status"
							value={financeData?.selfEmployedIndicator}
							handleChange={(event) =>
								setData(
									data.map((data, index) => {
										if (financeIndex === index) {
											return {
												...data,
												selfEmployedIndicator: event.target.value,
											};
										} else {
											return data;
										}
									})
								)
							}
							label="Employment:"
							disabled={disabled}
							data={selfEmployedOptions}
						/>
					</Stack>
				</Stack>

				<Stack direction="row" className={styles.inputSectionContainer}>
					{/* employer name */}
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.fullWidthContainer}
					>
						<InputLabel htmlFor="outlined-input-employer-name">
							<Heading6
								text={
									financeData?.selfEmployedIndicator === true
										? "Business Name"
										: "Employer Name"
								}
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<OutlinedTextInput
							id="outlined-input-employer-name"
							placeholder="Name"
							type="text"
							fullWidth={true}
							value={financeData?.employerName}
							handleChange={(event) =>
								setData(
									data.map((data, index) => {
										if (financeIndex === index) {
											return {
												...data,
												employerName: event.target.value,
											};
										} else {
											return data;
										}
									})
								)
							}
							disabled={disabled}
						/>
					</Stack>

					{/* ownership share */}
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.inlineContainer}
					>
						{financeData?.selfEmployedIndicator === true && (
							<CustomRadio
								id="ownership-share"
								value={financeData?.ownershipInterestType}
								handleChange={(event) =>
									setData(
										data.map((data, index) => {
											if (financeIndex === index) {
												return {
													...data,
													ownershipInterestType: event.target.value,
												};
											} else {
												return data;
											}
										})
									)
								}
								label="Ownership Share:"
								disabled={disabled}
								data={ownershipShareOptions}
							/>
						)}
					</Stack>

					{/* employer address line */}
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.fullWidthContainer}
					>
						<InputLabel htmlFor="outlined-input-employer-address">
							<Heading6
								text={
									financeData?.selfEmployedIndicator === true
										? "Address Line 1"
										: "Employment Address Line 1"
								}
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<OutlinedTextInput
							id="outlined-input-employer-address"
							placeholder="Address Line 1"
							type="text"
							fullWidth={true}
							value={financeData?.uRLA2020StreetAddress}
							handleChange={(event) =>
								setData(
									data.map((data, index) => {
										if (financeIndex === index) {
											return {
												...data,
												addressStreetLine1: event.target.value,
												uRLA2020StreetAddress: event.target.value,
											};
										} else {
											return data;
										}
									})
								)
							}
							disabled={disabled}
						/>
					</Stack>

					{/* employer unit number */}
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.fullWidthContainer}
					>
						<InputLabel htmlFor="outlined-input-employer-unit">
							<Heading6
								text={
									financeData?.selfEmployedIndicator === true
										? "Address Line 2 / Unit Number"
										: "Employment Address Line 2 / Unit Number"
								}
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<OutlinedTextInput
							id="outlined-input-employer-unit"
							placeholder="Unit Number"
							type="text"
							fullWidth={true}
							value={financeData?.unitNumber}
							handleChange={(event) =>
								setData(
									data.map((data, index) => {
										if (financeIndex === index) {
											return {
												...data,
												unitNumber: event.target.value,
											};
										} else {
											return data;
										}
									})
								)
							}
							disabled={disabled}
						/>
					</Stack>

					{/* employer city */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor="outlined-input-employer-city">
							<Heading6
								text="City"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<OutlinedTextInput
							id="outlined-input-employer-city"
							placeholder="City"
							type="text"
							fullWidth={true}
							value={financeData?.addressCity}
							handleChange={(event) =>
								setData(
									data.map((data, index) => {
										if (financeIndex === index) {
											return {
												...data,
												addressCity: event.target.value,
											};
										} else {
											return data;
										}
									})
								)
							}
							disabled={disabled}
						/>
					</Stack>

					{/* employer state */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor="select-state">
							<Heading6
								text="State"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<SecondarySelect
							id="select-state"
							displayEmpty={true}
							value={financeData?.addressState || ""}
							handleChange={(event) =>
								setData(
									data.map((data, index) => {
										if (financeIndex === index) {
											return {
												...data,
												addressState: event.target.value,
											};
										} else {
											return data;
										}
									})
								)
							}
							options={stateOptions}
							disabled={disabled}
						/>
					</Stack>

					{/* employer postal code */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor="outlined-input-employer-zip">
							<Heading6
								text="Zip Code"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<OutlinedTextInput
							id="outlined-input-employer-zip"
							placeholder="Zip Code"
							type="text"
							fullWidth={true}
							value={financeData?.addressPostalCode}
							handleChange={(event) =>
								setData(
									data.map((data, index) => {
										if (financeIndex === index) {
											return {
												...data,
												addressPostalCode: event.target.value,
											};
										} else {
											return data;
										}
									})
								)
							}
							disabled={disabled}
						/>
					</Stack>

					{/* employer phone */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor="outlined-input-employer-phone">
							<Heading6
								text="Phone"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<MaskedInput
							id="outlined-input-employer-phone"
							placeholder="[XXX] XXX-XXXX"
							type="phone"
							value={phoneNumber}
							onInputChange={handlePhoneNumber}
							disabled={disabled}
						/>
					</Stack>
				</Stack>

				<Stack direction="row" className={styles.inputSectionContainer}>
					{/* job title */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor="outlined-input-title">
							<Heading6
								text="Title or Position"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<OutlinedTextInput
							id="outlined-input-title"
							placeholder="Title"
							type="text"
							value={financeData?.positionDescription}
							handleChange={(event) =>
								setData(
									data.map((data, index) => {
										if (financeIndex === index) {
											return {
												...data,
												positionDescription: event.target.value,
											};
										} else {
											return data;
										}
									})
								)
							}
							disabled={disabled}
						/>
					</Stack>

					{/* <input
						className="form-control box83 col-span-1 min-w-[198px]"
						id="inputGroup-sizing-default21"
						placeholder="EIN "
						value={
							coBorrower
								? vestingData?.borrowerTaxIdentificationNumberIdentifier2
								: vestingData?.borrowerTaxIdentificationNumberIdentifier1
						}
						onChange={(event) => {
							if (coBorrower) {
								setVestingData(() => ({
									...vestingData,
									borrowerTaxIdentificationNumberIdentifier2:
										event.target.value,
								}));
							} else {
								setVestingData(() => ({
									...vestingData,
									borrowerTaxIdentificationNumberIdentifier1:
										event.target.value,
								}));
							}
						}}
						disabled={disabled}
					/> */}

					{/* monthly income */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor="masked-income">
							<Heading6
								text="Monthly Base Income"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<MaskedInput
							id="masked-income"
							placeholder="$"
							type="currency"
							value={baseIncome}
							onInputChange={handleBaseIncome}
							disabled={disabled}
						/>
					</Stack>

					{/* start date */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor="start-date-picker">
							<Heading6
								text="Start Date"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<DatePicker
							id="start-date-picker"
							className={
								disabled
									? styles.datepicker + " " + styles.disabledDatepicker
									: styles.datepicker
							}
							format="MM-dd-yyyy"
							value={employmentStartDate}
							onChange={setEmploymentStartDate}
							disabled={disabled}
							maxDate={new Date()}
						/>
					</Stack>
				</Stack>
			</Stack>

			<CustomDivider />
		</Box>
	);
};

export default EmploymentInfo;
