import React from "react";
import { DateTime } from "luxon";

import { Checkbox, TableCell, TableRow } from "@mui/material";

import Body1 from "../../typography/body-01";

import styles from "../index.module.scss";

const SatisfyingConditionsRow = ({ row, handleRowClick, selectedRow }) => {
	const isSelected = (name) => selectedRow.indexOf(name) !== -1;

	const isItemSelected = isSelected(row.id);
	const labelId = `enhanced-table-checkbox-${row.id}`;

	return (
		<TableRow
			hover
			tabIndex={-1}
			key={row.id}
			onClick={(event) => handleRowClick(event, row.id)}
			role="checkbox"
			aria-checked={isItemSelected}
			selected={isItemSelected}
			className={styles.conditionRow}
		>
			<TableCell padding="checkbox">
				<Checkbox
					color="primary"
					checked={isItemSelected}
					inputProps={{
						"aria-labelledby": labelId,
					}}
				/>
			</TableCell>
			<TableCell>
				<Body1
					text={DateTime.fromISO(row.createdDate, {
						zone: "America/New_York",
					}).toFormat("MM/dd/yyyy")}
				/>
			</TableCell>
			<TableCell>
				<Body1 text={row.title} extraClass={styles.truncatedText} />
			</TableCell>
			<TableCell>
				<Body1 text={row.description} extraClass={styles.truncatedText} />
			</TableCell>
			<TableCell>
				<Body1 text={row.category} />
			</TableCell>
			<TableCell>
				<Body1 text={row.status} />
			</TableCell>
			<TableCell>
				<Body1 text={row.sub_status} />
			</TableCell>
		</TableRow>
	);
};

export default SatisfyingConditionsRow;
