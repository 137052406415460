import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// mui components
import { Box, Stack, Tab, Tabs } from "@mui/material";

// custom components
import TabPanel from "../../tab-panel";
import ContactInformation from "../../loan-form-card/contact-information";
import EmploymentInfo from "../../loan-form-card/employment-information";
import AssetsInformation from "../../loan-form-card/assets-information";
import LiabilitesInformation from "../../loan-form-card/liabilities-information";
import RealEstate from "../../loan-form-card/real-estate";
import MilitaryInfo from "../../loan-form-card/military-information";
import DeclarationInfo from "../../loan-form-card/declaration-information";
import DemographicInfo from "../../loan-form-card/demographic-information";
import DemographicProvidedInfo from "../../loan-form-card/demographic-provided-information";
import PrimaryButton from "../../buttons/primary-button";
import SecondaryButton from "../../buttons/secondary-button";
import Heading6 from "../../typography/heading-06";

// api slice
import { useGetBorrowerPairMutation } from "../../../features/get-borrower-pair/getBorrowerPairApiSlice";
import { useUpdateBorrowerPairMutation } from "../../../features/update-borrower-pair/updateBorrowerPairApiSlice";
import { useFieldMutation } from "../../../features/field-values/fieldValueApiSlice";
import { useUpdateFieldsMutation } from "../../../features/update-fields/updateFieldsApiSlice";

// reducer slice
import { selectSameAsCurrentAddress } from "../../../features/same-as-current-address/sameAsCurrentAddressSlice";

// mui icons
import { ArrowBackOutlined, ArrowForwardOutlined } from "@mui/icons-material";

// custom modal
import LoaderModal from "../../modal/loader-modal";
import ProcessingModal from "../../modal/processing-modal";
import SuccessModal from "../../modal/success-modal";
import ErrorModal from "../../modal/error-modal";
import LiabilityLinkModal from "../../modal/link-liability-modal";

// custom styles
import styles from "./index.module.scss";
import RequiredError from "../../required-error";

export const UpdateLoanApplication = ({
	setLoanApplicationStage,
	loanGuid,
}) => {
	console.log("45 loan id:", loanGuid);

	const [field, { isLoading: loadingValues }] = useFieldMutation();

	const [updateFields, { isLoading: isUpdatingFields }] =
		useUpdateFieldsMutation();

	const [getBorrowerPair, { isLoading: loadingBorrowerPairData }] =
		useGetBorrowerPairMutation();

	const [updateBorrowerPair, { isLoading: updatingBorrowerPair }] =
		useUpdateBorrowerPairMutation();

	const [borrowerResponseData, setBorrowerResponseData] = useState([]);
	const [borrowerPairData, setBorrowerPairData] = useState([]);

	const [borrowerResidences, setBorrowerResidences] = useState([]);
	const [borrowerFinances, setBorrowerFinances] = useState([]);
	const [borrowerMilitary, setBorrowerMilitary] = useState([]);
	const [borrowerDemographicProvided, setBorrowerDemographicProvided] =
		useState([]);

	const [borrowerDemographicInfo, setBorrowerDemographicInfo] = useState([]);
	const [borrowerAsset, setBorrowerAsset] = useState([]);
	const [borrowerLiability, setBorrowerLiability] = useState([]);
	const [borrowerRealEstate, setBorrowerRealEstate] = useState([]);
	const [borrowerDeclaration, setBorrowerDeclaration] = useState([]);

	const [borrowerVesting, setBorrowerVesting] = useState([]);

	const [sameAddress, setSameAddress] = useState([]);

	const [tabValue, setTabValue] = useState(0);

	const sameAddressValue = useSelector(selectSameAsCurrentAddress);
	console.log("75 same address reducer:", sameAddressValue);

	const [selectedRealEstate, setSelectedRealEstate] = useState(null);
	const [selectedLiability, setSelectedLiability] = useState([]);
	const [liabilityModalVisible, setLiabilityModalVisible] = useState(false);

	const handleOpenLiabilityModal = () => {
		setLiabilityModalVisible(true);
	};

	const handleCloseLiabilityModal = () => {
		setLiabilityModalVisible(false);
		setSelectedRealEstate(null);
		setSelectedLiability([]);
	};

	const modelArr = [
		{
			id: "Residence/0",
			applicantType: "Borrower",
			mailingAddressIndicator: false,
			residencyType: "Current",
		},
		{
			id: "Residence/1",
			applicantType: "Borrower",
			mailingAddressIndicator: true,
		},
		{
			id: "Residence/2",
			applicantType: "CoBorrower",
			mailingAddressIndicator: false,
			residencyType: "Current",
		},
		{
			id: "Residence/3",
			applicantType: "CoBorrower",
			mailingAddressIndicator: true,
		},
	];

	const reorderResidencesArray = (
		arr,
		borrowerMailingAddress,
		coborrowerMailingAddress,
		borrowerPairIndex
	) => {
		console.log("100 arr:", arr);
		// Separate the objects into two groups: borrowers and co-borrowers
		const borrowers = arr
			.filter((obj) => obj.applicantType === "Borrower")
			.sort((a, b) => {
				const numA = parseInt(a.id.split("Residence/")[1]);
				const numB = parseInt(b.id.split("Residence/")[1]);
				return numA - numB;
			});

		const coBorrowers = arr
			.filter((obj) => obj.applicantType === "CoBorrower")
			.sort((a, b) => {
				const numA = parseInt(a.id.split("Residence/")[1]);
				const numB = parseInt(b.id.split("Residence/")[1]);
				return numA - numB;
			});

		console.log("125 borrowers:", borrowers);
		console.log("125 Co Borrowers:", coBorrowers);

		// Sort the residency objects (the first two objects) by residencyType
		const residencyObjects = [borrowers[0], coBorrowers[0]].filter(Boolean);

		residencyObjects.sort((a, b) => {
			const aIndex = a.residencyType === "Current" ? 0 : 1;
			const bIndex = b.residencyType === "Current" ? 0 : 1;
			return aIndex - bIndex;
		});

		// Sort the mailing objects (the next two objects) by mailingAddressIndicator
		const mailingObjects = [borrowers[1], coBorrowers[1]].filter(Boolean);

		mailingObjects.sort((a, b) => {
			const aIndex = a.mailingAddressIndicator ? 1 : 0;
			const bIndex = b.mailingAddressIndicator ? 1 : 0;
			return aIndex - bIndex;
		});

		console.log("60 residency:", residencyObjects);
		console.log("60 mailing:", mailingObjects);

		console.log("150 borrower mailing address:", borrowerMailingAddress);
		console.log("150 coborrower mailing address:", coborrowerMailingAddress);

		// mailingObjects[0]["mailingAddressSameAsPresentIndicator"] =
		// 	borrowerMailingAddress;
		// mailingObjects[1]["mailingAddressSameAsPresentIndicator"] =
		// 	borrowerMailingAddress;

		// console.log("160 mailing:", mailingObjects);

		var mailingArr = JSON.parse(JSON.stringify(mailingObjects));
		// mailingArr[0]["sameAddress"] = borrowerMailingAddress;
		// mailingArr[1]["sameAddress"] = coborrowerMailingAddress;

		if (borrowerPairIndex === 0 && borrowerMailingAddress) {
			// borrower at first borrower pair
			setSameAddress((prevArr) => [...prevArr, 0]);
		} else if (borrowerPairIndex === 1 && borrowerMailingAddress) {
			// borrower at second borrower pair
			setSameAddress((prevArr) => [...prevArr, 4]);
		} else if (borrowerPairIndex === 2 && borrowerMailingAddress) {
			// borrower at third borrower pair
			setSameAddress((prevArr) => [...prevArr, 8]);
		}

		if (borrowerPairIndex === 0 && coborrowerMailingAddress) {
			// co-borrower at first borrower pair
			setSameAddress((prevArr) => [...prevArr, 2]);
		} else if (borrowerPairIndex === 1 && coborrowerMailingAddress) {
			// co-borrower at second borrower pair
			setSameAddress((prevArr) => [...prevArr, 6]);
		} else if (borrowerPairIndex === 2 && coborrowerMailingAddress) {
			// co-borrower at third borrower pair
			setSameAddress((prevArr) => [...prevArr, 10]);
		}

		// console.log("160 mailing:", mailingArr);

		const mergeArrays = (a, b) =>
			(a.length > b.length ? a : b).reduce(
				(acc, cur, i) => (a[i] && b[i] ? [...acc, a[i], b[i]] : [...acc, cur]),
				[]
			);

		// Combine the sorted residency and mailing objects into a new array
		const reorderedArray = mergeArrays(residencyObjects, mailingArr);

		/* let l = Math.min(residencyObjects.length, mailingObjects.length);
		
			for (i = 0; i < l; i++) {
			reorderedArray.push(residencyObjects[i], mailingObjects[i]);
			} */

		console.log("60 reorder:", reorderedArray);

		// Assign the correct id value to each object based on its index in the new array
		let finalArray;

		// if array length is less than 4
		if (reorderedArray?.length <= 4) {
			finalArray = reorderedArray.map((obj, index) => {
				const id = `Residence/${
					obj.applicantType === "Borrower" && obj.residencyType === "Current"
						? 0
						: obj.applicantType === "Borrower" && obj.mailingAddressIndicator
						? 1
						: obj.applicantType === "CoBorrower" &&
						  obj.residencyType === "Current"
						? 2
						: obj.applicantType === "CoBorrower" && obj.mailingAddressIndicator
						? 3
						: index
				}`;
				return { ...obj, id };
			});
		} else {
			finalArray = reorderedArray;
		}

		console.log("70 finalArray:", finalArray);

		const sortedArr = finalArray.sort((a, b) => {
			const aIndex = parseInt(a.id.split("/")[1]);
			const bIndex = parseInt(b.id.split("/")[1]);
			return aIndex - bIndex;
		});

		console.log("70 sorted:", sortedArr);

		const missingId = modelArr.filter((obj) => {
			return !sortedArr.find((o) => {
				return o.id === obj.id;
			});
		});

		console.log("80 missing:", missingId);

		if (missingId.length > 0) {
			missingId.forEach((missingObj) => {
				const missingIndex = parseInt(missingObj.id.split("/")[1]);
				sortedArr.splice(missingIndex, 0, missingObj);
			});
		}

		console.log("200 sorted arr:", sortedArr);
		return sortedArr;
	};

	const financesModelArr = [
		{
			id: "Employment/0",
			owner: "Borrower",
			currentEmploymentIndicator: true,
			noLinkToDocTrackIndicator: true,
			militaryEmployer: false,
		},
		{
			id: "Employment/1",
			owner: "CoBorrower",
			currentEmploymentIndicator: true,
			noLinkToDocTrackIndicator: true,
			militaryEmployer: false,
		},
	];

	const reorderFinanceArray = (arr) => {
		console.log("235 finance arr:", arr);
		// Separate the objects into two groups: borrowers and co-borrowers
		const borrowers = arr.filter((obj) => obj.owner === "Borrower");

		const coBorrowers = arr.filter((obj) => obj.owner === "CoBorrower");

		console.log("125 borrowers:", borrowers);
		console.log("125 Co Borrowers:", coBorrowers);

		const mergeArrays = (a, b) =>
			(a.length > b.length ? a : b).reduce(
				(acc, cur, i) => (a[i] && b[i] ? [...acc, a[i], b[i]] : [...acc, cur]),
				[]
			);

		// Combine the sorted residency and mailing objects into a new array
		const reorderedArray = mergeArrays(borrowers, coBorrowers);

		console.log("250 reorder finance:", reorderedArray);

		// Assign the correct id value to each object based on its index in the new array
		let finalArray;

		if (reorderedArray?.length < 2) {
			finalArray = reorderedArray.map((obj, index) => {
				const id = `Employment/${index}`;
				return { ...obj, id };
			});
		} else {
			finalArray = reorderedArray;
		}

		console.log("70 finalArray:", finalArray);

		const sortedArr = finalArray.sort((a, b) => {
			const aIndex = parseInt(a.id.split("/")[1]);
			const bIndex = parseInt(b.id.split("/")[1]);
			return aIndex - bIndex;
		});

		console.log("70 sorted:", sortedArr);

		const missingId = financesModelArr.filter((obj) => {
			return !sortedArr.find((o) => {
				return o.id === obj.id;
			});
		});

		console.log("80 missing:", missingId);

		if (missingId.length > 0) {
			missingId.forEach((missingObj) => {
				const missingIndex = parseInt(missingObj.id.split("/")[1]);
				sortedArr.splice(missingIndex, 0, missingObj);
			});
		}

		console.log("200 sorted arr:", sortedArr);
		return sortedArr;
	};

	const updateField = (fieldValues) => {
		console.log("88 field values:", fieldValues);

		let borrowerVesting = fieldValues?.find(
			(o) => o.fieldId === "IRS4506.X4"
		)?.value;
		let coborrowerVesting = fieldValues?.find(
			(o) => o.fieldId === "Vesting.Trst2TaxID"
		)?.value;

		let vestingArr = {};

		vestingArr.borrowerTaxIdentificationNumberIdentifier1 = borrowerVesting;

		vestingArr.borrowerTaxIdentificationNumberIdentifier2 = coborrowerVesting;

		setBorrowerVesting(vestingArr);
	};

	console.log("370 borrower vesting:", borrowerVesting);

	const getFieldValues = async (loanId) => {
		console.log("175 loan id:", loanId);
		try {
			const getValues = await field({
				loan_guid: loanId,
				fields: "IRS4506.X4,Vesting.Trst2TaxID",
			}).unwrap();
			console.log("175 all fields:", getValues?.response);
			if (getValues?.response?.length > 0) {
				updateField(getValues?.response);
			}
		} catch (err) {
			console.log("584 error:", err);
		}
	};

	useEffect(() => {
		console.log("160 loan Guid:", loanGuid);
		if (loanGuid !== undefined && loanGuid !== "") {
			getFieldValues(loanGuid);
		}
	}, [loanGuid]);

	useEffect(() => {
		const getAllBorrowerPair = async () => {
			const borrowerPairData = await getBorrowerPair({
				loanGuid,
			}).unwrap();

			console.log("90 borrower data:", borrowerPairData?.response);

			let residenceArr = [];
			let financeArr = [];
			let militaryArr = [];
			let demographicProvidedArr = [];
			let demographicInfoArr = [];
			let assetArr = [];
			let liabilityArr = [];
			let realEstateArr = [];
			let declarationArr = [];

			for (let index = 0; index < borrowerPairData?.response?.length; index++) {
				console.log("100 res data:", borrowerPairData?.response[index]);

				residenceArr.push(
					...reorderResidencesArray(
						borrowerPairData?.response[index]?.residences?.filter(
							(item) => item?.residencyType !== "Prior"
						),
						borrowerPairData?.response[index]?.borrower
							?.mailingAddressSameAsPresentIndicator,
						borrowerPairData?.response[index]?.coborrower
							?.mailingAddressSameAsPresentIndicator,
						index
					)
				);

				if (
					borrowerPairData?.response[index]?.employment !== undefined &&
					borrowerPairData?.response[index]?.employment?.length > 0
				) {
					financeArr.push(
						...reorderFinanceArray(
							borrowerPairData?.response[index]?.employment
						)
					);
				} else {
					financeArr.push(
						{
							owner: "Borrower",
						},
						{
							owner: "CoBorrower",
						}
					);
				}

				if (
					Object.keys(borrowerPairData?.response[index]?.borrower).length > 0 &&
					Object.keys(borrowerPairData?.response[index]?.coborrower).length > 0
				) {
					// both borrower and co-borrower
					militaryArr.push(
						{
							militaryServiceExpectedCompletionDate:
								borrowerPairData?.response[index]?.borrower
									?.militaryServiceExpectedCompletionDate,
							SelfDeclaredMilitaryServiceIndicator:
								borrowerPairData?.response[index]?.borrower
									?.SelfDeclaredMilitaryServiceIndicator,
							selfDeclaredMilitaryServiceIndicator:
								borrowerPairData?.response[index]?.borrower
									?.selfDeclaredMilitaryServiceIndicator,
							activeDuty:
								borrowerPairData?.response[index]?.borrower?.activeDuty,
							veteran: borrowerPairData?.response[index]?.borrower?.veteran,
							reserveNationalGuardReserveActivated:
								borrowerPairData?.response[index]?.borrower
									?.reserveNationalGuardReserveActivated,
							spousalVABenefitsEligibilityIndicator:
								borrowerPairData?.response[index]?.borrower
									?.spousalVABenefitsEligibilityIndicator,
							SpousalVABenefitsEligibilityIndicator:
								borrowerPairData?.response[index]?.borrower
									?.SpousalVABenefitsEligibilityIndicator,
						},
						{
							militaryServiceExpectedCompletionDate:
								borrowerPairData?.response[index]?.coborrower
									?.militaryServiceExpectedCompletionDate,
							SelfDeclaredMilitaryServiceIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.SelfDeclaredMilitaryServiceIndicator,
							selfDeclaredMilitaryServiceIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.selfDeclaredMilitaryServiceIndicator,
							activeDuty:
								borrowerPairData?.response[index]?.coborrower?.activeDuty,
							veteran: borrowerPairData?.response[index]?.coborrower?.veteran,
							reserveNationalGuardReserveActivated:
								borrowerPairData?.response[index]?.coborrower
									?.reserveNationalGuardReserveActivated,
							spousalVABenefitsEligibilityIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.spousalVABenefitsEligibilityIndicator,
							SpousalVABenefitsEligibilityIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.SpousalVABenefitsEligibilityIndicator,
						}
					);
					demographicProvidedArr.push(
						// borrower
						{
							applicationTakenMethodType:
								borrowerPairData?.response[index]?.borrower
									?.applicationTakenMethodType,
							isEthnicityBasedOnVisual:
								borrowerPairData?.response[index]?.borrower
									?.isEthnicityBasedOnVisual,
							isRaceBasedOnVisual:
								borrowerPairData?.response[index]?.borrower
									?.isRaceBasedOnVisual,
							isSexBasedOnVisual:
								borrowerPairData?.response[index]?.borrower?.isSexBasedOnVisual,
						},
						// coborrower
						{
							applicationTakenMethodType:
								borrowerPairData?.response[index]?.coborrower
									?.applicationTakenMethodType,
							isEthnicityBasedOnVisual:
								borrowerPairData?.response[index]?.coborrower
									?.isEthnicityBasedOnVisual,
							isRaceBasedOnVisual:
								borrowerPairData?.response[index]?.coborrower
									?.isRaceBasedOnVisual,
							isSexBasedOnVisual:
								borrowerPairData?.response[index]?.coborrower
									?.isSexBasedOnVisual,
						}
					);
					demographicInfoArr.push(
						// borrower
						{
							hmdaEthnicityHispanicLatinoIndicator:
								borrowerPairData?.response[index]?.borrower
									?.hmdaEthnicityHispanicLatinoIndicator,
							hmdaEthnicityNotHispanicLatinoIndicator:
								borrowerPairData?.response[index]?.borrower
									?.hmdaEthnicityNotHispanicLatinoIndicator,
							hmdaMexicanIndicator:
								borrowerPairData?.response[index]?.borrower
									?.hmdaMexicanIndicator,
							hmdaPuertoRicanIndicator:
								borrowerPairData?.response[index]?.borrower
									?.hmdaPuertoRicanIndicator,
							hmdaCubanIndicator:
								borrowerPairData?.response[index]?.borrower?.hmdaCubanIndicator,
							hmdaHispanicLatinoOtherOriginIndicator:
								borrowerPairData?.response[index]?.borrower
									?.hmdaHispanicLatinoOtherOriginIndicator,
							hmdaOtherHispanicLatinoOrigin:
								borrowerPairData?.response[index]?.borrower
									?.hmdaOtherHispanicLatinoOrigin,
							hmdaEthnicityDoNotWishIndicator:
								borrowerPairData?.response[index]?.borrower
									?.hmdaEthnicityDoNotWishIndicator,
							hmdaAmericanIndianIndicator:
								borrowerPairData?.response[index]?.borrower
									?.hmdaAmericanIndianIndicator,
							hmdaRaceDoNotWishProvideIndicator:
								borrowerPairData?.response[index]?.borrower
									?.hmdaRaceDoNotWishProvideIndicator,
							hmdaAmericanIndianTribe:
								borrowerPairData?.response[index]?.borrower
									?.hmdaAmericanIndianTribe,
							americanIndianOtherValue:
								borrowerPairData?.response[index]?.borrower
									?.americanIndianOtherValue,
							hmdaAsianIndicator:
								borrowerPairData?.response[index]?.borrower?.hmdaAsianIndicator,
							hmdaAsianIndianIndicator:
								borrowerPairData?.response[index]?.borrower
									?.hmdaAsianIndianIndicator,
							hmdaChineseIndicator:
								borrowerPairData?.response[index]?.borrower
									?.hmdaChineseIndicator,
							hmdaFilipinoIndicator:
								borrowerPairData?.response[index]?.borrower
									?.hmdaFilipinoIndicator,
							hmdaJapaneseIndicator:
								borrowerPairData?.response[index]?.borrower
									?.hmdaJapaneseIndicator,
							hmdaKoreanIndicator:
								borrowerPairData?.response[index]?.borrower
									?.hmdaKoreanIndicator,
							hmdaVietnameseIndicator:
								borrowerPairData?.response[index]?.borrower
									?.hmdaVietnameseIndicator,
							hmdaAsianOtherRaceIndicator:
								borrowerPairData?.response[index]?.borrower
									?.hmdaAsianOtherRaceIndicator,
							hmdaOtherAsianRace:
								borrowerPairData?.response[index]?.borrower?.hmdaOtherAsianRace,
							hmdaAfricanAmericanIndicator:
								borrowerPairData?.response[index]?.borrower
									?.hmdaAfricanAmericanIndicator,
							hmdaPacificIslanderIndicator:
								borrowerPairData?.response[index]?.borrower
									?.hmdaPacificIslanderIndicator,
							hmdaNativeHawaiianIndicator:
								borrowerPairData?.response[index]?.borrower
									?.hmdaNativeHawaiianIndicator,
							hmdaGuamanianOrChamorroIndicator:
								borrowerPairData?.response[index]?.borrower
									?.hmdaGuamanianOrChamorroIndicator,
							hmdaSamoanIndicator:
								borrowerPairData?.response[index]?.borrower
									?.hmdaSamoanIndicator,
							hmdaPacificIslanderOtherIndicator:
								borrowerPairData?.response[index]?.borrower
									?.hmdaPacificIslanderOtherIndicator,
							hmdaOtherPacificIslanderRace:
								borrowerPairData?.response[index]?.borrower
									?.hmdaOtherPacificIslanderRace,
							hmdaWhiteIndicator:
								borrowerPairData?.response[index]?.borrower?.hmdaWhiteIndicator,
							hmdaGendertypeFemaleIndicator:
								borrowerPairData?.response[index]?.borrower
									?.hmdaGendertypeFemaleIndicator,
							HmdaGendertypeFemaleIndicator:
								borrowerPairData?.response[index]?.borrower
									?.HmdaGendertypeFemaleIndicator,
							hmdaGendertypeMaleIndicator:
								borrowerPairData?.response[index]?.borrower
									?.hmdaGendertypeMaleIndicator,
							HmdaGendertypeMaleIndicator:
								borrowerPairData?.response[index]?.borrower
									?.HmdaGendertypeMaleIndicator,
							hmdaGendertypeDoNotWishIndicator:
								borrowerPairData?.response[index]?.borrower
									?.hmdaGendertypeDoNotWishIndicator,
							HmdaGendertypeDoNotWishIndicator:
								borrowerPairData?.response[index]?.borrower
									?.HmdaGendertypeDoNotWishIndicator,
						},
						// coborrower
						{
							hmdaEthnicityHispanicLatinoIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.hmdaEthnicityHispanicLatinoIndicator,
							hmdaEthnicityNotHispanicLatinoIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.hmdaEthnicityNotHispanicLatinoIndicator,
							hmdaMexicanIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.hmdaMexicanIndicator,
							hmdaPuertoRicanIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.hmdaPuertoRicanIndicator,
							hmdaCubanIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.hmdaCubanIndicator,
							hmdaHispanicLatinoOtherOriginIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.hmdaHispanicLatinoOtherOriginIndicator,
							hmdaOtherHispanicLatinoOrigin:
								borrowerPairData?.response[index]?.coborrower
									?.hmdaOtherHispanicLatinoOrigin,
							hmdaEthnicityDoNotWishIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.hmdaEthnicityDoNotWishIndicator,
							hmdaAmericanIndianIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.hmdaAmericanIndianIndicator,
							hmdaRaceDoNotWishProvideIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.hmdaRaceDoNotWishProvideIndicator,
							hmdaAmericanIndianTribe:
								borrowerPairData?.response[index]?.coborrower
									?.hmdaAmericanIndianTribe,
							americanIndianOtherValue:
								borrowerPairData?.response[index]?.coborrower
									?.americanIndianOtherValue,
							hmdaAsianIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.hmdaAsianIndicator,
							hmdaAsianIndianIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.hmdaAsianIndianIndicator,
							hmdaChineseIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.hmdaChineseIndicator,
							hmdaFilipinoIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.hmdaFilipinoIndicator,
							hmdaJapaneseIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.hmdaJapaneseIndicator,
							hmdaKoreanIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.hmdaKoreanIndicator,
							hmdaVietnameseIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.hmdaVietnameseIndicator,
							hmdaAsianOtherRaceIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.hmdaAsianOtherRaceIndicator,
							hmdaOtherAsianRace:
								borrowerPairData?.response[index]?.coborrower
									?.hmdaOtherAsianRace,
							hmdaAfricanAmericanIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.hmdaAfricanAmericanIndicator,
							hmdaPacificIslanderIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.hmdaPacificIslanderIndicator,
							hmdaNativeHawaiianIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.hmdaNativeHawaiianIndicator,
							hmdaGuamanianOrChamorroIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.hmdaGuamanianOrChamorroIndicator,
							hmdaSamoanIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.hmdaSamoanIndicator,
							hmdaPacificIslanderOtherIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.hmdaPacificIslanderOtherIndicator,
							hmdaOtherPacificIslanderRace:
								borrowerPairData?.response[index]?.coborrower
									?.hmdaOtherPacificIslanderRace,
							hmdaWhiteIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.hmdaWhiteIndicator,
							hmdaGendertypeFemaleIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.hmdaGendertypeFemaleIndicator,
							HmdaGendertypeFemaleIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.HmdaGendertypeFemaleIndicator,
							hmdaGendertypeMaleIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.hmdaGendertypeMaleIndicator,
							HmdaGendertypeMaleIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.HmdaGendertypeMaleIndicator,
							hmdaGendertypeDoNotWishIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.hmdaGendertypeDoNotWishIndicator,
							HmdaGendertypeDoNotWishIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.HmdaGendertypeDoNotWishIndicator,
						}
					);
					declarationArr.push(
						{
							undisclosedBorrowedFundsAmount:
								borrowerPairData?.response[index]?.borrower
									?.undisclosedBorrowedFundsAmount,
							intentToOccupyIndicator:
								borrowerPairData?.response[index]?.borrower
									?.intentToOccupyIndicator,
							homeownerPastThreeYearsIndicator:
								borrowerPairData?.response[index]?.borrower
									?.homeownerPastThreeYearsIndicator,
							priorPropertyUsageType:
								borrowerPairData?.response[index]?.borrower
									?.priorPropertyUsageType,
							priorPropertyTitleType:
								borrowerPairData?.response[index]?.borrower
									?.priorPropertyTitleType,
							specialBorrowerSellerRelationshipIndicator:
								borrowerPairData?.response[index]?.borrower
									?.specialBorrowerSellerRelationshipIndicator,
							undisclosedBorrowedFundsIndicator:
								borrowerPairData?.response[index]?.borrower
									?.undisclosedBorrowedFundsIndicator,
							undisclosedMortgageApplicationIndicator:
								borrowerPairData?.response[index]?.borrower
									?.undisclosedMortgageApplicationIndicator,
							undisclosedCreditApplicationIndicator:
								borrowerPairData?.response[index]?.borrower
									?.undisclosedCreditApplicationIndicator,
							propertyProposedCleanEnergyLienIndicator:
								borrowerPairData?.response[index]?.borrower
									?.propertyProposedCleanEnergyLienIndicator,
							undisclosedComakerOfNoteIndicator:
								borrowerPairData?.response[index]?.borrower
									?.undisclosedComakerOfNoteIndicator,
							outstandingJudgementsIndicator:
								borrowerPairData?.response[index]?.borrower
									?.outstandingJudgementsIndicator,
							presentlyDelinquentIndicatorURLA:
								borrowerPairData?.response[index]?.borrower
									?.presentlyDelinquentIndicatorURLA,
							partyToLawsuitIndicatorURLA:
								borrowerPairData?.response[index]?.borrower
									?.partyToLawsuitIndicatorURLA,
							priorPropertyDeedInLieuConveyedIndicator:
								borrowerPairData?.response[index]?.borrower
									?.priorPropertyDeedInLieuConveyedIndicator,
							priorPropertyShortSaleCompletedIndicator:
								borrowerPairData?.response[index]?.borrower
									?.priorPropertyShortSaleCompletedIndicator,
							priorPropertyForeclosureCompletedIndicator:
								borrowerPairData?.response[index]?.borrower
									?.priorPropertyForeclosureCompletedIndicator,
							bankruptcyIndicator:
								borrowerPairData?.response[index]?.borrower
									?.bankruptcyIndicator,
							bankruptcyIndicatorChapterSeven:
								borrowerPairData?.response[index]?.borrower
									?.bankruptcyIndicatorChapterSeven,
							bankruptcyIndicatorChapterEleven:
								borrowerPairData?.response[index]?.borrower
									?.bankruptcyIndicatorChapterEleven,
							bankruptcyIndicatorChapterTwelve:
								borrowerPairData?.response[index]?.borrower
									?.bankruptcyIndicatorChapterTwelve,
							bankruptcyIndicatorChapterThirteen:
								borrowerPairData?.response[index]?.borrower
									?.bankruptcyIndicatorChapterThirteen,
						},
						// coborrower
						{
							undisclosedBorrowedFundsAmount:
								borrowerPairData?.response[index]?.coborrower
									?.undisclosedBorrowedFundsAmount,
							intentToOccupyIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.intentToOccupyIndicator,
							homeownerPastThreeYearsIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.homeownerPastThreeYearsIndicator,
							priorPropertyUsageType:
								borrowerPairData?.response[index]?.coborrower
									?.priorPropertyUsageType,
							priorPropertyTitleType:
								borrowerPairData?.response[index]?.coborrower
									?.priorPropertyTitleType,
							specialBorrowerSellerRelationshipIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.specialBorrowerSellerRelationshipIndicator,
							undisclosedBorrowedFundsIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.undisclosedBorrowedFundsIndicator,
							undisclosedMortgageApplicationIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.undisclosedMortgageApplicationIndicator,
							undisclosedCreditApplicationIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.undisclosedCreditApplicationIndicator,
							propertyProposedCleanEnergyLienIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.propertyProposedCleanEnergyLienIndicator,
							undisclosedComakerOfNoteIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.undisclosedComakerOfNoteIndicator,
							outstandingJudgementsIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.outstandingJudgementsIndicator,
							presentlyDelinquentIndicatorURLA:
								borrowerPairData?.response[index]?.coborrower
									?.presentlyDelinquentIndicatorURLA,
							partyToLawsuitIndicatorURLA:
								borrowerPairData?.response[index]?.coborrower
									?.partyToLawsuitIndicatorURLA,
							priorPropertyDeedInLieuConveyedIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.priorPropertyDeedInLieuConveyedIndicator,
							priorPropertyShortSaleCompletedIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.priorPropertyShortSaleCompletedIndicator,
							priorPropertyForeclosureCompletedIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.priorPropertyForeclosureCompletedIndicator,
							bankruptcyIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.bankruptcyIndicator,
							bankruptcyIndicatorChapterSeven:
								borrowerPairData?.response[index]?.coborrower
									?.bankruptcyIndicatorChapterSeven,
							bankruptcyIndicatorChapterEleven:
								borrowerPairData?.response[index]?.coborrower
									?.bankruptcyIndicatorChapterEleven,
							bankruptcyIndicatorChapterTwelve:
								borrowerPairData?.response[index]?.coborrower
									?.bankruptcyIndicatorChapterTwelve,
							bankruptcyIndicatorChapterThirteen:
								borrowerPairData?.response[index]?.coborrower
									?.bankruptcyIndicatorChapterThirteen,
						}
					);
				} else if (
					Object.keys(borrowerPairData?.response[index]?.coborrower).length <= 0
				) {
					// only borrower, no co-borrower
					militaryArr.push(
						{
							militaryServiceExpectedCompletionDate:
								borrowerPairData?.response[index]?.borrower
									?.militaryServiceExpectedCompletionDate,
							SelfDeclaredMilitaryServiceIndicator:
								borrowerPairData?.response[index]?.borrower
									?.SelfDeclaredMilitaryServiceIndicator,
							selfDeclaredMilitaryServiceIndicator:
								borrowerPairData?.response[index]?.borrower
									?.selfDeclaredMilitaryServiceIndicator,
							activeDuty:
								borrowerPairData?.response[index]?.borrower?.activeDuty,
							veteran: borrowerPairData?.response[index]?.borrower?.veteran,
							reserveNationalGuardReserveActivated:
								borrowerPairData?.response[index]?.borrower
									?.reserveNationalGuardReserveActivated,
							spousalVABenefitsEligibilityIndicator:
								borrowerPairData?.response[index]?.borrower
									?.spousalVABenefitsEligibilityIndicator,
							SpousalVABenefitsEligibilityIndicator:
								borrowerPairData?.response[index]?.borrower
									?.SpousalVABenefitsEligibilityIndicator,
						},
						{}
					);
					demographicProvidedArr.push(
						{
							applicationTakenMethodType:
								borrowerPairData?.response[index]?.borrower
									?.applicationTakenMethodType,
							isEthnicityBasedOnVisual:
								borrowerPairData?.response[index]?.borrower
									?.isEthnicityBasedOnVisual,
							isRaceBasedOnVisual:
								borrowerPairData?.response[index]?.borrower
									?.isRaceBasedOnVisual,
							isSexBasedOnVisual:
								borrowerPairData?.response[index]?.borrower?.isSexBasedOnVisual,
						},
						{}
					);
					demographicInfoArr.push(
						{
							hmdaEthnicityHispanicLatinoIndicator:
								borrowerPairData?.response[index]?.borrower
									?.hmdaEthnicityHispanicLatinoIndicator,
							hmdaEthnicityNotHispanicLatinoIndicator:
								borrowerPairData?.response[index]?.borrower
									?.hmdaEthnicityNotHispanicLatinoIndicator,
							hmdaMexicanIndicator:
								borrowerPairData?.response[index]?.borrower
									?.hmdaMexicanIndicator,
							hmdaPuertoRicanIndicator:
								borrowerPairData?.response[index]?.borrower
									?.hmdaPuertoRicanIndicator,
							hmdaCubanIndicator:
								borrowerPairData?.response[index]?.borrower?.hmdaCubanIndicator,
							hmdaHispanicLatinoOtherOriginIndicator:
								borrowerPairData?.response[index]?.borrower
									?.hmdaHispanicLatinoOtherOriginIndicator,
							hmdaOtherHispanicLatinoOrigin:
								borrowerPairData?.response[index]?.borrower
									?.hmdaOtherHispanicLatinoOrigin,
							hmdaEthnicityDoNotWishIndicator:
								borrowerPairData?.response[index]?.borrower
									?.hmdaEthnicityDoNotWishIndicator,
							hmdaAmericanIndianIndicator:
								borrowerPairData?.response[index]?.borrower
									?.hmdaAmericanIndianIndicator,
							hmdaRaceDoNotWishProvideIndicator:
								borrowerPairData?.response[index]?.borrower
									?.hmdaRaceDoNotWishProvideIndicator,
							hmdaAmericanIndianTribe:
								borrowerPairData?.response[index]?.borrower
									?.hmdaAmericanIndianTribe,
							americanIndianOtherValue:
								borrowerPairData?.response[index]?.borrower
									?.americanIndianOtherValue,
							hmdaAsianIndicator:
								borrowerPairData?.response[index]?.borrower?.hmdaAsianIndicator,
							hmdaAsianIndianIndicator:
								borrowerPairData?.response[index]?.borrower
									?.hmdaAsianIndianIndicator,
							hmdaChineseIndicator:
								borrowerPairData?.response[index]?.borrower
									?.hmdaChineseIndicator,
							hmdaFilipinoIndicator:
								borrowerPairData?.response[index]?.borrower
									?.hmdaFilipinoIndicator,
							hmdaJapaneseIndicator:
								borrowerPairData?.response[index]?.borrower
									?.hmdaJapaneseIndicator,
							hmdaKoreanIndicator:
								borrowerPairData?.response[index]?.borrower
									?.hmdaKoreanIndicator,
							hmdaVietnameseIndicator:
								borrowerPairData?.response[index]?.borrower
									?.hmdaVietnameseIndicator,
							hmdaAsianOtherRaceIndicator:
								borrowerPairData?.response[index]?.borrower
									?.hmdaAsianOtherRaceIndicator,
							hmdaOtherAsianRace:
								borrowerPairData?.response[index]?.borrower?.hmdaOtherAsianRace,
							hmdaAfricanAmericanIndicator:
								borrowerPairData?.response[index]?.borrower
									?.hmdaAfricanAmericanIndicator,
							hmdaPacificIslanderIndicator:
								borrowerPairData?.response[index]?.borrower
									?.hmdaPacificIslanderIndicator,
							hmdaNativeHawaiianIndicator:
								borrowerPairData?.response[index]?.borrower
									?.hmdaNativeHawaiianIndicator,
							hmdaGuamanianOrChamorroIndicator:
								borrowerPairData?.response[index]?.borrower
									?.hmdaGuamanianOrChamorroIndicator,
							hmdaSamoanIndicator:
								borrowerPairData?.response[index]?.borrower
									?.hmdaSamoanIndicator,
							hmdaPacificIslanderOtherIndicator:
								borrowerPairData?.response[index]?.borrower
									?.hmdaPacificIslanderOtherIndicator,
							hmdaOtherPacificIslanderRace:
								borrowerPairData?.response[index]?.borrower
									?.hmdaOtherPacificIslanderRace,
							hmdaWhiteIndicator:
								borrowerPairData?.response[index]?.borrower?.hmdaWhiteIndicator,
							hmdaGendertypeFemaleIndicator:
								borrowerPairData?.response[index]?.borrower
									?.hmdaGendertypeFemaleIndicator,
							HmdaGendertypeFemaleIndicator:
								borrowerPairData?.response[index]?.borrower
									?.HmdaGendertypeFemaleIndicator,
							hmdaGendertypeMaleIndicator:
								borrowerPairData?.response[index]?.borrower
									?.hmdaGendertypeMaleIndicator,
							HmdaGendertypeMaleIndicator:
								borrowerPairData?.response[index]?.borrower
									?.HmdaGendertypeMaleIndicator,
							hmdaGendertypeDoNotWishIndicator:
								borrowerPairData?.response[index]?.borrower
									?.hmdaGendertypeDoNotWishIndicator,
							HmdaGendertypeDoNotWishIndicator:
								borrowerPairData?.response[index]?.borrower
									?.HmdaGendertypeDoNotWishIndicator,
						},
						{}
					);
					declarationArr.push(
						{
							undisclosedBorrowedFundsAmount:
								borrowerPairData?.response[index]?.borrower
									?.undisclosedBorrowedFundsAmount,
							intentToOccupyIndicator:
								borrowerPairData?.response[index]?.borrower
									?.intentToOccupyIndicator,
							homeownerPastThreeYearsIndicator:
								borrowerPairData?.response[index]?.borrower
									?.homeownerPastThreeYearsIndicator,
							priorPropertyUsageType:
								borrowerPairData?.response[index]?.borrower
									?.priorPropertyUsageType,
							priorPropertyTitleType:
								borrowerPairData?.response[index]?.borrower
									?.priorPropertyTitleType,
							specialBorrowerSellerRelationshipIndicator:
								borrowerPairData?.response[index]?.borrower
									?.specialBorrowerSellerRelationshipIndicator,
							undisclosedBorrowedFundsIndicator:
								borrowerPairData?.response[index]?.borrower
									?.undisclosedBorrowedFundsIndicator,
							undisclosedMortgageApplicationIndicator:
								borrowerPairData?.response[index]?.borrower
									?.undisclosedMortgageApplicationIndicator,
							undisclosedCreditApplicationIndicator:
								borrowerPairData?.response[index]?.borrower
									?.undisclosedCreditApplicationIndicator,
							propertyProposedCleanEnergyLienIndicator:
								borrowerPairData?.response[index]?.borrower
									?.propertyProposedCleanEnergyLienIndicator,
							undisclosedComakerOfNoteIndicator:
								borrowerPairData?.response[index]?.borrower
									?.undisclosedComakerOfNoteIndicator,
							outstandingJudgementsIndicator:
								borrowerPairData?.response[index]?.borrower
									?.outstandingJudgementsIndicator,
							presentlyDelinquentIndicatorURLA:
								borrowerPairData?.response[index]?.borrower
									?.presentlyDelinquentIndicatorURLA,
							partyToLawsuitIndicatorURLA:
								borrowerPairData?.response[index]?.borrower
									?.partyToLawsuitIndicatorURLA,
							priorPropertyDeedInLieuConveyedIndicator:
								borrowerPairData?.response[index]?.borrower
									?.priorPropertyDeedInLieuConveyedIndicator,
							priorPropertyShortSaleCompletedIndicator:
								borrowerPairData?.response[index]?.borrower
									?.priorPropertyShortSaleCompletedIndicator,
							priorPropertyForeclosureCompletedIndicator:
								borrowerPairData?.response[index]?.borrower
									?.priorPropertyForeclosureCompletedIndicator,
							bankruptcyIndicator:
								borrowerPairData?.response[index]?.borrower
									?.bankruptcyIndicator,
							bankruptcyIndicatorChapterSeven:
								borrowerPairData?.response[index]?.borrower
									?.bankruptcyIndicatorChapterSeven,
							bankruptcyIndicatorChapterEleven:
								borrowerPairData?.response[index]?.borrower
									?.bankruptcyIndicatorChapterEleven,
							bankruptcyIndicatorChapterTwelve:
								borrowerPairData?.response[index]?.borrower
									?.bankruptcyIndicatorChapterTwelve,
							bankruptcyIndicatorChapterThirteen:
								borrowerPairData?.response[index]?.borrower
									?.bankruptcyIndicatorChapterThirteen,
						},
						{}
					);
				} else if (
					Object.keys(borrowerPairData?.response[index]?.borrower).length <= 0
				) {
					// no borrower, only co-borrower
					militaryArr.push(
						{},
						{
							militaryServiceExpectedCompletionDate:
								borrowerPairData?.response[index]?.coborrower
									?.militaryServiceExpectedCompletionDate,
							SelfDeclaredMilitaryServiceIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.SelfDeclaredMilitaryServiceIndicator,
							selfDeclaredMilitaryServiceIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.selfDeclaredMilitaryServiceIndicator,
							activeDuty:
								borrowerPairData?.response[index]?.coborrower?.activeDuty,
							veteran: borrowerPairData?.response[index]?.coborrower?.veteran,
							reserveNationalGuardReserveActivated:
								borrowerPairData?.response[index]?.coborrower
									?.reserveNationalGuardReserveActivated,
							spousalVABenefitsEligibilityIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.spousalVABenefitsEligibilityIndicator,
							SpousalVABenefitsEligibilityIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.SpousalVABenefitsEligibilityIndicator,
						}
					);
					demographicProvidedArr.push(
						{},
						{
							applicationTakenMethodType:
								borrowerPairData?.response[index]?.coborrower
									?.applicationTakenMethodType,
							isEthnicityBasedOnVisual:
								borrowerPairData?.response[index]?.coborrower
									?.isEthnicityBasedOnVisual,
							isRaceBasedOnVisual:
								borrowerPairData?.response[index]?.coborrower
									?.isRaceBasedOnVisual,
							isSexBasedOnVisual:
								borrowerPairData?.response[index]?.coborrower
									?.isSexBasedOnVisual,
						}
					);
					demographicInfoArr.push(
						{},
						{
							hmdaEthnicityHispanicLatinoIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.hmdaEthnicityHispanicLatinoIndicator,
							hmdaEthnicityNotHispanicLatinoIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.hmdaEthnicityNotHispanicLatinoIndicator,
							hmdaMexicanIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.hmdaMexicanIndicator,
							hmdaPuertoRicanIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.hmdaPuertoRicanIndicator,
							hmdaCubanIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.hmdaCubanIndicator,
							hmdaHispanicLatinoOtherOriginIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.hmdaHispanicLatinoOtherOriginIndicator,
							hmdaOtherHispanicLatinoOrigin:
								borrowerPairData?.response[index]?.coborrower
									?.hmdaOtherHispanicLatinoOrigin,
							hmdaEthnicityDoNotWishIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.hmdaEthnicityDoNotWishIndicator,
							hmdaAmericanIndianIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.hmdaAmericanIndianIndicator,
							hmdaRaceDoNotWishProvideIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.hmdaRaceDoNotWishProvideIndicator,
							hmdaAmericanIndianTribe:
								borrowerPairData?.response[index]?.coborrower
									?.hmdaAmericanIndianTribe,
							americanIndianOtherValue:
								borrowerPairData?.response[index]?.coborrower
									?.americanIndianOtherValue,
							hmdaAsianIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.hmdaAsianIndicator,
							hmdaAsianIndianIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.hmdaAsianIndianIndicator,
							hmdaChineseIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.hmdaChineseIndicator,
							hmdaFilipinoIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.hmdaFilipinoIndicator,
							hmdaJapaneseIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.hmdaJapaneseIndicator,
							hmdaKoreanIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.hmdaKoreanIndicator,
							hmdaVietnameseIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.hmdaVietnameseIndicator,
							hmdaAsianOtherRaceIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.hmdaAsianOtherRaceIndicator,
							hmdaOtherAsianRace:
								borrowerPairData?.response[index]?.coborrower
									?.hmdaOtherAsianRace,
							hmdaAfricanAmericanIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.hmdaAfricanAmericanIndicator,
							hmdaPacificIslanderIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.hmdaPacificIslanderIndicator,
							hmdaNativeHawaiianIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.hmdaNativeHawaiianIndicator,
							hmdaGuamanianOrChamorroIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.hmdaGuamanianOrChamorroIndicator,
							hmdaSamoanIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.hmdaSamoanIndicator,
							hmdaPacificIslanderOtherIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.hmdaPacificIslanderOtherIndicator,
							hmdaOtherPacificIslanderRace:
								borrowerPairData?.response[index]?.coborrower
									?.hmdaOtherPacificIslanderRace,
							hmdaWhiteIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.hmdaWhiteIndicator,
							hmdaGendertypeFemaleIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.hmdaGendertypeFemaleIndicator,
							HmdaGendertypeFemaleIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.HmdaGendertypeFemaleIndicator,
							hmdaGendertypeMaleIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.hmdaGendertypeMaleIndicator,
							HmdaGendertypeMaleIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.HmdaGendertypeMaleIndicator,
							hmdaGendertypeDoNotWishIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.hmdaGendertypeDoNotWishIndicator,
							HmdaGendertypeDoNotWishIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.HmdaGendertypeDoNotWishIndicator,
						}
					);
					declarationArr.push(
						{},
						{
							undisclosedBorrowedFundsAmount:
								borrowerPairData?.response[index]?.coborrower
									?.undisclosedBorrowedFundsAmount,
							intentToOccupyIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.intentToOccupyIndicator,
							homeownerPastThreeYearsIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.homeownerPastThreeYearsIndicator,
							priorPropertyUsageType:
								borrowerPairData?.response[index]?.coborrower
									?.priorPropertyUsageType,
							priorPropertyTitleType:
								borrowerPairData?.response[index]?.coborrower
									?.priorPropertyTitleType,
							specialBorrowerSellerRelationshipIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.specialBorrowerSellerRelationshipIndicator,
							undisclosedBorrowedFundsIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.undisclosedBorrowedFundsIndicator,
							undisclosedMortgageApplicationIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.undisclosedMortgageApplicationIndicator,
							undisclosedCreditApplicationIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.undisclosedCreditApplicationIndicator,
							propertyProposedCleanEnergyLienIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.propertyProposedCleanEnergyLienIndicator,
							undisclosedComakerOfNoteIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.undisclosedComakerOfNoteIndicator,
							outstandingJudgementsIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.outstandingJudgementsIndicator,
							presentlyDelinquentIndicatorURLA:
								borrowerPairData?.response[index]?.coborrower
									?.presentlyDelinquentIndicatorURLA,
							partyToLawsuitIndicatorURLA:
								borrowerPairData?.response[index]?.coborrower
									?.partyToLawsuitIndicatorURLA,
							priorPropertyDeedInLieuConveyedIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.priorPropertyDeedInLieuConveyedIndicator,
							priorPropertyShortSaleCompletedIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.priorPropertyShortSaleCompletedIndicator,
							priorPropertyForeclosureCompletedIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.priorPropertyForeclosureCompletedIndicator,
							bankruptcyIndicator:
								borrowerPairData?.response[index]?.coborrower
									?.bankruptcyIndicator,
							bankruptcyIndicatorChapterSeven:
								borrowerPairData?.response[index]?.coborrower
									?.bankruptcyIndicatorChapterSeven,
							bankruptcyIndicatorChapterEleven:
								borrowerPairData?.response[index]?.coborrower
									?.bankruptcyIndicatorChapterEleven,
							bankruptcyIndicatorChapterTwelve:
								borrowerPairData?.response[index]?.coborrower
									?.bankruptcyIndicatorChapterTwelve,
							bankruptcyIndicatorChapterThirteen:
								borrowerPairData?.response[index]?.coborrower
									?.bankruptcyIndicatorChapterThirteen,
						}
					);
				} else {
					// no borrower, no co-borrower
					militaryArr.push({}, {});
					demographicProvidedArr.push({}, {});
					demographicInfoArr.push({}, {});
					declarationArr.push({}, {});
				}

				if (borrowerPairData?.response[index]?.assets?.length > 0) {
					assetArr.push(borrowerPairData?.response[index]?.assets);
				} else {
					assetArr.push([]);
				}

				if (borrowerPairData?.response[index]?.liabilities?.length > 0) {
					liabilityArr.push(borrowerPairData?.response[index]?.liabilities);
				} else {
					liabilityArr.push([]);
				}

				if (borrowerPairData?.response[index]?.reoProperties?.length > 0) {
					realEstateArr.push(borrowerPairData?.response[index]?.reoProperties);
				} else {
					realEstateArr.push([]);
				}
			}

			setBorrowerResidences(residenceArr);
			setBorrowerFinances(financeArr);
			setBorrowerMilitary(militaryArr);
			setBorrowerDemographicProvided(demographicProvidedArr);
			setBorrowerDemographicInfo(demographicInfoArr);
			setBorrowerAsset(assetArr);
			setBorrowerLiability(liabilityArr);
			setBorrowerRealEstate(realEstateArr);
			setBorrowerDeclaration(declarationArr);

			setBorrowerResponseData(borrowerPairData?.response);

			let pairArr = [];

			borrowerPairData?.response?.map((item) =>
				pairArr.push(item.borrower, item.coborrower)
			);

			console.log("135 pair arr:", pairArr);

			setBorrowerPairData(pairArr);
		};

		if (loanGuid !== null && loanGuid !== undefined && loanGuid !== "") {
			getAllBorrowerPair();
		}
	}, [loanGuid]);

	console.log("200 residence arr:", borrowerResidences);
	console.log("200 finance arr:", borrowerFinances);
	console.log("200 military arr:", borrowerMilitary);
	console.log("200 demographicProvided arr:", borrowerDemographicProvided);
	console.log("200 demographicInfo arr:", borrowerDemographicInfo);
	console.log("200 asset arr:", borrowerAsset);
	console.log("200 liability arr:", borrowerLiability);
	console.log("200 real estate arr:", borrowerRealEstate);
	console.log("200 declaration arr:", borrowerDeclaration);

	const [error, setError] = useState("");

	const handleUpdateOnlyPair = async () => {
		console.log("285 running");

		let index = 0;

		console.log("345 index:", index);
		console.log("345 res data:", borrowerResponseData[index]);

		console.log("700 borrower Residences:", borrowerResidences);

		let borrowerData = {
			...borrowerPairData[index],
			...borrowerMilitary[index],
			...borrowerDemographicProvided[index],
			...borrowerDeclaration[index],
			...borrowerDemographicInfo[index],
			hmdaNoCoApplicantIndicator: false,
			mailingAddressSameAsPresentIndicator:
				index === 0
					? // borrower at first borrower pair
					  sameAddressValue?.includes(1)
					: index === 1
					? // borrower at second borrower pair
					  sameAddressValue?.includes(5)
					: index === 2
					? // borrower at third borrower pair
					  sameAddressValue?.includes(9)
					: false,
		};

		console.log("205 borrower data:", borrowerData);

		let coBorrowerData = {
			...borrowerPairData[index + 1],
			...borrowerMilitary[index + 1],
			...borrowerDemographicProvided[index + 1],
			...borrowerDeclaration[index + 1],
			...borrowerDemographicInfo[index + 1],
			hmdaNoCoApplicantIndicator: false,
			mailingAddressSameAsPresentIndicator:
				index === 0
					? // co-borrower at first borrower pair
					  sameAddressValue?.includes(3)
					: index === 1
					? // co-borrower at second borrower pair
					  sameAddressValue?.includes(7)
					: index === 2
					? // co-borrower at third borrower pair
					  sameAddressValue?.includes(11)
					: false,
		};

		// console.log("215 coBorrower data:", coBorrowerData);

		console.log("325 assets:", borrowerAsset[index]);
		console.log("325 borrower:", borrowerData);
		console.log("325 co-borrower:", coBorrowerData);
		console.log("325 income:", [
			borrowerFinances[index + 0],
			borrowerFinances[index + 1],
		]);
		console.log("325 liability:", borrowerLiability[index]);
		console.log("325 real estate:", borrowerRealEstate[index]);

		console.log("325 residences:", [
			borrowerResidences[index + 0],
			borrowerResidences[index + 1],
			borrowerResidences[index + 2],
			borrowerResidences[index + 3],
		]);

		console.log("333 borrowerResponseData:", borrowerResponseData[0]);

		console.log("1500 index:", index);
		console.log("1500 borrowerLiability:", borrowerLiability);

		let applications = [
			{
				id: borrowerResponseData[0]?.id,
				assets:
					borrowerAsset[index]?.length > 0
						? borrowerResponseData[0]?.assets?.length > 0
							? [borrowerResponseData[0]?.assets[0], ...borrowerAsset[index]]
							: [...borrowerAsset[index]]
						: borrowerResponseData[0]?.assets,

				borrower: borrowerData,
				coborrower: coBorrowerData,

				employment: [borrowerFinances[index + 0], borrowerFinances[index + 1]],

				liabilities:
					borrowerLiability[index]?.length > 0
						? borrowerResponseData[0]?.liabilities?.length > 0
							? [
									borrowerResponseData[0]?.liabilities[0],
									...borrowerLiability[index],
							  ]
							: [...borrowerLiability[index]]
						: borrowerResponseData[0]?.liabilities,

				reoProperties:
					borrowerRealEstate[index]?.length > 0
						? borrowerRealEstate[index]
						: [],

				residences: [
					borrowerResidences[index + 0],
					borrowerResidences[index + 1],
					borrowerResidences[index + 2],
					borrowerResidences[index + 3],
				],
			},
		];

		console.log("1500 update borrower pair data:", {
			id: borrowerResponseData[index].borrowerPairId,
			...applications[0],
		});

		const updateLoanBroker = await updateBorrowerPair({
			loanGuid,
			loanData: {
				id: borrowerResponseData[index].borrowerPairId,
				applicationId: borrowerResponseData[index].borrowerPairId,
				applicationIndex: index,
				...applications[0],
			},
			borrowerPairId: borrowerResponseData[index].borrowerPairId,
		}).unwrap();

		console.log("90 update broker loan:", updateLoanBroker);

		if (updateLoanBroker.message.includes("success")) {
			console.log("395 update borrower pair");
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: "smooth",
			});
			setLoanApplicationStage(3);
		}
	};

	const handleLoanUpdateAPI = async () => {
		try {
			console.log("700 loan id:", loanGuid);
			console.log("700 borrowerResponseData:", borrowerResponseData);
			console.log("700 borrowerPairData:", borrowerPairData);

			if (borrowerPairData?.length <= 2) {
				console.log("325 borrowerPairData:", borrowerPairData);

				handleUpdateOnlyPair();
			} else {
				for (let index = 0; index < borrowerResponseData.length; index++) {
					const element = borrowerResponseData[index];
					console.log("390 el:", element);

					let updateResponse = await updateBorrowerPairFunc(index);

					console.log("393 updateResponse:", updateResponse);

					if (
						index === borrowerResponseData.length - 1 &&
						updateResponse.message.includes("success")
					) {
						console.log("395 update borrower pair");
						window.scrollTo({
							top: 0,
							left: 0,
							behavior: "smooth",
						});
						setLoanApplicationStage(3);
					}
				}
			}
		} catch (err) {
			console.log("95 err:", err);
		}
	};

	const updateBorrowerPairFunc = async (index) => {
		console.log("345 index:", index);
		console.log("345 res data:", borrowerResponseData[index]);

		console.log("695 borrowerDeclaration:", borrowerDeclaration);

		let borrowerDataIndex =
			index === 0 ? 0 : index === 1 ? 2 : index === 2 && 4;

		let coBorrowerDataIndex =
			index === 0 ? 1 : index === 1 ? 3 : index === 2 && 5;

		let borrowerFinancesIndex =
			index === 0 ? 0 : index === 1 ? 2 : index === 2 && 4;

		let borrowerResidencesIndex =
			index === 0 ? 0 : index === 1 ? 4 : index === 2 && 8;

		console.log("700 borrower data:", borrowerPairData[coBorrowerDataIndex]);

		console.log(
			"700 borrower Military:",
			borrowerMilitary[coBorrowerDataIndex]
		);
		console.log(
			"700 borrower DemographicProvided:",
			borrowerDemographicProvided[coBorrowerDataIndex]
		);

		console.log(
			"700 borrowerDeclaration at index:",
			borrowerDeclaration[coBorrowerDataIndex]
		);

		console.log(
			"700 borrower DemographicInfo:",
			borrowerDemographicInfo[coBorrowerDataIndex]
		);

		console.log("700 borrower Residences:", borrowerResidences);

		let borrowerData = {
			...borrowerPairData[borrowerDataIndex],
			...borrowerMilitary[borrowerDataIndex],
			...borrowerDemographicProvided[borrowerDataIndex],
			...borrowerDeclaration[borrowerDataIndex],
			...borrowerDemographicInfo[borrowerDataIndex],
			hmdaNoCoApplicantIndicator: false,
			mailingAddressSameAsPresentIndicator:
				index === 0
					? // borrower at first borrower pair
					  sameAddressValue?.includes(1)
					: index === 1
					? // borrower at second borrower pair
					  sameAddressValue?.includes(5)
					: index === 2
					? // borrower at third borrower pair
					  sameAddressValue?.includes(9)
					: false,
		};

		console.log("205 borrower data:", borrowerData);

		let coBorrowerData = {
			...borrowerPairData[coBorrowerDataIndex],
			...borrowerMilitary[coBorrowerDataIndex],
			...borrowerDemographicProvided[coBorrowerDataIndex],
			...borrowerDeclaration[coBorrowerDataIndex],
			...borrowerDemographicInfo[coBorrowerDataIndex],
			hmdaNoCoApplicantIndicator: false,
			mailingAddressSameAsPresentIndicator:
				index === 0
					? // co-borrower at first borrower pair
					  sameAddressValue?.includes(3)
					: index === 1
					? // co-borrower at second borrower pair
					  sameAddressValue?.includes(7)
					: index === 2
					? // co-borrower at third borrower pair
					  sameAddressValue?.includes(11)
					: false,
		};

		console.log("215 coBorrower data:", coBorrowerData);

		let applications = [
			{
				assets: borrowerAsset[index]?.length > 0 ? borrowerAsset[index] : [],
				borrower: borrowerData,
				coborrower: coBorrowerData,

				employment: [
					borrowerFinances[borrowerFinancesIndex + 0],
					borrowerFinances[borrowerFinancesIndex + 1],
				],

				liabilities:
					borrowerLiability[index]?.length > 0 ? borrowerLiability[index] : [],
				reoProperties:
					borrowerRealEstate[index]?.length > 0
						? borrowerRealEstate[index]
						: [],

				residences: [
					borrowerResidences[borrowerResidencesIndex + 0],
					borrowerResidences[borrowerResidencesIndex + 1],
					borrowerResidences[borrowerResidencesIndex + 2],
					borrowerResidences[borrowerResidencesIndex + 3],
				],
			},
		];

		console.log("1700 update borrower pair data:", {
			id: borrowerResponseData[index].borrowerPairId,
			...applications[0],
		});

		const updateLoanBroker = await updateBorrowerPair({
			loanGuid,
			loanData: {
				id: borrowerResponseData[index].borrowerPairId,
				applicationId: borrowerResponseData[index].borrowerPairId,
				applicationIndex: index,
				...applications[0],
			},
			borrowerPairId: borrowerResponseData[index].borrowerPairId,
		}).unwrap();

		console.log("90 update broker loan:", updateLoanBroker);

		return updateLoanBroker;
	};

	const handlePrev = () => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});
		setLoanApplicationStage(1);
	};

	const handleNext = async () => {
		setError("");

		const valuesArr = [
			{
				id: "IRS4506.X4",
				value: borrowerVesting?.borrowerTaxIdentificationNumberIdentifier1,
			},
			{
				id: "Vesting.Trst2TaxID",
				value: borrowerVesting?.borrowerTaxIdentificationNumberIdentifier2,
			},
		];

		const updateBorrowerVesting = await updateFields({
			loan_guid: loanGuid,
			field_values: valuesArr,
		}).unwrap();

		console.log("1875 update borrower vesting:", updateBorrowerVesting);

		if (
			updateBorrowerVesting?.response !== undefined &&
			updateBorrowerVesting?.response?.includes("values updated success")
		) {
			console.log("450 pairData:", borrowerPairData);
			console.log("450 residencesData:", borrowerResidences);
			console.log("450 financesData:", borrowerFinances);
			console.log("450 militaryData:", borrowerMilitary);
			console.log("450 demographicProvidedData:", borrowerDemographicProvided);
			console.log("450 demographicInfoData:", borrowerDemographicInfo);
			console.log("450 assetData:", borrowerAsset);
			console.log("450 liabilityData:", borrowerLiability);
			console.log("450 realEstateData:", borrowerRealEstate);
			console.log("450 declarationData:", borrowerDeclaration);

			handleLoanUpdateAPI();
		} else {
			setError("Error updating EIN values.");
		}
	};

	function a11yProps(index) {
		return {
			id: `simple-tab-${index}`,
			"aria-controls": `simple-tabpanel-${index}`,
		};
	}

	const handleChangeTab = (event, newValue) => {
		setTabValue(newValue);
	};

	console.log("525 borrower pair:", borrowerPairData);

	const [processingText, setProcessingText] = useState("");
	const [processingModalVisible, setProcessingModalVisible] = useState(false);

	const handleOpenProcessingModal = () => {
		setProcessingModalVisible(true);
	};

	const handleCloseProcessingModal = () => {
		setProcessingModalVisible(false);
	};

	const [successText, setSuccessText] = useState("");
	const [successModalVisible, setSuccessModalVisible] = useState(false);

	const handleOpenSuccessModal = () => {
		setSuccessModalVisible(true);
	};

	const handleCloseSuccessModal = () => {
		setSuccessModalVisible(false);
	};

	const [errorText, setErrorText] = useState("");
	const [errorModalVisible, setErrorModalVisible] = useState(false);

	const handleOpenErrorModal = () => {
		setErrorModalVisible(true);
	};

	const handleCloseErrorModal = () => {
		setErrorModalVisible(false);
	};

	return (
		<Stack direction="column" className={styles.stackContainer}>
			<Stack className={styles.bodyContainer}>
				{/* tabs section */}
				{borrowerPairData?.length > 2 && (
					<Tabs
						value={tabValue}
						onChange={handleChangeTab}
						aria-label="basic tabs example"
						TabIndicatorProps={{
							style: {
								backgroundColor: "#76D99E",
							},
						}}
					>
						{borrowerPairData?.length >= 3 && (
							<Tab
								className={styles.tabBtnContainer}
								label={
									<Stack direction="row" className={styles.tabLabelContainer}>
										<Heading6
											text="Borrower Pair 1"
											fontType="semibold"
											extraClass={
												tabValue === 0 ? styles.activeTabText : styles.tabText
											}
										/>
									</Stack>
								}
								{...a11yProps(0)}
							/>
						)}
						{borrowerPairData?.length >= 4 && (
							<Tab
								className={styles.tabBtnContainer}
								label={
									<Stack direction="row" className={styles.tabLabelContainer}>
										<Heading6
											text="Borrower Pair 2"
											fontType="semibold"
											extraClass={
												tabValue === 1 ? styles.activeTabText : styles.tabText
											}
										/>
									</Stack>
								}
								{...a11yProps(1)}
							/>
						)}
						{borrowerPairData?.length >= 6 && (
							<Tab
								className={styles.tabBtnContainer}
								label={
									<Stack direction="row" className={styles.tabLabelContainer}>
										<Heading6
											text="Borrower Pair 3"
											fontType="semibold"
											extraClass={
												tabValue === 2 ? styles.activeTabText : styles.tabText
											}
										/>
									</Stack>
								}
								{...a11yProps(2)}
							/>
						)}
					</Tabs>
				)}

				{/* default view no tabs */}
				{borrowerPairData?.length <= 2 && (
					<Box className={styles.tabPanelContainer}>
						{error !== "" && (
							<RequiredError
								text={
									error.includes("Something went wrong")
										? "Updates Not Received by LOS. Please Try Again."
										: error.includes("provide a value") &&
										  "Please provide a value for all fields highlighted above."
								}
							/>
						)}
						{borrowerResidences?.slice(0, 4)?.map((residence, index) => (
							<ContactInformation
								key={index}
								residenceIndex={index}
								coBorrower={index >= 2}
								residenceData={residence}
								data={borrowerResidences}
								setData={setBorrowerResidences}
								addressType={index % 2 !== 0 ? "mail" : "current"}
								sameAddress={sameAddress}
								setSameAddress={setSameAddress}
							/>
						))}

						{borrowerFinances?.slice(0, 2)?.map((finance, index) => (
							<EmploymentInfo
								key={index}
								financeIndex={index}
								coBorrower={index % 2 !== 0}
								financeData={finance}
								data={borrowerFinances}
								setData={setBorrowerFinances}
								vestingData={borrowerVesting}
								setVestingData={setBorrowerVesting}
								error={error.includes("provide a value")}
							/>
						))}

						{/* assets */}
						{borrowerAsset?.slice(0, 1)?.map((asset, index) => (
							<AssetsInformation
								key={index}
								assetIndex={index}
								assetData={asset}
								data={borrowerAsset}
								setData={setBorrowerAsset}
							/>
						))}

						{/* liabilities */}
						{borrowerLiability?.slice(0, 1)?.map((liability, index) => (
							<LiabilitesInformation
								key={index}
								liabilityIndex={index}
								liabilityData={liability}
								data={borrowerLiability}
								setData={setBorrowerLiability}
							/>
						))}

						{/* real estate */}
						{borrowerRealEstate?.slice(0, 1)?.map((estate, index) => (
							<RealEstate
								key={index}
								estateIndex={index}
								estateData={estate}
								data={borrowerRealEstate}
								setData={setBorrowerRealEstate}
								liabilityCount={borrowerLiability?.length}
								handleOpenLiabilityModal={handleOpenLiabilityModal}
								setSelectedRealEstate={setSelectedRealEstate}
							/>
						))}

						{/* declaration info */}
						{borrowerDeclaration
							?.slice(0, 2)
							?.map(
								(declaration, index) =>
									index === borrowerDeclaration?.slice(0, 2)?.length - 1 && (
										<DeclarationInfo
											key={index}
											declarationIndex={index}
											coBorrower={index % 2 !== 0}
											declarationData={declaration}
											data={borrowerDeclaration}
											setData={setBorrowerDeclaration}
										/>
									)
							)}

						{/* military */}
						{borrowerMilitary?.slice(0, 2)?.map((military, index) => (
							<MilitaryInfo
								key={index}
								militaryIndex={index}
								coBorrower={index % 2 !== 0}
								militaryData={military}
								data={borrowerMilitary}
								setData={setBorrowerMilitary}
								error={error.includes("provide a value")}
							/>
						))}

						{/* demographic provided info */}
						{borrowerDemographicProvided
							?.slice(0, 2)
							?.map((demographic, index) => (
								<DemographicProvidedInfo
									key={index}
									demographicIndex={index}
									coBorrower={index % 2 !== 0}
									demographicData={demographic}
									data={borrowerDemographicProvided}
									setData={setBorrowerDemographicProvided}
									error={error.includes("provide a value")}
								/>
							))}

						{/* demographic info */}
						{borrowerDemographicInfo?.slice(0, 2)?.map((demographic, index) => (
							<DemographicInfo
								key={index}
								demographicIndex={index}
								coBorrower={index % 2 !== 0}
								demographicData={demographic}
								data={borrowerDemographicInfo}
								setData={setBorrowerDemographicInfo}
								error={error.includes("provide a value")}
							/>
						))}
					</Box>
				)}

				{/* borrower pair 1 */}
				{borrowerPairData?.length >= 3 && (
					<TabPanel
						value={tabValue}
						index={0}
						className={tabValue === 0 ? styles.tabPanelContainer : ""}
					>
						{error !== "" && (
							<RequiredError
								text={
									error.includes("Something went wrong")
										? "Updates Not Received by LOS. Please Try Again."
										: error.includes("provide a value") &&
										  "Please provide a value for all fields highlighted above."
								}
							/>
						)}
						{borrowerResidences?.slice(0, 4)?.map((residence, index) => (
							<ContactInformation
								key={index}
								residenceIndex={index}
								coBorrower={index >= 2}
								residenceData={residence}
								data={borrowerResidences}
								setData={setBorrowerResidences}
								addressType={index % 2 !== 0 ? "mail" : "current"}
								sameAddress={sameAddress}
								setSameAddress={setSameAddress}
							/>
						))}

						{borrowerFinances?.slice(0, 2)?.map((finance, index) => (
							<EmploymentInfo
								key={index}
								financeIndex={index}
								coBorrower={index % 2 !== 0}
								financeData={finance}
								data={borrowerFinances}
								setData={setBorrowerFinances}
								vestingData={borrowerVesting}
								setVestingData={setBorrowerVesting}
								error={error.includes("provide a value")}
							/>
						))}

						{/* assets */}
						{borrowerAsset?.slice(0, 1)?.map((asset, index) => (
							<AssetsInformation
								key={index}
								assetIndex={index}
								assetData={asset}
								data={borrowerAsset}
								setData={setBorrowerAsset}
							/>
						))}

						{/* liabilities */}
						{borrowerLiability?.slice(0, 1)?.map((liability, index) => (
							<LiabilitesInformation
								key={index}
								liabilityIndex={index}
								liabilityData={liability}
								data={borrowerLiability}
								setData={setBorrowerLiability}
							/>
						))}

						{/* real estate */}
						{borrowerRealEstate?.slice(0, 1)?.map((estate, index) => (
							<RealEstate
								key={index}
								estateIndex={index}
								estateData={estate}
								data={borrowerRealEstate}
								setData={setBorrowerRealEstate}
								liabilityCount={borrowerLiability?.length}
								handleOpenLiabilityModal={handleOpenLiabilityModal}
								setSelectedRealEstate={setSelectedRealEstate}
							/>
						))}

						{/* declaration info */}
						{borrowerDeclaration
							?.slice(0, 2)
							?.map(
								(declaration, index) =>
									index === borrowerDeclaration?.slice(0, 2)?.length - 1 && (
										<DeclarationInfo
											key={index}
											declarationIndex={index}
											coBorrower={index % 2 !== 0}
											declarationData={declaration}
											data={borrowerDeclaration}
											setData={setBorrowerDeclaration}
										/>
									)
							)}

						{/* military */}
						{borrowerMilitary?.slice(0, 2)?.map((military, index) => (
							<MilitaryInfo
								key={index}
								militaryIndex={index}
								coBorrower={index % 2 !== 0}
								militaryData={military}
								data={borrowerMilitary}
								setData={setBorrowerMilitary}
								error={error.includes("provide a value")}
							/>
						))}

						{/* demographic provided info */}
						{borrowerDemographicProvided
							?.slice(0, 2)
							?.map((demographic, index) => (
								<DemographicProvidedInfo
									key={index}
									demographicIndex={index}
									coBorrower={index % 2 !== 0}
									demographicData={demographic}
									data={borrowerDemographicProvided}
									setData={setBorrowerDemographicProvided}
									error={error.includes("provide a value")}
								/>
							))}

						{/* demographic info */}
						{borrowerDemographicInfo?.slice(0, 2)?.map((demographic, index) => (
							<DemographicInfo
								key={index}
								demographicIndex={index}
								coBorrower={index % 2 !== 0}
								demographicData={demographic}
								data={borrowerDemographicInfo}
								setData={setBorrowerDemographicInfo}
								error={error.includes("provide a value")}
							/>
						))}
					</TabPanel>
				)}

				{/* borrower pair 2 */}
				{borrowerPairData?.length >= 4 && (
					<TabPanel
						value={tabValue}
						index={1}
						className={tabValue === 1 ? styles.tabPanelContainer : ""}
					>
						{error !== "" && (
							<RequiredError
								text={
									error.includes("Something went wrong")
										? "Updates Not Received by LOS. Please Try Again."
										: error.includes("provide a value") &&
										  "Please provide a value for all fields highlighted above."
								}
							/>
						)}
						{borrowerResidences?.slice(4, 8)?.map((residence, index) => (
							<ContactInformation
								key={index}
								residenceIndex={index + 4}
								coBorrower={index >= 2}
								residenceData={residence}
								data={borrowerResidences}
								setData={setBorrowerResidences}
								addressType={index % 2 !== 0 ? "mail" : "current"}
								sameAddress={sameAddress}
								setSameAddress={setSameAddress}
							/>
						))}

						{borrowerFinances?.slice(2, 4)?.map((finance, index) => (
							<EmploymentInfo
								key={index}
								financeIndex={index + 2}
								coBorrower={index % 2 !== 0}
								financeData={finance}
								data={borrowerFinances}
								setData={setBorrowerFinances}
								vestingData={borrowerVesting}
								setVestingData={setBorrowerVesting}
								error={error.includes("provide a value")}
							/>
						))}

						{/* assets */}
						{borrowerAsset?.slice(1, 2)?.map((asset, index) => (
							<AssetsInformation
								key={index}
								assetIndex={index + 1}
								assetData={asset}
								data={borrowerAsset}
								setData={setBorrowerAsset}
							/>
						))}

						{/* liabilities */}
						{borrowerLiability?.slice(1, 2)?.map((liability, index) => (
							<LiabilitesInformation
								key={index}
								liabilityIndex={index + 1}
								liabilityData={liability}
								data={borrowerLiability}
								setData={setBorrowerLiability}
							/>
						))}

						{/* real estate */}
						{borrowerRealEstate?.slice(1, 2)?.map((estate, index) => (
							<RealEstate
								key={index}
								estateIndex={index + 1}
								estateData={estate}
								data={borrowerRealEstate}
								setData={setBorrowerRealEstate}
								liabilityCount={borrowerLiability?.length}
								handleOpenLiabilityModal={handleOpenLiabilityModal}
								setSelectedRealEstate={setSelectedRealEstate}
							/>
						))}

						{/* declaration info */}
						{borrowerDeclaration
							?.slice(2, 4)
							?.map(
								(declaration, index) =>
									index === borrowerDeclaration?.slice(2, 4)?.length - 1 && (
										<DeclarationInfo
											key={index}
											declarationIndex={index + 2}
											coBorrower={index % 2 !== 0}
											declarationData={declaration}
											data={borrowerDeclaration}
											setData={setBorrowerDeclaration}
										/>
									)
							)}

						{/* military */}
						{borrowerMilitary?.slice(2, 4)?.map((military, index) => (
							<MilitaryInfo
								key={index}
								militaryIndex={index + 2}
								coBorrower={index % 2 !== 0}
								militaryData={military}
								data={borrowerMilitary}
								setData={setBorrowerMilitary}
								error={error.includes("provide a value")}
							/>
						))}

						{/* demographic provided info */}
						{borrowerDemographicProvided
							?.slice(2, 4)
							?.map((demographic, index) => (
								<DemographicProvidedInfo
									key={index}
									demographicIndex={index + 2}
									coBorrower={index % 2 !== 0}
									demographicData={demographic}
									data={borrowerDemographicProvided}
									setData={setBorrowerDemographicProvided}
									error={error.includes("provide a value")}
								/>
							))}

						{/* demographic info */}
						{borrowerDemographicInfo?.slice(2, 4)?.map((demographic, index) => (
							<DemographicInfo
								key={index}
								demographicIndex={index + 2}
								coBorrower={index % 2 !== 0}
								demographicData={demographic}
								data={borrowerDemographicInfo}
								setData={setBorrowerDemographicInfo}
								error={error.includes("provide a value")}
							/>
						))}
					</TabPanel>
				)}

				{/* borrower pair 3 */}
				{borrowerPairData?.length >= 6 && (
					<TabPanel
						value={tabValue}
						index={2}
						className={tabValue === 2 ? styles.tabPanelContainer : ""}
					>
						{error !== "" && (
							<RequiredError
								text={
									error.includes("Something went wrong")
										? "Updates Not Received by LOS. Please Try Again."
										: error.includes("provide a value") &&
										  "Please provide a value for all fields highlighted above."
								}
							/>
						)}
						{borrowerResidences?.slice(8, 12)?.map((residence, index) => (
							<ContactInformation
								key={index}
								residenceIndex={index + 8}
								coBorrower={index >= 2}
								residenceData={residence}
								data={borrowerResidences}
								setData={setBorrowerResidences}
								addressType={index % 2 !== 0 ? "mail" : "current"}
								sameAddress={sameAddress}
								setSameAddress={setSameAddress}
							/>
						))}

						{borrowerFinances?.slice(4, 6)?.map((finance, index) => (
							<EmploymentInfo
								key={index}
								financeIndex={index + 4}
								coBorrower={index % 2 !== 0}
								financeData={finance}
								data={borrowerFinances}
								setData={setBorrowerFinances}
								vestingData={borrowerVesting}
								setVestingData={setBorrowerVesting}
								error={error.includes("provide a value")}
							/>
						))}

						{/* assets */}
						{borrowerAsset?.slice(2, 3)?.map((asset, index) => (
							<AssetsInformation
								key={index}
								assetIndex={index + 2}
								assetData={asset}
								data={borrowerAsset}
								setData={setBorrowerAsset}
							/>
						))}

						{/* liabilities */}
						{borrowerLiability?.slice(2, 3)?.map((liability, index) => (
							<LiabilitesInformation
								key={index}
								liabilityIndex={index + 2}
								liabilityData={liability}
								data={borrowerLiability}
								setData={setBorrowerLiability}
							/>
						))}

						{/* real estate */}
						{borrowerRealEstate?.slice(2, 3)?.map((estate, index) => (
							<RealEstate
								key={index}
								estateIndex={index + 2}
								estateData={estate}
								data={borrowerRealEstate}
								setData={setBorrowerRealEstate}
								liabilityCount={borrowerLiability?.length}
								handleOpenLiabilityModal={handleOpenLiabilityModal}
								setSelectedRealEstate={setSelectedRealEstate}
							/>
						))}

						{/* declaration info */}
						{borrowerDeclaration
							?.slice(4, 6)
							?.map(
								(declaration, index) =>
									index === borrowerDeclaration?.slice(4, 6)?.length - 1 && (
										<DeclarationInfo
											key={index}
											declarationIndex={index + 4}
											coBorrower={index % 2 !== 0}
											declarationData={declaration}
											data={borrowerDeclaration}
											setData={setBorrowerDeclaration}
										/>
									)
							)}

						{/* military */}
						{borrowerMilitary?.slice(4, 6)?.map((military, index) => (
							<MilitaryInfo
								key={index}
								militaryIndex={index + 4}
								coBorrower={index % 2 !== 0}
								militaryData={military}
								data={borrowerMilitary}
								setData={setBorrowerMilitary}
								error={error.includes("provide a value")}
							/>
						))}

						{/* demographic provided info */}
						{borrowerDemographicProvided
							?.slice(4, 6)
							?.map((demographic, index) => (
								<DemographicProvidedInfo
									key={index}
									demographicIndex={index + 4}
									coBorrower={index % 2 !== 0}
									demographicData={demographic}
									data={borrowerDemographicProvided}
									setData={setBorrowerDemographicProvided}
									error={error.includes("provide a value")}
								/>
							))}

						{/* demographic info */}
						{borrowerDemographicInfo?.slice(4, 6)?.map((demographic, index) => (
							<DemographicInfo
								key={index}
								demographicIndex={index + 4}
								coBorrower={index % 2 !== 0}
								demographicData={demographic}
								data={borrowerDemographicInfo}
								setData={setBorrowerDemographicInfo}
								error={error.includes("provide a value")}
							/>
						))}
					</TabPanel>
				)}
			</Stack>

			<Stack direction="row" className={styles.footerContainer}>
				<SecondaryButton
					text="Previous"
					startIcon={<ArrowBackOutlined className={styles.prevIcon} />}
					onClick={handlePrev}
				/>
				<PrimaryButton
					text="Save and Continue"
					endIcon={<ArrowForwardOutlined className={styles.nextIcon} />}
					onClick={handleNext}
				/>
			</Stack>
			{/* liability modal */}
			<LoaderModal
				open={
					loadingBorrowerPairData ||
					loadingValues ||
					updatingBorrowerPair ||
					isUpdatingFields
				}
			/>
			<LiabilityLinkModal
				open={liabilityModalVisible}
				handleClose={handleCloseLiabilityModal}
				realEstate={borrowerRealEstate[tabValue]}
				selectedRealEstate={selectedRealEstate}
				setSelectedRealEstate={setSelectedRealEstate}
				selectedLiability={selectedLiability}
				setSelectedLiability={setSelectedLiability}
				liabilitiesData={borrowerLiability}
				setLiabilitiesData={setBorrowerLiability}
				selectedTab={tabValue}
				confirmText="Link"
				closeText="Cancel"
			/>
			<ProcessingModal open={processingModalVisible} text={processingText} />
			<SuccessModal
				open={successModalVisible}
				handleClose={handleCloseSuccessModal}
				text={successText}
			/>
			<ErrorModal
				open={errorModalVisible}
				text={errorText}
				handleClose={handleCloseErrorModal}
			/>
		</Stack>
	);
};

export default UpdateLoanApplication;
