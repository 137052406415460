import React, { useEffect, useState } from "react";

// mui icons
import CloseIcon from "@mui/icons-material/Close";
import UploadFileIcon from "@mui/icons-material/UploadFile";

// mui components
import { Box, InputLabel, Stack } from "@mui/material";

// api slice
import { useResourceLibraryFolderMutation } from "../../../../features/resource-library-folder/resourceLibraryFolderApiSlice";
import { useDocumentRepositoryMutation } from "../../../../features/admin/upload-document-repository/uploadDocumentRepositoryApiSlice";

// custom components
import OutlinedTextInput from "../../../custom-input/outlined-text-input";
import SecondarySelect from "../../../custom-select/secondary-select";
import SupportingDocs from "../../../supporting-docs";
import SecondaryButton from "../../../buttons/secondary-button";
import PrimaryButton from "../../../buttons/primary-button";
import Heading6 from "../../../typography/heading-06";
import Heading3 from "../../../typography/heading-03";
import Body3 from "../../../typography/body-03";
import Body2 from "../../../typography/body-02";

// helper utils
import { formatBytes } from "../../../../utils/format-bytes";

// custom styles
import styles from "./index.module.scss";

const AddResourceDrawer = ({
	handleCloseDrawer,
	handleOpenProcessingModal,
	handleCloseProcessingModal,
	setProcessingText,
	handleOpenSuccessModal,
	setSuccessText,
	handleOpenErrorModal,
	setErrorText,
}) => {
	const fileTypes = ["PDF", "DOC", "DOCX", "XLSX", "PPT", "PPTX"];

	const [resourceLibraryFolder, { isLoading: requestingFolder }] =
		useResourceLibraryFolderMutation();
	const [documentRepository, { isLoading: requestingUploadDocument }] =
		useDocumentRepositoryMutation();

	const [existingFolder, setExistingFolder] = useState(true);
	const [folderName, setFolderName] = useState("");
	const [folderList, setFolderList] = useState([]);

	const getResourceFolders = async () => {
		const resourcesList = await resourceLibraryFolder().unwrap();
		console.log("50 folder list:", resourcesList);

		let valueArr = [];

		if (resourcesList !== undefined && resourcesList?.length > 0) {
			valueArr = resourcesList?.map((item) => ({
				...item,
				label: item?.folder_name,
				value: item?.folder_name,
			}));
		}

		console.log("50 value arr:", valueArr);
		setFolderList(valueArr);
	};

	useEffect(() => {
		getResourceFolders();
	}, []);

	const [attachedFiles, setAttachedFiles] = useState([]);

	const handleFileAttach = async (file) => {
		let newArr = [];
		Array.from(file).forEach((file) => newArr.push(file));
		setAttachedFiles(newArr);
	};

	const deleteFile = (event, index) => {
		console.log("85 files:", attachedFiles, index);

		let newArr = attachedFiles;
		newArr.splice(index, 1);

		setAttachedFiles([...newArr]);
	};

	const handleCancel = () => {
		console.log("50 cancel clicked.");
		setFolderName("");
		setAttachedFiles([]);
	};

	const handleSubmitDocuments = async () => {
		console.log("50 submit click!!!", attachedFiles);

		if (attachedFiles?.length === 0) return;

		console.log("315 running ");

		handleCloseDrawer();
		handleOpenProcessingModal();
		setProcessingText("Uploading new documents");

		for (let index = 0; index < attachedFiles.length; index++) {
			const element = attachedFiles[index];

			var formData = new FormData();
			formData.append("file", element);
			formData.append("folder_name", folderName);

			// dispatch API and open modal if successful
			try {
				const documentRepositoryData = await documentRepository({
					formData,
				}).unwrap();
				console.log("310 file upload res:", documentRepositoryData);

				// end of the loop
				if (index === attachedFiles.length - 1) {
					// call api to fetch updated list
					getResourceFolders();

					handleCloseProcessingModal();
					handleOpenSuccessModal();
					setSuccessText(
						`Resources have been added to the folder ${folderName}`
					);

					console.log("70 all files attached");
				}
			} catch (err) {
				console.log("95 err:", err);

				handleCloseProcessingModal();
				setErrorText(err?.data?.message);
				handleOpenErrorModal();
			}
		}
	};

	return (
		<Stack direction="column" className={styles.stackContainer}>
			<Stack
				direction="row"
				alignItems="center"
				justifyContent="space-between"
				className={styles.drawerHeaderContainer}
			>
				<Heading3
					text="Add Resources"
					extraClass={styles.headerText}
					fontType="semibold"
				/>
				<SecondaryButton
					startIcon={<CloseIcon />}
					text="Close"
					onClick={handleCloseDrawer}
				/>
			</Stack>
			<Stack direction="column" className={styles.drawerBodyContainer}>
				{/* state */}
				<Stack direction="column" className={styles.inputContainer}>
					<Stack direction="row" className={styles.folderContainer}>
						<InputLabel htmlFor="input-folder-name">
							<Heading6
								text={
									existingFolder
										? "Select Existing Folder"
										: "Enter New Folder Name"
								}
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>
						<Body2
							text={
								existingFolder
									? "Add resource to new folder"
									: "Add resource to existing folder"
							}
							isLink={true}
							handleClick={() => setExistingFolder(!existingFolder)}
						/>
					</Stack>

					{existingFolder ? (
						<SecondarySelect
							displayEmpty={true}
							value={folderName || ""}
							handleChange={(event) => setFolderName(event.target.value)}
							options={folderList}
						/>
					) : (
						<OutlinedTextInput
							id="input-folder-name"
							type="text"
							placeholder="Folder Name"
							value={folderName}
							handleChange={(event) => setFolderName(event.target.value)}
						/>
					)}
				</Stack>

				<SupportingDocs
					fileCount={attachedFiles?.length}
					fileTypes={fileTypes}
					text="or drag to upload files"
					handleFileAttach={handleFileAttach}
					multiple={true}
				/>

				{attachedFiles !== undefined && attachedFiles?.length > 0 && (
					<Stack direction="column" className={styles.uploadedFileContainer}>
						{attachedFiles?.map((file, index) => (
							<Stack
								direction="row"
								key={index}
								className={styles.fileListContainer}
							>
								<Box className={styles.fileIconContainer}>
									<UploadFileIcon className={styles.fileIcon} />
								</Box>
								<Stack direction="column" className={styles.fileNameContainer}>
									<Body2 text={file?.name} />
									<Body3 text={formatBytes(file?.size)} />
								</Stack>

								<Box
									sx={{ cursor: "pointer" }}
									onClick={(event) => deleteFile(event, index)}
									className={styles.deleteIconContainer}
								>
									<CloseIcon className={styles.deleteIcon} />
								</Box>
							</Stack>
						))}
					</Stack>
				)}
			</Stack>
			<Stack
				direction="row"
				alignItems="center"
				justifyContent="flex-end"
				className={styles.drawerFooterContainer}
			>
				<SecondaryButton
					text="Reset"
					extraClass={styles.cancelBtn}
					extraTextClass={styles.cancelText}
					onClick={handleCancel}
					disabled={attachedFiles?.length === 0 || folderName === ""}
				/>
				<PrimaryButton
					text="Add Resource"
					onClick={handleSubmitDocuments}
					disabled={attachedFiles?.length === 0 || folderName === ""}
				/>
			</Stack>
		</Stack>
	);
};

export default AddResourceDrawer;
