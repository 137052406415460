import React from "react";
import { IconButton, InputAdornment, OutlinedInput } from "@mui/material";

import styles from "./index.module.scss";
import "./mui-styles.scss";

const OutlinedTextInput = ({
	extraClass,
	type,
	id,
	placeholder,
	name,
	value,
	handleChange,
	disabled,
	error,
	showEndIcon,
	endIcon,
	fullWidth,
	props,
}) => {
	const customClass = `${styles.inputField} ${extraClass} ${
		fullWidth ? styles.fullWidth : ""
	} ${error ? styles.error : ""}`;

	return (
		<OutlinedInput
			className={customClass}
			type={type}
			name={name}
			id={id}
			placeholder={placeholder}
			value={value}
			onChange={handleChange}
			disabled={disabled}
			endAdornment={
				showEndIcon && (
					<InputAdornment position="end">
						<IconButton edge="end">{endIcon}</IconButton>
					</InputAdornment>
				)
			}
			{...props}
		/>
	);
};

export default OutlinedTextInput;
