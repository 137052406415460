import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// mui components
import { Box, IconButton, Stack, Tab, Tabs } from "@mui/material";

// custom components
import { TabPanel } from "../../tab-panel";
import RequiredError from "../../required-error";
import BorrowerInformation from "../../loan-form-card/borrower-information";
import PropertyDetails from "../../loan-form-card/property-details";
import LoanOriginator from "../../loan-form-card/loan-originator";
import LoanProcessor from "../../loan-form-card/loan-processor";
import PrimaryButton from "../../buttons/primary-button";
import Heading6 from "../../typography/heading-06";

// mui icons
import {
	AddOutlined,
	ArrowForwardOutlined,
	CloseOutlined,
} from "@mui/icons-material";

// api slice
import { useUpdateBorrowerPairMutation } from "../../../features/update-borrower-pair/updateBorrowerPairApiSlice";
import { useGetBorrowerPairMutation } from "../../../features/get-borrower-pair/getBorrowerPairApiSlice";
import { useGetLoanMutation } from "../../../features/get-loan-data/getLoanApiSlice";
import { useDeleteBorrowerPairMutation } from "../../../features/delete-borrower-pair/deleteBorrowerPairApiSlice";
import { useCreateBorrowerPairMutation } from "../../../features/create-borrower-pair/createBorrowerPairApiSlice";
import { useUpdateFieldsMutation } from "../../../features/update-fields/updateFieldsApiSlice";
import { useGetBrokerListByTitleMutation } from "../../../features/broker-search-title/brokerSearchTitleApiSlice";
import { useGetOrganizationDetailsMutation } from "../../../features/organization-detail/organizationDetailApiSlice";
import { useUpdateLoanMutation } from "../../../features/update-loan/updateLoanApiSlice";

// reducer slice
import { selectOrganizationName } from "../../../features/organization-detail/organizationDetailsUserSlice";
import { selectImpersonatedOrgName } from "../../../features/admin/impersonated-organization-name/impersonatedOrganizationNameSlice";
import { selectImpersonatedUser } from "../../../features/admin/impersonated-user/impersonatedUserSlice";
import { selectUserDetails } from "../../../features/auth/authSlice";
import { setBrokerListReducer } from "../../../features/broker-list/brokerListSlice";

// custom modal
import LoaderModal from "../../modal/loader-modal";
import ErrorModal from "../../modal/error-modal";
import ConfirmationModal from "../../modal/confirmation-modal";

// helper utils
import { requiredFields } from "../../../utils/requiredFields";

// custom styles
import styles from "./index.module.scss";

export const EditLoanApplication = ({
	setLoanApplicationStage,
	applicationData,
	setApplicationData,
	loanGuid,
}) => {
	console.log("24 applicationData:", applicationData);
	console.log("45 loan id:", loanGuid);

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [getBorrowerPair, { isLoading: loadingBorrowerPairData }] =
		useGetBorrowerPairMutation();

	const [getLoan, { isLoading: loadingloanData }] = useGetLoanMutation();

	const [updateFields, { isLoading: isUpdatingFields }] =
		useUpdateFieldsMutation();

	const [updateLoan, { isLoading: updatingLoan }] = useUpdateLoanMutation();

	const [updateBorrowerPair, { isLoading: updatingBorrowerPair }] =
		useUpdateBorrowerPairMutation();

	const [createBorrowerPair, { isLoading: creatingBorrowerPair }] =
		useCreateBorrowerPairMutation();

	const [deleteBorrowerPair, { isLoading: deletingBorrowerPair }] =
		useDeleteBorrowerPairMutation();

	const [loanApplicationData, setLoanApplicationData] = useState([{}, {}]);
	const [borrowerResponseData, setBorrowerResponseData] = useState([]);

	const [borrowerDataExist, setBorrowerDataExist] = useState(false);

	const [tabValue, setTabValue] = useState(0);

	const [propertyInfo, setPropertyInfo] = useState({});

	// loan originator
	const userDetails = useSelector(selectUserDetails);
	const userOrganizationName = useSelector(selectOrganizationName);
	const impersonatedOrganizationName = useSelector(selectImpersonatedOrgName);

	const [organizationName, setOrganizationName] = useState("");

	const [getBrokerListByTitle, { isLoading: brokerListLoading }] =
		useGetBrokerListByTitleMutation();

	const [getOrganizationDetails, { isLoading: organizationDetailsLoading }] =
		useGetOrganizationDetailsMutation();
	const [organizationDetails, setOrganizationDetails] = useState({});

	const [loanOriginatorEmail, setLoanOriginatorEmail] = useState("");
	const [organizationUsers, setOrganizationUsers] = useState([]);
	const [loanOriginator, setLoanOriginator] = useState({});

	const [organizationLicense, setOrganizationLicense] = useState("");

	const [confirmModalText, setConfirmModalText] = useState("");
	const [confirmModalVisible, setConfirmModalVisible] = useState(false);
	const handleOpenConfirmModal = () => {
		setConfirmModalVisible(true);
	};
	const handleCloseConfirmModal = () => {
		setConfirmModalVisible(false);
	};

	const handleSubmitConfirmModal = () => {
		navigate("/admin");
	};

	const [errorModalVisible, setErrorModalVisible] = useState(false);

	const handleOpenErrorModal = () => {
		setErrorModalVisible(true);
	};

	const handleCloseErrorModal = () => {
		setErrorModalVisible(false);
	};

	useEffect(() => {
		console.log("130 userOrganizationName:", userOrganizationName);
		console.log(
			"130 impersonatedOrganizationName:",
			impersonatedOrganizationName
		);

		if (userDetails?.user_roles?.includes("admin")) {
			setOrganizationName(impersonatedOrganizationName);
		} else {
			setOrganizationName(userOrganizationName);
		}
	}, [userOrganizationName, impersonatedOrganizationName]);

	useEffect(() => {
		// check if admin signed in as another user has an organization
		console.log("150 user roles:", userDetails?.user_roles);
		console.log("150 organization name:", organizationName);

		if (
			userDetails?.user_roles?.includes("admin") &&
			(impersonatedOrganizationName === undefined ||
				impersonatedOrganizationName === null ||
				impersonatedOrganizationName === "")
		) {
			console.log("140 No organization");
			setConfirmModalText("Sign in as a broker to gain access.");
			handleOpenConfirmModal();
		}
	}, [impersonatedOrganizationName, userDetails]);

	const [loanProcessorEmail, setLoanProcessorEmail] = useState("");
	const [organizationProcessor, setOrganizationProcessor] = useState([]);
	const [loanProcessor, setLoanProcessor] = useState({});

	useEffect(() => {
		const getBrokerListFunc = async () => {
			const searchResults = await getBrokerListByTitle({
				orgName: organizationName,
				title: "Loan Officer",
			}).unwrap();
			dispatch(setBrokerListReducer(searchResults));

			let updatedArr = searchResults.map((item) => ({
				...item,
				label: item?.user?.email || "",
				value: item?.user?.email || "",
			}));

			console.log("150 loan origin updated:", updatedArr);
			setOrganizationUsers(updatedArr);
		};

		const getProcessorListFunc = async () => {
			const searchResults = await getBrokerListByTitle({
				orgName: organizationName,
				title: "Loan Processor",
			}).unwrap();

			let updatedArr = searchResults.map((item) => ({
				...item,
				label: item?.user?.email || "",
				value: item?.user?.email || "",
			}));

			console.log("150 loan origin updated:", updatedArr);
			setOrganizationProcessor(updatedArr);
		};

		const getOrganizationDetailsFunc = async () => {
			const searchResults = await getOrganizationDetails({
				organizationName,
			}).unwrap();
			setOrganizationDetails(searchResults);
		};

		if (organizationName !== undefined && organizationName !== "") {
			getBrokerListFunc();
			getProcessorListFunc();
			getOrganizationDetailsFunc();
		}
	}, [organizationName]);

	useEffect(() => {
		if (loanOriginatorEmail !== {} || loanOriginatorEmail !== "") {
			const originatorDetails = organizationUsers?.find(
				(item) => item?.user?.email === loanOriginatorEmail
			);

			setLoanOriginator(originatorDetails);
		}
	}, [loanOriginatorEmail]);

	useEffect(() => {
		if (loanProcessorEmail !== {} || loanProcessorEmail !== "") {
			const processorDetails = organizationProcessor?.find(
				(item) => item?.user?.email === loanProcessorEmail
			);

			setLoanProcessor(processorDetails);
		}
	}, [loanProcessorEmail]);

	const [error, setError] = useState("");

	useEffect(() => {
		const getAllBorrowerPair = async () => {
			const borrowerPairData = await getBorrowerPair({
				loanGuid,
			}).unwrap();

			setBorrowerResponseData(borrowerPairData?.response);
		};

		if (loanGuid !== null && loanGuid !== undefined && loanGuid !== "") {
			getAllBorrowerPair();
		}
	}, [loanGuid]);

	useEffect(() => {
		const getLoanDetails = async () => {
			const loanData = await getLoan({
				loanid: loanGuid,
			}).unwrap();

			console.log("1130 loan data:", loanData);

			setLoanOriginatorEmail(loanData?.response?.tPO?.lOEmail || "");
			setLoanProcessorEmail(loanData?.response?.tPO?.lPEmail || "");

			setPropertyInfo(loanData?.response?.property || {});

			let borrowerPairs = loanData?.response?.applications
				?.map((application) => [application.borrower, application.coborrower])
				.flat();

			setLoanApplicationData(borrowerPairs);
		};

		console.log("1130 loan guid:", loanGuid);
		if (loanGuid !== null && loanGuid !== undefined && loanGuid !== "") {
			getLoanDetails();
		}
	}, [loanGuid]);

	const updateField = async (loanGuid, apiObj) => {
		let valuesArr = [
			{
				id: "CX.PROPERTY.COMMERCIAL.UNITS",
				value: apiObj?.property?.financedNumberOfUnits || "",
			},
		];

		if (
			apiObj?.applications[0]?.borrower?.urla2020CitizenshipResidencyType ===
			"Foreign National"
		) {
			valuesArr.push({
				id: "CX.FOREIGNNATLBORR1",
				value: "Yes",
			});
			valuesArr.push({
				id: "URLA.X1",
				value: "NonPermanentResidentAlien",
			});
			valuesArr.push({
				id: "URLA.X263",
				value: apiObj?.applications[0]?.borrower?.urla2020CountryOfCitizenship,
			});
		} else {
			valuesArr.push({
				id: "CX.FOREIGNNATLBORR1",
				value: "No",
			});
		}

		if (
			apiObj?.applications[0]?.coBorrower?.urla2020CitizenshipResidencyType ===
			"Foreign National"
		) {
			valuesArr.push({
				id: "CX.FOREIGNNATLBORR2",
				value: "Yes",
			});
			valuesArr.push({
				id: "URLA.X2",
				value: "NonPermanentResidentAlien",
			});
			valuesArr.push({
				id: "URLA.X264",
				value:
					apiObj?.applications[0]?.coBorrower?.urla2020CountryOfCitizenship,
			});
		} else {
			valuesArr.push({
				id: "CX.FOREIGNNATLBORR2",
				value: "No",
			});
		}

		console.log("340 loan update values:", valuesArr);

		const updateFieldValues = await updateFields({
			loan_guid: loanGuid,
			field_values: valuesArr,
		}).unwrap();
		console.log("90 file res:", updateFieldValues);

		if (updateFieldValues?.response === "Field values updated successfully.") {
			setError("");
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: "smooth",
			});
			setLoanApplicationStage(2);
		} else {
			setError(updateFieldValues?.response);
		}
	};

	const handleNext = async () => {
		setError("");

		if (
			!requiredFields(
				loanApplicationData,
				propertyInfo,
				loanOriginatorEmail,
				loanProcessorEmail,
				setError
			)
		) {
			return;
		}

		let borrowerPairData = [];

		console.log("445 loan application data:", loanApplicationData);

		for (let index = 0; index < loanApplicationData.length; index++) {
			if (index % 2 === 0) {
				borrowerPairData.push([
					{
						borrower: loanApplicationData[index],
						coborrower: loanApplicationData[index + 1],
					},
				]);
			}
		}

		console.log("285 borrower pair data;", borrowerPairData);

		let applications = [];

		for (let index = 0; index < loanApplicationData.length; index += 2) {
			applications.push([
				{
					borrower: loanApplicationData[index],
					coBorrower: loanApplicationData[index + 1],
				},
			]);
		}

		console.log("247 application data:", applicationData);

		setApplicationData({
			...applicationData,
			applications,
			property: propertyInfo,
			tPO: {
				lOEmail: loanOriginatorEmail,
				lPEmail: loanProcessorEmail,
			},
		});

		console.log("305 loan guid:", loanGuid);

		let apiObj = {
			...applicationData,
			applications: applications[0],
			property: propertyInfo,
		};

		// update loan api
		console.log("510 loan id:", loanGuid);
		console.log("510 loan data:", apiObj);

		const loanUpdateResponse = await updateLoan({
			loanGuid,
			loanData: {
				property: propertyInfo,
			},
		}).unwrap();

		console.log("440 loan update res:", loanUpdateResponse);

		// if (loanApplicationData?.length >= 3) {
		console.log("440 borrower pair data:", borrowerPairData);
		console.log("440 borrower response data:", borrowerResponseData);

		let borrowerPair;

		for (let index = 0; index < borrowerPairData.length; index++) {
			console.log("445 index:", index);
			console.log(
				"450 borrower match:",
				borrowerResponseData[index]?.borrowerPairId
			);

			if (
				borrowerResponseData[index]?.borrowerPairId !== null &&
				borrowerResponseData[index]?.borrowerPairId !== undefined &&
				borrowerResponseData[index]?.borrowerPairId !== ""
			) {
				// if borrower pair id exists then update the pair
				try {
					borrowerPair = await updateBorrowerPair({
						loanGuid: loanGuid,
						borrowerPairId: borrowerResponseData[index].borrowerPairId,
						loanData: { ...borrowerPairData[index][0] },
					}).unwrap();
				} catch (err) {
					console.log("450 err:", err);
					if (err?.data?.details?.includes("was not found")) {
						// if borrower pair id does not exists then create the pair
						borrowerPair = await createBorrowerPair({
							loanGuid: loanGuid,
							data: { ...borrowerPairData[index][0] },
						}).unwrap();
					} else {
						setError(err?.data?.details);
					}
				}
			} else {
				// if borrower pair id does not exists then create the pair
				borrowerPair = await createBorrowerPair({
					loanGuid: loanGuid,
					data: { ...borrowerPairData[index][0] },
				}).unwrap();
			}
		}

		console.log("550 update borrower pair data:", borrowerPair);
		// }

		if (borrowerPair !== null && borrowerPair !== undefined) {
			updateField(loanGuid, apiObj);
		}
	};

	const handleAddBorrowerPair = () => {
		setLoanApplicationData([...loanApplicationData, {}, {}]);
	};

	const handleRemovePair = async () => {
		console.log("470 pair count:", loanApplicationData?.length / 2 - 1);

		if (tabValue === loanApplicationData?.length / 2 - 1) {
			setTabValue(tabValue - 1);
		}

		let newArr = loanApplicationData;

		if (tabValue === 0) {
			newArr?.splice(tabValue, 2);
		} else {
			newArr?.splice(tabValue * 2, 2);
		}

		setLoanApplicationData([...newArr]);
	};

	const handleRemoveBorrowerPair = async () => {
		console.log("485 tab value:", tabValue);
		console.log("485 borrower id:", borrowerResponseData[tabValue]?.id);

		let deleteBorrowerPairResponse;

		if (borrowerResponseData[tabValue]?.id !== undefined) {
			// if pair exists on loan, remove borrower pair after deleting from the loan
			try {
				deleteBorrowerPairResponse = await deleteBorrowerPair({
					loanGuid: loanGuid,
					borrowerPairId: borrowerResponseData[tabValue]?.id,
					loanData: {},
				}).unwrap();

				console.log("475 delete:", deleteBorrowerPairResponse);

				if (deleteBorrowerPairResponse?.message?.includes("success")) {
					handleRemovePair();
				}
			} catch (err) {
				console.log("515 err:", err);
				if (err?.data?.details?.includes("was not found")) {
					handleRemovePair();
				} else if (
					err?.data?.details?.includes(
						"Loan should have at least one application associated"
					)
				) {
					// show modal with error for deleting borrower pair
					console.log("520 err:", err);
					handleOpenErrorModal();
				}
			}
		} else {
			// if pair is new and does not exist on loan, remove pair without running API
			handleRemovePair();
		}
	};

	function a11yProps(index) {
		return {
			id: `simple-tab-${index}`,
			"aria-controls": `simple-tabpanel-${index}`,
		};
	}

	const handleChangeTab = (event, newValue) => {
		setTabValue(newValue);
	};

	// loader - loadingBorrowerPairData
	return (
		<Stack direction="column" className={styles.stackContainer}>
			<Stack className={styles.bodyContainer}>
				{/* tabs section */}
				<Tabs
					value={tabValue}
					onChange={handleChangeTab}
					aria-label="basic tabs example"
					TabIndicatorProps={{
						style: {
							backgroundColor: "#76D99E",
						},
					}}
				>
					<Tab
						className={styles.tabBtnContainer}
						label={
							<Stack direction="row" className={styles.tabLabelContainer}>
								<Heading6
									text="Borrower Pair 1"
									fontType="semibold"
									extraClass={
										tabValue === 0 ? styles.activeTabText : styles.tabText
									}
								/>

								{tabValue === 0 && (
									<Stack
										className={styles.closeIconContainer}
										onClick={() =>
											loanApplicationData?.length > 2 &&
											borrowerDataExist === false &&
											handleRemoveBorrowerPair()
										}
									>
										<CloseOutlined className={styles.closeIcon} />
									</Stack>
								)}
							</Stack>
						}
						{...a11yProps(0)}
					/>
					{loanApplicationData?.length > 2 && (
						<Tab
							className={styles.tabBtnContainer}
							label={
								<Stack direction="row" className={styles.tabLabelContainer}>
									<Heading6
										text="Borrower Pair 2"
										fontType="semibold"
										extraClass={
											tabValue === 1 ? styles.activeTabText : styles.tabText
										}
									/>

									{tabValue === 1 && (
										<Stack
											className={styles.closeIconContainer}
											onClick={() =>
												borrowerDataExist === false &&
												handleRemoveBorrowerPair()
											}
										>
											<CloseOutlined className={styles.closeIcon} />
										</Stack>
									)}
								</Stack>
							}
							{...a11yProps(1)}
						/>
					)}
					{loanApplicationData?.length > 4 && (
						<Tab
							className={styles.tabBtnContainer}
							label={
								<Stack direction="row" className={styles.tabLabelContainer}>
									<Heading6
										text="Borrower Pair 3"
										fontType="semibold"
										extraClass={
											tabValue === 2 ? styles.activeTabText : styles.tabText
										}
									/>

									{tabValue === 2 && (
										<Stack
											className={styles.closeIconContainer}
											onClick={() =>
												borrowerDataExist === false &&
												handleRemoveBorrowerPair()
											}
										>
											<CloseOutlined className={styles.closeIcon} />
										</Stack>
									)}
								</Stack>
							}
							{...a11yProps(2)}
						/>
					)}

					<Box>
						<IconButton
							className={styles.addPairContainer}
							onClick={() =>
								loanApplicationData?.length < 6 &&
								borrowerDataExist === false &&
								handleAddBorrowerPair()
							}
							disabled={
								loanApplicationData?.length > 4 && borrowerDataExist === false
							}
						>
							<AddOutlined className={styles.addPairIcon} />
						</IconButton>
					</Box>
				</Tabs>

				{/* borrower pair 1 */}
				{loanApplicationData?.length > 0 && (
					<TabPanel
						value={tabValue}
						index={0}
						className={tabValue === 0 ? styles.tabPanelContainer : ""}
					>
						{error !== "" && (
							<RequiredError
								text={
									error.includes("Something went wrong")
										? "Updates Not Received by LOS. Please Try Again."
										: error.includes("provide a value")
										? "Please provide a value for all fields highlighted above."
										: error.includes("borrower.dependentCount") &&
										  "Please check your dependent count."
								}
							/>
						)}
						{loanApplicationData?.slice(0, 2)?.map((borrower, index) => (
							<BorrowerInformation
								key={index}
								borrowerIndex={index}
								coBorrower={index % 2 !== 0}
								borrowerData={borrower}
								data={loanApplicationData}
								setData={setLoanApplicationData}
								error={error.includes("provide a value")}
								disabled={borrowerDataExist}
							/>
						))}

						<PropertyDetails
							data={propertyInfo}
							setData={setPropertyInfo}
							loanData={applicationData}
							setLoanData={setApplicationData}
							error={error.includes("provide a value")}
							disabled={borrowerDataExist}
						/>

						<LoanOriginator
							organizationName={organizationName}
							organizationDetails={organizationDetails}
							loanOriginatorEmail={loanOriginatorEmail}
							setLoanOriginatorEmail={setLoanOriginatorEmail}
							organizationUsers={organizationUsers}
							loanOriginator={loanOriginator}
							setLoanOriginator={setLoanOriginator}
							setOrganizationLicense={setOrganizationLicense}
							propertyState={propertyInfo?.state}
							error={error.includes("provide a value")}
							disabled={true}
						/>

						<LoanProcessor
							organizationName={organizationName}
							organizationDetails={organizationDetails}
							loanProcessorEmail={loanProcessorEmail}
							setLoanProcessorEmail={setLoanProcessorEmail}
							organizationProcessor={organizationProcessor}
							loanProcessor={loanProcessor}
							propertyState={propertyInfo?.state}
							error={error.includes("provide a value")}
							disabled={true}
						/>
					</TabPanel>
				)}

				{/* borrower pair 2 */}
				{loanApplicationData?.length > 2 && (
					<TabPanel
						value={tabValue}
						index={1}
						className={tabValue === 1 ? styles.tabPanelContainer : ""}
					>
						{error !== "" && (
							<RequiredError
								text={
									error.includes("Something went wrong")
										? "Updates Not Received by LOS. Please Try Again."
										: error.includes("provide a value")
										? "Please provide a value for all fields highlighted above."
										: error.includes("borrower.dependentCount") &&
										  "Please check your dependent count."
								}
							/>
						)}
						{loanApplicationData?.slice(2, 4)?.map((borrower, index) => (
							<BorrowerInformation
								key={index}
								borrowerIndex={index + 2}
								coBorrower={index % 2 !== 0}
								borrowerData={borrower}
								data={loanApplicationData}
								setData={setLoanApplicationData}
								error={error.includes("provide a value")}
								disabled={borrowerDataExist}
							/>
						))}

						<PropertyDetails
							data={propertyInfo}
							setData={setPropertyInfo}
							loanData={applicationData}
							setLoanData={setApplicationData}
							error={error.includes("provide a value")}
							disabled={borrowerDataExist}
						/>

						<LoanOriginator
							organizationName={organizationName}
							organizationDetails={organizationDetails}
							loanOriginatorEmail={loanOriginatorEmail}
							setLoanOriginatorEmail={setLoanOriginatorEmail}
							organizationUsers={organizationUsers}
							loanOriginator={loanOriginator}
							setLoanOriginator={setLoanOriginator}
							setOrganizationLicense={setOrganizationLicense}
							propertyState={propertyInfo?.state}
							error={error.includes("provide a value")}
							disabled={true}
						/>

						<LoanProcessor
							organizationName={organizationName}
							organizationDetails={organizationDetails}
							loanProcessorEmail={loanProcessorEmail}
							setLoanProcessorEmail={setLoanProcessorEmail}
							organizationProcessor={organizationProcessor}
							loanProcessor={loanProcessor}
							propertyState={propertyInfo?.state}
							error={error.includes("provide a value")}
							disabled={true}
						/>
					</TabPanel>
				)}

				{/* borrower pair 3 */}
				{loanApplicationData?.length > 4 && (
					<TabPanel
						value={tabValue}
						index={2}
						className={tabValue === 2 ? styles.tabPanelContainer : ""}
					>
						{error !== "" && (
							<RequiredError
								text={
									error.includes("Something went wrong")
										? "Updates Not Received by LOS. Please Try Again."
										: error.includes("provide a value")
										? "Please provide a value for all fields highlighted above."
										: error.includes("borrower.dependentCount") &&
										  "Please check your dependent count."
								}
							/>
						)}
						{loanApplicationData?.slice(4, 6)?.map((borrower, index = 4) => (
							<BorrowerInformation
								key={index}
								borrowerIndex={index + 4}
								coBorrower={index % 2 !== 0}
								borrowerData={borrower}
								data={loanApplicationData}
								setData={setLoanApplicationData}
								error={error.includes("provide a value")}
								disabled={borrowerDataExist}
							/>
						))}
						<PropertyDetails
							data={propertyInfo}
							setData={setPropertyInfo}
							loanData={applicationData}
							setLoanData={setApplicationData}
							error={error.includes("provide a value")}
							disabled={borrowerDataExist}
						/>
						<LoanOriginator
							organizationName={organizationName}
							organizationDetails={organizationDetails}
							loanOriginatorEmail={loanOriginatorEmail}
							setLoanOriginatorEmail={setLoanOriginatorEmail}
							organizationUsers={organizationUsers}
							loanOriginator={loanOriginator}
							setLoanOriginator={setLoanOriginator}
							setOrganizationLicense={setOrganizationLicense}
							propertyState={propertyInfo?.state}
							error={error.includes("provide a value")}
							disabled={true}
						/>
						<LoanProcessor
							organizationName={organizationName}
							organizationDetails={organizationDetails}
							loanProcessorEmail={loanProcessorEmail}
							setLoanProcessorEmail={setLoanProcessorEmail}
							organizationProcessor={organizationProcessor}
							loanProcessor={loanProcessor}
							propertyState={propertyInfo?.state}
							error={error.includes("provide a value")}
							disabled={true}
						/>
					</TabPanel>
				)}
			</Stack>

			<Stack direction="row" className={styles.footerContainer}>
				<PrimaryButton
					text="Save and Continue"
					endIcon={<ArrowForwardOutlined className={styles.nextIcon} />}
					onClick={handleNext}
				/>
			</Stack>

			{/* modals */}
			<LoaderModal
				open={
					loadingBorrowerPairData ||
					loadingloanData ||
					isUpdatingFields ||
					updatingLoan ||
					updatingBorrowerPair ||
					creatingBorrowerPair ||
					deletingBorrowerPair ||
					brokerListLoading ||
					organizationDetailsLoading
				}
			/>
			<ConfirmationModal
				open={confirmModalVisible}
				text={confirmModalText}
				confirmText="Sign In"
				handleConfirm={handleSubmitConfirmModal}
				closeText="Close"
				handleClose={handleCloseConfirmModal}
			/>
			<ErrorModal
				open={errorModalVisible}
				text="Unable to delete the primary borrower pair"
				handleClose={handleCloseErrorModal}
			/>
		</Stack>
	);
};

export default EditLoanApplication;
