import { createSlice } from "@reduxjs/toolkit";

const loanSlice = createSlice({
	name: "uploadXml",
	initialState: { xmlData: null },
	reducers: {
		setFileReducer: (state, action) => {
			console.log("17 action:", action);
			const data = action.payload;
			console.log("10 data:", data);
			state.xmlData = data;
		},
	},
});

export const { setFileReducer } = loanSlice.actions;

export default loanSlice.reducer;

export const selectCurrentXml = (state) => state.xmlFile.xmlData;
