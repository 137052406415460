import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "./api/apiSlice";
import authReducer from "../features/auth/authSlice";
import loanReducer from "../features/loan/loanSlice";
import createLoanReducer from "../features/createLoan/createLoanSlice";
import resetPasswordReducer from "../features/reset-password/resetSlice";
import setPasswordReducer from "../features/set-password/setPasswordSlice";
import loanDocsReducer from "../features/loan-docs/loanDocsSlice";
import brokerListReducer from "../features/broker-list/brokerListSlice";
import impersonatedUserReducer from "../features/admin/impersonated-user/impersonatedUserSlice";
import organizationNameReducer from "../features/admin/organization-name/organizationNameSlice";
import impersonatedOrgNameReducer from "../features/admin/impersonated-organization-name/impersonatedOrganizationNameSlice";
import sameAsCurrentAddressReducer from "../features/same-as-current-address/sameAsCurrentAddressSlice";
import brokerUploadedDocsReducer from "../features/broker-uploads/brokerUploadSlice";
import lockRequestLogReducer from "../features/lock-request-log/lockRequestLogSlice";
import categoryConditionReducer from "../features/category-condition/categoryConditonSlice";
import underwritingConditionsReducer from "../features/underwriting-conditions/underwritingSlice";
import preliminaryConditionsReducer from "../features/preliminary-conditions/preliminarySlice";
import postClosingConditionsReducer from "../features/post-closing-conditions/postClosingSlice";
import fieldValuesReducer from "../features/field-values/fieldValueSlice";

const combinedReducers = combineReducers({
	[apiSlice.reducerPath]: apiSlice.reducer,
	auth: authReducer,
	xmlFile: loanReducer,
	loan: createLoanReducer,
	resetPassword: resetPasswordReducer,
	setPassword: setPasswordReducer,
	loanDocs: loanDocsReducer,
	brokerList: brokerListReducer,
	impersonatedUser: impersonatedUserReducer,
	organizationName: organizationNameReducer,
	impersonatedOrgName: impersonatedOrgNameReducer,
	sameAsCurrentAddress: sameAsCurrentAddressReducer,
	brokerUploadedDocs: brokerUploadedDocsReducer,
	lockRequestLog: lockRequestLogReducer,
	categoryCondition: categoryConditionReducer,
	underwritingConditions: underwritingConditionsReducer,
	preliminaryConditions: preliminaryConditionsReducer,
	postClosingConditions: postClosingConditionsReducer,
	fieldValues: fieldValuesReducer,
});

const rootReducer = (state, action) => {
	if (action.type === "auth/logOut") {
		state = undefined;
	}
	return combinedReducers(state, action);
};

export const store = configureStore({
	reducer: rootReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().concat(apiSlice.middleware),
	devTools: true,
});
