import { createSlice } from "@reduxjs/toolkit";

const createAdminLoanSlice = createSlice({
	name: "createLoan",
	initialState: { loan: null },
	reducers: {
		createAdminLoanReducer: (state, action) => {
			state.loan = action.payload;
		},
	},
});

export const { createAdminLoanReducer } = createAdminLoanSlice.actions;

export default createAdminLoanSlice.reducer;

export const selectCurrentLoan = (state) => state.loan.loan;
