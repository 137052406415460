import React from "react";

// mui components
import { IconButton, TableCell, TableRow } from "@mui/material";

// custom components
import OutlinedTextInput from "../../custom-input/outlined-text-input";
import SecondarySelect from "../../custom-select/secondary-select";

// helper utils
import { ownerOptions, assetTypeOptions } from "../../../utils/select-options";

// mui icons
import { DeleteOutlined } from "@mui/icons-material";

// custom styles
import styles from "../index.module.scss";
import MaskedInput from "../../custom-input/masked-input";

const AssetInformationRow = ({
	row,
	index,
	handleRemoveRow,
	handleUpdate,
	disableRow,
}) => {
	return (
		<TableRow hover tabIndex={-1} key={row.label}>
			<TableCell>
				<IconButton
					className={styles.deleteIconBtn}
					onClick={() => handleRemoveRow(index, row?.vodIndex)}
					disabled={disableRow}
				>
					<DeleteOutlined className={styles.deleteIcon} />
				</IconButton>
			</TableCell>
			<TableCell>
				<SecondarySelect
					value={row?.owner}
					handleChange={(event) =>
						handleUpdate(index, "owner", event.target.value)
					}
					options={ownerOptions}
					disabled={disableRow}
				/>
			</TableCell>
			<TableCell>
				<SecondarySelect
					value={row?.assetType}
					handleChange={(event) =>
						handleUpdate(index, "assetType", event.target.value)
					}
					options={assetTypeOptions}
					disabled={disableRow}
				/>
			</TableCell>
			<TableCell>
				<OutlinedTextInput
					type="text"
					value={row?.holderName}
					handleChange={(event) =>
						handleUpdate(index, "holderName", event.target.value)
					}
					disabled={disableRow}
				/>
			</TableCell>
			<TableCell>
				<OutlinedTextInput
					type="text"
					value={row?.accountIdentifier}
					handleChange={(event) =>
						handleUpdate(index, "accountIdentifier", event.target.value)
					}
					disabled={disableRow}
				/>
			</TableCell>
			<TableCell>
				<MaskedInput
					placeholder="$"
					type="currency"
					value={row?.urla2020CashOrMarketValueAmount}
					onInputChange={(value) =>
						disableRow !== true &&
						handleUpdate(index, "urla2020CashOrMarketValueAmount", value)
					}
					disabled={disableRow}
				/>
			</TableCell>
		</TableRow>
	);
};

export default AssetInformationRow;
