import React, { useState, useEffect } from "react";
import { useGetUserStateLicenseMutation } from "../../../features/user-state-license/userStateLicenseApiSlice";

// mui components
import { Box, Stack } from "@mui/system";
import { InputLabel } from "@mui/material";

// custom components
import OutlinedTextInput from "../../custom-input/outlined-text-input";
import SecondarySelect from "../../custom-select/secondary-select";
import CustomDivider from "../../custom-divider";
import Heading5 from "../../typography/heading-05";
import Heading6 from "../../typography/heading-06";
import Body3 from "../../typography/body-03";

// helper utils
import { stateOptions } from "../../../utils/select-options";

// custom animations
import Lottie from "lottie-react";
import loadingAnimation from "../../../assets/animations/loading.json";

// custom styles
import styles from "./index.module.scss";

const LoanOriginator = ({
	organizationName,
	organizationDetails,
	loanOriginatorEmail,
	setLoanOriginatorEmail,
	organizationUsers,
	loanOriginator,
	setLoanOriginator,
	setOrganizationLicense,
	propertyState,
	disabled,
	viewLoan,
	error,
}) => {
	console.log("22 viewLoan originator:", viewLoan);
	console.log("22 disabled originator:", disabled);

	const [organizationStateLicense, setOrganizationStateLicense] = useState("");
	const [userStateLicense, setUserStateLicense] = useState("");

	const [getUserStateLicense, { isLoading: isLoadingUserStateLicense }] =
		useGetUserStateLicenseMutation();

	useEffect(() => {
		if (propertyState !== "") {
			let matchedLicense = organizationDetails?.state_licenses?.find(
				(item) =>
					item.selected === true && item.stateAbbrevation === propertyState
			);

			if (
				matchedLicense !== undefined &&
				matchedLicense?.licenseNumber !== ""
			) {
				setOrganizationStateLicense(matchedLicense?.licenseNumber);
				setOrganizationLicense(matchedLicense?.licenseNumber);
			} else {
				setOrganizationStateLicense("");
				setOrganizationLicense("");
			}
		} else {
			setOrganizationStateLicense("");
			setOrganizationLicense("");
		}
	}, [organizationDetails, propertyState]);

	const getUserLicense = async (userId) => {
		let userLicense = await getUserStateLicense({
			brokerId: userId,
		}).unwrap();

		console.log("56 userLicense:", userLicense);

		let matchedLicense = userLicense?.find(
			(item) =>
				item.selected === true && item.stateAbbrevation === propertyState
		);

		console.log("60 matched license:", matchedLicense);

		if (matchedLicense !== undefined && matchedLicense?.licenseNumber !== "") {
			setUserStateLicense(matchedLicense?.licenseNumber);
		} else {
			setUserStateLicense("");
		}

		if (
			matchedLicense?.licenseNumber !== undefined &&
			matchedLicense?.licenseNumber !== ""
		) {
			setLoanOriginator((prev) => {
				return { ...prev, state_license: matchedLicense?.licenseNumber };
			});
		} else {
			setLoanOriginator((prev) => {
				return { ...prev, state_license: "" };
			});
		}
	};

	useEffect(() => {
		if (loanOriginatorEmail !== "" && propertyState !== "") {
			console.log("100 org users:", organizationUsers);

			let matchedUser = organizationUsers?.find(
				(item) => item.value === loanOriginatorEmail
			);

			if (matchedUser !== undefined && matchedUser?.broker_id !== "") {
				getUserLicense(matchedUser?.broker_id);
			}
		}
	}, [loanOriginatorEmail, propertyState]);

	return (
		<Box className={styles.cardContainer}>
			<Stack direction="column" className={styles.cardHeaderContainer}>
				<Heading5 text="Loan Originator Information" fontType="semibold" />

				<Body3 text="Provide details about the loan originator" />
			</Stack>
			<Stack direction="column" className={styles.formSectionContainer}>
				{/* organization information */}
				<Stack direction="row" className={styles.inputSectionContainer}>
					{/* organization name */}
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.fullWidthContainer}
					>
						<InputLabel htmlFor="outlined-input-organization-name">
							<Heading6
								text="Organization Name"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<OutlinedTextInput
							id="outlined-input-organization-name"
							type="text"
							placeholder="Organization Name"
							fullWidth={true}
							value={organizationName}
							disabled={true}
						/>
					</Stack>

					{/* address line */}
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.fullWidthContainer}
					>
						<InputLabel htmlFor="outlined-input-address-line">
							<Heading6
								text="Address Line 1"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<OutlinedTextInput
							id="outlined-input-address-line"
							type="text"
							placeholder="Address Line"
							fullWidth={true}
							value={organizationDetails?.address}
							disabled={true}
						/>
					</Stack>

					{/* unit number */}
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.fullWidthContainer}
					>
						<InputLabel htmlFor="outlined-input-unit-number">
							<Heading6
								text="Address Line 2 / Unit Number"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<OutlinedTextInput
							id="outlined-input-unit-number"
							type="text"
							placeholder="Unit Number"
							fullWidth={true}
							// value={data?.addressUnitIdentifier}
							disabled={true}
						/>
					</Stack>

					{/* city */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor="outlined-input-city">
							<Heading6
								text="City"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<OutlinedTextInput
							id="outlined-input-city"
							type="text"
							placeholder="City"
							value={organizationDetails?.city}
							disabled={true}
						/>
					</Stack>

					{/* state */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor="select-state">
							<Heading6
								text="State"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<SecondarySelect
							id="select-state"
							displayEmpty={true}
							value={organizationDetails?.state || ""}
							options={stateOptions}
							disabled={true}
						/>
					</Stack>

					{/* postal code */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor="outlined-input-postal-code">
							<Heading6
								text="Postal Code"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<OutlinedTextInput
							id="outlined-input-postal-code"
							type="text"
							placeholder="123456"
							value={organizationDetails?.zip}
							disabled={true}
						/>
					</Stack>
				</Stack>

				{/* organization information */}
				<Stack direction="row" className={styles.inputSectionContainer}>
					{/* organization NMLS */}
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.inlineContainer}
					>
						<InputLabel htmlFor="outlined-input-organization-nmls">
							<Heading6
								text="LO Organization NMLS"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<OutlinedTextInput
							id="outlined-input-organization-nmls"
							type="text"
							placeholder="LO Organization NMLS"
							fullWidth={true}
							value={organizationDetails?.nmls_id}
							disabled={true}
						/>
					</Stack>

					{/* state license */}
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.inlineContainer}
					>
						<InputLabel htmlFor="outlined-input-license">
							<Heading6
								text="State License Number"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<OutlinedTextInput
							id="outlined-input-license"
							type="text"
							placeholder="State License Number"
							fullWidth={true}
							value={organizationStateLicense}
							disabled={true}
						/>
					</Stack>
				</Stack>

				{/* loan originator information */}
				<Stack direction="row" className={styles.inputSectionContainer}>
					{/* LO email */}
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.fullWidthContainer}
					>
						<InputLabel htmlFor="select-email">
							<Heading6
								text="Loan Originator"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						{console.log("320 organizationUsers:", organizationUsers)}

						<SecondarySelect
							id="select-email"
							displayEmpty={true}
							fullWidth={true}
							value={loanOriginatorEmail || ""}
							handleChange={(event) =>
								setLoanOriginatorEmail(event.target.value)
							}
							options={organizationUsers}
							disabled={disabled}
							error={
								error &&
								(loanOriginatorEmail === undefined ||
									loanOriginatorEmail === "")
							}
						/>
					</Stack>
				</Stack>

				{isLoadingUserStateLicense ? (
					<Box className={styles.animationContainer}>
						<Lottie animationData={loadingAnimation} loop={true} />
					</Box>
				) : (
					<Stack direction="row" className={styles.inputSectionContainer}>
						{/* LO Name */}
						<Stack direction="column" className={styles.inputContainer}>
							<InputLabel htmlFor="outlined-input-lo-name">
								<Heading6
									text="Loan Officer Name"
									fontType="semibold"
									extraClass={styles.inputHeader}
								/>
							</InputLabel>

							<OutlinedTextInput
								id="outlined-input-lo-name"
								type="text"
								placeholder="LO Name"
								value={
									viewLoan
										? loanOriginator?.user?.name
										: loanOriginator?.user?.first_name +
										  " " +
										  loanOriginator?.user?.last_name
								}
								disabled={true}
							/>
						</Stack>

						{/* LO NMLS ID */}
						<Stack direction="column" className={styles.inputContainer}>
							<InputLabel htmlFor="outlined-input-lo-nmls">
								<Heading6
									text="NMLS ID"
									fontType="semibold"
									extraClass={styles.inputHeader}
								/>
							</InputLabel>

							<OutlinedTextInput
								id="outlined-input-lo-nmls"
								type="text"
								placeholder="NMLS ID"
								value={loanOriginator?.nmls_id}
								disabled={true}
							/>
						</Stack>

						{/* LO state license */}
						<Stack direction="column" className={styles.inputContainer}>
							<InputLabel htmlFor="outlined-input-license">
								<Heading6
									text="State License Number"
									fontType="semibold"
									extraClass={styles.inputHeader}
								/>
							</InputLabel>

							<OutlinedTextInput
								id="outlined-input-license"
								type="text"
								placeholder="State License Number"
								value={
									viewLoan ? loanOriginator?.stateLicense : userStateLicense
								}
								disabled={true}
							/>
						</Stack>

						{/* LO phone */}
						<Stack direction="column" className={styles.inputContainer}>
							<InputLabel htmlFor="outlined-input-phone">
								<Heading6
									text="Phone Number"
									fontType="semibold"
									extraClass={styles.inputHeader}
								/>
							</InputLabel>

							<OutlinedTextInput
								id="outlined-input-phone"
								type="text"
								placeholder="[XXX] XXX-XXXX"
								value={loanOriginator?.phone}
								disabled={true}
							/>
						</Stack>

						{/* LO cell phone */}
						<Stack direction="column" className={styles.inputContainer}>
							<InputLabel htmlFor="outlined-input-cell-phone">
								<Heading6
									text="Cell Phone Number"
									fontType="semibold"
									extraClass={styles.inputHeader}
								/>
							</InputLabel>

							<OutlinedTextInput
								id="outlined-input-cell-phone"
								type="text"
								placeholder="[XXX] XXX-XXXX"
								value={
									loanOriginator?.cell_phone === null ||
									loanOriginator?.cell_phone === undefined
										? ""
										: loanOriginator?.cell_phone
								}
								disabled={true}
							/>
						</Stack>

						{/* LO email */}
						<Stack direction="column" className={styles.inputContainer}>
							<InputLabel htmlFor="outlined-input-email">
								<Heading6
									text="Email ID"
									fontType="semibold"
									extraClass={styles.inputHeader}
								/>
							</InputLabel>

							<OutlinedTextInput
								id="outlined-input-email"
								type="email"
								placeholder="email@mail.com"
								value={loanOriginator?.user?.email}
								disabled={true}
							/>
						</Stack>
					</Stack>
				)}
			</Stack>
			<CustomDivider />
		</Box>
	);
};

export default LoanOriginator;
