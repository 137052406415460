import React from "react";

import {
	FormControl,
	FormControlLabel,
	FormLabel,
	Radio,
	RadioGroup,
} from "@mui/material";

import styles from "./index.module.scss";
import Body2 from "../typography/body-02";

const CustomRadio = ({
	id,
	value,
	handleChange,
	label,
	data,
	disabled,
	extraClass,
	extraLabelClass,
}) => {
	const customContainerClass = `${styles.radioContainer} ${extraClass}`;
	const customLabelClass = `${styles.radioContainerHeader} ${extraLabelClass}`;

	return (
		<FormControl className={customContainerClass} disabled={disabled}>
			{label !== undefined && label !== "" && (
				<FormLabel id={id}>
					<Body2 text={label} extraClass={customLabelClass} />
				</FormLabel>
			)}

			<RadioGroup
				aria-labelledby={id}
				name={id}
				value={value}
				onChange={handleChange}
				className={styles.radioGroup}
			>
				{data?.map((item, index) => (
					<FormControlLabel
						key={index}
						value={item?.value}
						control={<Radio className={styles.radio} />}
						label={<Body2 text={item?.label} className={styles.radioLabel} />}
					/>
				))}
			</RadioGroup>
		</FormControl>
	);
};

export default CustomRadio;
