import React, { useEffect, useState } from "react";
import uuid from "react-uuid";

// mui components
import { Box, IconButton, Stack } from "@mui/material";

// custom components
import CustomTable from "../../custom-table";
import CustomDivider from "../../custom-divider";
import Heading5 from "../../typography/heading-05";
import Body3 from "../../typography/body-03";

// mui icons
import { AddOutlined } from "@mui/icons-material";

// custom styles
import styles from "./index.module.scss";

const RealEstate = ({
	estateIndex,
	estateData,
	data,
	setData,
	disabled,
	liabilityCount,
	handleOpenLiabilityModal,
	setSelectedRealEstate,
}) => {
	const [estateRows, setEstateRows] = useState([]);

	const estateInfoColumns = [
		{ id: "index", label: "", minWidth: 48 },
		{ id: "property-owner", label: "Property Owner", minWidth: 160 },
		{ id: "property-value", label: "Property Value", minWidth: 160 },
		{ id: "status", label: "Status", minWidth: 160 },
		{ id: "rental-income", label: "Rental Income", minWidth: 160 },
		{ id: "taxes", label: "Taxes", minWidth: 160 },
		{ id: "liabilities", label: "Liabilities", minWidth: 160 },
	];

	useEffect(() => {
		if (estateData !== undefined) {
			setEstateRows(estateData);
		}
	}, [estateData]);

	const handleAddRow = () => {
		setData(
			data.map((data, index) => {
				if (estateIndex === index) {
					return [...data, { reoId: uuid() }];
				} else {
					return data;
				}
			})
		);
	};

	const handleDeleteRow = (rowIndex) => {
		// let removeRow = data?.filter((row, i) => index !== i);
		// setData(removeRow);

		setData(
			data.map((data, index) => {
				if (estateIndex === index) {
					return estateData?.filter((row, i) => rowIndex !== i);
				} else {
					return data;
				}
			})
		);
	};

	const handleChangeRow = (rowIndex, field, value) => {
		setEstateRows(() =>
			estateRows?.map((obj, i) => {
				if (i === rowIndex) {
					return { ...obj, [field]: value };
				}

				return obj;
			})
		);

		setData(
			data.map((data, index) => {
				if (estateIndex === index) {
					let updatedData = estateData?.map((obj, i) => {
						if (i === rowIndex) {
							return { ...obj, [field]: value };
						}
						return obj;
					});
					return updatedData;
				} else {
					return data;
				}
			})
		);
	};

	const handleReOwned = (index) => {
		console.log("60 clicked:", index);
		setSelectedRealEstate(index);
		handleOpenLiabilityModal();
	};

	return (
		<Box className={styles.cardContainer}>
			<Stack direction="column" className={styles.cardHeaderContainer}>
				<Heading5 text="Real Estate Owned" fontType="semibold" />

				<Body3 text="Provide details about the real estate owned" />
			</Stack>
			<Stack direction="column" className={styles.formSectionContainer}>
				<Stack direction="row" className={styles.inputSectionContainer}>
					{/* real estate owned information table  */}
					<CustomTable
						tableType="real-estate-information"
						columnData={estateInfoColumns}
						rowData={estateRows}
						handleRemoveRow={handleDeleteRow}
						handleUpdate={handleChangeRow}
						disableRow={disabled}
						liabilityCount={liabilityCount}
						handleReOwned={handleReOwned}
					/>

					<Box className={styles.addIconContainer}>
						<IconButton
							className={styles.addIconBtn}
							onClick={() => handleAddRow()}
							disabled={disabled}
						>
							<AddOutlined className={styles.addIcon} />
						</IconButton>
					</Box>
				</Stack>
			</Stack>

			<CustomDivider />
		</Box>
	);
};

export default RealEstate;
