import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// mui components
import { Box, Stack } from "@mui/material";

// custom components
import CustomLayout from "../../components/layout";
import LoanProgress from "../../components/loan-progress";
import CreateLoanApplication from "../../components/loan-form/create-loan-application";
import UpdateLoanApplication from "../../components/loan-form/update-loan-application";
import EditLoanApplication from "../../components/loan-form/edit-loan-application";
import LoanPricingApplication from "../../components/loan-form/create-loan-pricing";
import ShowMyFees from "../../components/loan-form/create-loan-fee";
import LoanDocuments from "../../components/loan-form/create-loan-documents";
import Heading5 from "../../components/typography/heading-05";

// mui icons
import { CloseOutlined } from "@mui/icons-material";

// api slice
import { useUserDetailsMutation } from "../../features/user-details/userDetailsApiSlice";

// reducer slice
import {
	selectCurrentToken,
	selectRefreshToken,
	setUserDetails,
} from "../../features/auth/authSlice";
import { setOrganizationNameReducer } from "../../features/admin/organization-name/organizationNameSlice";

// helper utils
import { storageLoad } from "../../utils/localStorage";

// custom modal
import LoaderModal from "../../components/modal/loader-modal";
import ConfirmationModal from "../../components/modal/confirmation-modal";

// custom styles
import styles from "./index.module.scss";

export const CreateLoan = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [userDetailsAPI] = useUserDetailsMutation();

	const loanSteps = [
		"Borrower Information",
		"Additional Borrower Information",
		"Loan Product and Terms",
		"Fees",
		"Supporting Documents",
	];

	const token = useSelector(selectCurrentToken);
	const refreshToken = useSelector(selectRefreshToken);
	const localAccessToken = storageLoad("access-token");
	const localRefreshToken = storageLoad("refresh-token");

	const getUserDetails = async () => {
		const userDetailData = await userDetailsAPI().unwrap();
		dispatch(setUserDetails({ ...userDetailData?.data }));
		dispatch(
			setOrganizationNameReducer(userDetailData?.data?.organization_name)
		);
	};

	useEffect(() => {
		if (token || refreshToken || localAccessToken || localRefreshToken) {
			getUserDetails();
		}
	}, []);

	const [loanApplicationStage, setLoanApplicationStage] = useState(0);
	const [applicationData, setApplicationData] = useState({});
	const [loanGuid, setLoanGuid] = useState("");

	const [loaderModal, setLoaderModal] = useState(false);

	const handleOpenLoaderModal = () => {
		setLoaderModal(true);
	};

	const handleCloseLoaderModal = () => {
		setLoaderModal(false);
	};

	const [exitModal, setExitModal] = useState(false);

	const handleOpenExitModal = () => {
		setExitModal(true);
	};

	const handleCloseExitModal = () => {
		setExitModal(false);
	};

	const handleConfirmExit = () => {
		navigate(`/home`);
	};

	const breadcrumbData = [
		{
			label: "Home",
			path: "/home",
		},
		{
			label: "Loans",
			path: "/pipeline",
		},
		{
			label: loanGuid,
			path: "",
		},
	];

	return (
		<Box>
			<CustomLayout breadcrumb={breadcrumbData} disabledOverflow={true}>
				<Stack direction="row" className={styles.stackContainer}>
					<Stack direction="row" className={styles.bodyContainer}>
						<Stack direction="column" className={styles.loanContainer}>
							{/* page title and close btn container */}
							<Box direction="row" className={styles.headerContainer}>
								<Stack className={styles.headerTextContainer}>
									<Heading5
										text={`Step ${
											loanApplicationStage === 0
												? loanApplicationStage + 1
												: loanApplicationStage
										}: ${
											loanSteps[
												loanApplicationStage === 0
													? loanApplicationStage
													: loanApplicationStage - 1
											]
										}`}
										fontType="semibold"
										className={styles.headerText}
									/>
								</Stack>

								<Stack
									className={styles.closeIconContainer}
									onClick={handleOpenExitModal}
								>
									<CloseOutlined className={styles.closeIcon} />
								</Stack>
							</Box>

							{loanApplicationStage === 0 ? (
								<CreateLoanApplication
									setLoanApplicationStage={setLoanApplicationStage}
									applicationData={applicationData}
									setApplicationData={setApplicationData}
									loanGuid={loanGuid}
									setLoanGuid={setLoanGuid}
								/>
							) : loanApplicationStage === 1 ? (
								<EditLoanApplication
									setLoanApplicationStage={setLoanApplicationStage}
									applicationData={applicationData}
									setApplicationData={setApplicationData}
									loanGuid={loanGuid}
								/>
							) : loanApplicationStage === 2 ? (
								<UpdateLoanApplication
									setLoanApplicationStage={setLoanApplicationStage}
									loanGuid={loanGuid}
								/>
							) : loanApplicationStage === 3 ? (
								<LoanPricingApplication
									setLoanApplicationStage={setLoanApplicationStage}
									loanGuid={loanGuid}
								/>
							) : loanApplicationStage === 4 ? (
								<ShowMyFees
									setLoanApplicationStage={setLoanApplicationStage}
									loanGuid={loanGuid}
								/>
							) : (
								loanApplicationStage === 5 && (
									<LoanDocuments
										setLoanApplicationStage={setLoanApplicationStage}
										loanGuid={loanGuid}
									/>
								)
							)}
						</Stack>

						{/* application progress */}
						<LoanProgress
							loanSteps={loanSteps}
							currentStep={
								loanApplicationStage === 0
									? loanApplicationStage
									: loanApplicationStage - 1
							}
						/>
					</Stack>
				</Stack>
			</CustomLayout>

			{/* modals */}
			<LoaderModal open={loaderModal} />
			<ConfirmationModal
				open={exitModal}
				text="Are you sure you want to exit?"
				confirmText="Exit Application"
				handleConfirm={handleConfirmExit}
				closeText="Return to Application"
				handleClose={handleCloseExitModal}
			/>
		</Box>
	);
};

export default CreateLoan;
