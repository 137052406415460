export const maritalStatusOptions = [
	{ value: "Married", label: "Married" },
	{ value: "Separated", label: "Separated" },
	{ value: "Unmarried", label: "Unmarried" },
];

export const citizenshipTypeOptions = [
	{ value: "USCitizen", label: "U.S Citizen" },
	{ value: "PermanentResidentAlien", label: "Permanent Resident Alien" },
	{
		value: "NonPermanentResidentAlien",
		label: "Non-Permanent Resident Alien",
	},
	{ value: "Foreign National", label: "Foreign National" },
];

export const countryOptions = [
	{ value: "Afghanistan", label: "Afghanistan" },
	{ value: "Aland Islands", label: "Åland Islands" },
	{ value: "Albania", label: "Albania" },
	{ value: "Algeria", label: "Algeria" },
	{ value: "American Samoa", label: "American Samoa" },
	{ value: "Andorra", label: "Andorra" },
	{ value: "Angola", label: "Angola" },
	{ value: "Anguilla", label: "Anguilla" },
	{ value: "Antarctica", label: "Antarctica" },
	{ value: "Antigua and Barbuda", label: "Antigua & Barbuda" },
	{ value: "Argentina", label: "Argentina" },
	{ value: "Armenia", label: "Armenia" },
	{ value: "Aruba", label: "Aruba" },
	{ value: "Australia", label: "Australia" },
	{ value: "Austria", label: "Austria" },
	{ value: "Azerbaijan", label: "Azerbaijan" },
	{ value: "Bahamas, The", label: "Bahamas, The" },
	{ value: "Bahrain", label: "Bahrain" },
	{ value: "Bangladesh", label: "Bangladesh" },
	{ value: "Barbados", label: "Barbados" },
	{ value: "Belarus", label: "Belarus" },
	{ value: "Belgium", label: "Belgium" },
	{ value: "Belize", label: "Belize" },
	{ value: "Benin", label: "Benin" },
	{ value: "Bermuda", label: "Bermuda" },
	{ value: "Bhutan", label: "Bhutan" },
	{ value: "Bolivia", label: "Bolivia" },
	{
		value: "Bonaire, Sint Eustatius and Saba",
		label: "Caribbean Netherlands",
	},
	{ value: "Bosnia and Herzegovina", label: "Bosnia & Herzegovina" },
	{ value: "Botswana", label: "Botswana" },
	{ value: "Bouvet Island", label: "Bouvet Island" },
	{ value: "Brazil", label: "Brazil" },
	{
		value: "British Indian Ocean Territory",
		label: "British Indian Ocean Territory",
	},
	{ value: "Brunei Darussalam", label: "Brunei" },
	{ value: "Bulgaria", label: "Bulgaria" },
	{ value: "Burkina Faso", label: "Burkina Faso" },
	{ value: "Burundi", label: "Burundi" },
	{ value: "Cambodia", label: "Cambodia" },
	{ value: "Cameroon", label: "Cameroon" },
	{ value: "Canada", label: "Canada" },
	{ value: "Cape Verde", label: "Cape Verde" },
	{ value: "Cayman Islands", label: "Cayman Islands" },
	{ value: "Central African Republic", label: "Central African Republic" },
	{ value: "Chad", label: "Chad" },
	{ value: "Chile", label: "Chile" },
	{ value: "China", label: "China" },
	{ value: "Christmas Island", label: "Christmas Island" },
	{ value: "Cocos (Keeling) Islands", label: "Cocos (Keeling) Islands" },
	{ value: "Colombia", label: "Colombia" },
	{ value: "Comoros", label: "Comoros" },
	{ value: "Congo", label: "Congo" },
	{
		value: "Congo, The Democratic Republic of the",
		label: "Congo, The Democratic Republic of the",
	},
	{ value: "Cook Islands", label: "Cook Islands" },
	{ value: "Costa Rica", label: "Costa Rica" },
	{ value: "Cote D'ivoire", label: "Côte D’ivoire" },
	{ value: "Croatia", label: "Croatia" },
	{ value: "Cuba", label: "Cuba" },
	{ value: "Curacao", label: "Curaçao" },
	{ value: "Cyprus", label: "Cyprus" },
	{ value: "Czech Republic", label: "Czechia" },
	{ value: "Denmark", label: "Denmark" },
	{ value: "Djibouti", label: "Djibouti" },
	{ value: "Dominica", label: "Dominica" },
	{ value: "Dominican Republic", label: "Dominican Republic" },
	{ value: "Ecuador", label: "Ecuador" },
	{ value: "Egypt", label: "Egypt" },
	{ value: "El Salvador", label: "El Salvador" },
	{ value: "Equatorial Guinea", label: "Equatorial Guinea" },
	{ value: "Eritrea", label: "Eritrea" },
	{ value: "Estonia", label: "Estonia" },
	{ value: "Ethiopia", label: "Ethiopia" },
	{
		value: "Falkland Islands (Malvinas)",
		label: "Falkland Islands (Islas Malvinas)",
	},
	{ value: "Faroe Islands", label: "Faroe Islands" },
	{ value: "Fiji", label: "Fiji" },
	{ value: "Finland", label: "Finland" },
	{ value: "France", label: "France" },
	{ value: "French Guiana", label: "French Guiana" },
	{ value: "French Polynesia", label: "French Polynesia" },
	{
		value: "French Southern Territories",
		label: "French Southern Territories",
	},
	{ value: "Gabon", label: "Gabon" },
	{ value: "Gambia, The", label: "Gambia, The" },
	{ value: "Georgia", label: "Georgia" },
	{ value: "Germany", label: "Germany" },
	{ value: "Ghana", label: "Ghana" },
	{ value: "Gibraltar", label: "Gibraltar" },
	{ value: "Greece", label: "Greece" },
	{ value: "Greenland", label: "Greenland" },
	{ value: "Grenada", label: "Grenada" },
	{ value: "Guadeloupe", label: "Guadeloupe" },
	{ value: "Guam (US Territory)", label: "Guam (US Territory)" },
	{ value: "Guatemala", label: "Guatemala" },
	{ value: "Guernsey", label: "Guernsey" },
	{ value: "Guinea", label: "Guinea" },
	{ value: "Guinea-Bissau", label: "Guinea-Bissau" },
	{ value: "Guyana", label: "Guyana" },
	{ value: "Haiti", label: "Haiti" },
	{
		value: "Heard Island and McDonald Islands",
		label: "Heard & McDonald Islands",
	},
	{ value: "Holy See", label: "Holy See" },
	{ value: "Honduras", label: "Honduras" },
	{ value: "Hong Kong", label: "Hong Kong" },
	{ value: "Hungary", label: "Hungary" },
	{ value: "Iceland", label: "Iceland" },
	{ value: "India", label: "India" },
	{ value: "Indonesia", label: "Indonesia" },
	{ value: "Iran", label: "Iran" },
	{ value: "Iraq", label: "Iraq" },
	{ value: "Ireland", label: "Ireland" },
	{ value: "Isle of Man", label: "Isle of Man" },
	{ value: "Israel", label: "Israel" },
	{ value: "Italy", label: "Italy" },
	{ value: "Jamaica", label: "Jamaica" },
	{ value: "Japan", label: "Japan" },
	{ value: "Jersey", label: "Jersey" },
	{ value: "Jordan", label: "Jordan" },
	{ value: "Kazakhstan", label: "Kazakhstan" },
	{ value: "Kenya", label: "Kenya" },
	{ value: "Kiribati", label: "Kiribati" },
	{ value: "Korea, Republic of", label: "Korea, Republic of" },
	{
		value: "Korea, The Democratic People's Republic of (North Korea)",
		label: "Korea, The Democratic People's Republic of (North Korea)",
	},
	{ value: "Kosovo", label: "Kosovo" },
	{ value: "Kuwait", label: "Kuwait" },
	{ value: "Kyrgyzstan", label: "Kyrgyzstan" },
	{ value: "Lao People's Democratic Republic", label: "Laos" },
	{ value: "Latvia", label: "Latvia" },
	{ value: "Lebanon", label: "Lebanon" },
	{ value: "Lesotho", label: "Lesotho" },
	{ value: "Liberia", label: "Liberia" },
	{ value: "Libya", label: "Libya" },
	{ value: "Liechtenstein", label: "Liechtenstein" },
	{ value: "Lithuania", label: "Lithuania" },
	{ value: "Luxembourg", label: "Luxembourg" },
	{ value: "Macao", label: "Macao" },
	{
		value: "Macedonia, The Former Yugoslav Republic of",
		label: "North Macedonia",
	},
	{ value: "Madagascar", label: "Madagascar" },
	{ value: "Malawi", label: "Malawi" },
	{ value: "Malaysia", label: "Malaysia" },
	{ value: "Maldives", label: "Maldives" },
	{ value: "Mali", label: "Mali" },
	{ value: "Malta", label: "Malta" },
	{ value: "Marshall Islands", label: "Marshall Islands" },
	{ value: "Martinique", label: "Martinique" },
	{ value: "Mauritania", label: "Mauritania" },
	{ value: "Mauritius", label: "Mauritius" },
	{ value: "Mayotte", label: "Mayotte" },
	{ value: "Mexico", label: "Mexico" },
	{
		value: "Micronesia, Federated States of",
		label: "Micronesia, Federated States of",
	},
	{ value: "Moldova, Republic of", label: "Moldova, Republic of" },
	{ value: "Monaco", label: "Monaco" },
	{ value: "Mongolia", label: "Mongolia" },
	{ value: "Montenegro", label: "Montenegro" },
	{ value: "Montserrat", label: "Montserrat" },
	{ value: "Morocco", label: "Morocco" },
	{ value: "Mozambique", label: "Mozambique" },
	{ value: "Myanmar", label: "Myanmar" },
	{ value: "Namibia", label: "Namibia" },
	{ value: "Nauru", label: "Nauru" },
	{ value: "Nepal", label: "Nepal" },
	{ value: "Netherlands", label: "Netherlands" },
	{ value: "New Caledonia", label: "New Caledonia" },
	{ value: "New Zealand", label: "New Zealand" },
	{ value: "Nicaragua", label: "Nicaragua" },
	{ value: "Niger", label: "Niger" },
	{ value: "Nigeria", label: "Nigeria" },
	{ value: "Niue", label: "Niue" },
	{ value: "Norfolk Island", label: "Norfolk Island" },
	{
		value: "Northern Mariana Islands, The (US Territory)",
		label: "Northern Mariana Islands",
	},
	{ value: "Norway", label: "Norway" },
	{ value: "Oman", label: "Oman" },
	{ value: "Pakistan", label: "Pakistan" },
	{ value: "Palau", label: "Palau" },
	{ value: "Palestinian Territories", label: "Palestine" },
	{ value: "Panama", label: "Panama" },
	{ value: "Papua New Guinea", label: "Papua New Guinea" },
	{ value: "Paraguay", label: "Paraguay" },
	{ value: "Peru", label: "Peru" },
	{ value: "Philippines", label: "Philippines" },
	{ value: "Pitcairn", label: "Pitcairn Islands" },
	{ value: "Poland", label: "Poland" },
	{ value: "Portugal", label: "Portugal" },
	{ value: "Qatar", label: "Qatar" },
	{ value: "Reunion", label: "Réunion" },
	{ value: "Romania", label: "Romania" },
	{ value: "Russian Federation", label: "Russia Federation" },
	{ value: "Rwanda", label: "Rwanda" },
	{ value: "Saint Barthelemy", label: "Saint Barthélemy" },
	{
		value: "Saint Helena, Ascension and Tristan da Cunha",
		label: "Saint Helena",
	},
	{ value: "Saint Kitts and Nevis", label: "Saint Kitts & Nevis" },
	{ value: "Saint Lucia", label: "Saint Lucia" },
	{ value: "Saint Martin", label: "Saint Martin" },
	{ value: "Saint Pierre and Miquelon", label: "Saint Pierre & Miquelon" },
	{
		value: "Saint Vincent and the Grenadines",
		label: "Saint Vincent & Grenadines",
	},
	{ value: "Samoa", label: "Samoa" },
	{ value: "San Marino", label: "San Marino" },
	{ value: "Sao Tome and Principe", label: "São Tomé & Príncipe" },
	{ value: "Saudi Arabia", label: "Saudi Arabia" },
	{ value: "Senegal", label: "Senegal" },
	{ value: "Serbia", label: "Serbia" },
	{ value: "Seychelles", label: "Seychelles" },
	{ value: "Sierra Leone", label: "Sierra Leone" },
	{ value: "Singapore", label: "Singapore" },
	{ value: "Sint Maarten", label: "Sint Maarten" },
	{ value: "Slovakia", label: "Slovakia" },
	{ value: "Slovenia", label: "Slovenia" },
	{ value: "Solomon Islands", label: "Solomon Islands" },
	{ value: "Somalia", label: "Somalia" },
	{ value: "South Africa", label: "South Africa" },
	{
		value: "South Georgia and the South Sandwich Islands",
		label: "South Georgia & South Sandwich Islands",
	},
	{ value: "South Sudan", label: "South Sudan" },
	{ value: "Spain", label: "Spain" },
	{ value: "Sri Lanka", label: "Sri Lanka" },
	{ value: "Sudan", label: "Sudan" },
	{ value: "Suriname", label: "Suriname" },
	{ value: "Svalbard and Jan Mayen", label: "Svalbard & Jan Mayen" },
	{ value: "Swaziland", label: "Eswatini" },
	{ value: "Sweden", label: "Sweden" },
	{ value: "Switzerland", label: "Switzerland" },
	{ value: "Syria", label: "Syria" },
	{
		value: "Syria (Syrian Arab Republic, The)",
		label: "Syrian Arab Republic",
	},
	{ value: "Taiwan", label: "Taiwan" },
	{ value: "Tajikistan", label: "Tajikistan" },
	{ value: "Tanzania, United Republic of", label: "Tanzania" },
	{ value: "Thailand", label: "Thailand" },
	{ value: "Timor-leste", label: "Timor-Leste" },
	{ value: "Togo", label: "Togo" },
	{ value: "Tokelau", label: "Tokelau" },
	{ value: "Tonga", label: "Tonga" },
	{ value: "Trinidad and Tobago", label: "Trinidad & Tobago" },
	{ value: "Tunisia", label: "Tunisia" },
	{ value: "Turkey", label: "Turkey" },
	{ value: "Turkmenistan", label: "Turkmenistan" },
	{ value: "Turks and Caicos Islands", label: "Turks & Caicos Islands" },
	{ value: "Tuvalu", label: "Tuvalu" },
	{ value: "Uganda", label: "Uganda" },
	{ value: "Ukraine", label: "Ukraine" },
	{ value: "United Arab Emirates", label: "United Arab Emirates" },
	{ value: "United Kingdom", label: "United Kingdom" },
	{
		value: "United States Minor Outlying Islands",
		label: "U.S. Outlying Islands",
	},
	{ value: "Uruguay", label: "Uruguay" },
	{ value: "Uzbekistan", label: "Uzbekistan" },
	{ value: "Vanuatu", label: "Vanuatu" },
	{ value: "Venezuela", label: "Venezuela" },
	{ value: "Vietnam", label: "Vietnam" },
	{ value: "Virgin Islands, British", label: "British Virgin Islands" },
	{ value: "Wallis and Futuna", label: "Wallis & Futuna" },
	{ value: "Western Sahara", label: "Western Sahara" },
	{ value: "Yemen", label: "Yemen" },
	{ value: "Zambia", label: "Zambia" },
	{ value: "Zimbabwe", label: "Zimbabwe" },
];

export const legalSpouseOptions = [
	{ value: "true", label: "Yes" },
	{ value: "false", label: "No" },
];

export const relationshipTypeOptions = [
	{ value: "CivilUnion", label: "Civil Union" },
	{ value: "DomesticPartnership", label: "Domestic Partnership" },
	{ value: "Other", label: "Other" },
	{
		value: "RegisteredReciprocalBeneficiaryRelationship",
		label: "Registered Reciprocal Beneficiary Relationship",
	},
];

export const stateOptions = [
	{
		value: "AL",
		label: "AL",
	},
	{
		value: "AK",
		label: "AK",
	},
	{
		value: "AZ",
		label: "AZ",
	},
	{
		value: "AR",
		label: "AR",
	},
	{
		value: "CA",
		label: "CA",
	},
	{
		value: "CO",
		label: "CO",
	},
	{
		value: "CT",
		label: "CT",
	},
	{
		value: "DE",
		label: "DE",
	},
	{
		value: "DC",
		label: "DC",
	},
	{
		value: "FL",
		label: "FL",
	},
	{
		value: "GA",
		label: "GA",
	},
	{
		value: "HI",
		label: "HI",
	},
	{
		value: "ID",
		label: "ID",
	},
	{
		value: "IL",
		label: "IL",
	},
	{
		value: "IN",
		label: "IN",
	},
	{
		value: "IA",
		label: "IA",
	},
	{
		value: "KS",
		label: "KS",
	},
	{
		value: "KY",
		label: "KY",
	},
	{
		value: "LA",
		label: "LA",
	},
	{
		value: "ME",
		label: "ME",
	},
	{
		value: "MD",
		label: "MD",
	},
	{
		value: "MA",
		label: "MA",
	},
	{
		value: "MI",
		label: "MI",
	},
	{
		value: "MN",
		label: "MN",
	},
	{
		value: "MS",
		label: "MS",
	},
	{
		value: "MO",
		label: "MO",
	},
	{
		value: "MT",
		label: "MT",
	},
	{
		value: "NE",
		label: "NE",
	},
	{
		value: "NV",
		label: "NV",
	},
	{
		value: "NH",
		label: "NH",
	},
	{
		value: "NJ",
		label: "NJ",
	},
	{
		value: "NM",
		label: "NM",
	},
	{
		value: "NY",
		label: "NY",
	},
	{
		value: "NC",
		label: "NC",
	},
	{
		value: "ND",
		label: "ND",
	},
	{
		value: "OH",
		label: "OH",
	},
	{
		value: "OK",
		label: "OK",
	},
	{
		value: "OR",
		label: "OR",
	},
	{
		value: "PA",
		label: "PA",
	},
	{
		value: "RI",
		label: "RI",
	},
	{
		value: "SC",
		label: "SC",
	},
	{
		value: "SD",
		label: "SD",
	},
	{
		value: "TN",
		label: "TN",
	},
	{
		value: "TX",
		label: "TX",
	},
	{
		value: "UT",
		label: "UT",
	},
	{
		value: "VT",
		label: "VT",
	},
	{
		value: "VA",
		label: "VA",
	},
	{
		value: "WA",
		label: "WA",
	},
	{
		value: "WV",
		label: "WV",
	},
	{
		value: "WI",
		label: "WI",
	},
	{
		value: "WY",
		label: "WY",
	},
];

export const housingExpenseOptions = [
	{ value: "NoPrimaryHousingExpense", label: "None" },
	{ value: "Own", label: "Own" },
	{ value: "Rent", label: "Rent" },
];

export const selfEmployedOptions = [
	{ value: false, label: "Employed" },
	{ value: true, label: "Self-Employed" },
];

export const ownershipShareOptions = [
	{ value: "LessThan25Percent", label: "Less than 25%" },
	{
		value: "GreaterThanOrEqualTo25Percent",
		label: "More than or Equal to 25%",
	},
];

export const ownerOptions = [
	{ value: "Borrower", label: "Borrower" },
	{ value: "CoBorrower", label: "Co Borrower" },
	{ value: "Both", label: "Both" },
];

export const assetTypeOptions = [
	{ value: "CheckingAccount", label: "Checking Account" },
	{ value: "SavingsAccount", label: "Savings Account" },
	{ value: "MoneyMarketFund", label: "Money Market Fund" },
	{ value: "CertificateOfDepositTimeDeposit", label: "Certificate Of Deposit" },
	{ value: "MutualFund", label: "Mutual Fund" },
	{ value: "Stock", label: "Stock" },
	{ value: "StockOptions", label: "Stock Options" },
	{ value: "Bond", label: "Bond" },
	{ value: "RetirementFund", label: "Retirement Funds" },
	{ value: "BridgeLoanNotDeposited", label: "Bridge Loan Not Deposited" },
	{
		value: "IndividualDevelopmentAccount",
		label: "Individual Development Account",
	},
	{ value: "LifeInsurance", label: "Life Insurance" },
	{ value: "TrustAccount", label: "Trust Account" },
];

export const liabilityTypeOptions = [
	{ value: "ChildCare", label: "Child Care" },
	{
		value: "CollectionsJudgmentsAndLiens",
		label: "Collections Judgements And Liens",
	},
	{ value: "HELOC", label: "HELOC" },
	{ value: "Installment", label: "Installment" },
	{ value: "LeasePayment", label: "Lease Payments" },
	{ value: "MortgageLoan", label: "Mortgage" },
	{ value: "Open30DayChargeAccount", label: "Open 30 Days Charge Account" },
	{ value: "Other", label: "Other Liability" },
	{ value: "Revolving", label: "Revolving" },
	{ value: "Taxes", label: "Taxes" },
	{ value: "TaxLien", label: "Tax Lien" },
];

export const realEstateStatusOptions = [
	{ value: "Sold", label: "Sold" },
	{ value: "PendingSale", label: "Pending Sale" },
	{ value: "RetainForRental", label: "Retain" },
];

export const booleanOptions = [
	{ value: true, label: "Yes" },
	{ value: false, label: "No" },
];

export const propertyTypeOptions = [
	{ value: "PrimaryResidence", label: "Primary Residence" },
	{ value: "SecondaryResidence", label: "Second Home" },
	{ value: "Investment", label: "Investment Property" },
];

export const propertyJointOptions = [
	{ value: "Sole", label: "Sole Ownership" },
	{ value: "JointWithSpouse", label: "Joint With Spouse" },
	{ value: "JointWithOtherThanSpouse", label: "Joint With Other Than Spouse" },
];

export const incomeTypeOptions = [
	{ value: "Investor - DSCR", label: "DSCR" },
	{
		value: "Business Bank Stmt: 12 Mos",
		label: "12 Mo. Business Bank Statement",
	},
	{
		value: "Business Bank Stmt: 24 Mos",
		label: "24 Mo. Business Bank Statement",
	},
	{
		value: "Personal Bank Stmt: 12 Mos",
		label: "12 Mo. Personal Bank Statement",
	},
	{
		value: "Personal Bank Stmt: 24 Mos",
		label: "24 Mo. Personal Bank Statement",
	},
	{ value: "Full Doc", label: "Full Documentation" },
	{ value: "Asset Related", label: "Asset Related" },
	{ value: "1 Year Alt Doc", label: "1 Year Alternative Doc" },
	{ value: "2 Year Alt Doc", label: "2 Year Alternative Doc" },
];

export const loanPurposeOptions = [
	{ value: "Purchase", label: "Purchase" },
	{ value: "NoCash-Out Refinance", label: "Rate/Term Refinance" },
	{ value: "Cash-Out Refinance", label: "Cash Out Refinance" },
];

export const YNOptions = [
	{ value: "Y", label: "Yes" },
	{ value: "N", label: "No" },
];

export const reservesOptions = [
	{ value: "3", label: "3" },
	{ value: "6", label: "6" },
	{ value: "9", label: "9" },
	{ value: "12", label: "12" },
	{ value: "18", label: "18" },
	{ value: "24", label: "24" },
];

export const prepaymentPenaltyOptions = [
	{ value: 0, label: "None" },
	{ value: 1, label: "1 Year" },
	{ value: 2, label: "2 Year" },
	{ value: 3, label: "3 Year" },
	{ value: 5, label: "5 Year" },
];

export const occupancyOptions = [
	{ value: "PrimaryResidence", label: "Primary" },
	{ value: "SecondHome", label: "Secondary" },
	{ value: "Investor", label: "Investment" },
];

export const housingTypeOptions = [
	{ value: "None", label: "None" },
	{ value: "Deed-In-Lieu", label: "Deed in Lieu" },
	{ value: "Foreclosure", label: "Foreclosure" },
	{ value: "Modification", label: "Modification" },
	{ value: "Short Sale", label: "Short Sale" },
];

export const housingSeasoningOptions = [
	{ value: "Not Applicable", label: "Not Applicable" },
	{ value: "1 - 12 Months", label: "Last 1 to 12 Months" },
	{ value: "13 - 24 Months", label: "Last 13 to 24 Months" },
	{ value: "25 - 36 Months", label: "Last 25 to 36 Months" },
	{ value: "37 - 48 Months", label: "Last 37 to 48 Months" },
	{ value: "49 - 60 Months", label: "Last 49 to 60 Months" },
	{ value: "More than Five Years Ago", label: "More than 5 Years Ago" },
];

export const bankruptcyTypeOptions = [
	{ value: "None", label: "None" },
	{ value: "Chapter 7", label: "Chapter 7" },
	{ value: "Chapter 11", label: "Chapter 11" },
	{ value: "Chapter 13", label: "Chapter 13" },
];

export const bankruptcyOutcomeOptions = [
	{ value: "Not Applicable", label: "Not Applicable" },
	{ value: "Discharged", label: "Discharged" },
	{ value: "Dismissed", label: "Dismissed" },
	{ value: "Open/Pending", label: "Open or Pending" },
];

export const bankruptcySeasoningOptions = [
	{ value: "Not Applicable", label: "Not Applicable" },
	{ value: "1 - 12 Months", label: "Last 1 to 12 Months" },
	{ value: "13 - 24 Months", label: "Last 13 to 24 Months" },
	{ value: "25 - 36 Months", label: "Last 25 to 36 Months" },
	{ value: "37 - 48 Months", label: "Last 37 to 48 Months" },
	{ value: "49 - 60 Months", label: "Last 49 to 60 Months" },
	{ value: "More than Five Years Ago", label: "More than 5 Years Ago" },
];

export const propertyInfoTypeOptions = [
	{ value: "Attached", label: "Attached" },
	{ value: "Condominium - Warrantable", label: "Warrantable Condo" },
	{ value: "Condominium - Non-Warrantable", label: "Non-Warrantable Condo" },
	{ value: "Detached", label: "Detached" },
	{ value: "PUD", label: "PUD" },
	{ value: "5-8 Unit Residential", label: "5-8 Unit Residential" },
	{ value: "5-8 Unit w/ Mixed Use", label: "5-8 Unit w/ Mixed Use" },
	{ value: "9+ Units Residential", label: "9+ Units Residential" },
	{ value: "9+ Units Mixed Use", label: "9+ Units Mixed Use" },
];

export const vestingMethodOptions = [
	{ value: "Sole Ownership", label: "Sole Ownership" },
	{ value: "Tenancy in Common", label: "Tenancy in Common" },
	{ value: "Tenancy by the Entirety", label: "Tenancy by the Entirety" },
	{
		value: "As Joint Tenants With Right of Survivorship",
		label: "As Joint Tenants With Right of Survivorship",
	},
	{
		value: "A Limited Liability Company",
		label: "A Limited Liability Company",
	},
	{ value: "A Corporation", label: "A Corporation" },
	{ value: "An Inter Vivos Trust", label: "An Inter Vivos Trust" },
	{ value: "A Trust", label: "A Trust" },
];

export const lpcOptions = [
	{ value: "%", label: "LPC in percentage" },
	{ value: "$", label: "LPC in dollars" },
];

export const lpcTypeOptions = [
	{ value: "0.000", label: "0" },
	{ value: "0.125", label: "0.125" },
	{ value: "0.250", label: "0.25" },
	{ value: "0.375", label: "0.375" },
	{ value: "0.500", label: "0.5" },
	{ value: "0.625", label: "0.625" },
	{ value: "0.750", label: "0.75" },
	{ value: "0.875", label: "0.875" },
	{ value: "1.000", label: "1.0" },
	{ value: "1.125", label: "1.125" },
	{ value: "1.250", label: "1.25" },
	{ value: "1.375", label: "1.375" },
	{ value: "1.500", label: "1.5" },
	{ value: "1.625", label: "1.625" },
	{ value: "1.750", label: "1.75" },
	{ value: "1.875", label: "1.875" },
	{ value: "2.000", label: "2.0" },
];

export const bpcValueOptions = [
	{ value: "0.000", label: "0" },
	{ value: "0.125", label: "0.125" },
	{ value: "0.250", label: "0.25" },
	{ value: "0.375", label: "0.375" },
	{ value: "0.500", label: "0.5" },
	{ value: "0.625", label: "0.625" },
	{ value: "0.750", label: "0.75" },
	{ value: "0.875", label: "0.875" },
	{ value: "1.000", label: "1.0" },
	{ value: "1.125", label: "1.125" },
	{ value: "1.250", label: "1.25" },
	{ value: "1.375", label: "1.375" },
	{ value: "1.500", label: "1.5" },
	{ value: "1.625", label: "1.625" },
	{ value: "1.750", label: "1.75" },
	{ value: "1.875", label: "1.875" },
	{ value: "2.000", label: "2.0" },
	{ value: "2.125", label: "2.125" },
	{ value: "2.250", label: "2.25" },
	{ value: "2.375", label: "2.375" },
	{ value: "2.500", label: "2.5" },
	{ value: "2.625", label: "2.625" },
	{ value: "2.750", label: "2.75" },
	{ value: "2.875", label: "2.875" },
	{ value: "3.000", label: "3.0" },
];

export const militaryServingOptions = [
	{ value: true, label: "Yes" },
	{ value: false, label: "No" },
];
