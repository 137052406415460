import React, { useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import { DateTime } from "luxon";

// mui components
import { Box, InputLabel, Stack } from "@mui/material";

// custom components
import MaskedInput from "../../custom-input/masked-input";
import OutlinedTextInput from "../../custom-input/outlined-text-input";
import SecondarySelect from "../../custom-select/secondary-select";
import CustomDivider from "../../custom-divider";
import Heading5 from "../../typography/heading-05";
import Heading6 from "../../typography/heading-06";
import Body3 from "../../typography/body-03";

// mui icons
import { ErrorOutlined } from "@mui/icons-material";

// helper utils
import {
	maritalStatusOptions,
	citizenshipTypeOptions,
	countryOptions,
	legalSpouseOptions,
	relationshipTypeOptions,
} from "../../../utils/select-options";

// custom styles
import styles from "./index.module.scss";
import "./datepicker.scss";

const BorrowerInformation = ({
	borrowerIndex,
	coBorrower,
	borrowerData,
	data,
	setData,
	disabled,
	error,
}) => {
	const [birthDate, setBirthDate] = useState("");
	const [ssnNumber, setSsnNumber] = useState("");
	const [homePhone, setHomePhone] = useState("");
	const [workPhone, setWorkPhone] = useState("");
	const [cellPhone, setCellPhone] = useState("");

	useEffect(() => {
		let number = data[borrowerIndex]?.taxIdentificationIdentifier;
		number = number?.replace(/\D/g, "");
		number = number?.replace(/(\d{3})/, "$1-");
		number = number?.replace(/-(\d{2})/, "-$1-");
		number = number?.replace(/(\d)-(\d{4}).*/, "$1-$2");

		setSsnNumber(number);
		setHomePhone(data[borrowerIndex]?.homePhoneNumber);
		setWorkPhone(data[borrowerIndex]?.workPhoneNumber);
		setCellPhone(data[borrowerIndex]?.mobilePhone);
	}, [data]);

	const handleSSN = (value) => {
		console.log("40 val:", value);
		setSsnNumber(value);

		console.log("45 data:", data);

		if (value !== "") {
			setData(
				data.map((data, index) => {
					if (borrowerIndex === index) {
						return { ...data, taxIdentificationIdentifier: value };
					} else {
						return data;
					}
				})
			);
		}
	};

	const handleHomePhone = (value) => {
		setHomePhone(value);
		setData(
			data.map((data, index) => {
				if (borrowerIndex === index) {
					return { ...data, homePhoneNumber: value };
				} else {
					return data;
				}
			})
		);
	};

	const handleWorkPhone = (value) => {
		setWorkPhone(value);
		setData(
			data.map((data, index) => {
				if (borrowerIndex === index) {
					return { ...data, workPhoneNumber: value };
				} else {
					return data;
				}
			})
		);
	};

	const handleCellPhone = (value) => {
		setCellPhone(value);
		setData(
			data.map((data, index) => {
				if (borrowerIndex === index) {
					return { ...data, mobilePhone: value };
				} else {
					return data;
				}
			})
		);
	};

	const handleBirthDate = (dateValue) => {
		console.log("100 date:", dateValue);

		let formattedDate = DateTime.fromJSDate(dateValue).toFormat("yyyy-MM-dd");
		console.log("100 formatted date:", formattedDate);
		console.log("100 date match:", formattedDate === "Invalid DateTime");

		setBirthDate(dateValue);
		setData(
			data.map((data, index) => {
				if (borrowerIndex === index) {
					if (formattedDate === "Invalid DateTime") {
						return data;
					} else {
						return { ...data, birthDate: formattedDate };
					}
				} else {
					return data;
				}
			})
		);
	};

	useEffect(() => {
		if (coBorrower) {
			setData(
				data.map((data, index) => {
					if (borrowerIndex === index) {
						return { ...data, hmdaNoCoApplicantIndicator: false };
					} else {
						return data;
					}
				})
			);
		}
	}, [coBorrower]);

	useEffect(() => {
		console.log("130 borrowerData birthDate:", borrowerData?.birthDate);
		if (borrowerData?.birthDate !== undefined) {
			let formattedDate = DateTime.fromISO(borrowerData?.birthDate, {
				zone: "utc",
			}).toFormat("yyyy-MM-dd");

			console.log("135 formatted date:", formattedDate);

			const isValidDate = (dateObject) =>
				new Date(dateObject).toString() !== "Invalid Date";

			if (isValidDate(formattedDate)) {
				console.log("135 isValid:", formattedDate);
				setBirthDate(formattedDate);
			} else {
				let newMonth = borrowerData?.birthDate?.slice(0, 2);
				let newDate = borrowerData?.birthDate?.slice(3, 5);
				let newYear = borrowerData?.birthDate?.slice(6, 11);

				console.log("150 month:", newMonth);
				console.log("150 date:", newDate);
				console.log("150 year:", newYear);

				let formattedNewDate = new Date(newYear, newMonth - 1, newDate);
				console.log("150 formatted New date:", formattedNewDate);

				const isValidNewDate = (dateObject) =>
					new Date(dateObject).toString() !== "Invalid Date";

				if (isValidNewDate(formattedNewDate)) {
					setBirthDate(formattedNewDate);
				} else {
					setBirthDate(null);
				}
			}
		}
	}, [borrowerData]);

	return (
		<Box className={styles.cardContainer}>
			<Stack direction="column" className={styles.cardHeaderContainer}>
				<Heading5
					text={coBorrower ? "Co Borrower Information" : "Borrower Information"}
					fontType="semibold"
				/>

				<Body3 text="Provide details about the borrower" />
			</Stack>
			<Stack direction="column" className={styles.formSectionContainer}>
				{/* basic information */}
				<Stack direction="row" className={styles.inputSectionContainer}>
					{/* first name */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor="outlined-input-first-name">
							<Heading6
								text="First Name"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<OutlinedTextInput
							type="text"
							id="outlined-input-first-name"
							placeholder="First Name"
							value={data[borrowerIndex]?.firstName}
							handleChange={(event) =>
								setData(
									data.map((data, index) => {
										if (borrowerIndex === index) {
											return { ...data, firstName: event.target.value };
										} else {
											return data;
										}
									})
								)
							}
							disabled={disabled}
							error={
								!coBorrower &&
								error &&
								(data[borrowerIndex]?.firstName === undefined ||
									data[borrowerIndex]?.firstName === "")
							}
							showEndIcon={
								!coBorrower &&
								error &&
								(data[borrowerIndex]?.firstName === undefined ||
									data[borrowerIndex]?.firstName === "")
							}
							endIcon={<ErrorOutlined className={styles.errorIcon} />}
						/>
					</Stack>
					{/* middle name */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor="outlined-input-middle-name">
							<Heading6
								text="Middle Name"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<OutlinedTextInput
							type="text"
							id="outlined-input-middle-name"
							placeholder="Middle Name"
							value={data[borrowerIndex]?.middleName}
							handleChange={(event) =>
								setData(
									data.map((data, index) => {
										if (borrowerIndex === index) {
											return { ...data, middleName: event.target.value };
										} else {
											return data;
										}
									})
								)
							}
							disabled={disabled}
						/>
					</Stack>

					{/* last name */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor="outlined-input-last-name">
							<Heading6
								text="Last Name"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<OutlinedTextInput
							type="text"
							id="outlined-input-last-name"
							placeholder="Last Name"
							value={data[borrowerIndex]?.lastName}
							handleChange={(event) =>
								setData(
									data.map((data, index) => {
										if (borrowerIndex === index) {
											return { ...data, lastName: event.target.value };
										} else {
											return data;
										}
									})
								)
							}
							disabled={disabled}
							error={
								error &&
								(coBorrower
									? data[borrowerIndex]?.firstName !== undefined &&
									  data[borrowerIndex]?.firstName !== "" &&
									  (data[borrowerIndex]?.lastName === undefined ||
											data[borrowerIndex]?.lastName === "")
									: data[borrowerIndex]?.lastName === undefined ||
									  data[borrowerIndex]?.lastName === "")
							}
							showEndIcon={
								error &&
								(coBorrower
									? data[borrowerIndex]?.firstName !== undefined &&
									  data[borrowerIndex]?.firstName !== "" &&
									  (data[borrowerIndex]?.lastName === undefined ||
											data[borrowerIndex]?.lastName === "")
									: data[borrowerIndex]?.lastName === undefined ||
									  data[borrowerIndex]?.lastName === "")
							}
							endIcon={<ErrorOutlined className={styles.errorIcon} />}
						/>
					</Stack>

					{/* date of birth */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor="birth-date-picker">
							<Heading6
								text="Date of Birth"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<DatePicker
							id="birth-date-picker"
							className={
								disabled
									? styles.datepicker + " " + styles.disabledDatepicker
									: styles.datepicker
							}
							format="MM-dd-yyyy"
							value={
								birthDate !== undefined &&
								birthDate !== "" &&
								typeof birthDate === "string"
									? new Date(birthDate)
									: birthDate
							}
							onChange={handleBirthDate}
							disabled={disabled}
							maxDate={new Date()}
						/>
					</Stack>

					{/* marital status */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor="select-marital-status">
							<Heading6
								text="Marital Status"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<SecondarySelect
							id="select-marital-status"
							displayEmpty={true}
							value={data[borrowerIndex]?.maritalStatusType || ""}
							handleChange={(event) =>
								setData(
									data.map((data, index) => {
										if (borrowerIndex === index) {
											return {
												...data,
												maritalStatusType: event.target.value,
											};
										} else {
											return data;
										}
									})
								)
							}
							options={maritalStatusOptions}
							disabled={disabled}
							error={
								error &&
								(coBorrower
									? data[borrowerIndex]?.firstName !== undefined &&
									  data[borrowerIndex]?.firstName !== "" &&
									  (data[borrowerIndex]?.maritalStatusType === undefined ||
											data[borrowerIndex]?.maritalStatusType === "")
									: data[borrowerIndex]?.maritalStatusType === undefined ||
									  data[borrowerIndex]?.maritalStatusType === "")
							}
						/>
					</Stack>

					{/* legal spouse */}
					{data[borrowerIndex]?.maritalStatusType === "Unmarried" && (
						<Stack direction="column" className={styles.inputContainer}>
							<InputLabel htmlFor="select-legal-spouse">
								<Heading6
									text="Is there a person who is not your legal spouse but who currently has
							real property rights similar to those of a legal spouse?"
									fontType="semibold"
									extraClass={styles.inputHeader}
								/>
							</InputLabel>

							<SecondarySelect
								id="select-legal-spouse"
								displayEmpty={true}
								value={
									data[borrowerIndex]?.legalOtherThanSpouse?.toString() || ""
								}
								handleChange={(event) =>
									setData(
										data.map((data, index) => {
											if (borrowerIndex === index) {
												return {
													...data,
													legalOtherThanSpouse:
														event.target.value === "true" ? true : false,
												};
											} else {
												return data;
											}
										})
									)
								}
								options={legalSpouseOptions}
								disabled={disabled}
							/>
						</Stack>
					)}

					{/* relationship type */}
					{data[borrowerIndex]?.maritalStatusType === "Unmarried" && (
						<Stack direction="column" className={styles.inputContainer}>
							<InputLabel htmlFor="select-relationship-type">
								<Heading6
									text="Domestic Relationship Type"
									fontType="semibold"
									extraClass={styles.inputHeader}
								/>
							</InputLabel>

							<SecondarySelect
								id="select-relationship-type"
								displayEmpty={true}
								value={data[borrowerIndex]?.domesticRelationshipType || ""}
								handleChange={(event) =>
									setData(
										data.map((data, index) => {
											if (borrowerIndex === index) {
												return {
													...data,
													domesticRelationshipType: event.target.value,
												};
											} else {
												return data;
											}
										})
									)
								}
								options={relationshipTypeOptions}
								disabled={disabled}
							/>
						</Stack>
					)}

					{/* citizenship type */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor="select-citizenship-type">
							<Heading6
								text="Citizenship"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<SecondarySelect
							id="select-citizenship-type"
							displayEmpty={true}
							value={
								data[borrowerIndex]?.urla2020CitizenshipResidencyType || ""
							}
							handleChange={(event) =>
								setData(
									data.map((data, index) => {
										if (borrowerIndex === index) {
											if (
												data[borrowerIndex]
													?.urla2020CitizenshipResidencyType ===
													"NonPermanentResidentAlien" ||
												data[borrowerIndex]
													?.urla2020CitizenshipResidencyType ===
													"Foreign National"
											) {
												return {
													...data,
													urla2020CitizenshipResidencyType: event.target.value,
												};
											} else {
												return {
													...data,
													urla2020CitizenshipResidencyType: event.target.value,
													urla2020CountryOfCitizenship: "",
												};
											}
										} else {
											return data;
										}
									})
								)
							}
							options={citizenshipTypeOptions}
							disabled={disabled}
							error={
								error &&
								(coBorrower
									? data[borrowerIndex]?.firstName !== undefined &&
									  data[borrowerIndex]?.firstName !== "" &&
									  (data[borrowerIndex]?.urla2020CitizenshipResidencyType ===
											undefined ||
											data[borrowerIndex]?.urla2020CitizenshipResidencyType ===
												"")
									: data[borrowerIndex]?.urla2020CitizenshipResidencyType ===
											undefined ||
									  data[borrowerIndex]?.urla2020CitizenshipResidencyType ===
											"")
							}
						/>
					</Stack>

					{/* citizenship country */}
					{(data[borrowerIndex]?.urla2020CitizenshipResidencyType ===
						"NonPermanentResidentAlien" ||
						data[borrowerIndex]?.urla2020CitizenshipResidencyType ===
							"Foreign National") && (
						<Stack direction="column" className={styles.inputContainer}>
							<InputLabel htmlFor="select-citizenship-country">
								<Heading6
									text="Country of Citizenship"
									fontType="semibold"
									extraClass={styles.inputHeader}
								/>
							</InputLabel>

							<SecondarySelect
								id="select-citizenship-country"
								displayEmpty={true}
								value={data[borrowerIndex]?.urla2020CountryOfCitizenship || ""}
								handleChange={(event) =>
									setData(
										data.map((data, index) => {
											if (borrowerIndex === index) {
												return {
													...data,
													urla2020CountryOfCitizenship: event.target.value,
												};
											} else {
												return data;
											}
										})
									)
								}
								options={countryOptions}
								disabled={disabled}
								error={
									error &&
									(coBorrower
										? data[borrowerIndex]?.firstName !== undefined &&
										  data[borrowerIndex]?.firstName !== "" &&
										  (data[borrowerIndex]?.urla2020CountryOfCitizenship ===
												undefined ||
												data[borrowerIndex]?.urla2020CountryOfCitizenship ===
													"")
										: data[borrowerIndex]?.urla2020CountryOfCitizenship ===
												undefined ||
										  data[borrowerIndex]?.urla2020CountryOfCitizenship === "")
								}
							/>
						</Stack>
					)}

					{/* ssn */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor="masked-input-ssn">
							<Heading6
								text="SSN"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<MaskedInput
							id="masked-input-ssn"
							style={{
								border:
									error &&
									(coBorrower
										? data[borrowerIndex]?.firstName !== undefined &&
										  data[borrowerIndex]?.firstName !== "" &&
										  (data[borrowerIndex]?.taxIdentificationIdentifier ===
												undefined ||
												data[borrowerIndex]?.taxIdentificationIdentifier ===
													"") &&
										  "3px solid #f00"
										: (data[borrowerIndex]?.taxIdentificationIdentifier ===
												undefined ||
												data[borrowerIndex]?.taxIdentificationIdentifier ===
													"") &&
										  "3px solid #f00"),
							}}
							type="ssn"
							placeholder="___-___-___"
							value={ssnNumber || ""}
							onInputChange={handleSSN}
							disabled={disabled}
						/>
					</Stack>
				</Stack>

				{/* contact information */}
				<Stack direction="row" className={styles.inputSectionContainer}>
					{/* cell phone */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor="masked-cell-phone">
							<Heading6
								text="Cell Phone"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<MaskedInput
							id="masked-cell-phone"
							placeholder="[XXX] XXX-XXXX"
							style={{
								border:
									error &&
									(coBorrower
										? data[borrowerIndex]?.firstName !== undefined &&
										  data[borrowerIndex]?.firstName !== "" &&
										  (data[borrowerIndex]?.homePhoneNumber === undefined ||
												data[borrowerIndex]?.homePhoneNumber === "") &&
										  (data[borrowerIndex]?.workPhoneNumber === undefined ||
												data[borrowerIndex]?.workPhoneNumber === "") &&
										  (data[borrowerIndex]?.mobilePhone === undefined ||
												data[borrowerIndex]?.mobilePhone === "") &&
										  "3px solid #f00"
										: (data[borrowerIndex]?.homePhoneNumber === undefined ||
												data[borrowerIndex]?.homePhoneNumber === "") &&
										  (data[borrowerIndex]?.workPhoneNumber === undefined ||
												data[borrowerIndex]?.workPhoneNumber === "") &&
										  (data[borrowerIndex]?.mobilePhone === undefined ||
												data[borrowerIndex]?.mobilePhone === "") &&
										  "3px solid #f00"),
							}}
							type="phone"
							value={cellPhone}
							onInputChange={handleCellPhone}
							disabled={disabled}
						/>
					</Stack>
					{/* work phone */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor="masked-work-phone">
							<Heading6
								text="Work Phone"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<MaskedInput
							id="masked-work-phone"
							placeholder="[XXX] XXX-XXXX"
							style={{
								border:
									error &&
									(coBorrower
										? data[borrowerIndex]?.firstName !== undefined &&
										  data[borrowerIndex]?.firstName !== "" &&
										  (data[borrowerIndex]?.homePhoneNumber === undefined ||
												data[borrowerIndex]?.homePhoneNumber === "") &&
										  (data[borrowerIndex]?.workPhoneNumber === undefined ||
												data[borrowerIndex]?.workPhoneNumber === "") &&
										  (data[borrowerIndex]?.mobilePhone === undefined ||
												data[borrowerIndex]?.mobilePhone === "") &&
										  "3px solid #f00"
										: (data[borrowerIndex]?.homePhoneNumber === undefined ||
												data[borrowerIndex]?.homePhoneNumber === "") &&
										  (data[borrowerIndex]?.workPhoneNumber === undefined ||
												data[borrowerIndex]?.workPhoneNumber === "") &&
										  (data[borrowerIndex]?.mobilePhone === undefined ||
												data[borrowerIndex]?.mobilePhone === "") &&
										  "3px solid #f00"),
							}}
							type="phone"
							value={workPhone}
							onInputChange={handleWorkPhone}
							disabled={disabled}
						/>
					</Stack>

					{/* home phone */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor="masked-home-phone">
							<Heading6
								text="Home Phone"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<MaskedInput
							id="masked-home-phone"
							placeholder="[XXX] XXX-XXXX"
							style={{
								border:
									error &&
									(coBorrower
										? data[borrowerIndex]?.firstName !== undefined &&
										  data[borrowerIndex]?.firstName !== "" &&
										  (data[borrowerIndex]?.homePhoneNumber === undefined ||
												data[borrowerIndex]?.homePhoneNumber === "") &&
										  (data[borrowerIndex]?.workPhoneNumber === undefined ||
												data[borrowerIndex]?.workPhoneNumber === "") &&
										  (data[borrowerIndex]?.mobilePhone === undefined ||
												data[borrowerIndex]?.mobilePhone === "") &&
										  "3px solid #f00"
										: (data[borrowerIndex]?.homePhoneNumber === undefined ||
												data[borrowerIndex]?.homePhoneNumber === "") &&
										  (data[borrowerIndex]?.workPhoneNumber === undefined ||
												data[borrowerIndex]?.workPhoneNumber === "") &&
										  (data[borrowerIndex]?.mobilePhone === undefined ||
												data[borrowerIndex]?.mobilePhone === "") &&
										  "3px solid #f00"),
							}}
							type="phone"
							value={homePhone}
							onInputChange={handleHomePhone}
							disabled={disabled}
						/>
					</Stack>

					{/* email */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor="outlined-input-email">
							<Heading6
								text="Email"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<OutlinedTextInput
							type="text"
							id="outlined-input-email"
							placeholder="Email"
							value={data[borrowerIndex]?.emailAddressText}
							handleChange={(event) =>
								setData(
									data.map((data, index) => {
										if (borrowerIndex === index) {
											return {
												...data,
												emailAddressText: event.target.value,
											};
										} else {
											return data;
										}
									})
								)
							}
							disabled={disabled}
							error={
								error &&
								(coBorrower
									? data[borrowerIndex]?.firstName !== undefined &&
									  data[borrowerIndex]?.firstName !== "" &&
									  (data[borrowerIndex]?.emailAddressText === undefined ||
											data[borrowerIndex]?.emailAddressText === "")
									: data[borrowerIndex]?.emailAddressText === undefined ||
									  data[borrowerIndex]?.emailAddressText === "")
							}
							showEndIcon={
								error &&
								(coBorrower
									? data[borrowerIndex]?.firstName !== undefined &&
									  data[borrowerIndex]?.firstName !== "" &&
									  (data[borrowerIndex]?.emailAddressText === undefined ||
											data[borrowerIndex]?.emailAddressText === "")
									: data[borrowerIndex]?.emailAddressText === undefined ||
									  data[borrowerIndex]?.emailAddressText === "")
							}
							endIcon={<ErrorOutlined className={styles.errorIcon} />}
						/>
					</Stack>
				</Stack>
				{/* dependent information */}
				<Stack direction="row" className={styles.inputSectionContainer}>
					{/* dependants */}
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.inlineContainer}
					>
						<InputLabel htmlFor="outlined-input-dependants">
							<Heading6
								text="Dependants"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<OutlinedTextInput
							id="outlined-input-dependants"
							type="number"
							fullWidth={true}
							value={data[borrowerIndex]?.dependentCount || 0}
							handleChange={(event) =>
								setData(
									data.map((data, index) => {
										if (borrowerIndex === index) {
											return {
												...data,
												dependentCount: event.target.value,
											};
										} else {
											return data;
										}
									})
								)
							}
							disabled={disabled}
						/>
					</Stack>

					{/* dependants ages */}
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.inlineContainer}
					>
						<InputLabel htmlFor="outlined-input-dependants-ages">
							<Heading6
								text="Dependants Ages"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<OutlinedTextInput
							id="outlined-input-dependants-ages"
							type="text"
							placeholder="Ages"
							fullWidth={true}
							value={data[borrowerIndex]?.dependentsAgesDescription}
							handleChange={(event) =>
								setData(
									data.map((data, index) => {
										if (borrowerIndex === index) {
											return {
												...data,
												dependentsAgesDescription: event.target.value,
											};
										} else {
											return data;
										}
									})
								)
							}
							disabled={disabled}
						/>
					</Stack>
				</Stack>
			</Stack>

			<CustomDivider />
		</Box>
	);
};

export default BorrowerInformation;
