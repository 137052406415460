import React from "react";
import { Checkbox, FormControlLabel, Stack } from "@mui/material";

import styles from "./index.module.scss";
import Body2 from "../typography/body-02";
import Body3 from "../typography/body-03";

const CustomCheckbox = ({
	checked,
	handleChange,
	label,
	subLabel,
	disabled,
	extraClass,
}) => {
	const customClass = `${styles.checkboxContainer} ${extraClass}`;

	return (
		<FormControlLabel
			control={
				<Checkbox
					checked={checked}
					onChange={handleChange}
					inputProps={{ "aria-label": "controlled" }}
					className={styles.checkbox}
				/>
			}
			label={
				subLabel !== undefined && subLabel !== "" ? (
					<Stack direction="row" className={styles.labelStackContainer}>
						<Body2 text={label} extraClass={styles.checkboxLabel} />
						<Body3 text={subLabel} extraClass={styles.checkboxLabel} />
					</Stack>
				) : (
					<Body2 text={label} extraClass={styles.checkboxLabel} />
				)
			}
			className={customClass}
			disabled={disabled}
		/>
	);
};

export default CustomCheckbox;
