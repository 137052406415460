import React from "react";
import { Typography } from "@mui/material";

import styles from "./index.module.scss";

const Heading4 = ({ text, extraClass, error, fontType }) => {
	const customClass = `${styles.heading4} ${extraClass} ${
		error ? styles.error : ""
	} ${fontType === "semibold" && styles.semibold} `;

	return <Typography className={customClass}>{text}</Typography>;
};

export default Heading4;
