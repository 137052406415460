import React from "react";
import { useDispatch, useSelector } from "react-redux";

// mui components
import { Box, InputLabel, Stack } from "@mui/material";

// custom components
import OutlinedTextInput from "../../custom-input/outlined-text-input";
import MaskedInput from "../../custom-input/masked-input";
import SecondarySelect from "../../custom-select/secondary-select";
import CustomCheckbox from "../../custom-checkbox";
import CustomRadio from "../../custom-radio";
import CustomDivider from "../../custom-divider";
import Heading5 from "../../typography/heading-05";
import Heading6 from "../../typography/heading-06";
import Body2 from "../../typography/body-02";
import Body3 from "../../typography/body-03";

// helper utils
import {
	countryOptions,
	housingExpenseOptions,
	stateOptions,
} from "../../../utils/select-options";

// reducer slice
import {
	selectSameAsCurrentAddress,
	setSameAsCurrentAddressReducer,
} from "../../../features/same-as-current-address/sameAsCurrentAddressSlice";

// custom styles
import styles from "./index.module.scss";

const ContactInformation = ({
	residenceIndex,
	coBorrower,
	residenceData,
	data,
	setData,
	sameAddress,
	setSameAddress,
	addressType,
	disabled,
}) => {
	const dispatch = useDispatch();
	const sameAddressValue = useSelector(selectSameAsCurrentAddress);

	console.log("55 sameAddress:", sameAddress);
	console.log("55 same Address Value:", sameAddressValue);

	const handleChangeTermMonths = (event) => {
		const min = 0;
		const max = 12;

		const value = Math.max(min, Math.min(max, Number(event)));

		setData(
			data.map((data, index) => {
				if (residenceIndex === index) {
					return { ...data, durationTermMonths: value };
				} else {
					return data;
				}
			})
		);
	};

	const handleRentAmount = (value) => {
		setData(
			data.map((data, index) => {
				if (residenceIndex === index) {
					return { ...data, rent: value };
				} else {
					return data;
				}
			})
		);
	};

	const handleSameSwitch = (status) => {
		console.log("55 res index:", residenceIndex);

		if (status === true) {
			setSameAddress((prevArr) => [...prevArr, residenceIndex - 1]);

			let sameAddressArr = [];

			console.log("70 sameAddressValue:", sameAddressValue);

			if (
				sameAddressValue !== null &&
				sameAddressValue !== undefined &&
				sameAddressValue?.length > 0
			) {
				sameAddressArr.push(...sameAddressValue, residenceIndex);
			} else {
				sameAddressArr.push(residenceIndex);
			}

			console.log("65 same address arr add:", sameAddressArr);

			dispatch(setSameAsCurrentAddressReducer(sameAddressArr));

			setData(
				data.map((item, index) => {
					if (residenceIndex === index) {
						let updatedItem = {
							id: item.id,
							uRLA2020StreetAddress: data[index - 1]?.uRLA2020StreetAddress,
							addressUnitIdentifier: data[index - 1]?.addressUnitIdentifier,
							addressCity: data[index - 1]?.addressCity,
							addressState: data[index - 1]?.addressState,
							countryCode: data[index - 1]?.countryCode,
							addressPostalCode: data[index - 1]?.addressPostalCode,
							foreignAddress: data[index - 1]?.foreignAddress,
							foreignCountry: data[index - 1]?.foreignCountry,
							applicantType: data[index - 1]?.applicantType,
							mailingAddressIndicator: true,
							sameAddress: true,
							mailingAddressSameAsPresentIndicator: true,
						};

						return updatedItem;
					} else {
						return item;
					}
				})
			);
		} else {
			setSameAddress(
				sameAddress?.filter((item) => item !== residenceIndex - 1)
			);

			let sameAddressArr = sameAddressValue?.filter(
				(value) => value !== residenceIndex
			);
			console.log("115 same address arr remove:", sameAddressArr);

			dispatch(setSameAsCurrentAddressReducer(sameAddressArr));

			setData(
				data.map((item, index) => {
					if (residenceIndex === index) {
						let updatedItem = {
							id: item.id,
							uRLA2020StreetAddress: "",
							addressUnitIdentifier: "",
							addressCity: "",
							addressState: "",
							countryCode: "",
							addressPostalCode: "",
							foreignAddress: "",
							foreignCountry: "",
							applicantType: data[index - 1]?.applicantType,
							mailingAddressIndicator: true,
							sameAddress: false,
							mailingAddressSameAsPresentIndicator: false,
						};

						return updatedItem;
					} else {
						return item;
					}
				})
			);
		}
	};

	const handleUpdateMail = (fieldKey, fieldValue) => {
		setData(
			data.map((data, index) => {
				if (residenceIndex === index) {
					return { ...data, fieldKey: fieldValue };
				} else {
					return data;
				}
			})
		);
	};

	const headingText = `
  ${addressType === "mail" ? "Mailing " : "Current "}address -${
		coBorrower ? " Co Borrower" : " Borrower"
	}`;

	console.log("190 contact info data:", data);

	return (
		<Box className={styles.cardContainer}>
			<Stack direction="column" className={styles.cardHeaderContainer}>
				<Heading5 text={headingText} fontType="semibold" />
				<Body3
					text={`Provide details about the ${
						coBorrower ? " Co Borrower" : " Borrower"
					}`}
				/>
			</Stack>
			<Stack direction="column" className={styles.formSectionContainer}>
				{/* basic information */}
				<Stack direction="row" className={styles.inputSectionContainer}>
					{/* same as current address */}
					{addressType === "mail" && (
						<Stack
							direction="column"
							className={
								styles.inputContainer + " " + styles.fullWidthContainer
							}
						>
							{
								(console.log("205 res data:", residenceData),
								console.log(
									"205 res data same address:",
									residenceData?.sameAddress
								),
								console.log("205 sameAddress:", sameAddress),
								console.log("205 residenceIndex:", residenceIndex),
								console.log(
									"205 same address include:",
									sameAddress?.includes(residenceIndex - 1)
								))
							}
							<CustomCheckbox
								checked={
									residenceData?.sameAddress ||
									sameAddress?.includes(residenceIndex - 1) ||
									false
								}
								handleChange={() =>
									handleSameSwitch(!sameAddress?.includes(residenceIndex - 1))
								}
								label="Same as current information"
								disabled={disabled}
							/>
						</Stack>
					)}

					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.fullWidthContainer}
					>
						<CustomCheckbox
							checked={residenceData?.foreignAddress || false}
							handleChange={() =>
								setData(
									data.map((data, index) => {
										if (residenceIndex === index) {
											handleUpdateMail(
												"foreignAddress",
												!residenceData?.foreignAddress
											);
											return {
												...data,
												foreignAddress: !residenceData?.foreignAddress,
											};
										} else {
											return data;
										}
									})
								)
							}
							label="Foreign Address"
							disabled={disabled || sameAddress?.includes(residenceIndex - 1)}
						/>
					</Stack>

					<Stack direction="row" className={styles.inputSectionContainer}>
						{/* Address Line 1 */}
						<Stack
							direction="column"
							className={
								styles.inputContainer + " " + styles.fullWidthContainer
							}
						>
							<InputLabel htmlFor="outlined-input-address-line">
								<Heading6
									text="Address Line 1"
									fontType="semibold"
									extraClass={styles.inputHeader}
								/>
							</InputLabel>

							<OutlinedTextInput
								id="outlined-input-address-line"
								type="text"
								placeholder="Street Line 1"
								fullWidth={true}
								value={residenceData?.uRLA2020StreetAddress}
								handleChange={(event) =>
									setData(
										data.map((data, index) => {
											if (residenceIndex === index) {
												handleUpdateMail(
													"uRLA2020StreetAddress",
													event.target.value
												);
												return {
													...data,
													uRLA2020StreetAddress: event.target.value,
												};
											} else {
												return data;
											}
										})
									)
								}
								disabled={disabled || sameAddress?.includes(residenceIndex - 1)}
							/>
						</Stack>

						{/* Address Line 2 */}
						<Stack
							direction="column"
							className={
								styles.inputContainer + " " + styles.fullWidthContainer
							}
						>
							<InputLabel htmlFor="outlined-input-address-line">
								<Heading6
									text="Address Line 2 / Unit Number"
									fontType="semibold"
									extraClass={styles.inputHeader}
								/>
							</InputLabel>

							<OutlinedTextInput
								id="outlined-input-address-line"
								type="text"
								placeholder="Unit Number"
								fullWidth={true}
								value={residenceData?.addressUnitIdentifier}
								handleChange={(event) =>
									setData(
										data.map((data, index) => {
											if (residenceIndex === index) {
												handleUpdateMail(
													"addressUnitIdentifier",
													event.target.value
												);
												return {
													...data,
													addressUnitIdentifier: event.target.value,
												};
											} else {
												return data;
											}
										})
									)
								}
								disabled={disabled || sameAddress?.includes(residenceIndex - 1)}
							/>
						</Stack>

						{/* city */}
						<Stack direction="column" className={styles.inputContainer}>
							<InputLabel htmlFor="outlined-input-city">
								<Heading6
									text="City"
									fontType="semibold"
									extraClass={styles.inputHeader}
								/>
							</InputLabel>

							<OutlinedTextInput
								id="outlined-input-city"
								type="text"
								placeholder="City"
								value={residenceData?.addressCity}
								handleChange={(event) =>
									setData(
										data.map((data, index) => {
											if (residenceIndex === index) {
												handleUpdateMail("addressCity", event.target.value);
												return {
													...data,
													addressCity: event.target.value,
												};
											} else {
												return data;
											}
										})
									)
								}
								disabled={disabled || sameAddress?.includes(residenceIndex - 1)}
							/>
						</Stack>

						{/* state */}
						<Stack direction="column" className={styles.inputContainer}>
							<InputLabel htmlFor="select-state">
								<Heading6
									text={residenceData?.foreignAddress ? "Province" : "State"}
									fontType="semibold"
									extraClass={styles.inputHeader}
								/>
							</InputLabel>

							{residenceData?.foreignAddress ? (
								<OutlinedTextInput
									id="select-state"
									type="text"
									placeholder="Province"
									value={residenceData?.addressState}
									handleChange={(event) =>
										setData(
											data.map((data, index) => {
												if (residenceIndex === index) {
													handleUpdateMail("addressState", event.target.value);
													return {
														...data,
														addressState: event.target.value,
													};
												} else {
													return data;
												}
											})
										)
									}
									disabled={
										disabled || sameAddress?.includes(residenceIndex - 1)
									}
								/>
							) : (
								<SecondarySelect
									id="select-state"
									displayEmpty={true}
									value={residenceData?.addressState || ""}
									handleChange={(event) =>
										setData(
											data.map((data, index) => {
												if (residenceIndex === index) {
													handleUpdateMail("addressState", event.target.value);
													return {
														...data,
														addressState: event.target.value,
													};
												} else {
													return data;
												}
											})
										)
									}
									options={stateOptions}
									disabled={
										disabled || sameAddress?.includes(residenceIndex - 1)
									}
								/>
							)}
						</Stack>

						{/* postal code */}
						<Stack direction="column" className={styles.inputContainer}>
							<InputLabel htmlFor="outlined-input-postal-code">
								<Heading6
									text={
										residenceData?.foreignAddress ? "Postal Code" : "Zip Code"
									}
									fontType="semibold"
									extraClass={styles.inputHeader}
								/>
							</InputLabel>

							<OutlinedTextInput
								id="outlined-input-postal-code"
								type="text"
								placeholder="123456"
								value={residenceData?.addressPostalCode}
								handleChange={(event) =>
									setData(
										data.map((data, index) => {
											if (residenceIndex === index) {
												handleUpdateMail(
													"addressPostalCode",
													event.target.value
												);
												return {
													...data,
													addressPostalCode: event.target.value,
												};
											} else {
												return data;
											}
										})
									)
								}
								disabled={disabled || sameAddress?.includes(residenceIndex - 1)}
							/>
						</Stack>
					</Stack>

					{residenceData?.foreignAddress && (
						<Stack direction="column" className={styles.inputContainer}>
							<SecondarySelect
								id="select-citizenship-country"
								displayEmpty={true}
								value={residenceData?.foreignCountry}
								handleChange={(event) =>
									setData(
										data.map((data, index) => {
											if (residenceIndex === index) {
												handleUpdateMail("foreignCountry", event.target.value);
												return {
													...data,
													foreignCountry: event.target.value,
												};
											} else {
												return data;
											}
										})
									)
								}
								options={countryOptions}
								disabled={disabled || sameAddress?.includes(residenceIndex - 1)}
							/>
						</Stack>
					)}
				</Stack>

				<Stack direction="row" className={styles.inputSectionContainer}>
					{addressType === "current" && (
						<Stack direction="column" className={styles.inputContainer}>
							<CustomRadio
								id="radio-housing-expense"
								value={residenceData?.residencyBasisType}
								handleChange={(event) =>
									setData(
										data.map((data, index) => {
											if (residenceIndex === index) {
												if (
													residenceData?.residencyBasisType ===
													event.target.value
												) {
													return {
														...data,
														residencyBasisType: "",
													};
												} else {
													return {
														...data,
														residencyBasisType: event.target.value,
													};
												}
											} else {
												return data;
											}
										})
									)
								}
								label="Housing Expenses:"
								disabled={disabled}
								data={housingExpenseOptions}
							/>
						</Stack>
					)}

					{addressType === "current" &&
						residenceData?.residencyBasisType === "Rent" && (
							<Stack
								direction="row"
								alignItems="center"
								className={styles.inputContainer}
							>
								<Body2
									text="Monthly Rent:"
									extraClass={styles.monthlyRentText}
								/>

								<MaskedInput
									id="outlined-input-rental-income"
									type="currency"
									placeholder="Rent ($ / mo.)"
									value={residenceData?.rent?.toString()}
									onInputChange={handleRentAmount}
									disabled={disabled}
								/>
							</Stack>
						)}
				</Stack>

				{addressType === "current" && (
					<Stack direction="row" className={styles.durationContainer}>
						<Body2
							text="Living here for:"
							extraClass={styles.livingDurationHeader}
						/>

						<Stack direction="row" className={styles.durationContainer}>
							{/* duration years */}
							<Stack direction="column" className={styles.inputContainer}>
								<InputLabel htmlFor="outlined-input-units-owned">
									<Heading6
										text="Years"
										fontType="semibold"
										extraClass={styles.inputHeader}
									/>
								</InputLabel>

								<OutlinedTextInput
									id="outlined-input-units-owned"
									placeholder="Years"
									type="number"
									min={0}
									fullWidth={true}
									value={residenceData?.durationTermYears}
									handleChange={(event) =>
										setData(
											data.map((data, index) => {
												if (residenceIndex === index) {
													return {
														...data,
														durationTermYears: event.target.value,
													};
												} else {
													return data;
												}
											})
										)
									}
									disabled={disabled}
								/>
							</Stack>

							{/* duration months */}
							<Stack direction="column" className={styles.inputContainer}>
								<InputLabel htmlFor="outlined-input-units-owned">
									<Heading6
										text="Months"
										fontType="semibold"
										extraClass={styles.inputHeader}
									/>
								</InputLabel>

								<OutlinedTextInput
									id="outlined-input-units-owned"
									placeholder="Months"
									type="number"
									min={0}
									max={12}
									maxLength={2}
									fullWidth={true}
									value={residenceData?.durationTermMonths}
									handleChange={(event) =>
										handleChangeTermMonths(event.target.value)
									}
									disabled={disabled}
								/>
							</Stack>
						</Stack>
					</Stack>
				)}
			</Stack>

			<CustomDivider />
		</Box>
	);
};

export default ContactInformation;
