import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

// mui components
import { Box, Stack } from "@mui/material";

// mui icons
import CloseIcon from "@mui/icons-material/Close";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import LockResetOutlinedIcon from "@mui/icons-material/LockResetOutlined";

// custom components
import Body1 from "../../typography/body-01";
import Heading3 from "../../typography/heading-03";
import SecondaryButton from "../../buttons/secondary-button";
import PrimaryButton from "../../buttons/primary-button";
import CustomTable from "../../custom-table";

// helper functions
import { filterValue } from "../../../utils/filter-value";

//  api slices
import { useLockRequestLogMutation } from "../../../features/lock-request-log/lockRequestLogApiSlice";

// reducer slice
import { selectUserDetails } from "../../../features/auth/authSlice";
import { selectLockRequestLog } from "../../../features/lock-request-log/lockRequestLogSlice";

// api services
import { getLockRequestLog } from "../../../app/services/lock-request-log";

// custom styles
import styles from "./index.module.scss";

const LockInfoCard = ({
	loanData,
	fieldData,
	toggleDrawer,
	handleTabChange,
	handleOpenLoadingDataModal,
	handleCloseLoadingDataModal,
	setLoadingDataText,
	handleOpenErrorModal,
	setErrorModalText,
}) => {
	const userDetails = useSelector(selectUserDetails);

	const [lockRequestLog] = useLockRequestLogMutation();
	const [lockHistoryData, setLockHistoryData] = useState([]);

	const lockRequestLogData = useSelector(selectLockRequestLog);
	console.log("50 lockRequestLogData:", lockRequestLogData);

	const loanStatusColumns = [
		{ id: "label", label: "Requested by", minWidth: 310 },
		{ id: "date", label: "Date and Time (EST)", minWidth: 165 },
	];

	const handleErrorModal = (data) => {
		console.log("60 data:", data);

		handleCloseLoadingDataModal();
		handleOpenErrorModal();
		setErrorModalText("There was an error getting lock info");
	};

	useEffect(() => {
		console.log("75 use effect:", userDetails?.email, loanData);

		getLockRequestLog({
			email: userDetails?.email,
			loanNumber: loanData?.loanNumber,
			apiRequest: lockRequestLog,
			handleErrorModal: handleErrorModal,
		});

		handleOpenLoadingDataModal();
		setLoadingDataText("Setting up the lock info");
	}, []);

	useEffect(() => {
		if (lockRequestLogData !== undefined && lockRequestLogData?.length > 0) {
			setLockHistoryData(lockRequestLogData);
		}

		handleCloseLoadingDataModal();
	}, [lockRequestLogData]);

	return (
		<Stack direction="column" className={styles.stackContainer}>
			<Stack
				direction="row"
				alignItems="center"
				justifyContent="space-between"
				className={styles.topContainer}
			>
				<Heading3
					text="Lock Information and Activities"
					extraClass={styles.tabTitle}
					fontType="semibold"
				/>

				<Stack
					direction="row"
					alignItems="center"
					justifyContent="flex-end"
					className={styles.topBtnContainer}
				>
					<SecondaryButton
						text="Close"
						startIcon={<CloseIcon />}
						onClick={(e) => handleTabChange(e, false)}
					/>
					<SecondaryButton
						text="Extend Lock"
						startIcon={
							<LockResetOutlinedIcon className={styles.extendLockIcon} />
						}
						onClick={(e) => toggleDrawer(e, "extend-lock")}
						disabled={lockHistoryData?.length === 0}
					/>
					<PrimaryButton
						text="Request Lock"
						startIcon={<LockOutlinedIcon className={styles.primaryBtnIcon} />}
						onClick={(e) => toggleDrawer(e, "request-lock")}
						disabled={lockHistoryData?.length > 0}
					/>
				</Stack>
			</Stack>

			<Stack direction="row" className={styles.bottomContainer}>
				<Stack direction="column" className={styles.infoContainer}>
					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1 text="Lock Requested Date" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={filterValue(fieldData, "CX.LOCKREQUESTTIMESTAMP") || "NA"}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1 text="Lock Date" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={filterValue(fieldData, "2149") || "NA"}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1
								text="Lock Expiration Date"
								extraClass={styles.labelText}
							/>
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={filterValue(fieldData, "2151") || "NA"}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1 text="Loan Program" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={filterValue(fieldData, "1401") || "NA"}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1 text="Base Buy Price" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={Number(filterValue(fieldData, "3420")).toFixed(3)}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1
								text="Total Price Adjustments"
								extraClass={styles.labelText}
							/>
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={Number(filterValue(fieldData, "2202")).toFixed(3)}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1 text="Net Buy Price" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={Number(filterValue(fieldData, "2203")).toFixed(3)}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1 text="Net Buy Rate" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={Number(filterValue(fieldData, "2160")).toFixed(3)}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>
				</Stack>

				<Stack direction="column" className={styles.historyContainer}>
					<Body1 text="Lock History" extraClass={styles.labelText} />

					{/* lock status table  */}
					<CustomTable
						columnData={loanStatusColumns}
						rowData={lockHistoryData}
						tableType="lock-history"
					/>
				</Stack>
			</Stack>
		</Stack>
	);
};

export default LockInfoCard;
