import { XMask } from "./xmask";

export const maskMap = {
	phone: { mask: "(000) 000-0000" },
	ssn: {
		mask: "XXX-XX-0000",
		definitions: {
			X: XMask,
		},
	},
	currency: {
		mask: [
			{
				mask: "$ num",
				blocks: {
					num: {
						mask: Number,
						padFractionalZeros: true,
						thousandsSeparator: ",",
						radix: ".",
						mapToRadix: [","],
						scale: 2,
					},
				},
			},
		],
	},
	percentage: {
		mask: [
			{
				mask: "num%",
				lazy: false,
				blocks: {
					num: {
						mask: Number,
						padFractionalZeros: true,
						scale: 3,
						min: 0,
						max: 100,
						radix: ".",
						mapToRadix: [","],
					},
				},
			},
		],
	},
};

export const setLimitedPercentage = (inputValue, step) => {
	if (inputValue % step !== 0) {
		return Math.round(inputValue / step) * step;
	}
	return inputValue;
};
