import React, { useState } from "react";
import {
	Box,
	Stack,
	TableCell,
	TableHead,
	TableRow,
	TableSortLabel,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";

// helper functions
import { sortAmount, sortDate } from "../../../utils/sort-methods";

// custom components
import OutlinedTextInput from "../../custom-input/outlined-text-input";
import MultiSelect from "../../custom-select/multi-select";
import Heading4 from "../../typography/heading-04";

// custom styles
import styles from "./index.module.scss";

const TableHeadSatisfyCondition = (props) => {
	const {
		data,
		rowData,
		// sort
		order,
		orderBy,
		onRequestSort,
		filteredLoans,
		setFilteredLoans,
	} = props;

	const [selectedCategory, setSelectedCategory] = useState([]);
	const [selectedStatus, setSelectedStatus] = useState([]);
	const [selectedSubStatus, setSelectedSubStatus] = useState([]);

	const [searchValue, setSearchValue] = useState({
		date: "",
		title: "",
		description: "",
		category: "",
		status: "",
		sub_status: "",
	});

	const createSortHandler = (property) => (event) => {
		let fieldId;

		if (property === "upload-date") {
			fieldId = "createdDate";
		} else if (property === "name") {
			fieldId = "title";
		} else if (property === "description") {
			fieldId = "description";
		} else if (property === "category") {
			fieldId = "category";
		} else if (property === "status") {
			fieldId = "status";
		} else if (property === "sub-status") {
			fieldId = "sub_status";
		}

		let sortedData;

		if (property.includes("date")) {
			sortedData = sortDate(filteredLoans, order, fieldId);
		} else {
			sortedData = sortAmount(filteredLoans, order, fieldId);
		}

		console.log("70 sorted data:", sortedData);
		setFilteredLoans(sortedData);
		onRequestSort(event, property);
	};

	const handleSearchTable = (value, name) => {
		console.log("value:", value);
		console.log("name:", name);

		let updatedSearchValue = { ...searchValue, [name]: value };
		console.log("80 updated values:", updatedSearchValue);
		setSearchValue(updatedSearchValue);

		let searchedLoans = [];

		searchedLoans = rowData.filter(
			(item) =>
				item?.createdDate?.toString()?.includes(updatedSearchValue?.date) &&
				item?.title
					?.toLowerCase()
					?.includes(updatedSearchValue?.title?.toLowerCase()) &&
				item?.description
					?.toLowerCase()
					?.includes(updatedSearchValue?.description?.toLowerCase()) &&
				(updatedSearchValue?.category?.length === 0 ||
					updatedSearchValue?.category?.includes(item?.category)) &&
				(updatedSearchValue?.status?.length === 0 ||
					updatedSearchValue?.status?.includes(item?.status)) &&
				(updatedSearchValue?.sub_status?.length === 0 ||
					updatedSearchValue?.sub_status?.includes(item?.sub_status))
		);

		console.log("105 searched loans:", searchedLoans);
		setFilteredLoans(searchedLoans);
	};

	const handleSelectCategory = (selectedList) => {
		handleSearchTable(selectedList, "category", "category");
		setSelectedCategory(selectedList);
	};

	const handleSelectStatus = (selectedList) => {
		handleSearchTable(selectedList, "status", "status");
		setSelectedStatus(selectedList);
	};

	const handleSelectSubStatus = (selectedList) => {
		handleSearchTable(selectedList, "sub_status", "sub_status");
		setSelectedSubStatus(selectedList);
	};

	return (
		<TableHead>
			<TableRow>
				<TableCell padding="checkbox"></TableCell>
				{data.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={headCell.align}
						padding={headCell.disablePadding ? "none" : "normal"}
						sortDirection={orderBy === headCell.id ? order : false}
						style={{
							minWidth: headCell.minWidth,
							verticalAlign: headCell.label === "Remedy" && "text-top",
						}}
					>
						<Stack direction="column" className={styles.headerStack}>
							{headCell.label === "Remedy" ? (
								<Heading4
									text={headCell.label}
									extraClass={styles.thText}
									fontType="semibold"
								/>
							) : (
								<TableSortLabel
									active={orderBy === headCell.id}
									direction={orderBy === headCell.id ? order : "asc"}
									onClick={createSortHandler(headCell.id)}
									className={styles.tableLabel}
								>
									<Heading4
										text={headCell.label}
										extraClass={styles.thText}
										fontType="semibold"
									/>
									{orderBy === headCell.id ? (
										<Box component="span" sx={visuallyHidden}>
											{order === "desc"
												? "sorted descending"
												: "sorted ascending"}
										</Box>
									) : null}
								</TableSortLabel>
							)}

							{headCell.label === "Category" ? (
								<MultiSelect
									multiple={true}
									limitTags={1}
									id="category-tags"
									options={[
										"Assets",
										"Credit",
										"Income",
										"Legal",
										"Misc",
										"Property",
									]}
									value={selectedCategory}
									handleChange={(event, newValue) => {
										handleSelectCategory(newValue);
									}}
								/>
							) : headCell.label === "Status" ? (
								<MultiSelect
									multiple={true}
									limitTags={1}
									id="status-tags"
									options={["Open", "Closed"]}
									value={selectedStatus}
									handleChange={(event, newValue) => {
										handleSelectStatus(newValue);
									}}
								/>
							) : headCell.label === "Sub Status" ? (
								<MultiSelect
									multiple={true}
									limitTags={1}
									id="sub-status-tags"
									options={[
										"Added",
										"Cleared",
										"Expected",
										"Expired",
										"Fulfilled",
										"Past Due",
										"Received",
										"Rejected",
										"Requested",
										"Re-Requested",
										"Reviewed",
										"Sent",
										"Waived",
									]}
									value={selectedSubStatus}
									handleChange={(event, newValue) => {
										handleSelectSubStatus(newValue);
									}}
								/>
							) : (
								(headCell.label === "Date" ||
									headCell.label === "Name" ||
									headCell.label === "Description") && (
									<OutlinedTextInput
										extraClass={styles.inputField}
										variant="outlined"
										placeholder="Search here..."
										name={
											headCell.label === "Date"
												? "date"
												: headCell.label === "Name"
												? "title"
												: headCell.label === "Description" && "description"
										}
										value={
											headCell.label === "Date"
												? searchValue?.date || ""
												: headCell.label === "Name"
												? searchValue?.title || ""
												: (headCell.label === "Description" &&
														searchValue?.description) ||
												  ""
										}
										handleChange={(event) =>
											headCell.label === "Date"
												? handleSearchTable(
														event.target.value,
														event.target.name,
														"date"
												  )
												: headCell.label === "Name"
												? handleSearchTable(
														event.target.value,
														event.target.name,
														"title"
												  )
												: headCell.label === "Description" &&
												  handleSearchTable(
														event.target.value,
														event.target.name,
														"description"
												  )
										}
									/>
								)
							)}
						</Stack>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
};

export default TableHeadSatisfyCondition;
