import React from "react";

// mui components
import { Box, Drawer } from "@mui/material";

// custom components
import RequestLockDrawer from "./components/request-lock";
import ExtendLockDrawer from "./components/extend-lock";
import ConditionTrackingDrawer from "./components/condition-tracking";
import ChangeRequestDrawer from "./components/change-request";
import DocumentsDrawer from "./components/documents";
import AddResourceDrawer from "./components/add-resource";

// custom styles
import styles from "./index.module.scss";

export default function TemporaryDrawer({
	// request-lock props
	loanData,
	fieldData,
	// condition tracking props
	selectedCondition,
	setSelectedCondition,
	// drawer props
	drawerOpen,
	drawerDirection,
	drawerType,
	handleCloseDrawer,
	handleOpenProcessingModal,
	handleCloseProcessingModal,
	setProcessingText,
	handleOpenSuccessModal,
	setSuccessText,
	handleOpenErrorModal,
	setErrorText,
}) {
	return (
		<Drawer
			anchor={drawerDirection}
			open={drawerOpen[drawerDirection]}
			onClose={handleCloseDrawer}
			className={styles.drawerContainer}
		>
			<Box role="presentation" className={styles.drawerMainDiv}>
				{drawerType === "request-lock" ? (
					<RequestLockDrawer
						loanData={loanData}
						fieldData={fieldData}
						// default drawer props
						handleCloseDrawer={handleCloseDrawer}
						handleOpenProcessingModal={handleOpenProcessingModal}
						handleCloseProcessingModal={handleCloseProcessingModal}
						handleOpenSuccessModal={handleOpenSuccessModal}
						setProcessingText={setProcessingText}
						setSuccessText={setSuccessText}
					/>
				) : drawerType === "extend-lock" ? (
					<ExtendLockDrawer
						loanData={loanData}
						// default drawer props
						handleCloseDrawer={handleCloseDrawer}
						// processing
						handleOpenProcessingModal={handleOpenProcessingModal}
						handleCloseProcessingModal={handleCloseProcessingModal}
						setProcessingText={setProcessingText}
						// success
						handleOpenSuccessModal={handleOpenSuccessModal}
						setSuccessText={setSuccessText}
						// error
						handleOpenErrorModal={handleOpenErrorModal}
						setErrorText={setErrorText}
					/>
				) : drawerType === "condition-tracking" ? (
					<ConditionTrackingDrawer
						// default drawer props
						handleCloseDrawer={handleCloseDrawer}
						handleOpenProcessingModal={handleOpenProcessingModal}
						handleCloseProcessingModal={handleCloseProcessingModal}
						handleOpenSuccessModal={handleOpenSuccessModal}
						setProcessingText={setProcessingText}
						setSuccessText={setSuccessText}
						handleOpenErrorModal={handleOpenErrorModal}
						setErrorText={setErrorText}
						// custom props
						selectedCondition={selectedCondition}
						setSelectedCondition={setSelectedCondition}
					/>
				) : drawerType === "change-request" ? (
					<ChangeRequestDrawer
						// default drawer props
						handleCloseDrawer={handleCloseDrawer}
						handleOpenProcessingModal={handleOpenProcessingModal}
						handleCloseProcessingModal={handleCloseProcessingModal}
						handleOpenSuccessModal={handleOpenSuccessModal}
						setProcessingText={setProcessingText}
						setSuccessText={setSuccessText}
						// error
						handleOpenErrorModal={handleOpenErrorModal}
						setErrorText={setErrorText}
					/>
				) : drawerType === "upload-documents" ? (
					<DocumentsDrawer
						// default drawer props
						handleCloseDrawer={handleCloseDrawer}
						handleOpenProcessingModal={handleOpenProcessingModal}
						handleCloseProcessingModal={handleCloseProcessingModal}
						handleOpenSuccessModal={handleOpenSuccessModal}
						setProcessingText={setProcessingText}
						setSuccessText={setSuccessText}
					/>
				) : (
					drawerType === "add-resource" && (
						<AddResourceDrawer
							// default drawer props
							handleCloseDrawer={handleCloseDrawer}
							handleOpenProcessingModal={handleOpenProcessingModal}
							handleCloseProcessingModal={handleCloseProcessingModal}
							setProcessingText={setProcessingText}
							handleOpenSuccessModal={handleOpenSuccessModal}
							setSuccessText={setSuccessText}
							handleOpenErrorModal={handleOpenErrorModal}
							setErrorText={setErrorText}
						/>
					)
				)}
			</Box>
		</Drawer>
	);
}
