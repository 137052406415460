import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { DateTime } from "luxon";

// mui icons
import CloseIcon from "@mui/icons-material/Close";

// mui components
import { Box, Stack } from "@mui/material";

// api slice
import { useUpdateFieldsMutation } from "../../../../features/update-fields/updateFieldsApiSlice";
import { useFieldMutation } from "../../../../features/field-values/fieldValueApiSlice";

// reducer slice
import { selectUserDetails } from "../../../../features/auth/authSlice";
import { getFieldValues } from "../../../../app/services/field-values";

// custom components
import OutlinedTextarea from "../../../custom-input/outlined-textarea";
import SecondaryButton from "../../../buttons/secondary-button";
import PrimaryButton from "../../../buttons/primary-button";
import Heading3 from "../../../typography/heading-03";
import Heading4 from "../../../typography/heading-04";

// custom styles
import styles from "./index.module.scss";

const ChangeRequestDrawer = ({
	handleCloseDrawer,
	handleOpenProcessingModal,
	handleCloseProcessingModal,
	handleOpenSuccessModal,
	setProcessingText,
	setSuccessText,
	// error
	handleOpenErrorModal,
	setErrorText,
}) => {
	const params = useParams();
	const userDetails = useSelector(selectUserDetails);

	console.log("42 user details:", userDetails);

	const [updateFields] = useUpdateFieldsMutation();
	const [field] = useFieldMutation();

	const [comment, setComment] = useState("");

	const handleCancel = () => {
		console.log("50 cancel clicked.");

		setComment("");
	};

	const handleErrorModal = (data) => {
		console.log("60 data:", data);

		handleCloseProcessingModal();
		handleOpenErrorModal();
		setErrorText("There was an error requesting change");
	};

	const updateChangeRequested = () => {
		getFieldValues({
			loanGuid: params?.id,
			fieldList:
				"CX.BSM.CIC.REQUEST.01.DT,CX.BSM.CIC.REQUEST.02.DT,CX.BSM.CIC.REQUEST.03.DT,CX.BSM.CIC.REQUEST.04.DT,CX.BSM.CIC.REQUEST.05.DT,CX.BSM.CIC.REQUEST.06.DT,CX.BSM.CIC.REQUEST.07.DT,CX.BSM.CIC.REQUEST.08.DT,CX.BSM.CIC.REQUEST.09.DT,CX.BSM.CIC.REQUEST.10.DT,CX.BSM.CIC.REQUEST.01.STATUS,CX.BSM.CIC.REQUEST.02.STATUS,CX.BSM.CIC.REQUEST.03.STATUS,CX.BSM.CIC.REQUEST.04.STATUS,CX.BSM.CIC.REQUEST.05.STATUS,CX.BSM.CIC.REQUEST.06.STATUS,CX.BSM.CIC.REQUEST.07.STATUS,CX.BSM.CIC.REQUEST.08.STATUS,CX.BSM.CIC.REQUEST.09.STATUS,CX.BSM.CIC.REQUEST.10.STATUS,CX.BSM.COC.REQUESTOR.01,CX.BSM.COC.REQUESTOR.02,CX.BSM.COC.REQUESTOR.03,CX.BSM.COC.REQUESTOR.04,CX.BSM.COC.REQUESTOR.05,CX.BSM.COC.REQUESTOR.06,CX.BSM.COC.REQUESTOR.07,CX.BSM.COC.REQUESTOR.08,CX.BSM.COC.REQUESTOR.09,CX.BSM.COC.REQUESTOR.10,CX.BSM.COC.REQ.FRM.TPO.01,CX.BSM.COC.REQ.FRM.TPO.02,CX.BSM.COC.REQ.FRM.TPO.03,CX.BSM.COC.REQ.FRM.TPO.04,CX.BSM.COC.REQ.FRM.TPO.05,CX.BSM.COC.REQ.FRM.TPO.06,CX.BSM.COC.REQ.FRM.TPO.07,CX.BSM.COC.REQ.FRM.TPO.08,CX.BSM.COC.REQ.FRM.TPO.09,CX.BSM.COC.REQ.FRM.TPO.10",
			getFieldValueApi: field,
			handleErrorModal: handleErrorModal,
		});

		handleOpenProcessingModal();
		setProcessingText("Setting up the lock info");
	};

	// upload documents
	const handleSubmitDocuments = async () => {
		if (comment?.length === 0) return;

		console.log("315 running ");

		handleCloseDrawer();
		handleOpenProcessingModal();
		setProcessingText("Your Change Request is being added");

		let valuesArr = [
			{
				id: "CX.BSM.CIC.REQUEST.TIMESTAMP",
				value: DateTime.now().toFormat("MM/dd/yyyy"),
			},
			{ id: "CX.BSM.COC.REQ.FRM.TPO.RCNT", value: comment },
			{
				id: "CX.BSM.COC.REQUESTOR",
				value: `${userDetails?.first_name} ${userDetails?.last_name}`,
			},
		];

		// dispatch API and open modal if successful
		try {
			// uploaded successfully
			const updateFieldValues = await updateFields({
				loan_guid: params?.id,
				field_values: valuesArr,
			}).unwrap();
			console.log("90 updated values:", updateFieldValues);

			// get updated conditions data
			updateChangeRequested();

			setComment("");
			handleCloseProcessingModal();
			handleOpenSuccessModal();
			setSuccessText("Change Request added");
			console.log("70 all files attached");
		} catch (err) {
			console.log("95 err:", err);
			// TODO aqib open error modal
			handleCloseProcessingModal();
		}
	};

	return (
		<Stack direction="column" className={styles.stackContainer}>
			<Stack
				direction="row"
				alignItems="center"
				justifyContent="space-between"
				className={styles.drawerHeaderContainer}
			>
				<Heading3
					text="Add Change Request"
					extraClass={styles.headerText}
					fontType="semibold"
				/>
				<SecondaryButton
					startIcon={<CloseIcon />}
					text="Close"
					onClick={handleCloseDrawer}
				/>
			</Stack>

			{/* change request */}
			<Stack direction="column" className={styles.drawerBodyContainer}>
				<Box className={styles.headerContainer}>
					<Heading3
						text="Change request Comments:"
						extraClass={styles.labelText}
						fontType="semibold"
					/>

					<Heading4
						text="(Required)"
						fontType="semibold"
						extraClass={styles.subText}
					/>
				</Box>
				<OutlinedTextarea
					extraClass={styles.changeCommentInput}
					variant="outlined"
					placeholder="Type the items you are requesting to be changed"
					multiline={true}
					rows={5}
					value={comment}
					handleChange={(e) => setComment(e.target.value)}
				/>
			</Stack>
			<Stack
				direction="row"
				alignItems="center"
				justifyContent="flex-end"
				className={styles.drawerFooterContainer}
			>
				<SecondaryButton
					text="Reset"
					extraClass={styles.cancelBtn}
					extraTextClass={styles.cancelText}
					onClick={handleCancel}
					disabled={comment === ""}
				/>
				<PrimaryButton
					text="Request"
					onClick={handleSubmitDocuments}
					disabled={comment === ""}
				/>
			</Stack>
		</Stack>
	);
};

export default ChangeRequestDrawer;
