import React, { useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import { useNavigate, useParams } from "react-router-dom";

import { useFieldMutation } from "../../../features/field-values/fieldValueApiSlice";
import { useUpdateFieldsMutation } from "../../../features/update-fields/updateFieldsApiSlice";
import MaskedInput from "../../custom-input/masked-input";
import { Box, InputLabel, Stack } from "@mui/material";
import SecondaryButton from "../../buttons/secondary-button";
import { ArrowBackOutlined, ArrowForwardOutlined } from "@mui/icons-material";
import PrimaryButton from "../../buttons/primary-button";

import styles from "./index.module.scss";
import Heading5 from "../../typography/heading-05";
import Body3 from "../../typography/body-03";
import RequiredError from "../../required-error";
import Heading6 from "../../typography/heading-06";
import CustomDivider from "../../custom-divider";
import Body2 from "../../typography/body-02";
import OutlinedTextInput from "../../custom-input/outlined-text-input";
import SecondarySelect from "../../custom-select/secondary-select";
import CustomRadio from "../../custom-radio";
import {
	YNOptions,
	bpcValueOptions,
	lpcOptions,
	lpcTypeOptions,
	stateOptions,
	vestingMethodOptions,
} from "../../../utils/select-options";

const ShowMyFees = ({ setLoanApplicationStage, loanGuid }) => {
	const [updateFields, { isLoading: isUpdatingFields }] =
		useUpdateFieldsMutation();
	const [field, { isLoading: loadingValues }] = useFieldMutation();

	const params = useParams();

	const [coBorrowerName, setCoBorrowerName] = useState("");

	const [closingDate, setClosingDate] = useState("");
	const [vestingMethod, setVestingMethod] = useState("");
	const [vestingFull, setVestingFull] = useState("");

	// const [companyTitle, setCompanyTitle] = useState("");
	// const [officerName, setOfficerName] = useState("");
	// const [officerEmail, setOfficerEmail] = useState("");
	// const [officerPhone, setOfficerPhone] = useState("");
	// const [companyAddress, setCompanyAddress] = useState("");
	// const [companyZipcode, setCompanyZipcode] = useState("");
	// const [companyCity, setCompanyCity] = useState("");
	// const [companyState, setCompanyState] = useState("");

	const [escrowCompanyName, setEscrowCompanyName] = useState("");
	const [escrowOfficerName, setEscrowOfficerName] = useState("");
	const [escrowOfficerEmail, setEscrowOfficerEmail] = useState("");
	const [escrowOfficerPhone, setEscrowOfficerPhone] = useState("");
	const [escrowCompanyAddress, setEscrowCompanyAddress] = useState("");
	const [escrowCompanyZipcode, setEscrowCompanyZipcode] = useState("");
	const [escrowCompanyCity, setEscrowCompanyCity] = useState("");
	const [escrowCompanyState, setEscrowCompanyState] = useState("");

	const [borrowerPoa, setBorrowerPoa] = useState("");
	const [coBorrowerPoa, setCoBorrowerPoa] = useState("");

	const [lpcType, setLpcType] = useState("");
	const [lpcValue, setLpcValue] = useState("");
	const [bpcValue, setBpcValue] = useState("");
	const [bpcAmount, setBpcAmount] = useState("");

	const [creditReport, setCreditReport] = useState("");
	const [appraisalFee, setAppraisalFee] = useState("");
	const [secondAppraisalFee, setSecondAppraisalFee] = useState("");
	const [processingFee, setProcessingFee] = useState("");
	const [thirdPartyProcessingFee, setThirdPartyProcessingFee] = useState("");

	const [serviceCreditReport, setServiceCreditReport] = useState("");
	const [serviceAppraisalFee, setServiceAppraisalFee] = useState("");
	const [serviceSecondAppraisalFee, setServiceSecondAppraisalFee] =
		useState("");
	const [serviceProcessingFee, setServiceProcessingFee] = useState("");
	const [serviceThirdPartyProcessingFee, setServiceThirdPartyProcessingFee] =
		useState("");

	const [brokerName, setBrokerName] = useState("");

	const [error, setError] = useState("");
	const [inputError, setInputError] = useState("");

	const handlePrev = () => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});
		setLoanApplicationStage(3);
	};

	const updateLoanFeeFields = async () => {
		console.log("50 loanGuid:", loanGuid);
		console.log("50 params:", params?.id);

		let valuesArr = [
			{ id: 763, value: closingDate },
			{ id: 2966, value: closingDate },
			// { id: 411, value: companyTitle },
			// { id: 416, value: officerName },
			// { id: 88, value: officerEmail },
			// { id: 417, value: officerPhone },
			// { id: 412, value: companyAddress },
			// { id: 413, value: companyCity },
			// { id: 1174, value: companyState },
			// { id: 414, value: companyZipcode },
			{ id: 610, value: escrowCompanyName },
			{ id: 611, value: escrowOfficerName },
			{ id: 87, value: escrowOfficerEmail },
			{ id: 615, value: escrowOfficerPhone },
			{ id: 612, value: escrowCompanyAddress },
			{ id: 613, value: escrowCompanyCity },
			{ id: 1175, value: escrowCompanyState },
			{ id: 614, value: escrowCompanyZipcode },
			{ id: "CX.CPL.USINGPOA.BORROWER", value: borrowerPoa },
			{ id: "CX.CPL.USINGPOA.COBORROWER", value: coBorrowerPoa },

			{ id: 640, value: creditReport },
			{ id: 641, value: appraisalFee },
			{ id: "NEWHUD.X138", value: secondAppraisalFee },
			{ id: "NEWHUD.X136", value: processingFee },
			{ id: "NEWHUD.X1285", value: thirdPartyProcessingFee },

			{ id: "NEWHUD2.X1139", value: creditReport },
			{ id: "NEWHUD2.X1106", value: appraisalFee },
			{ id: "NEWHUD2.X1304", value: secondAppraisalFee },
			{ id: "NEWHUD2.X1238", value: processingFee },
			{ id: "NEWHUD2.X875", value: thirdPartyProcessingFee },

			{ id: 624, value: serviceCreditReport },
			{ id: 617, value: serviceAppraisalFee },
			{ id: "NEWHUD.X1052", value: serviceSecondAppraisalFee },
			{ id: "NEWHUD.X1050", value: serviceProcessingFee },
			{ id: "NEWHUD.X1284", value: serviceThirdPartyProcessingFee },
			{
				id: "NEWHUD.X1287",
				value: "Broker",
			},
		];

		if (secondAppraisalFee !== "") {
			valuesArr.push({ id: "NEWHUD.X128", value: "2nd Appraisal Fee" });
		}

		if (processingFee !== "") {
			valuesArr.push({ id: "NEWHUD.X126", value: "Processing Fee" });
		}

		if (thirdPartyProcessingFee !== "") {
			valuesArr.push({ id: "NEWHUD.X1283", value: "ThirdPartyProcessingFee" });
		}

		if (
			vestingMethod === "Sole Ownership" ||
			vestingMethod === "Tenancy in Common" ||
			vestingMethod === "Tenancy by the Entirety" ||
			vestingMethod === "As Joint Tenants With Right of Survivorship"
		) {
			valuesArr.push({ id: "33", value: vestingMethod });
		} else if (
			vestingMethod === "A Limited Liability Company" ||
			vestingMethod === "A Corporation" ||
			vestingMethod === "An Inter Vivos Trust" ||
			vestingMethod === "A Trust"
		) {
			valuesArr.push({ id: "CX.PORTAL.CORPTRUSTORGTYP", value: vestingMethod });
			valuesArr.push({ id: "33", value: "" });
		}

		if (
			vestingMethod === "Sole Ownership" ||
			vestingMethod === "Tenancy in Common" ||
			vestingMethod === "Tenancy by the Entirety" ||
			vestingMethod === "As Joint Tenants With Right of Survivorship"
		) {
			valuesArr.push({ id: "CX.PORTAL.FINAL.VESTING", value: vestingFull });
		} else if (
			vestingMethod === "A Limited Liability Company" ||
			vestingMethod === "A Corporation" ||
			vestingMethod === "An Inter Vivos Trust" ||
			vestingMethod === "A Trust"
		) {
			valuesArr.push({ id: "CX.PORTAL.CORPTRUSTNAME", value: vestingFull });
		}

		if (lpcType === "%") {
			valuesArr.push({ id: "NEWHUD.X1141", value: lpcValue });
		} else if (lpcType === "$") {
			valuesArr.push({ id: "NEWHUD.X1225", value: lpcValue });
		}

		valuesArr.push({ id: "NEWHUD.X223", value: bpcValue });
		valuesArr.push({ id: "NEWHUD.X224", value: bpcAmount });

		try {
			const updateFieldValues = await updateFields({
				loan_guid: loanGuid || params?.id,
				field_values: valuesArr,
			}).unwrap();
			console.log("90 file res:", updateFieldValues);
			if (
				updateFieldValues?.response === "Field values updated successfully."
			) {
				if (loanGuid !== undefined && loanGuid !== "") {
					window.scrollTo({
						top: 0,
						left: 0,
						behavior: "smooth",
					});
					setLoanApplicationStage(5);
				} else if (params?.id !== undefined && params?.id !== "") {
					window.scrollTo({
						top: 0,
						left: 0,
						behavior: "smooth",
					});
					setLoanApplicationStage(5);
				}
			} else {
				setError(updateFieldValues?.response);
			}
		} catch (err) {
			console.log("90 file err:", err);
		}
	};

	const handleSubmitApplication = async () => {
		console.log("502 params id:", params?.id);
		console.log("502 loan id:", loanGuid);

		setInputError(false);

		// required fields fee
		if (
			closingDate === null ||
			closingDate === undefined ||
			closingDate === "" ||
			vestingMethod === undefined ||
			vestingMethod === "" ||
			borrowerPoa === undefined ||
			borrowerPoa === "" ||
			// // either one of these has value
			((lpcValue === undefined || lpcValue === "") &&
				(bpcValue === undefined ||
					bpcValue === "" ||
					bpcAmount === undefined ||
					bpcAmount === ""))
		) {
			setInputError(true);
			setError("Please provide a value for all fields highlighted above.");
			return;
		}

		if (coBorrowerName !== undefined && coBorrowerName !== "") {
			if (coBorrowerPoa === undefined || coBorrowerPoa === "") {
				setInputError(true);
				setError("Please provide a value for all fields highlighted above.");
				return;
			}
		}

		if (loanGuid !== undefined && loanGuid !== "") {
			updateLoanFeeFields();
		} else if (params?.id !== undefined && params?.id !== "") {
			updateLoanFeeFields();
		} else {
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: "smooth",
			});
			setLoanApplicationStage(5);
		}
	};

	const updateField = (fieldValues) => {
		console.log("88 field values:", fieldValues);

		setCoBorrowerName(fieldValues?.find((o) => o.fieldId === "4004")?.value);

		setBrokerName(fieldValues?.find((o) => o.fieldId === "TPO.X61")?.value);

		setClosingDate(fieldValues?.find((o) => o.fieldId === "2966")?.value);

		let corpTrustOrgType = fieldValues?.find(
			(o) => o.fieldId === "CX.PORTAL.CORPTRUSTORGTYP"
		)?.value;
		let vestingField = fieldValues?.find((o) => o.fieldId === "33")?.value;

		if (corpTrustOrgType !== "") {
			setVestingMethod(corpTrustOrgType);
		} else if (vestingField !== "") {
			setVestingMethod(vestingField);
		}

		let finalVestingField = fieldValues?.find(
			(o) => o.fieldId === "CX.PORTAL.FINAL.VESTING"
		)?.value;
		let trustName = fieldValues?.find(
			(o) => o.fieldId === "CX.PORTAL.CORPTRUSTNAME"
		)?.value;

		if (finalVestingField !== "") {
			setVestingFull(finalVestingField);
		} else if (trustName !== "") {
			setVestingFull(trustName);
		}

		// setCompanyTitle(fieldValues?.find((o) => o.fieldId === "411")?.value);

		// setOfficerName(fieldValues?.find((o) => o.fieldId === "416")?.value);

		// setOfficerEmail(fieldValues?.find((o) => o.fieldId === "88")?.value);

		// setOfficerPhone(fieldValues?.find((o) => o.fieldId === "417")?.value);

		// setCompanyAddress(fieldValues?.find((o) => o.fieldId === "412")?.value);

		// setCompanyCity(fieldValues?.find((o) => o.fieldId === "413")?.value);

		// setCompanyState(fieldValues?.find((o) => o.fieldId === "1174")?.value);

		// setCompanyZipcode(fieldValues?.find((o) => o.fieldId === "414")?.value);

		let escrowCompanyName = fieldValues?.find(
			(o) => o.fieldId === "610"
		)?.value;

		let escrowOfficerName = fieldValues?.find(
			(o) => o.fieldId === "611"
		)?.value;

		let escrowOfficerEmail = fieldValues?.find(
			(o) => o.fieldId === "87"
		)?.value;

		let escrowOfficerPhone = fieldValues?.find(
			(o) => o.fieldId === "615"
		)?.value;

		let escrowCompanyAddress = fieldValues?.find(
			(o) => o.fieldId === "612"
		)?.value;

		let escrowCompanyCity = fieldValues?.find(
			(o) => o.fieldId === "613"
		)?.value;

		let escrowCompanyState = fieldValues?.find(
			(o) => o.fieldId === "1175"
		)?.value;

		let escrowCompanyZipcode = fieldValues?.find(
			(o) => o.fieldId === "614"
		)?.value;

		if (
			escrowCompanyName !== "" ||
			escrowOfficerName !== "" ||
			escrowOfficerEmail !== "" ||
			escrowOfficerPhone !== "" ||
			escrowCompanyAddress !== "" ||
			escrowCompanyCity !== "" ||
			escrowCompanyState !== "" ||
			escrowCompanyZipcode !== ""
		) {
			setEscrowCompanyName(escrowCompanyName);
			setEscrowOfficerName(escrowOfficerName);
			setEscrowOfficerEmail(escrowOfficerEmail);
			setEscrowOfficerPhone(escrowOfficerPhone);
			setEscrowCompanyAddress(escrowCompanyAddress);
			setEscrowCompanyCity(escrowCompanyCity);
			setEscrowCompanyState(escrowCompanyState);
			setEscrowCompanyZipcode(escrowCompanyZipcode);
		}

		setBorrowerPoa(
			fieldValues?.find((o) => o.fieldId === "CX.CPL.USINGPOA.BORROWER")?.value
		);

		setCoBorrowerPoa(
			fieldValues?.find((o) => o.fieldId === "CX.CPL.USINGPOA.COBORROWER")
				?.value
		);

		setCreditReport(fieldValues?.find((o) => o.fieldId === "640")?.value);
		setAppraisalFee(fieldValues?.find((o) => o.fieldId === "641")?.value);
		setSecondAppraisalFee(
			fieldValues?.find((o) => o.fieldId === "NEWHUD.X138")?.value
		);
		setProcessingFee(
			fieldValues?.find((o) => o.fieldId === "NEWHUD.X136")?.value
		);
		setThirdPartyProcessingFee(
			fieldValues?.find((o) => o.fieldId === "NEWHUD.X1285")?.value
		);

		setServiceCreditReport(
			fieldValues?.find((o) => o.fieldId === "624")?.value
		);
		setServiceAppraisalFee(
			fieldValues?.find((o) => o.fieldId === "617")?.value
		);

		let lpcTypePercentage = fieldValues?.find(
			(o) => o.fieldId === "NEWHUD.X1141"
		)?.value;

		let lpcTypeAmount = fieldValues?.find(
			(o) => o.fieldId === "NEWHUD.X1225"
		)?.value;

		let bpcTypePercentage = fieldValues?.find(
			(o) => o.fieldId === "NEWHUD.X223"
		)?.value;

		let bpcTypeAmount = fieldValues?.find(
			(o) => o.fieldId === "NEWHUD.X224"
		)?.value;

		if (lpcTypePercentage !== "") {
			setLpcType("%");
			setLpcValue(lpcTypePercentage);
		} else if (lpcTypeAmount !== "") {
			setLpcType("$");
			setLpcValue(lpcTypeAmount);
		}

		setBpcValue(bpcTypePercentage);
		setBpcAmount(bpcTypeAmount);
	};

	const getFieldValues = async (loanId) => {
		console.log("175 loan id:", loanId);
		try {
			const getValues = await field({
				loan_guid: loanId,
				fields:
					"763,2966,33,CX.PORTAL.CORPTRUSTORGTYP,CX.PORTAL.FINAL.VESTING,CX.PORTAL.CorpTrustName,411,416,88,417,412,413,1174,414,610,611,87,615,612,613,1175,614,CX.CPL.USINGPOA.BORROWER,CX.CPL.USINGPOA.COBORROWER,NEWHUD.X251,NEWHUD.X254,650,644,651,645,40,41,43,44,1782,1783,1787,1788,1792,1793,NEWHUD.X1141,NEWHUD.X1225,NEWHUD.X223,NEWHUD.X224,640,641,NEWHUD.X138,NEWHUD.X136,NEWHUD.X1285,TPO.X61,624,617,4004",
			}).unwrap();
			console.log("175 all fields:", getValues?.response);
			if (getValues?.response?.length > 0) {
				updateField(getValues?.response);
			}
		} catch (err) {
			console.log("584 error:", err);
		}
	};

	useEffect(() => {
		console.log("160 loan Guid:", loanGuid);
		if (loanGuid !== undefined && loanGuid !== "") {
			getFieldValues(loanGuid);
		}
	}, [loanGuid]);

	return (
		<Stack direction="column" className={styles.stackContainer}>
			<Stack className={styles.bodyContainer}>
				<Stack direction="column" className={styles.cardHeaderContainer}>
					<Heading5 text="Loan Type" fontType="semibold" />
					<Body3 text="Provide details about the loan" />
				</Stack>

				{/* loan flow error */}
				{error !== "" && (
					<RequiredError
						text={
							error.includes("Something went wrong")
								? "Updates Not Received by LOS. Please Try Again."
								: error.includes("provide a value")
								? "Please provide a value for all fields highlighted above."
								: error
						}
					/>
				)}

				<Stack direction="row" className={styles.formContainer}>
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor="closing-date-picker">
							<Heading6
								text="Closing Date"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<DatePicker
							id="closing-date-picker"
							className={
								inputError &&
								(closingDate === null ||
									closingDate === undefined ||
									closingDate === "")
									? styles.errorDatepicker
									: styles.datepicker
							}
							value={closingDate || ""}
							onChange={setClosingDate}
							minDate={new Date()}
							disabled={false}
							error={
								inputError &&
								(closingDate === null ||
									closingDate === undefined ||
									closingDate === "")
							}
						/>
					</Stack>
				</Stack>

				<CustomDivider />

				<Stack direction="column" className={styles.cardHeaderContainer}>
					<Heading5 text="Fee reimbursement" fontType="semibold" />
					<Body3 text="Do you need any fees reimbursed back to you that you incurred on behalf of the borrower(s)?" />
				</Stack>

				<Stack
					direction="column"
					className={styles.formContainer + " " + styles.feeFormContainer}
				>
					{/* credit report */}
					<Stack direction="row" className={styles.formSectionContainer}>
						<Body2 text="Credit Report:" extraClass={styles.sectionHeader} />

						<Stack direction="row" className={styles.formContainer}>
							<Stack
								direction="column"
								className={styles.inputContainer + " " + styles.inlineContainer}
							>
								<InputLabel htmlFor="credit-report-fee">
									<Heading6
										text="Fees"
										fontType="semibold"
										extraClass={styles.inputHeader}
									/>
								</InputLabel>

								<MaskedInput
									id="credit-report-fee"
									placeholder="$"
									type="currency"
									fullWidth={true}
									value={creditReport}
									onInputChange={setCreditReport}
								/>
							</Stack>

							<Stack
								direction="column"
								className={styles.inputContainer + " " + styles.inlineContainer}
							>
								<InputLabel htmlFor="credit-report-service-provider">
									<Heading6
										text="Service Provider"
										fontType="semibold"
										extraClass={styles.inputHeader}
									/>
								</InputLabel>

								<OutlinedTextInput
									id="credit-report-service-provider"
									placeholder="Enter service provider"
									fullWidth={true}
									value={serviceCreditReport}
									handleChange={(event) =>
										setServiceCreditReport(event.target.value)
									}
								/>
							</Stack>
						</Stack>
					</Stack>

					{/* appraisal Fee: */}
					<Stack direction="row" className={styles.formSectionContainer}>
						<Body2 text="Appraisal Fee:" extraClass={styles.sectionHeader} />

						<Stack direction="row" className={styles.formContainer}>
							<Stack
								direction="column"
								className={styles.inputContainer + " " + styles.inlineContainer}
							>
								<InputLabel htmlFor="appraisal-fee">
									<Heading6
										text="Fees"
										fontType="semibold"
										extraClass={styles.inputHeader}
									/>
								</InputLabel>

								<MaskedInput
									id="appraisal-fee"
									placeholder="$"
									type="currency"
									fullWidth={true}
									value={appraisalFee}
									onInputChange={setAppraisalFee}
								/>
							</Stack>

							<Stack
								direction="column"
								className={styles.inputContainer + " " + styles.inlineContainer}
							>
								<InputLabel htmlFor="appraisal-fee-service-provider">
									<Heading6
										text="Service Provider"
										fontType="semibold"
										extraClass={styles.inputHeader}
									/>
								</InputLabel>

								<OutlinedTextInput
									id="appraisal-fee-service-provider"
									placeholder="Enter service provider"
									fullWidth={true}
									value={serviceAppraisalFee}
									handleChange={(event) =>
										setServiceAppraisalFee(event.target.value)
									}
								/>
							</Stack>
						</Stack>
					</Stack>

					{/* Second Appraisal Fee: */}
					<Stack direction="row" className={styles.formSectionContainer}>
						<Body2
							text="Second Appraisal Fee:"
							extraClass={styles.sectionHeader}
						/>

						<Stack direction="row" className={styles.formContainer}>
							<Stack
								direction="column"
								className={styles.inputContainer + " " + styles.inlineContainer}
							>
								<InputLabel htmlFor="second-appraisal-fee">
									<Heading6
										text="Fees"
										fontType="semibold"
										extraClass={styles.inputHeader}
									/>
								</InputLabel>
								<MaskedInput
									id="second-appraisal-fee"
									placeholder="$"
									type="currency"
									fullWidth={true}
									value={secondAppraisalFee}
									onInputChange={setSecondAppraisalFee}
								/>
							</Stack>

							<Stack
								direction="column"
								className={styles.inputContainer + " " + styles.inlineContainer}
							>
								<InputLabel htmlFor="second-appraisal-fee-service-provider">
									<Heading6
										text="Service Provider"
										fontType="semibold"
										extraClass={styles.inputHeader}
									/>
								</InputLabel>

								<OutlinedTextInput
									id="second-appraisal-fee-service-provider"
									placeholder="Enter service provider"
									fullWidth={true}
									value={serviceSecondAppraisalFee}
									handleChange={(event) =>
										setServiceSecondAppraisalFee(event.target.value)
									}
								/>
							</Stack>
						</Stack>
					</Stack>

					{/* processing Fee: */}
					<Stack direction="row" className={styles.formSectionContainer}>
						<Body2 text="Processing Fee:" extraClass={styles.sectionHeader} />

						<Stack direction="row" className={styles.formContainer}>
							<Stack
								direction="column"
								className={styles.inputContainer + " " + styles.inlineContainer}
							>
								<InputLabel htmlFor="processing-fee">
									<Heading6
										text="Fees"
										fontType="semibold"
										extraClass={styles.inputHeader}
									/>
								</InputLabel>

								<MaskedInput
									id="processing-fee"
									placeholder="$"
									type="currency"
									fullWidth={true}
									value={processingFee}
									onInputChange={setProcessingFee}
								/>
							</Stack>

							<Stack
								direction="column"
								className={styles.inputContainer + " " + styles.inlineContainer}
							>
								<InputLabel htmlFor="processing-fee-service-provider">
									<Heading6
										text="Service Provider"
										fontType="semibold"
										extraClass={styles.inputHeader}
									/>
								</InputLabel>

								<OutlinedTextInput
									id="processing-fee-service-provider"
									placeholder="Enter service provider"
									fullWidth={true}
									value={serviceProcessingFee}
									handleChange={(event) =>
										setServiceProcessingFee(event.target.value)
									}
								/>
							</Stack>
						</Stack>
					</Stack>

					{/* third Party Processing Fee: */}
					<Stack direction="row" className={styles.formSectionContainer}>
						<Body2
							text="Third Party Processing Fee:"
							extraClass={styles.sectionHeader}
						/>

						<Stack direction="row" className={styles.formContainer}>
							<Stack
								direction="column"
								className={styles.inputContainer + " " + styles.inlineContainer}
							>
								<InputLabel htmlFor="third-party-processing-fee">
									<Heading6
										text="Fees"
										fontType="semibold"
										extraClass={styles.inputHeader}
									/>
								</InputLabel>

								<MaskedInput
									id="third-party-processing-fee"
									placeholder="$"
									type="currency"
									fullWidth={true}
									value={thirdPartyProcessingFee}
									onInputChange={setThirdPartyProcessingFee}
								/>
							</Stack>

							<Stack
								direction="column"
								className={styles.inputContainer + " " + styles.inlineContainer}
							>
								<InputLabel htmlFor="third-party-processing-service-provider">
									<Heading6
										text="Service Provider"
										fontType="semibold"
										extraClass={styles.inputHeader}
									/>
								</InputLabel>

								<OutlinedTextInput
									id="third-party-processing-service-provider"
									placeholder="Enter service provider"
									fullWidth={true}
									value={serviceThirdPartyProcessingFee}
									handleChange={(event) =>
										setServiceThirdPartyProcessingFee(event.target.value)
									}
								/>
							</Stack>
						</Stack>
					</Stack>
				</Stack>

				<CustomDivider />

				<Stack direction="column" className={styles.cardHeaderContainer}>
					<Heading5 text="Vesting" fontType="semibold" />
					<Body3 text="Provide details about the loan" />
				</Stack>

				<Stack direction="row" className={styles.formContainer}>
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.inlineContainer}
					>
						<InputLabel htmlFor="select-vesting-method">
							<Heading6
								text="How will the transaction be vested?"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<SecondarySelect
							id="select-vesting-method"
							fullWidth={true}
							value={vestingMethod || ""}
							handleChange={(event) => setVestingMethod(event.target.value)}
							options={vestingMethodOptions}
							error={
								inputError &&
								(vestingMethod === undefined || vestingMethod === "")
							}
						/>
					</Stack>

					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.inlineContainer}
					>
						<InputLabel htmlFor="input-vesting-wrriten">
							<Heading6
								text="Vesting fully written as:"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<OutlinedTextInput
							id="input-vesting-wrriten"
							placeholder="Type here"
							fullWidth={true}
							value={vestingFull}
							handleChange={(event) => setVestingFull(event.target.value)}
						/>
					</Stack>
				</Stack>

				<CustomDivider />

				<Stack direction="column" className={styles.cardHeaderContainer}>
					<Heading5 text="Compensation" fontType="semibold" />
					<Body3 text="Provide details about the loan" />
				</Stack>

				<Stack
					direction="column"
					className={styles.formContainer + " " + styles.feeFormContainer}
				>
					{/* LPC */}
					<Stack direction="row" className={styles.formSectionContainer}>
						<Body2 text="LPC:" extraClass={styles.compensationHeader} />

						<Stack direction="row" className={styles.formContainer}>
							<Stack
								direction="column"
								className={
									styles.inputContainer + " " + styles.fullWidthContainer
								}
							>
								<CustomRadio
									fullWidth={true}
									value={lpcType}
									handleChange={(event) => setLpcType(event.target.value)}
									data={lpcOptions}
								/>

								<Box className={styles.lpcInputContainer}>
									{lpcType === "%" ? (
										<SecondarySelect
											fullWidth={true}
											value={lpcValue || ""}
											handleChange={(event) => setLpcValue(event.target.value)}
											options={lpcTypeOptions}
											disabled={lpcType === ""}
											error={
												inputError &&
												(lpcValue === undefined || lpcValue === "") &&
												(bpcValue === undefined ||
													bpcValue === "" ||
													bpcAmount === undefined ||
													bpcAmount === "")
											}
										/>
									) : (
										<MaskedInput
											placeholder="LPC Amount ($)"
											type="currency"
											value={lpcValue || ""}
											onInputChange={setLpcValue}
											disabled={lpcType === ""}
											error={
												inputError &&
												(lpcValue === undefined || lpcValue === "") &&
												(bpcValue === undefined ||
													bpcValue === "" ||
													bpcAmount === undefined ||
													bpcAmount === "")
											}
										/>
									)}
								</Box>
							</Stack>
						</Stack>
					</Stack>

					{/* BPC */}
					<Stack direction="row" className={styles.formSectionContainer}>
						<Body2 text="BPC:" extraClass={styles.compensationHeader} />

						<Stack direction="row" className={styles.formContainer}>
							<Stack
								direction="column"
								className={styles.inputContainer + " " + styles.bpcContainer}
							>
								<InputLabel htmlFor="select-bpc-percentage">
									<Heading6
										text="BPC in percentage"
										fontType="semibold"
										extraClass={styles.inputHeader}
									/>
								</InputLabel>

								<SecondarySelect
									id="select-bpc-percentage"
									value={bpcValue || ""}
									handleChange={(event) => setBpcValue(event.target.value)}
									options={bpcValueOptions}
									error={
										inputError &&
										(lpcValue === undefined || lpcValue === "") &&
										(bpcValue === undefined ||
											bpcValue === "" ||
											bpcAmount === undefined ||
											bpcAmount === "")
									}
								/>
							</Stack>
						</Stack>

						<Stack direction="row" className={styles.formContainer}>
							<Stack
								direction="column"
								className={styles.inputContainer + " " + styles.bpcContainer}
							>
								<InputLabel htmlFor="input-bpc-amount">
									<Heading6
										text="BPC in dollars"
										fontType="semibold"
										extraClass={styles.inputHeader}
									/>
								</InputLabel>

								<MaskedInput
									id="input-bpc-amount"
									placeholder="$"
									type="currency"
									value={bpcAmount}
									onInputChange={setBpcAmount}
									error={
										inputError &&
										(lpcValue === undefined || lpcValue === "") &&
										(bpcValue === undefined ||
											bpcValue === "" ||
											bpcAmount === undefined ||
											bpcAmount === "")
									}
								/>
							</Stack>
						</Stack>
					</Stack>
				</Stack>

				<CustomDivider />

				<Stack direction="column" className={styles.cardHeaderContainer}>
					<Heading5 text="Escrow" fontType="semibold" />
					<Body3 text="Provide details about the escrow" />
				</Stack>

				<Stack direction="row" className={styles.formContainer}>
					{/* escrow company name */}
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.fullWidthContainer}
					>
						<InputLabel htmlFor="input-company-name">
							<Heading6
								text="Company Name"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<OutlinedTextInput
							id="input-company-name"
							fullWidth={true}
							value={escrowCompanyName}
							handleChange={(event) => setEscrowCompanyName(event.target.value)}
						/>
					</Stack>

					{/* escrow company address */}
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.fullWidthContainer}
					>
						<InputLabel htmlFor="input-company-address-line">
							<Heading6
								text="escrowCompanyAddress"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<OutlinedTextInput
							id="input-company-address-line"
							fullWidth={true}
							value={escrowCompanyAddress}
							handleChange={(event) =>
								setEscrowCompanyAddress(event.target.value)
							}
						/>
					</Stack>

					{/* escrow company city */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor="input-company-city">
							<Heading6
								text="City"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<OutlinedTextInput
							id="input-company-city"
							fullWidth={true}
							value={escrowCompanyCity}
							handleChange={(event) => setEscrowCompanyCity(event.target.value)}
						/>
					</Stack>

					{/* escrow company state */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor="select-state">
							<Heading6
								text="State"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<SecondarySelect
							id="select-state"
							displayEmpty={true}
							value={escrowCompanyState || ""}
							handleChange={(event) =>
								setEscrowCompanyState(event.target.value)
							}
							options={stateOptions}
						/>
					</Stack>

					{/* escrow company zipcode */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor="input-company-zipcode">
							<Heading6
								text="Zipcode"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<OutlinedTextInput
							id="input-company-zipcode"
							fullWidth={true}
							value={escrowCompanyZipcode}
							handleChange={(event) =>
								setEscrowCompanyZipcode(event.target.value)
							}
						/>
					</Stack>

					{/* escrow officer name */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor="input-officer-name">
							<Heading6
								text="Escrow Officer Name"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<OutlinedTextInput
							id="input-officer-name"
							fullWidth={true}
							value={escrowOfficerName}
							handleChange={(event) => setEscrowOfficerName(event.target.value)}
						/>
					</Stack>

					{/* escrow officer phone */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor="input-officer-phone">
							<Heading6
								text="Escrow Officer Cell Phone Number"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<MaskedInput
							id="input-officer-phone"
							placeholder="(XXX) XXX-XXXX"
							type="phone"
							value={escrowOfficerPhone}
							onInputChange={setEscrowOfficerPhone}
						/>
					</Stack>

					{/* escrow officer name */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor="input-officer-email">
							<Heading6
								text="Escrow Officer Email ID"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<OutlinedTextInput
							id="input-officer-email"
							fullWidth={true}
							value={escrowOfficerEmail}
							handleChange={(event) =>
								setEscrowOfficerEmail(event.target.value)
							}
						/>
					</Stack>
				</Stack>

				<CustomDivider />

				<Stack direction="column" className={styles.cardHeaderContainer}>
					<Heading5 text="POA" fontType="semibold" />
					<Body3 text="Provide details about the loan" />
				</Stack>

				<Stack direction="row" className={styles.formContainer}>
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.inlineContainer}
					>
						<InputLabel htmlFor="select-poa-borrower">
							<Heading6
								text="Will POA be used for Borrower?"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<SecondarySelect
							id="select-poa-borrower"
							fullWidth={true}
							value={borrowerPoa || ""}
							handleChange={(event) => setBorrowerPoa(event.target.value)}
							options={YNOptions}
							error={
								inputError && (borrowerPoa === undefined || borrowerPoa === "")
							}
						/>
					</Stack>

					{coBorrowerName !== undefined && coBorrowerName !== "" && (
						<Stack
							direction="column"
							className={styles.inputContainer + " " + styles.inlineContainer}
						>
							<InputLabel htmlFor="select-poa-co-borrower">
								<Heading6
									text="Will POA be used for Co Borrower?"
									fontType="semibold"
									extraClass={styles.inputHeader}
								/>
							</InputLabel>

							<SecondarySelect
								id="select-poa-co-borrower"
								fullWidth={true}
								value={coBorrowerPoa || ""}
								handleChange={(event) => setCoBorrowerPoa(event.target.value)}
								options={YNOptions}
								error={
									inputError &&
									(coBorrowerPoa === undefined || coBorrowerPoa === "")
								}
							/>
						</Stack>
					)}
				</Stack>

				{/* footer buttons */}
			</Stack>
			<Stack direction="row" className={styles.footerContainer}>
				<SecondaryButton
					text="Previous"
					startIcon={<ArrowBackOutlined className={styles.prevIcon} />}
					onClick={handlePrev}
				/>
				<PrimaryButton
					// TODO loader isUpdatingFields
					text="Save and Continue"
					endIcon={<ArrowForwardOutlined className={styles.nextIcon} />}
					onClick={handleSubmitApplication}
				/>
			</Stack>
		</Stack>
	);
};

export default ShowMyFees;
