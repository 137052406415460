import React from "react";

// mui components
import { IconButton, TableCell, TableRow } from "@mui/material";

// custom components
import SecondarySelect from "../../custom-select/secondary-select";
import MaskedInput from "../../custom-input/masked-input";
import Body3 from "../../typography/body-03";

// helper utils
import {
	ownerOptions,
	realEstateStatusOptions,
} from "../../../utils/select-options";

// mui icons
import { DeleteOutlined } from "@mui/icons-material";

// custom styles
import styles from "../index.module.scss";

const RealEstateInformationRow = ({
	row,
	index,
	handleRemoveRow,
	handleUpdate,
	disableRow,
	liabilityCount,
	handleReOwned,
}) => {
	return (
		<TableRow hover tabIndex={-1} key={row.label}>
			<TableCell>
				<IconButton
					className={styles.deleteIconBtn}
					onClick={() => handleRemoveRow(index, row?.vodIndex)}
					disabled={disableRow}
				>
					<DeleteOutlined className={styles.deleteIcon} />
				</IconButton>
			</TableCell>
			<TableCell>
				<SecondarySelect
					value={row?.owner}
					handleChange={(event) =>
						handleUpdate(index, "owner", event.target.value)
					}
					options={ownerOptions}
					disabled={disableRow}
				/>
			</TableCell>
			<TableCell>
				<MaskedInput
					placeholder="$"
					type="currency"
					value={row?.marketValueAmount?.toString()}
					onInputChange={(value) =>
						disableRow !== true &&
						handleUpdate(index, "marketValueAmount", value)
					}
					disabled={disableRow}
				/>
			</TableCell>
			<TableCell>
				<SecondarySelect
					value={
						row?.dispositionStatusType === "Retain"
							? "RetainForRental" || ""
							: row?.dispositionStatusType || ""
					}
					handleChange={(event) =>
						handleUpdate(index, "dispositionStatusType", event.target.value)
					}
					options={realEstateStatusOptions}
					disabled={disableRow}
				/>
			</TableCell>
			<TableCell>
				<MaskedInput
					placeholder="$"
					type="currency"
					value={row?.rentalIncomeGrossAmount?.toString()}
					onInputChange={(value) =>
						disableRow !== true &&
						handleUpdate(index, "rentalIncomeGrossAmount", value)
					}
					disabled={disableRow}
				/>
			</TableCell>
			<TableCell>
				<MaskedInput
					placeholder="$"
					type="currency"
					value={row?.maintenanceExpenseAmount?.toString()}
					onInputChange={(value) =>
						disableRow !== true &&
						handleUpdate(index, "maintenanceExpenseAmount", value)
					}
					disabled={disableRow}
				/>
			</TableCell>
			<TableCell>
				<Body3
					text="Click to link liabilities"
					isLink={liabilityCount > 0}
					extraClass={
						disableRow || liabilityCount <= 0 ? styles.disabledText : ""
					}
					handleClick={() =>
						liabilityCount > 0 && disableRow !== true && handleReOwned(index)
					}
				/>
			</TableCell>
		</TableRow>
	);
};

export default RealEstateInformationRow;
