import React from "react";
import { Box, Stack } from "@mui/material";

import styles from "./index.module.scss";
import { ErrorOutline } from "@mui/icons-material";
import Heading5 from "../typography/heading-05";
import Body3 from "../typography/body-03";

const RequiredError = ({ text }) => {
	return (
		<Stack direction="row" className={styles.requiredErrorContainer}>
			<Box className={styles.errorIconContainer}>
				<ErrorOutline className={styles.errorIcon} />
			</Box>
			<Stack direction="column" className={styles.textContainer}>
				<Heading5 text="Missing fields" extraClass={styles.headerText} />
				<Body3 text={text} extraClass={styles.bodyText} />
			</Stack>
		</Stack>
	);
};

export default RequiredError;
