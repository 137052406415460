import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

// mui components
import { Box, InputLabel, Stack } from "@mui/material";

// api slice
import { useGetBrokerListMutation } from "../../features/broker-list/brokerListApiSlice";
import { useCheckUserMutation } from "../../features/admin/check-user/checkUserApiSlice";
import { useResetPasswordMutation } from "../../features/admin/reset-password/resetPasswordApiSlice";
import { useImportTpoUserMutation } from "../../features/admin/import-tpo/importTpoApiSlice";

// reducer slice
import { setBrokerListReducer } from "../../features/broker-list/brokerListSlice";
import { setImpersonatedUserReducer } from "../../features/admin/impersonated-user/impersonatedUserSlice";
import { setOrganizationNameReducer } from "../../features/organization-detail/organizationDetailsUserSlice";
import { setImpersonatedOrgNameReducer } from "../../features/admin/impersonated-organization-name/impersonatedOrganizationNameSlice";

// custom components
import CustomLayout from "../../components/layout";
import CustomTable from "../../components/custom-table";
import OutlinedTextInput from "../../components/custom-input/outlined-text-input";
import PrimaryButton from "../../components/buttons/primary-button";
import SecondaryButton from "../../components/buttons/secondary-button";
import Heading4 from "../../components/typography/heading-04";
import Heading6 from "../../components/typography/heading-06";

// custom modal
import LoaderModal from "../../components/modal/loader-modal";
import UpdatePasswordModal from "../../components/modal/update-password-modal";
import SuccessModal from "../../components/modal/success-modal";
import ErrorModal from "../../components/modal/error-modal";

// custom styles
import styles from "./index.module.scss";
import Body3 from "../../components/typography/body-03";
import { SearchOutlined, SyncOutlined } from "@mui/icons-material";

export const AdminScreen = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [getBrokerList, { isLoading: gettingBrokerList }] =
		useGetBrokerListMutation();
	const [importTpoUser, { isLoading: updatingDatabase }] =
		useImportTpoUserMutation();
	const [checkUser, { isLoading: checkingUser }] = useCheckUserMutation();
	const [resetPassword, { isLoading: updatingPassword }] =
		useResetPasswordMutation();

	const [successModalText, setSuccessModalText] = useState("");
	const [successModalVisible, setSuccessModalVisible] = useState(false);

	const handleOpenSuccessModal = () => {
		setSuccessModalVisible(true);
	};

	const handleCloseSuccessModal = () => {
		setSuccessModalVisible(false);
		navigate("/pipeline");
	};

	const [errorModalText, setErrorModalText] = useState("");
	const [errorModalVisible, setErrorModalVisible] = useState(false);

	const handleOpenErrorModal = () => {
		setErrorModalVisible(true);
	};

	const handleCloseErrorModal = () => {
		setErrorModalVisible(false);
	};

	const adminColumns = [
		{ id: "", label: "", minWidth: 50 },
		{ id: "name", label: "Name", minWidth: 300 },
		{ id: "nmls-id", label: "NMLS ID", minWidth: 180 },
		{ id: "organization-name", label: "Organization Name", minWidth: 325 },
		{ id: "email", label: "Email", minWidth: 250 },
		{ id: "email-login", label: "Email for Login", minWidth: 250 },
		{ id: "phone", label: "Phone Number", minWidth: 200 },
	];

	const [modalError, setModalError] = useState("");

	const [orgName, setOrgName] = useState("");
	const [email, setEmail] = useState("");
	const [username, setUsername] = useState("");

	const [organizationUsers, setOrganizationUsers] = useState("");
	const [selectedBroker, setSelectedBroker] = useState(null);

	const [updatePasswordVisible, setUpdatePasswordVisible] = useState(false);

	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");

	const handleOpenUpdatePassword = () => {
		setUpdatePasswordVisible(true);
	};

	const handleCloseUpdatePassword = () => {
		setModalError("");
		setPassword("");
		setConfirmPassword("");
		setUpdatePasswordVisible(false);
	};

	const handleDatabaseClick = async () => {
		const updateDatabase = await importTpoUser({}).unwrap();
		console.log("45 update db:", updateDatabase);
	};

	const handlePasswordClick = () => {
		console.log("15 update clicked!!!", email);
		if (email !== "") {
			handleOpenUpdatePassword();
		} else {
			setErrorModalText("Enter broker email address.");
			handleOpenErrorModal();
		}
	};

	const handleSearchClick = async () => {
		const searchResults = await getBrokerList({
			email,
			companyName: orgName,
		}).unwrap();
		dispatch(setBrokerListReducer(searchResults));
		setOrganizationUsers(searchResults);
	};

	const handleSignInClick = async () => {
		if (email !== "") {
			try {
				var formData = new FormData();
				formData.append("email", email);

				const checkUserExists = await checkUser({ formData }).unwrap();

				console.log("140 check user exists:", checkUserExists);
				setSuccessModalText(`Succesfully signed in as ${email}.`);
				handleOpenSuccessModal();

				dispatch(setImpersonatedUserReducer(email));
				dispatch(setOrganizationNameReducer(checkUserExists.organization));
				dispatch(setImpersonatedOrgNameReducer(checkUserExists.organization));
			} catch (err) {
				setErrorModalText(err.data.message);
				handleOpenErrorModal();
			}
		} else {
			setErrorModalText("Enter broker email address.");
			handleOpenErrorModal();
		}
	};

	const handleChangePassword = async () => {
		if (password === "") {
			setModalError("Password cannot be empty.");
		} else if (password === confirmPassword) {
			if (password?.length < 8) {
				setModalError("Enter a stronger password.");
			} else {
				// change password api
				try {
					const updatePassword = await resetPassword({
						username,
						password,
					}).unwrap();

					console.log("150 updatePassword:", updatePassword);
					if (updatePassword.message.includes("success")) {
						setPassword("");
						setConfirmPassword("");

						handleCloseUpdatePassword();
						setSuccessModalText("Password updated successfully.");
						handleOpenSuccessModal();
					}
				} catch (err) {
					console.log("150 err:", err);
					if (err.data.message.toLowerCase().includes("user match")) {
						setModalError("Incorrect email. User does not exist.");
					} else {
						setModalError(err.data.message);
					}
				}
			}
		} else {
			setModalError("Passwords do not match.");
		}
	};

	const handleChangeSelectedBroker = (value) => {
		console.log("180 value:", value);

		setSelectedBroker(value);
		setOrgName(organizationUsers[value]?.organization?.name);
		setEmail(organizationUsers[value]?.user?.email);
		setUsername(organizationUsers[value]?.user?.username);
	};

	const breadcrumbData = [
		{
			label: "Home",
			path: "/home",
		},
		{
			label: "Admin",
			path: "/admin",
		},
	];

	return (
		<Box>
			<CustomLayout breadcrumb={breadcrumbData} disabledOverflow={true}>
				<Stack
					direction="column"
					className={styles.stackContainer}
					sx={{
						overflow: "hidden",
					}}
				>
					<Stack direction="column" className={styles.bodyContainer}>
						<Stack direction="row" className={styles.bodyTopContainer}>
							<Stack direction="column" className={styles.bodyHeaderContainer}>
								<Heading4 text="Broker Search and Login" fontType="semibold" />
								<Body3 text="Find the broker’s credentials by typing the organization’s name or their Email ID or both" />
							</Stack>

							<SecondaryButton
								extraClass={styles.databaseBtn}
								onClick={handleDatabaseClick}
								text="Update Database"
								fontType="semibold"
								startIcon={<SyncOutlined className={styles.databaseIcon} />}
							/>
						</Stack>

						<Stack direction="row" className={styles.searchContainer}>
							<Stack direction="row" className={styles.searchInputContainer}>
								<Stack direction="column" className={styles.inputContainer}>
									<InputLabel htmlFor="input-org-name">
										<Heading6
											text="Enter Organization Name"
											fontType="semibold"
											extraClass={styles.inputHeader}
										/>
									</InputLabel>

									<OutlinedTextInput
										id="input-org-name"
										placeholder="Type Name"
										fullWidth={true}
										value={orgName}
										handleChange={(event) => setOrgName(event.target.value)}
									/>
								</Stack>

								<Stack direction="row" className={styles.orContainer}>
									<Heading6 text="(or)" fontType="semibold" />
								</Stack>

								<Stack direction="column" className={styles.inputContainer}>
									<InputLabel htmlFor="input-email">
										<Heading6
											text="Enter Email ID"
											fontType="semibold"
											extraClass={styles.inputHeader}
										/>
									</InputLabel>

									<OutlinedTextInput
										id="input-email"
										placeholder="abc@lending.com"
										fullWidth={true}
										value={email}
										handleChange={(event) => setEmail(event.target.value)}
									/>
								</Stack>
							</Stack>

							<Stack direction="row" className={styles.searchBtnContainer}>
								<PrimaryButton
									extraClass={styles.searchBtn}
									onClick={handleSearchClick}
									text="Search"
									fontType="semibold"
									startIcon={<SearchOutlined className={styles.searchIcon} />}
								/>
							</Stack>
						</Stack>

						<Stack className={styles.tableContainer}>
							<CustomTable
								columnData={adminColumns}
								rowData={organizationUsers || []}
								tableType="admin-users"
								// admin user props
								selectedBroker={selectedBroker}
								handleSelectBroker={handleChangeSelectedBroker}
							/>
						</Stack>

						<Stack direction="row" className={styles.footerContainer}>
							<SecondaryButton
								extraClass={styles.footerBtn}
								onClick={handlePasswordClick}
								text="Update Password"
								disabled={email === ""}
							/>

							<PrimaryButton
								extraClass={styles.footerBtn}
								onClick={handleSignInClick}
								text="Sign in as this broker"
								disabled={email === ""}
							/>
						</Stack>
					</Stack>
				</Stack>
			</CustomLayout>

			{/* modals */}
			<LoaderModal
				open={
					updatingDatabase ||
					updatingPassword ||
					gettingBrokerList ||
					checkingUser
				}
			/>
			<SuccessModal
				open={successModalVisible}
				handleClose={handleCloseSuccessModal}
				text={successModalText}
			/>
			<ErrorModal
				open={errorModalVisible}
				handleClose={handleCloseErrorModal}
				text={errorModalText}
			/>
			<UpdatePasswordModal
				open={updatePasswordVisible}
				password={password}
				setPassword={setPassword}
				confirmPassword={confirmPassword}
				setConfirmPassword={setConfirmPassword}
				modalError={modalError}
				handleClose={handleCloseUpdatePassword}
				handleSubmit={handleChangePassword}
			/>
		</Box>
	);
};

export default AdminScreen;
