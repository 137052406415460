import { createSlice } from "@reduxjs/toolkit";

const createLoanSlice = createSlice({
	name: "createLoan",
	initialState: { loan: null },
	reducers: {
		createLoanReducer: (state, action) => {
			state.loan = action.payload;
		},
	},
});

export const { createLoanReducer } = createLoanSlice.actions;

export default createLoanSlice.reducer;

export const selectCurrentLoan = (state) => state.loan.loan;
