import React, { useEffect, useState } from "react";

// mui components
import { Modal, Stack } from "@mui/material";

// custom components
import CustomTable from "../../custom-table";
import PrimaryButton from "../../buttons/primary-button";
import SecondaryButton from "../../buttons/secondary-button";
import Heading4 from "../../typography/heading-04";

// custom styles
import styles from "./index.module.scss";

export default function LiabilityLinkModal({
	open,
	handleClose,
	realEstate,
	selectedRealEstate,
	selectedLiability,
	setSelectedLiability,
	liabilitiesData,
	selectedTab,
	confirmText,
	closeText,
}) {
	const [liabilitiesInfo, setLiabilitiesInfo] = useState([]);

	const linkLiabilityColumns = [
		{ id: "index", label: "", minWidth: 48 },
		{ id: "account-owner", label: "Account Owner", minWidth: 165 },
		{ id: "account-type", label: "Account Type", minWidth: 165 },
		{
			id: "company-name",
			label: "Company Name",
			minWidth: 310,
		},
		{ id: "account-number", label: "Account Number", minWidth: 310 },
		{ id: "unpaid-balance", label: "Unpaid Balance", minWidth: 165 },
		{ id: "paid-off", label: "Paid Off", minWidth: 165, align: "center" },
		{ id: "monthly-amount", label: "Monthly Amount", minWidth: 165 },
	];

	useEffect(() => {
		if (liabilitiesData !== undefined && liabilitiesData?.length > 0) {
			setLiabilitiesInfo(liabilitiesData[selectedTab]);
		}
	}, [liabilitiesData]);

	const handleLink = () => {
		for (let index = 0; index < liabilitiesInfo.length; index++) {
			const element = liabilitiesInfo[index];

			if (element?.reoId === undefined || element?.reoId === "") {
				setLiabilitiesInfo(() =>
					liabilitiesInfo?.map((obj, i) => {
						if (selectedLiability.includes(obj?.volIndex)) {
							return { ...obj, reoId: realEstate[selectedRealEstate]?.reoId };
						}

						return obj;
					})
				);

				let newArr = liabilitiesData;

				newArr[selectedTab]?.map((obj, i) => {
					if (selectedLiability.includes(obj?.volIndex)) {
						obj.reoId = realEstate[selectedRealEstate]?.reoId;
					}
					return obj;
				});
			}

			if (index === liabilitiesInfo?.length - 1) {
				handleClose();
			}
		}
	};

	const removeRealEstate = (index) => {
		setSelectedLiability(selectedLiability.filter((item) => item !== index));
	};

	const addRealEstate = (index) => {
		setSelectedLiability([...selectedLiability, index]);
	};

	const handleChangeCheckbox = (item) => {
		if (item?.reoId !== undefined && item?.reoId !== "") {
			if (item?.reoId === realEstate[selectedRealEstate]?.reoId) {
				// checked
				setLiabilitiesInfo((prevState) => {
					return prevState.map((el) =>
						el.volIndex === item?.volIndex
							? {
									...el,
									reoId: "",
							  }
							: el
					);
				});
			} else {
				// unchecked
				setLiabilitiesInfo((prevState) => {
					return prevState.map((el) =>
						el.volIndex === item?.volIndex
							? {
									...el,
									reoId: realEstate[selectedRealEstate]?.reoId,
							  }
							: el
					);
				});
			}
		} else {
			if (selectedLiability.includes(item?.volIndex)) {
				removeRealEstate(item?.volIndex);
			} else {
				addRealEstate(item?.volIndex);
			}
		}
	};

	return (
		<Modal
			open={open}
			aria-labelledby="link liability modal"
			aria-describedby="link liability modal"
			disableAutoFocus={true}
			disableEscapeKeyDown={true}
		>
			<Stack direction="column" className={styles.modalBodyContainer}>
				<Heading4 text="Link Liabilities" fontType="semibold" />

				<CustomTable
					tableType="link-liability"
					columnData={linkLiabilityColumns}
					rowData={
						liabilitiesInfo !== undefined
							? liabilitiesInfo?.filter(
									(item) =>
										item?.reoId === undefined ||
										item?.reoId === "" ||
										item?.reoId === realEstate[selectedRealEstate]?.reoId
							  )
							: []
					}
					selectedLiability={selectedLiability}
					realEstate={realEstate}
					selectedRealEstate={selectedRealEstate}
					handleSelectLiability={handleChangeCheckbox}
				/>

				<Stack direction="row" className={styles.footerContainer}>
					<PrimaryButton
						text={confirmText}
						onClick={handleLink}
						fullWidth={true}
					/>

					<SecondaryButton
						text={closeText}
						onClick={handleClose}
						fullWidth={true}
						extraClass={styles.closeBtn}
						extraTextClass={styles.closeBtnText}
					/>
				</Stack>
			</Stack>
		</Modal>
	);
}
