import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

// mui components
import { InputLabel, Stack } from "@mui/material";

// custom components
import OutlinedTextInput from "../../custom-input/outlined-text-input";
import MaskedInput from "../../custom-input/masked-input";
import PrimaryButton from "../../buttons/primary-button";
import SecondaryButton from "../../buttons/secondary-button";
import SecondarySelect from "../../custom-select/secondary-select";
import CustomDivider from "../../custom-divider";
import Heading5 from "../../typography/heading-05";
import Heading6 from "../../typography/heading-06";
import Body3 from "../../typography/body-03";

// mui icons
import { ArrowBackOutlined, ArrowForwardOutlined } from "@mui/icons-material";

// api slices
import { useFieldMutation } from "../../../features/field-values/fieldValueApiSlice";
import { useCountyListMutation } from "../../../features/county-list/countyListApiSlice";
import { useUpdateFieldsMutation } from "../../../features/update-fields/updateFieldsApiSlice";

// helper utils
import {
	YNOptions,
	bankruptcyOutcomeOptions,
	bankruptcySeasoningOptions,
	bankruptcyTypeOptions,
	housingSeasoningOptions,
	housingTypeOptions,
	loanPurposeOptions,
	occupancyOptions,
	prepaymentPenaltyOptions,
	propertyInfoTypeOptions,
	reservesOptions,
	stateOptions,
} from "../../../utils/select-options";

// custom styles
import styles from "./index.module.scss";
import Body2 from "../../typography/body-02";
import RequiredError from "../../required-error";

const LoanPricingApplication = ({
	data,
	setLoanApplicationStage,
	disabled,
	loanGuid,
}) => {
	const params = useParams();
	const [updateFields, { isLoading: isUpdatingFields }] =
		useUpdateFieldsMutation();
	const [field] = useFieldMutation();

	const [countyList] = useCountyListMutation();

	console.log("24 data:", data);
	console.log("23 params:", params);
	console.log("23 loan id:", loanGuid);

	const productFamilyOptions = [
		{
			value: "DSCR: 5-8 Unit",
			label: "DSCR: 5-8 Unit",
		},
		{
			value: "Alternative Documentation",
			label: "Alternative Documentation",
		},
		{
			value: "Full Documentation",
			label: "Full Documentation",
		},
		{
			value: "DSCR",
			label: "DSCR",
		},
		{
			value: "DSCR: Elite",
			label: "DSCR: Elite",
		},
		{
			value: "DSCR: Mixed Use",
			label: "DSCR: Mixed Use",
		},
	];

	const loanProductOptions = {
		"DSCR: 5-8 Unit": [
			{
				value: "LFC Non-QM 5-8 Investor DSCR >=120.00 30 Yr Fixed - EG",
				label: "LFC Non-QM 5-8 Investor DSCR >=120.00 30 Yr Fixed - EG",
			},
			{
				value: "LFC Non-QM 5-8 Investor DSCR >=120.00 30 Yr Fixed I/O - EG",
				label: "LFC Non-QM 5-8 Investor DSCR >=120.00 30 Yr Fixed I/O - EG",
			},
			{
				value: "LFC Non-QM 5-8 Investor DSCR >=120.00 5/6 SOFR ARM - EG",
				label: "LFC Non-QM 5-8 Investor DSCR >=120.00 5/6 SOFR ARM - EG",
			},
			{
				value: "LFC Non-QM 5-8 Investor DSCR >=120.00 5/6 SOFR ARM I/O - EG",
				label: "LFC Non-QM 5-8 Investor DSCR >=120.00 5/6 SOFR ARM I/O - EG",
			},
			{
				value: "LFC Non-QM 5-8 Investor DSCR 110.00 - 119.99 5/6 SOFR ARM - EG",
				label: "LFC Non-QM 5-8 Investor DSCR 110.00 - 119.99 5/6 SOFR ARM - EG",
			},
			{
				value:
					"LFC Non-QM 5-8 Investor DSCR 110.00 - 119.99 5/6 SOFR ARM I/O - EG",
				label:
					"LFC Non-QM 5-8 Investor DSCR 110.00 - 119.99 5/6 SOFR ARM I/O - EG",
			},
			{
				value: "LFC Non-QM 5-8 Investor DSCR 30 Yr Fixed - EG",
				label: "LFC Non-QM 5-8 Investor DSCR 30 Yr Fixed - EG",
			},
			{
				value: "LFC Non-QM 5-8 Investor DSCR 30 Yr Fixed I/O - EG",
				label: "LFC Non-QM 5-8 Investor DSCR 30 Yr Fixed I/O - EG",
			},
			{
				value: "LFC Non-QM 5-8 Investor DSCR 5/6 SOFR AM - EG",
				label: "LFC Non-QM 5-8 Investor DSCR 5/6 SOFR AM - EG",
			},
			{
				value: "LFC Non-QM 5-8 Investor DSCR 5/6 SOFR AM I/O - EG",
				label: "LFC Non-QM 5-8 Investor DSCR 5/6 SOFR AM I/O - EG",
			},
		],
		"Alternative Documentation": [
			{
				value: "LFC Non-QM Alt Doc 30 Yr Fixed - EG",
				label: "LFC Non-QM Alt Doc 30 Yr Fixed - EG",
			},
			{
				value: "LFC Non-QM Alt Doc 30 Yr Fixed I/O - EG",
				label: "LFC Non-QM Alt Doc 30 Yr Fixed I/O - EG",
			},
			{
				value: "LFC Non-QM Alt Doc 40 Yr Fixed I/O - EG",
				label: "LFC Non-QM Alt Doc 40 Yr Fixed I/O - EG",
			},
			{
				value: "LFC Non-QM Alt Doc 5/6-mo ARM SOFR - EG",
				label: "LFC Non-QM Alt Doc 5/6-mo ARM SOFR - EG",
			},
			{
				value: "LFC Non-QM Alt Doc 5/6-mo ARM SOFR 30 Yr I/O - EG",
				label: "LFC Non-QM Alt Doc 5/6-mo ARM SOFR 30 Yr I/O - EG",
			},
			{
				value: "LFC Non-QM Alt Doc 5/6-mo ARM SOFR 40 Yr I/O - EG",
				label: "LFC Non-QM Alt Doc 5/6-mo ARM SOFR 40 Yr I/O - EG",
			},
		],
		"Full Documentation": [
			{
				value: "LFC Non-QM Full Doc 30 Yr Fixed - EG",
				label: "LFC Non-QM Full Doc 30 Yr Fixed - EG",
			},
			{
				value: "LFC Non-QM Full Doc 30 Yr Fixed I/O - EG",
				label: "LFC Non-QM Full Doc 30 Yr Fixed I/O - EG",
			},
			{
				value: "LFC Non-QM Full Doc 40 Yr Fixed I/O - EG",
				label: "LFC Non-QM Full Doc 40 Yr Fixed I/O - EG",
			},
			{
				value: "LFC Non-QM Full Doc 5/6-mo ARM SOFR - EG",
				label: "LFC Non-QM Full Doc 5/6-mo ARM SOFR - EG",
			},
			{
				value: "LFC Non-QM Full Doc 5/6-mo ARM SOFR 30 Yr I/O - EG",
				label: "LFC Non-QM Full Doc 5/6-mo ARM SOFR 30 Yr I/O - EG",
			},
			{
				value: "LFC Non-QM Full Doc 5/6-mo ARM SOFR 40 Yr I/O - EG",
				label: "LFC Non-QM Full Doc 5/6-mo ARM SOFR 40 Yr I/O - EG",
			},
		],
		DSCR: [
			{
				value: "LFC Non-QM Investor DSCR 30 Yr Fixed - EG",
				label: "LFC Non-QM Investor DSCR 30 Yr Fixed - EG",
			},
			{
				value: "LFC Non-QM Investor DSCR 30 Yr Fixed I/O - EG",
				label: "LFC Non-QM Investor DSCR 30 Yr Fixed I/O - EG",
			},
			{
				value: "LFC Non-QM Investor DSCR 40 Yr Fixed I/O - EG",
				label: "LFC Non-QM Investor DSCR 40 Yr Fixed I/O - EG",
			},
			{
				value: "LFC Non-QM Investor DSCR 5/6-mo ARM SOFR - EG",
				label: "LFC Non-QM Investor DSCR 5/6-mo ARM SOFR - EG",
			},
			{
				value: "LFC Non-QM Investor DSCR 5/6-mo ARM SOFR 30 Yr I/O - EG",
				label: "LFC Non-QM Investor DSCR 5/6-mo ARM SOFR 30 Yr I/O - EG",
			},
			{
				value: "LFC Non-QM Investor DSCR 5/6-mo ARM SOFR 40 Yr I/O - EG",
				label: "LFC Non-QM Investor DSCR 5/6-mo ARM SOFR 40 Yr I/O - EG",
			},
		],
		"DSCR: Elite": [
			{
				value: "LFC Non-QM Investor Elite DSCR 30 Yr Fixed - EG",
				label: "LFC Non-QM Investor Elite DSCR 30 Yr Fixed - EG",
			},
			{
				value: "LFC Non-QM Investor Elite DSCR 30 Yr Fixed I/O - EG",
				label: "LFC Non-QM Investor Elite DSCR 30 Yr Fixed I/O - EG",
			},
			{
				value: "LFC Non-QM Investor Elite DSCR 5/6-mo ARM SOFR - EG",
				label: "LFC Non-QM Investor Elite DSCR 5/6-mo ARM SOFR - EG",
			},
			{
				value: "LFC Non-QM Investor Elite DSCR 5/6-mo ARM SOFR 30 Yr I/O - EG",
				label: "LFC Non-QM Investor Elite DSCR 5/6-mo ARM SOFR 30 Yr I/O - EG",
			},
		],
		"DSCR: Mixed Use": [
			{
				value: "LFC Non-QM Mixed Use Investor DSCR 30 Yr Fixed - EG",
				label: "LFC Non-QM Mixed Use Investor DSCR 30 Yr Fixed - EG",
			},
			{
				value: "LFC Non-QM Mixed Use Investor DSCR 30 Yr Fixed I/O - EG",
				label: "LFC Non-QM Mixed Use Investor DSCR 30 Yr Fixed I/O - EG",
			},
			{
				value: "LFC Non-QM Mixed Use Investor DSCR 5/6 SOFR ARM - EG",
				label: "LFC Non-QM Mixed Use Investor DSCR 5/6 SOFR ARM - EG",
			},
			{
				value: "LFC Non-QM Mixed Use Investor DSCR 5/6 SOFR ARM I/O - EG",
				label: "LFC Non-QM Mixed Use Investor DSCR 5/6 SOFR ARM I/O - EG",
			},
		],
	};

	const incomeDocumentOptions = {
		"Full Documentation": [
			{
				label: "Full Documentation",
				value: "Full Doc",
			},
		],
		"Alternative Documentation": [
			{
				label: "12 Mo. Business Bank Statement",
				value: "Business Bank Stmt: 12 Mos",
			},
			{
				label: "24 Mo. Business Bank Statement",
				value: "Business Bank Stmt: 24 Mos",
			},
			{
				label: "12 Mo. Personal Bank Statement",
				value: "Personal Bank Stmt: 12 Mos",
			},
			{
				label: "24 Mo. Personal Bank Statement",
				value: "Personal Bank Stmt: 24 Mos",
			},
			{
				label: "Asset Related",
				value: "Asset Related",
			},
			{
				label: "Profit and Loss: 1 Year",
				value: "P&L: 1 Year",
			},
			{
				label: "Profit and Loss: 2 Year",
				value: "P&L: 2 Year",
			},
			{
				label: "1099: 1 Year",
				value: "1099: 1 Year",
			},
			{
				label: "1099: 2 Year",
				value: "1099: 2 Year",
			},
		],
		"DSCR: 5-8 Unit": [
			{
				label: "DSCR",
				value: "Investor - DSCR",
			},
		],
		"DSCR: Mixed Use": [
			{
				label: "DSCR",
				value: "Investor - DSCR",
			},
		],
		"DSCR: Elite": [
			{
				label: "DSCR",
				value: "Investor - DSCR",
			},
		],
		DSCR: [
			{
				label: "DSCR",
				value: "Investor - DSCR",
			},
		],
	};

	const [loanProductFamily, setLoanProductFamily] = useState("");
	const [loanProduct, setLoanProduct] = useState("");
	const [documentType, setDocumentType] = useState("");
	const [loanPurpose, setLoanPurpose] = useState("");
	const [purchasePrice, setPurchasePrice] = useState("");
	const [estimatedValue, setEstimatedValue] = useState("");
	const [loanAmount, setLoanAmount] = useState("");
	const [employmentStatus, setEmploymentStatus] = useState("");
	const [occupancy, setOccupancy] = useState("");
	const [borrowerCitizenshipStatus, setBorrowerCitizenshipStatus] =
		useState("");
	const [coBorrowerCitizenshipStatus, setCoBorrowerCitizenshipStatus] =
		useState("");
	const [loanValue, setLoanValue] = useState(0);

	const [reservesDuration, setReservesDuration] = useState(0);

	const [paymentPenalty, setPaymentPenalty] = useState("");
	const [interestOnly, setInterestOnly] = useState("");
	const [cashOut, setCashOut] = useState("");

	const [creditScore, setCreditScore] = useState("");
	const [housingType, setHousingType] = useState("");
	const [housingSeasoning, setHousingSeasoning] = useState("");
	const [bankruptcyType, setBankruptcyType] = useState("");
	const [bankruptcyOutcome, setBankruptcyOutcome] = useState("");
	const [bankruptcySeasoning, setBankruptcySeasoning] = useState("");

	const [mortgageLates, setMortgageLates] = useState(null);
	const [mortgageLates1, setMortgageLates1] = useState("");
	const [mortgageLates2, setMortgageLates2] = useState("");
	const [mortgageLates3, setMortgageLates3] = useState("");
	const [mortgageLates4, setMortgageLates4] = useState("");
	const [mortgageLates5, setMortgageLates5] = useState("");
	const [mortgageLates6, setMortgageLates6] = useState("");
	const [mortgageLates7, setMortgageLates7] = useState("");
	const [mortgageLates8, setMortgageLates8] = useState("");
	const [mortgageLates9, setMortgageLates9] = useState("");

	const [propertyType, setPropertyType] = useState("");
	const [unitCount, setUnitCount] = useState("");
	const [state, setState] = useState("");
	const [zipcode, setZipcode] = useState("");
	const [county, setCounty] = useState("");
	const [countyListData, setCountyListData] = useState("");
	const [occupancyRate, setOccupancyRate] = useState(null);

	const [requestedInterestRate, setRequestedInterestRate] = useState("");
	const [mortgageTotal, setMortgageTotal] = useState("");
	const [drawAmount, setDrawAmount] = useState("");
	const [creditLimit, setCreditLimit] = useState("");

	const [cltv, setCltv] = useState("");
	const [hcltv, setHcltv] = useState("");

	const [escrow, setEscrow] = useState("");

	const [error, setError] = useState("");
	const [inputError, setInputError] = useState(false);
	const [creditLimitError, setCreditLimitError] = useState(false);

	useEffect(() => {
		async function getCountyList() {
			const countyListResponse = await countyList({
				q: zipcode,
			}).unwrap();
			console.log("70 county list response:", countyListResponse?.response);
			let facetGroups = countyListResponse?.response?.facet_groups;
			console.log("79 coty name:", facetGroups);

			let cotyValues = facetGroups?.find(
				(item) => item.name === "coty_name"
			).facets;

			console.log("82 coty value:", cotyValues);

			let valueArr = [];

			if (cotyValues !== undefined && cotyValues?.length > 0) {
				setCounty(cotyValues[0]?.name);

				valueArr = cotyValues?.map((item) => ({
					...item,
					label: item.name,
					value: item.name,
				}));
			}

			setCountyListData(valueArr);
		}

		getCountyList();
	}, [zipcode]);

	const handleIncomeDocUpdate = (loanProductValue) => {
		console.log("1390 loan product value:", loanProductValue);

		if (loanProductValue === "Full Documentation") {
			setDocumentType("Full Doc");
		} else if (loanProductValue?.includes("DSCR")) {
			setDocumentType("Investor - DSCR");
		} else {
			setDocumentType("");
		}
	};

	const updateField = (fieldValues) => {
		console.log("88 field values:", fieldValues);

		setCreditScore(fieldValues?.find((o) => o.fieldId === "VASUMM.X23")?.value);
		setRequestedInterestRate(
			fieldValues?.find((o) => o.fieldId === "3")?.value
		);
		setMortgageTotal(fieldValues?.find((o) => o.fieldId === "427")?.value);
		setDrawAmount(
			fieldValues?.find((o) => o.fieldId === "CASASRN.X167")?.value
		);
		setCreditLimit(
			fieldValues?.find((o) => o.fieldId === "CASASRN.X168")?.value
		);
		setCltv(fieldValues?.find((o) => o.fieldId === "976")?.value);
		setHcltv(fieldValues?.find((o) => o.fieldId === "1540")?.value);
		setEscrow(fieldValues?.find((o) => o.fieldId === "MORNET.X15")?.value);

		let loanProductValue = fieldValues?.find(
			(o) => o.fieldId === "1401"
		)?.value;
		console.log("168 loan product:", loanProductValue);
		console.log("168 loan product family:", loanProductOptions);

		handleIncomeDocUpdate(loanProductValue);

		for (const key in loanProductOptions) {
			if (loanProductOptions.hasOwnProperty(key)) {
				if (
					loanProductOptions[key]?.findIndex(
						(item) => item.value === loanProductValue
					) >= 0
				) {
					setLoanProductFamily(key);
					setLoanProduct(loanProductValue);
				}
			}
		}

		setLoanPurpose(fieldValues?.find((o) => o.fieldId === "19")?.value);

		setPurchasePrice(fieldValues?.find((o) => o.fieldId === "136")?.value);

		if (fieldValues?.find((o) => o.fieldId === "356")?.value !== "") {
			setEstimatedValue(fieldValues?.find((o) => o.fieldId === "356")?.value);
		} else {
			setEstimatedValue(fieldValues?.find((o) => o.fieldId === "1821")?.value);
		}

		setLoanAmount(fieldValues?.find((o) => o.fieldId === "1109")?.value);
		setEmploymentStatus(
			fieldValues?.find((o) => o.fieldId === "FE0115")?.value
		);
		let occupancyValue = fieldValues?.find((o) => o.fieldId === "1811")?.value;
		if (occupancyValue === "") {
			setOccupancy("Investor");
		} else {
			setOccupancy(occupancyValue);
		}
		setLoanValue(fieldValues?.find((o) => o.fieldId === "353")?.value);

		setReservesDuration(fieldValues?.find((o) => o.fieldId === "1548")?.value);

		if (loanGuid !== undefined && loanGuid !== "") {
			if (fieldValues?.find((o) => o.fieldId === "1947")?.value === "") {
				setPaymentPenalty(0);
			} else {
				setPaymentPenalty(
					fieldValues?.find((o) => o.fieldId === "1947")?.value
				);
			}
		} else {
			setPaymentPenalty(fieldValues?.find((o) => o.fieldId === "1947")?.value);
		}

		setInterestOnly(fieldValues?.find((o) => o.fieldId === "2982")?.value);
		setCashOut(fieldValues?.find((o) => o.fieldId === "CASASRN.X79")?.value);
		setHousingType(
			fieldValues?.find((o) => o.fieldId === "CX.HOUSINGEVENTTYPE")?.value
		);

		setHousingSeasoning(
			fieldValues?.find((o) => o.fieldId === "CX.HOUSINGEVENTSEASONING")?.value
		);
		setBankruptcyType(
			fieldValues?.find((o) => o.fieldId === "CX.BANKRUPTCYTYPE")?.value
		);
		setBankruptcyOutcome(
			fieldValues?.find((o) => o.fieldId === "CX.BANKRUPTCYOUTCOME")?.value
		);
		setBankruptcySeasoning(
			fieldValues?.find((o) => o.fieldId === "CX.BANKRUPTCYSEASONING")?.value
		);

		setMortgageLates(
			fieldValues?.find((o) => o.fieldId === "CX.MTGLATE.ANY")?.value
		);

		let mortgageLatesValue1 = fieldValues?.find(
			(o) => o.fieldId === "CX.MTGLATEX30MOS6"
		)?.value;

		if (mortgageLatesValue1 !== "" && Number(mortgageLatesValue1) > 0) {
			setMortgageLates1(
				Number(mortgageLatesValue1) > 0 ? mortgageLatesValue1 : ""
			);
			setMortgageLates("Y");
		}

		let mortgageLatesValue2 = fieldValues?.find(
			(o) => o.fieldId === "CX.MTGLATEX30MOS12"
		)?.value;

		if (mortgageLatesValue2 !== "" && Number(mortgageLatesValue2) > 0) {
			setMortgageLates2(
				Number(mortgageLatesValue2) > 0 ? mortgageLatesValue2 : ""
			);
			setMortgageLates("Y");
		}
		let mortgageLatesValue3 = fieldValues?.find(
			(o) => o.fieldId === "CX.MTGLATEX60MOS12"
		)?.value;
		if (mortgageLatesValue3 !== "" && Number(mortgageLatesValue3) > 0) {
			setMortgageLates3(
				Number(mortgageLatesValue3) > 0 ? mortgageLatesValue3 : ""
			);
			setMortgageLates("Y");
		}
		let mortgageLatesValue4 = fieldValues?.find(
			(o) => o.fieldId === "CX.MTGLATEX90MOS12"
		)?.value;
		if (mortgageLatesValue4 !== "" && Number(mortgageLatesValue4) > 0) {
			setMortgageLates4(
				Number(mortgageLatesValue4) > 0 ? mortgageLatesValue4 : ""
			);
			setMortgageLates("Y");
		}
		let mortgageLatesValue5 = fieldValues?.find(
			(o) => o.fieldId === "CX.MTGLATEX120MOS12"
		)?.value;
		if (mortgageLatesValue5 !== "" && Number(mortgageLatesValue5) > 0) {
			setMortgageLates5(
				Number(mortgageLatesValue5) > 0 ? mortgageLatesValue5 : ""
			);
			setMortgageLates("Y");
		}
		let mortgageLatesValue6 = fieldValues?.find(
			(o) => o.fieldId === "CX.MTGLATEX30MOS13TO24"
		)?.value;
		if (mortgageLatesValue6 !== "" && Number(mortgageLatesValue6) > 0) {
			setMortgageLates6(
				Number(mortgageLatesValue6) > 0 ? mortgageLatesValue6 : ""
			);
			setMortgageLates("Y");
		}
		let mortgageLatesValue7 = fieldValues?.find(
			(o) => o.fieldId === "CX.MTGLATEX60MOS13TO24"
		)?.value;
		if (mortgageLatesValue7 !== "" && Number(mortgageLatesValue7) > 0) {
			setMortgageLates7(
				Number(mortgageLatesValue7) > 0 ? mortgageLatesValue7 : ""
			);
			setMortgageLates("Y");
		}
		let mortgageLatesValue8 = fieldValues?.find(
			(o) => o.fieldId === "CX.MTGLATEX90MOS13TO24"
		)?.value;
		if (mortgageLatesValue8 !== "" && Number(mortgageLatesValue8) > 0) {
			setMortgageLates8(
				Number(mortgageLatesValue8) > 0 ? mortgageLatesValue8 : ""
			);
			setMortgageLates("Y");
		}
		let mortgageLatesValue9 = fieldValues?.find(
			(o) => o.fieldId === "CX.MTGLATEX120MOS13TO24"
		)?.value;
		if (mortgageLatesValue9 !== "" && Number(mortgageLatesValue9) > 0) {
			setMortgageLates9(
				Number(mortgageLatesValue9) > 0 ? mortgageLatesValue9 : ""
			);
			setMortgageLates("Y");
		}

		let unitCountValue;

		let fieldUnitCount = fieldValues?.find((o) => o.fieldId === "16")?.value;
		if (fieldUnitCount !== "") {
			unitCountValue = fieldUnitCount;
		}

		let customFieldUnitCount = fieldValues?.find(
			(o) => o.fieldId === "CX.PROPERTY.COMMERCIAL.UNITS"
		)?.value;
		if (customFieldUnitCount !== "") {
			unitCountValue = customFieldUnitCount;
		}

		setUnitCount(unitCountValue);
		setState(fieldValues?.find((o) => o.fieldId === "14")?.value);
		setZipcode(fieldValues?.find((o) => o.fieldId === "15")?.value);
		setCounty(fieldValues?.find((o) => o.fieldId === "13")?.value);
		setOccupancyRate(fieldValues?.find((o) => o.fieldId === "1487")?.value);

		setDocumentType(
			fieldValues?.find((o) => o.fieldId === "CX.INCOMEVERIFYTYPE")?.value
		);

		let citizenshipStatusValue = fieldValues?.find(
			(o) => o.fieldId === "URLA.X1"
		)?.value;
		let borrowerForeignNationalValue = fieldValues?.find(
			(o) => o.fieldId === "CX.FOREIGNNATLBORR1"
		)?.value;

		if (citizenshipStatusValue === "" && borrowerForeignNationalValue === "Y") {
			setBorrowerCitizenshipStatus("ForeignNational");
		} else {
			setBorrowerCitizenshipStatus(citizenshipStatusValue);
		}

		let coBorrowerForeignNationalValue = fieldValues?.find(
			(o) => o.fieldId === "CX.FOREIGNNATLBORR1"
		)?.value;

		if (
			citizenshipStatusValue === "" &&
			coBorrowerForeignNationalValue === "Y"
		) {
			setCoBorrowerCitizenshipStatus("ForeignNational");
		} else {
			setCoBorrowerCitizenshipStatus(citizenshipStatusValue);
		}

		let propertyTypeValue = fieldValues?.find(
			(o) => o.fieldId === "1041"
		)?.value;
		let nonWarrantableValue = fieldValues?.find(
			(o) => o.fieldId === 3316
		)?.value;
		let propertyCommercialValue = fieldValues?.find(
			(o) => o.fieldId === "CX.PROPERTY.COMMERCIAL"
		)?.value;
		let propertyCommercialTypeValue = fieldValues?.find(
			(o) => o.fieldId === "CX.PROPERTY.COMMERCIAL.TYPE"
		)?.value;
		let isCondominium = fieldValues?.find(
			(o) => o.fieldId === "URLA.X205"
		)?.value;

		let isPUD = fieldValues?.find((o) => o.fieldId === "URLA.X207")?.value;

		let isEqualToOrMore9 = unitCountValue >= 9;

		if (
			propertyTypeValue === "Detached" &&
			propertyCommercialTypeValue === "Mixed Use Residential" &&
			propertyCommercialValue &&
			unitCountValue >= 0 &&
			isEqualToOrMore9
		) {
			setPropertyType("9+ Units Mixed Use");
		} else if (
			propertyTypeValue === "Detached" &&
			propertyCommercialTypeValue === "Residential 9+" &&
			propertyCommercialValue &&
			unitCountValue >= 0
		) {
			setPropertyType("9+ Units Residential");
		} else if (
			propertyTypeValue === "Detached" &&
			propertyCommercialTypeValue === "Mixed Use Residential" &&
			propertyCommercialValue &&
			unitCountValue >= 0 &&
			!isEqualToOrMore9
		) {
			setPropertyType("5-8 Unit w/ Mixed Use");
		} else if (
			propertyTypeValue === "Detached" &&
			propertyCommercialTypeValue === "Residential 5 - 8" &&
			propertyCommercialValue &&
			unitCountValue >= 0
		) {
			setPropertyType("5-8 Unit Residential");
		} else if (propertyTypeValue === "Detached") {
			setPropertyType("Detached");
		} else if (propertyTypeValue === "Attached") {
			setPropertyType("Attached");
		} else if (
			propertyTypeValue === "Condominium" &&
			!nonWarrantableValue &&
			isCondominium
		) {
			setPropertyType("Condominium - Warrantable");
		} else if (
			propertyTypeValue === "Condominium" &&
			nonWarrantableValue &&
			isCondominium
		) {
			setPropertyType("Condominium - Non-Warrantable");
		} else if (propertyTypeValue === "PUD" && isPUD) {
			setPropertyType("PUD");
		} else {
			setPropertyType("");
		}
	};

	const getFieldValues = async (loanId) => {
		console.log("175 loan id:", loanId);
		try {
			const getValues = await field({
				loan_guid: loanId,
				fields:
					"1401,1487,MORNET.X67,19,URLA.X165,136,356,1821,1109,FE0115,1811,URLA.X1,URLA.X205,URLA.X207,353,CX.DSCR.CALC,HMDA.X36,1548,1947,2982,MORNET.X15,CASASRN.X79,VASUMM.X23,CX.PROPERTY.COMMERCIAL.UNITS,CX.HOUSINGEVENTTYPE,CX.HOUSINGEVENTSEASONING,CX.BANKRUPTCYTYPE,CX.BANKRUPTCYOUTCOME,CX.BANKRUPTCYSEASONING,1041,16,14,15,13,CX.IncomeVerifyType,CX.FOREIGNNATLBORR1,CX.FOREIGNNATLBORR2,3316,CX.Property.Commercial,CX.PROPERTY.COMMERCIAL.TYPE,3,427,CASASRN.X167,CASASRN.X168,976,1540,CX.MTGLATE.ANY,CX.MTGLATEX30MOS6,CX.MTGLATEX30MOS12,CX.MTGLATEX60MOS12,CX.MTGLATEX90MOS12,CX.MTGLATEX120MOS12,CX.MTGLATEX30MOS13TO24,CX.MTGLATEX60MOS13TO24,CX.MTGLATEX90MOS13TO24,CX.MTGLATEX120MOS13TO24",
			}).unwrap();
			console.log("175 all fields:", getValues?.response);
			if (getValues?.response?.length > 0) {
				updateField(getValues?.response);
			}
		} catch (err) {
			console.log("270 error:", err);
		}
	};

	useEffect(() => {
		console.log("160 params:", params?.id);
		if (params?.id !== undefined && params?.id !== "") {
			console.log("180 params:", params?.id);
			getFieldValues(params?.id);
		}
	}, [params]);

	useEffect(() => {
		console.log("160 loan Guid:", loanGuid);
		if (loanGuid !== undefined && loanGuid !== "") {
			getFieldValues(loanGuid);
		}
	}, [loanGuid]);

	useEffect(() => {
		if (data !== undefined && Object.values(data)?.length > 0) {
			console.log("345 encompass id:", data?.encompassId);
			getFieldValues(data?.encompassId);
		}
	}, [data]);

	const handlePurchasePrice = (value) => {
		setPurchasePrice(value);

		// If Loan Purpose = “Purchase” then us lower of the “Purchase Price” or “Estimated Value”
		if (loanPurpose?.toLowerCase() === "purchase") {
			let calculationDenominator;

			if (Number(value) === 0 || Number(estimatedValue) === 0) {
				calculationDenominator =
					Number(value) > Number(estimatedValue)
						? Number(value)
						: Number(estimatedValue);

				console.log(
					"At least one value is zero, selecting higher value.",
					calculationDenominator
				);
			} else {
				calculationDenominator =
					Number(value) < Number(estimatedValue)
						? Number(value)
						: Number(estimatedValue);

				console.log(
					"Comparing values and selecting lesser value.",
					calculationDenominator
				);
			}

			setLoanValue(((loanAmount / calculationDenominator) * 100).toFixed(3));
			setCltv(
				(
					((Number(loanAmount || 0) +
						Number(mortgageTotal || 0) +
						Number(drawAmount || 0)) /
						calculationDenominator) *
					100
				).toFixed(3)
			);
			setHcltv(
				(
					((Number(loanAmount || 0) +
						Number(mortgageTotal || 0) +
						Number(creditLimit || 0)) /
						calculationDenominator) *
					100
				).toFixed(3)
			);
		}
	};

	const handleEstimatedValue = (value) => {
		setEstimatedValue(value);

		// If Loan Purpose = “Purchase” then us lower of the “Purchase Price” or “Estimated Value”
		if (loanPurpose?.toLowerCase() === "purchase") {
			let calculationDenominator;

			if (Number(purchasePrice) === 0 || Number(value) === 0) {
				calculationDenominator =
					Number(purchasePrice) > Number(value)
						? Number(purchasePrice)
						: Number(value);

				console.log(
					"565 At least one value is zero, selecting higher value.",
					calculationDenominator
				);
			} else {
				calculationDenominator =
					Number(purchasePrice) < Number(value)
						? Number(purchasePrice)
						: Number(value);

				console.log(
					"575 Comparing values and selecting lesser value.",
					calculationDenominator
				);
			}

			setLoanValue(((loanAmount / calculationDenominator) * 100).toFixed(3));
			setCltv(
				(
					((Number(loanAmount || 0) +
						Number(mortgageTotal || 0) +
						Number(drawAmount || 0)) /
						calculationDenominator) *
					100
				).toFixed(3)
			);
			setHcltv(
				(
					((Number(loanAmount || 0) +
						Number(mortgageTotal || 0) +
						Number(creditLimit || 0)) /
						calculationDenominator) *
					100
				).toFixed(3)
			);
		} else {
			let calculationDenominator = Number(value);

			setLoanValue(((loanAmount / calculationDenominator) * 100).toFixed(3));
			setCltv(
				(
					((Number(loanAmount || 0) +
						Number(mortgageTotal || 0) +
						Number(drawAmount || 0)) /
						calculationDenominator) *
					100
				).toFixed(3)
			);
			setHcltv(
				(
					((Number(loanAmount || 0) +
						Number(mortgageTotal || 0) +
						Number(creditLimit || 0)) /
						calculationDenominator) *
					100
				).toFixed(3)
			);
		}
	};

	const handleLoanAmount = (value) => {
		setLoanAmount(value);

		console.log("625");

		// If Loan Purpose = “Purchase” then us lower of the “Purchase Price” or “Estimated Value”
		if (loanPurpose?.toLowerCase() === "purchase") {
			let calculationDenominator;

			console.log("650 purchase:", purchasePrice);
			console.log("650 estimated:", estimatedValue);

			if (Number(purchasePrice) === 0 || Number(estimatedValue) === 0) {
				calculationDenominator =
					Number(purchasePrice) > Number(estimatedValue)
						? Number(purchasePrice)
						: Number(estimatedValue);

				console.log(
					"565 At least one value is zero, selecting higher value.",
					calculationDenominator
				);
			} else {
				calculationDenominator =
					Number(purchasePrice) < Number(estimatedValue)
						? Number(purchasePrice)
						: Number(estimatedValue);

				console.log(
					"575 Comparing values and selecting lesser value.",
					calculationDenominator
				);
			}

			setLoanValue(((value / calculationDenominator) * 100).toFixed(3));
			setCltv(
				(
					((Number(value) +
						Number(mortgageTotal || 0) +
						Number(drawAmount || 0)) /
						calculationDenominator) *
					100
				).toFixed(3)
			);
			setHcltv(
				(
					((Number(value) +
						Number(mortgageTotal || 0) +
						Number(creditLimit || 0)) /
						calculationDenominator) *
					100
				).toFixed(3)
			);
		} else {
			let calculationDenominator = Number(estimatedValue);

			console.log("600 loan amount:", value);
			console.log("600 calc denom:", calculationDenominator);
			console.log("600 calc math:", (value / calculationDenominator) * 100);

			setLoanValue(((value / calculationDenominator) * 100).toFixed(3));
			setCltv(
				(
					((Number(value) +
						Number(mortgageTotal || 0) +
						Number(drawAmount || 0)) /
						calculationDenominator) *
					100
				).toFixed(3)
			);
			setHcltv(
				(
					((Number(value) +
						Number(mortgageTotal || 0) +
						Number(creditLimit || 0)) /
						calculationDenominator) *
					100
				).toFixed(3)
			);
		}
	};

	const handleMortgageTotal = (value) => {
		setMortgageTotal(value);

		// If Loan Purpose = “Purchase” then us lower of the “Purchase Price” or “Estimated Value”
		if (loanPurpose?.toLowerCase() === "purchase") {
			let calculationDenominator;

			if (Number(purchasePrice) === 0 || Number(estimatedValue) === 0) {
				calculationDenominator =
					Number(purchasePrice) > Number(estimatedValue)
						? Number(purchasePrice)
						: Number(estimatedValue);

				console.log(
					"565 At least one value is zero, selecting higher value.",
					calculationDenominator
				);
			} else {
				calculationDenominator =
					Number(purchasePrice) < Number(estimatedValue)
						? Number(purchasePrice)
						: Number(estimatedValue);

				console.log(
					"575 Comparing values and selecting lesser value.",
					calculationDenominator
				);
			}

			setCltv(
				(
					((Number(loanAmount || 0) +
						Number(value || 0) +
						Number(drawAmount || 0)) /
						calculationDenominator) *
					100
				).toFixed(3)
			);
			setHcltv(
				(
					((Number(loanAmount || 0) +
						Number(value || 0) +
						Number(creditLimit || 0)) /
						calculationDenominator) *
					100
				).toFixed(3)
			);
		} else {
			let calculationDenominator = Number(estimatedValue);

			setCltv(
				(
					((Number(loanAmount || 0) +
						Number(value || 0) +
						Number(drawAmount || 0)) /
						calculationDenominator) *
					100
				).toFixed(3)
			);
			setHcltv(
				(
					((Number(loanAmount || 0) +
						Number(value || 0) +
						Number(creditLimit || 0)) /
						calculationDenominator) *
					100
				).toFixed(3)
			);
		}
	};

	const handleDrawAmount = (value) => {
		setDrawAmount(value);

		// If Loan Purpose = “Purchase” then us lower of the “Purchase Price” or “Estimated Value”
		if (loanPurpose?.toLowerCase() === "purchase") {
			let calculationDenominator;

			if (Number(purchasePrice) === 0 || Number(estimatedValue) === 0) {
				calculationDenominator =
					Number(purchasePrice) > Number(estimatedValue)
						? Number(purchasePrice)
						: Number(estimatedValue);

				console.log(
					"565 At least one value is zero, selecting higher value.",
					calculationDenominator
				);
			} else {
				calculationDenominator =
					Number(purchasePrice) < Number(estimatedValue)
						? Number(purchasePrice)
						: Number(estimatedValue);

				console.log(
					"575 Comparing values and selecting lesser value.",
					calculationDenominator
				);
			}

			setCltv(
				(
					((Number(loanAmount || 0) +
						Number(mortgageTotal || 0) +
						Number(value || 0)) /
						calculationDenominator) *
					100
				).toFixed(3)
			);
		} else {
			let calculationDenominator = Number(estimatedValue);

			setCltv(
				(
					((Number(loanAmount || 0) +
						Number(mortgageTotal || 0) +
						Number(value || 0)) /
						calculationDenominator) *
					100
				).toFixed(3)
			);
		}
	};

	const handleCreditLimit = (value) => {
		setCreditLimit(value);

		// If Loan Purpose = “Purchase” then us lower of the “Purchase Price” or “Estimated Value”
		if (loanPurpose?.toLowerCase() === "purchase") {
			let calculationDenominator;

			if (Number(purchasePrice) === 0 || Number(estimatedValue) === 0) {
				calculationDenominator =
					Number(purchasePrice) > Number(estimatedValue)
						? Number(purchasePrice)
						: Number(estimatedValue);

				console.log(
					"565 At least one value is zero, selecting higher value.",
					calculationDenominator
				);
			} else {
				calculationDenominator =
					Number(purchasePrice) < Number(estimatedValue)
						? Number(purchasePrice)
						: Number(estimatedValue);

				console.log(
					"575 Comparing values and selecting lesser value.",
					calculationDenominator
				);
			}

			setHcltv(
				(
					((Number(loanAmount || 0) +
						Number(mortgageTotal || 0) +
						Number(value || 0)) /
						calculationDenominator) *
					100
				).toFixed(3)
			);
		} else {
			let calculationDenominator = Number(estimatedValue);

			setHcltv(
				(
					((Number(loanAmount || 0) +
						Number(mortgageTotal || 0) +
						Number(value || 0)) /
						calculationDenominator) *
					100
				).toFixed(3)
			);
		}
	};

	const handlePrev = () => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});
		setLoanApplicationStage(2);
	};

	const updateLoanFields = async () => {
		let valuesArr = [
			{ id: "1172", value: "Other" },
			{ id: "MORNET.X67", value: "Full Documentation" },
			{ id: "CX.IncomeVerifyType", value: documentType },
		];

		console.log("562 update loan field:", valuesArr);

		if (requestedInterestRate !== "") {
			valuesArr.push({ id: "3", value: requestedInterestRate });
		}
		if (mortgageTotal !== "") {
			valuesArr.push({ id: "427", value: mortgageTotal, lock: true });
		}
		if (drawAmount !== "" && drawAmount !== "NaN") {
			valuesArr.push({ id: "CASASRN.X167", value: drawAmount, lock: true });
		}
		if (creditLimit !== "" && creditLimit !== "NaN") {
			valuesArr.push({ id: "CASASRN.X168", value: creditLimit, lock: true });
		}
		if (cltv !== "" && cltv !== "NaN") {
			valuesArr.push({ id: "976", value: cltv, lock: true });
		}
		if (hcltv !== "" && hcltv !== "NaN") {
			valuesArr.push({ id: "1540", value: hcltv, lock: true });
		}
		if (occupancyRate !== "") {
			valuesArr.push({ id: 1487, value: occupancyRate });
		}
		if (loanProduct !== "") {
			valuesArr.push({ id: 1401, value: loanProduct });
		}
		if (loanPurpose !== "") {
			valuesArr.push({ id: "19", value: loanPurpose });
		}
		if (loanPurpose === "Purchase") {
			valuesArr.push({ id: "URLA.X165", value: "", lock: true });
		}
		if (loanPurpose === "NoCash-Out Refinance") {
			valuesArr.push({ id: "URLA.X165", value: "NoCashOut", lock: true });
		}
		if (loanPurpose === "Cash-Out Refinance") {
			valuesArr.push({ id: "URLA.X165", value: "CashOut", lock: true });
		}

		if (purchasePrice !== "") {
			valuesArr.push({ id: 136, value: purchasePrice?.split(".")[0] });
		}

		if (estimatedValue !== "") {
			valuesArr.push({ id: 356, value: estimatedValue?.split(".")[0] });
		}

		if (loanPurpose === "Purchase") {
			valuesArr.push({ id: 1821, value: purchasePrice?.split(".")[0] });
		} else {
			valuesArr.push({ id: 1821, value: estimatedValue?.split(".")[0] });
		}

		if (loanAmount !== "") {
			valuesArr.push({ id: 1109, value: loanAmount?.split(".")[0] });
		}

		if (employmentStatus !== "") {
			valuesArr.push({
				id: "FE0115",
				value: employmentStatus === "Y" ? true : false,
			});
		}
		if (escrow !== "") {
			valuesArr.push({
				id: "MORNET.X15",
				value: escrow === "Y" ? true : false,
			});
		}
		if (occupancy !== "") {
			valuesArr.push({ id: 1811, value: occupancy });
		}
		if (borrowerCitizenshipStatus !== "") {
			valuesArr.push({ id: "URLA.X1", value: borrowerCitizenshipStatus });
		}
		if (coBorrowerCitizenshipStatus !== "") {
			valuesArr.push({ id: "URLA.X2", value: coBorrowerCitizenshipStatus });
		}
		if (loanValue !== "" && loanValue !== "NaN") {
			valuesArr.push({ id: 353, value: loanValue });
		}
		if (reservesDuration !== "") {
			valuesArr.push({ id: 1548, value: reservesDuration });
		}
		if (paymentPenalty !== "" && paymentPenalty !== "0") {
			valuesArr.push({ id: 1947, value: paymentPenalty });
			valuesArr.push({ id: 1172, value: "Conventional" });
		}

		if (interestOnly !== "") {
			if (interestOnly === "Y") {
				valuesArr.push({
					id: 1177,
					value: "120",
				});
				valuesArr.push({
					id: 2982,
					value: true,
					lock: true,
				});
			} else {
				valuesArr.push({
					id: 1177,
					value: "",
				});
				valuesArr.push({
					id: 2982,
					value: false,
					lock: true,
				});
			}
		}

		if (cashOut !== "") {
			valuesArr.push({ id: "CASASRN.X79", value: cashOut, lock: true });
		}
		if (creditScore !== "") {
			valuesArr.push({ id: "VASUMM.X23", value: creditScore });
		}
		if (state !== "") {
			valuesArr.push({ id: 14, value: state });
		}
		if (zipcode !== "") {
			valuesArr.push({ id: 15, value: zipcode });
		}
		if (county !== "") {
			valuesArr.push({ id: 13, value: county });
		}

		if (housingType !== "") {
			valuesArr.push({ id: "CX.HOUSINGEVENTTYPE", value: housingType });
		}

		if (housingSeasoning !== "") {
			valuesArr.push({
				id: "CX.HOUSINGEVENTSEASONING",
				value: housingSeasoning,
			});
		}

		if (bankruptcyType !== "") {
			valuesArr.push({ id: "CX.BANKRUPTCYTYPE", value: bankruptcyType });
		}

		if (bankruptcyOutcome !== "") {
			valuesArr.push({ id: "CX.BANKRUPTCYOUTCOME", value: bankruptcyOutcome });
		}

		if (bankruptcySeasoning !== "") {
			valuesArr.push({
				id: "CX.BANKRUPTCYSEASONING",
				value: bankruptcySeasoning,
			});
		}

		if (mortgageLates1 !== "") {
			valuesArr.push({ id: "CX.MTGLATEX30MOS6", value: mortgageLates1 });
		}
		if (mortgageLates2 !== "") {
			valuesArr.push({ id: "CX.MTGLATEX30MOS12", value: mortgageLates2 });
		}
		if (mortgageLates3 !== "") {
			valuesArr.push({ id: "CX.MTGLATEX60MOS12", value: mortgageLates3 });
		}
		if (mortgageLates4 !== "") {
			valuesArr.push({ id: "CX.MTGLATEX90MOS12", value: mortgageLates4 });
		}
		if (mortgageLates5 !== "") {
			valuesArr.push({ id: "CX.MTGLATEX120MOS12", value: mortgageLates5 });
		}
		if (mortgageLates6 !== "") {
			valuesArr.push({ id: "CX.MTGLATEX30MOS13TO24", value: mortgageLates6 });
		}
		if (mortgageLates7 !== "") {
			valuesArr.push({ id: "CX.MTGLATEX60MOS13TO24", value: mortgageLates7 });
		}
		if (mortgageLates8 !== "") {
			valuesArr.push({ id: "CX.MTGLATEX90MOS13TO24", value: mortgageLates8 });
		}
		if (mortgageLates9 !== "") {
			valuesArr.push({ id: "CX.MTGLATEX120MOS13TO24", value: mortgageLates9 });
		}

		if (borrowerCitizenshipStatus === "ForeignNational") {
			valuesArr.push({ id: "CX.FOREIGNNATLBORR1", value: "Y" });
		}
		if (coBorrowerCitizenshipStatus === "ForeignNational") {
			valuesArr.push({ id: "CX.FOREIGNNATLBORR2", value: "Y" });
		}

		if (propertyType === "Attached") {
			valuesArr.push({ id: 1041, value: "Attached" });
			valuesArr.push({ id: "16", value: unitCount });
		} else if (propertyType === "Condominium - Warrantable") {
			valuesArr.push({ id: 1041, value: "Condominium" });
			valuesArr.push({ id: "URLA.X205", value: true });
			valuesArr.push({ id: 3316, value: false });
			valuesArr.push({ id: "16", value: unitCount });
		} else if (propertyType === "Condominium - Non-Warrantable") {
			valuesArr.push({ id: 1041, value: "Condominium" });
			valuesArr.push({ id: "URLA.X205", value: true });
			valuesArr.push({ id: 3316, value: true });
			valuesArr.push({ id: "16", value: unitCount });
		} else if (propertyType === "Detached") {
			valuesArr.push({ id: 1041, value: "Detached" });
			valuesArr.push({ id: "16", value: unitCount });
		} else if (propertyType === "PUD") {
			valuesArr.push({ id: 1041, value: "PUD" });
			valuesArr.push({ id: "URLA.X207", value: true });
			valuesArr.push({ id: "16", value: unitCount });
		} else if (propertyType === "5-8 Unit Residential") {
			valuesArr.push({ id: 16, value: "4" });
			valuesArr.push({ id: 1041, value: "Detached" });
			valuesArr.push({ id: "CX.PROPERTY.COMMERCIAL", value: true });
			valuesArr.push({
				id: "CX.PROPERTY.COMMERCIAL.TYPE",
				value: "Residential 5 - 8",
			});
			valuesArr.push({ id: "CX.PROPERTY.COMMERCIAL.UNITS", value: unitCount });
		} else if (propertyType === "5-8 Unit w/ Mixed Use") {
			valuesArr.push({ id: 16, value: "4" });
			valuesArr.push({ id: 1041, value: "Detached" });
			valuesArr.push({ id: "CX.PROPERTY.COMMERCIAL", value: true });
			valuesArr.push({
				id: "CX.PROPERTY.COMMERCIAL.TYPE",
				value: "Mixed Use Residential",
			});
			valuesArr.push({ id: "CX.PROPERTY.COMMERCIAL.UNITS", value: unitCount });
		} else if (propertyType === "9+ Units Residential") {
			valuesArr.push({ id: 16, value: "4" });
			valuesArr.push({ id: 1041, value: "Detached" });
			valuesArr.push({ id: "CX.PROPERTY.COMMERCIAL", value: true });
			valuesArr.push({
				id: "CX.PROPERTY.COMMERCIAL.TYPE",
				value: "Residential 9+",
			});
			valuesArr.push({ id: "CX.PROPERTY.COMMERCIAL.UNITS", value: unitCount });
		} else if (propertyType === "9+ Units Mixed Use") {
			valuesArr.push({ id: 16, value: "4" });
			valuesArr.push({ id: 1041, value: "Detached" });
			valuesArr.push({ id: "CX.PROPERTY.COMMERCIAL", value: true });
			valuesArr.push({
				id: "CX.PROPERTY.COMMERCIAL.TYPE",
				value: "Mixed Use Residential",
			});
			valuesArr.push({ id: "CX.PROPERTY.COMMERCIAL.UNITS", value: unitCount });
		}

		console.log("update loan fields:", valuesArr);

		try {
			const updateFieldValues = await updateFields({
				loan_guid: loanGuid || params?.id,
				field_values: valuesArr,
			}).unwrap();
			console.log("90 file res:", updateFieldValues);
			if (
				updateFieldValues?.response === "Field values updated successfully."
			) {
				setError("");
				window.scrollTo({
					top: 0,
					left: 0,
					behavior: "smooth",
				});
				setLoanApplicationStage(4);
			} else {
				setError(updateFieldValues?.response);
			}
		} catch (err) {
			console.log("90 file err:", err);
		}
	};

	const handleSubmitApplication = async () => {
		console.log("502 params id:", params?.id);
		console.log("502 loan id:", loanGuid);
		console.log("502 data:", data);

		// required fields pricing
		setInputError(false);
		setError("");
		setCreditLimitError(false);

		if (
			loanProductFamily === undefined ||
			loanProductFamily === "" ||
			loanProduct === undefined ||
			loanProduct === "" ||
			documentType === undefined ||
			documentType === "" ||
			loanPurpose === undefined ||
			loanPurpose === "" ||
			purchasePrice === undefined ||
			purchasePrice === "" ||
			purchasePrice === "0.00" ||
			estimatedValue === undefined ||
			estimatedValue === "" ||
			estimatedValue === "0.00" ||
			loanAmount === undefined ||
			loanAmount === "" ||
			loanAmount === "0.00" ||
			loanValue === undefined ||
			loanValue === "" ||
			employmentStatus === undefined ||
			employmentStatus === "" ||
			reservesDuration === undefined ||
			reservesDuration === "" ||
			paymentPenalty === undefined ||
			paymentPenalty === "" ||
			interestOnly === undefined ||
			interestOnly === "" ||
			escrow === undefined ||
			escrow === "" ||
			creditScore === undefined ||
			creditScore === "" ||
			creditScore === 0 ||
			housingType === undefined ||
			housingType === "" ||
			housingSeasoning === undefined ||
			housingSeasoning === "" ||
			bankruptcyType === undefined ||
			bankruptcyType === "" ||
			bankruptcyOutcome === undefined ||
			bankruptcyOutcome === "" ||
			bankruptcySeasoning === undefined ||
			bankruptcySeasoning === "" ||
			mortgageLates === null ||
			mortgageLates === undefined ||
			mortgageLates === "" ||
			propertyType === undefined ||
			propertyType === "" ||
			unitCount === undefined ||
			unitCount === "" ||
			unitCount === "0" ||
			unitCount === 0 ||
			state === undefined ||
			state === "" ||
			county === undefined ||
			county === ""
		) {
			setInputError(true);
			setError("Please provide a value for all fields highlighted above.");
			return;
		}

		if (Number(drawAmount) > 0) {
			if (
				creditLimit === undefined ||
				creditLimit === "" ||
				Number(creditLimit) === 0
			) {
				setCreditLimitError(true);
				setError(
					"The value entered for the field “HELOC Credit Limit” is not valid it must be greater than or equal to the value in Field “HELOC Draw Amount."
				);
				return;
			} else if (Number(creditLimit) < Number(drawAmount)) {
				setCreditLimitError(true);
				setError(
					"The value entered for the field “HELOC Credit Limit” is not valid it must be greater than or equal to the value in Field “HELOC Draw Amount."
				);
				return;
			}
		}

		if (loanPurpose === "Cash-Out Refinance") {
			if (
				cashOut === undefined ||
				cashOut === "" ||
				cashOut === "0" ||
				cashOut === "0.00"
			) {
				setInputError(true);
				setError("Please provide a value for all fields highlighted above.");
				return;
			}
		}

		if (loanGuid !== undefined && loanGuid !== "") {
			console.log("485 loan id:", loanGuid);
			updateLoanFields();
		} else if (params?.id !== undefined && params?.id !== "") {
			console.log("515 nav:", params?.id);
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: "smooth",
			});
			setLoanApplicationStage(4);
		}
	};

	const handleChangeCreditScore = (event) => {
		const min = 0;
		const max = 850;

		const value = Math.max(min, Math.min(max, Number(event.target.value)));
		setCreditScore(value);
	};

	const handleChangeUnitCount = (event) => {
		const min = 0;
		const max = 999;

		const value = Math.max(min, Math.min(max, Number(event.target.value)));
		console.log("830 value:", value);
		setUnitCount(value.toFixed(0));
	};

	const handleLoanPurpose = (loanPurposeValue) => {
		// current value
		console.log("1300 loan purpose:", loanPurpose);
		// new value
		console.log("1300 loan purpose value:", loanPurposeValue);

		handleLoanAmount(loanAmount);
		handleMortgageTotal(mortgageTotal);
		handleDrawAmount(drawAmount);
		handleCreditLimit(creditLimit);

		setLoanPurpose(loanPurposeValue);
	};

	useEffect(() => {
		handleLoanAmount(loanAmount);
		handleMortgageTotal(mortgageTotal);
		handleDrawAmount(drawAmount);
		handleCreditLimit(creditLimit);
	}, [loanPurpose]);

	return (
		<Stack direction="column" className={styles.stackContainer}>
			<Stack className={styles.bodyContainer}>
				<Stack direction="column" className={styles.cardHeaderContainer}>
					<Heading5 text="Loan Type" fontType="semibold" />
					<Body3 text="Provide details about the loan" />
				</Stack>

				{/* loan flow error */}
				{error !== "" && (
					<RequiredError
						text={
							error.includes("Something went wrong")
								? "Updates Not Received by LOS. Please Try Again."
								: error.includes("provide a value")
								? "Please provide a value for all fields highlighted above."
								: error
						}
					/>
				)}

				<Body2
					text="Calyx Point XML Customers - Please confirm the Second Lien/CLTV for
					this loan as there is a known issue with importing this field from the
					XML that is being corrected."
					extraClass={styles.xmlNote}
				/>

				<Stack direction="row" className={styles.formContainer}>
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor="select-loan-product-family">
							<Heading6
								text="Loan Product Family"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<SecondarySelect
							id="select-loan-product-family"
							displayEmpty={true}
							value={loanProductFamily || ""}
							handleChange={(event) => {
								setLoanProductFamily(event.target.value);
								setLoanProduct("");
								handleIncomeDocUpdate(event.target.value);
							}}
							options={productFamilyOptions}
							disabled={disabled}
							error={
								inputError &&
								(loanProductFamily === undefined || loanProductFamily === "")
							}
						/>
					</Stack>

					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor="select-loan-product">
							<Heading6
								text="Loan Product"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<SecondarySelect
							id="select-loan-product"
							displayEmpty={true}
							value={loanProduct || ""}
							handleChange={(event) => setLoanProduct(event.target.value)}
							options={loanProductOptions[loanProductFamily]}
							disabled={disabled}
							error={
								inputError && (loanProduct === undefined || loanProduct === "")
							}
						/>
					</Stack>

					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor="select-income-type">
							<Heading6
								text="Income Document Type"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<SecondarySelect
							id="select-income-type"
							displayEmpty={true}
							value={documentType || ""}
							handleChange={(event) => setDocumentType(event.target.value)}
							options={incomeDocumentOptions[loanProductFamily]}
							disabled={disabled}
							error={
								inputError &&
								(documentType === undefined || documentType === "")
							}
						/>
					</Stack>

					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor="masked-interest-rate">
							<Heading6
								text="Requested Interest Rate"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<MaskedInput
							id="masked-interest-rate"
							placeholder="%"
							type="percentage"
							value={requestedInterestRate}
							onInputChange={setRequestedInterestRate}
							disabled={disabled}
						/>
					</Stack>
				</Stack>

				<CustomDivider />

				<Stack direction="column" className={styles.cardHeaderContainer}>
					<Heading5 text="Loan Criteria" fontType="semibold" />
					<Body3 text="Provide details about the loan" />
				</Stack>

				<Stack direction="row" className={styles.formContainer}>
					{/* loan purpose */}
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.inlineContainer}
					>
						<InputLabel htmlFor="select-loan-purpose">
							<Heading6
								text="Loan Purpose"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<SecondarySelect
							id="select-loan-purpose"
							displayEmpty={true}
							fullWidth={true}
							value={loanPurpose}
							handleChange={(event) => handleLoanPurpose(event.target.value)}
							options={loanPurposeOptions}
							disabled={disabled}
							error={
								inputError &&
								(loanPurpose === undefined ||
									loanPurpose === "" ||
									loanPurpose === "Other")
							}
						/>
					</Stack>

					{/* occupancy */}
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.inlineContainer}
					>
						<InputLabel htmlFor="select-occupancy">
							<Heading6
								text="Occupancy"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<SecondarySelect
							id="select-occupancy"
							displayEmpty={true}
							fullWidth={true}
							value={occupancy}
							handleChange={(event) => setOccupancy(event.target.value)}
							options={occupancyOptions}
							disabled={disabled}
							error={
								inputError && (occupancy === undefined || occupancy === "")
							}
						/>
					</Stack>

					{/* Estimated Value */}
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.inlineContainer}
					>
						<InputLabel htmlFor="masked-estimated-value">
							<Heading6
								text="Estimated/Appraised Value"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<MaskedInput
							id="masked-estimated-value"
							placeholder="$"
							type="currency"
							fullWidth={true}
							value={estimatedValue}
							onInputChange={handleEstimatedValue}
							disabled={disabled}
							error={
								inputError &&
								(estimatedValue === undefined ||
									estimatedValue === "" ||
									estimatedValue === "0.00")
							}
						/>
					</Stack>

					{/* purchase price */}
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.inlineContainer}
					>
						<InputLabel htmlFor="masked-purchase-price">
							<Heading6
								text="Purchase Price"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<MaskedInput
							id="masked-purchase-price"
							placeholder="$"
							type="currency"
							fullWidth={true}
							value={purchasePrice}
							onInputChange={handlePurchasePrice}
							disabled={disabled}
							error={
								inputError &&
								(purchasePrice === undefined ||
									purchasePrice === "" ||
									purchasePrice === "0.00")
							}
						/>
					</Stack>

					{/* Loan Amount */}
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.inlineContainer}
					>
						<InputLabel htmlFor="masked-loan-amount">
							<Heading6
								text="Loan Amount"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<MaskedInput
							id="masked-loan-amount"
							placeholder="$"
							type="currency"
							fullWidth={true}
							value={loanAmount}
							onInputChange={handleLoanAmount}
							disabled={disabled}
							error={
								inputError &&
								(loanAmount === undefined ||
									loanAmount === "" ||
									loanAmount === "0.00")
							}
						/>
					</Stack>

					{/* loan to value */}
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.inlineContainer}
					>
						<InputLabel htmlFor="masked-loan-value">
							<Heading6
								text="Loan to Value %"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<OutlinedTextInput
							id="masked-loan-value"
							placeholder="%"
							fullWidth={true}
							value={`${loanValue}%`}
							handleChange={() => console.log("1050 loan value:", loanValue)}
							disabled={true}
							error={
								inputError && (loanValue === undefined || loanValue === "")
							}
						/>
					</Stack>

					{/* heloc amount */}
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.inlineContainer}
					>
						<InputLabel htmlFor="masked-heloc-amount">
							<Heading6
								text="HELOC draw amounts"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<MaskedInput
							id="masked-heloc-amount"
							placeholder="$"
							type="currency"
							fullWidth={true}
							value={drawAmount}
							onInputChange={setDrawAmount}
							disabled={disabled || loanPurpose === ""}
						/>
					</Stack>

					{/* heloc limit */}
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.inlineContainer}
					>
						<InputLabel htmlFor="masked-heloc-limit">
							<Heading6
								text="HELOC credit limit"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<MaskedInput
							id="masked-heloc-limit"
							placeholder="$"
							type="currency"
							fullWidth={true}
							value={creditLimit}
							onInputChange={setCreditLimit}
							disabled={disabled || loanPurpose === ""}
							error={creditLimitError}
						/>
					</Stack>

					{/* Closed Subordinate */}
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.inlineContainer}
					>
						<InputLabel htmlFor="masked-mortgage-total">
							<Heading6
								text="Closed End Subordinate Mortgage Total"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<MaskedInput
							id="masked-mortgage-total"
							placeholder="$"
							type="currency"
							fullWidth={true}
							value={mortgageTotal}
							onInputChange={setMortgageTotal}
							disabled={disabled || loanPurpose === ""}
						/>
					</Stack>

					{/* cltv */}
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.inlineContainer}
					>
						<InputLabel htmlFor="masked-cltv">
							<Heading6
								text="CLTV"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<OutlinedTextInput
							id="masked-cltv"
							placeholder="%"
							fullWidth={true}
							value={`${cltv}%`}
							handleChange={() => console.log("1050 cltv:", cltv)}
							disabled={true}
						/>
					</Stack>

					{/* hcltv */}
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.inlineContainer}
					>
						<InputLabel htmlFor="masked-hcltv">
							<Heading6
								text="HCLTV"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<OutlinedTextInput
							id="masked-hcltv"
							placeholder="%"
							fullWidth={true}
							value={`${hcltv}%`}
							handleChange={() => console.log("1050 hcltv:", hcltv)}
							disabled={true}
						/>
					</Stack>

					{/* escrow */}
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.inlineContainer}
					>
						<InputLabel htmlFor="select-escrow">
							<Heading6
								text="Escrow Waiver"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<SecondarySelect
							id="select-escrow"
							displayEmpty={true}
							fullWidth={true}
							value={escrow}
							handleChange={(event) => setEscrow(event.target.value)}
							options={YNOptions}
							disabled={disabled}
							error={inputError && (escrow === undefined || escrow === "")}
						/>
					</Stack>

					{/* reserves */}
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.inlineContainer}
					>
						<InputLabel htmlFor="select-reserves">
							<Heading6
								text="Reserves (in months)"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<SecondarySelect
							id="select-reserves"
							displayEmpty={true}
							fullWidth={true}
							value={reservesDuration}
							handleChange={(event) => setReservesDuration(event.target.value)}
							options={reservesOptions}
							disabled={disabled}
							error={
								inputError &&
								(reservesDuration === undefined || reservesDuration === "")
							}
						/>
					</Stack>

					{console.log("2000 pre-payment penalty:", paymentPenalty)}
					{/* prepayment penalty */}
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.inlineContainer}
					>
						<InputLabel htmlFor="select-prepayment-penalty">
							<Heading6
								text="Pre-payment Penalty"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<SecondarySelect
							id="select-prepayment-penalty"
							displayEmpty={true}
							fullWidth={true}
							value={paymentPenalty}
							handleChange={(event) => setPaymentPenalty(event.target.value)}
							options={prepaymentPenaltyOptions}
							disabled={disabled}
							error={
								inputError &&
								(paymentPenalty === undefined || paymentPenalty === "")
							}
						/>
					</Stack>

					{/* interest */}
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.inlineContainer}
					>
						<InputLabel htmlFor="select-interest-only">
							<Heading6
								text="Interest Only"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<SecondarySelect
							id="select-interest-only"
							displayEmpty={true}
							fullWidth={true}
							value={interestOnly}
							handleChange={(event) => setInterestOnly(event.target.value)}
							options={YNOptions}
							disabled={disabled}
							error={
								inputError &&
								(interestOnly === undefined || interestOnly === "")
							}
						/>
					</Stack>

					{/* cashout amount */}
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.inlineContainer}
					>
						<InputLabel htmlFor="masked-cashout-amount">
							<Heading6
								text="Cashout Amount"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<MaskedInput
							id="masked-cashout-amount"
							placeholder="$"
							type="currency"
							fullWidth={true}
							value={cashOut}
							onInputChange={setCashOut}
							disabled={disabled}
							error={
								inputError &&
								loanPurpose === "Cash-Out Refinance" &&
								(cashOut === undefined ||
									cashOut === "" ||
									cashOut === "0" ||
									cashOut === "0.00")
							}
						/>
					</Stack>

					{/* self employed */}
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.inlineContainer}
					>
						<InputLabel htmlFor="select-self-employed">
							<Heading6
								text="Self-employed"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<SecondarySelect
							id="select-interest-only"
							displayEmpty={true}
							fullWidth={true}
							value={employmentStatus}
							handleChange={(event) => setEmploymentStatus(event.target.value)}
							options={YNOptions}
							disabled={disabled}
							error={
								inputError &&
								(employmentStatus === undefined || employmentStatus === "")
							}
						/>
					</Stack>
				</Stack>

				<CustomDivider />

				<Stack direction="column" className={styles.cardHeaderContainer}>
					<Heading5 text="Credit Profile" fontType="semibold" />
					<Body3 text="Provide details about the loan" />
				</Stack>

				{/* credit score */}
				<Stack direction="row" className={styles.formContainer}>
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor="credit-score">
							<Heading6
								text="Credit Score (Qualifying)"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<OutlinedTextInput
							id="credit-score"
							placeholder="Credit Score"
							type="number"
							min={0}
							max={999}
							maxLength={3}
							value={creditScore}
							handleChange={handleChangeCreditScore}
							disabled={disabled}
							error={
								inputError &&
								(creditScore === undefined ||
									creditScore === "" ||
									creditScore === 0)
							}
						/>
					</Stack>

					{/* housing event type */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor="select-housing-type">
							<Heading6
								text="Housing Event Type"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<SecondarySelect
							id="select-housing-type"
							displayEmpty={true}
							value={housingType}
							handleChange={(event) => setHousingType(event.target.value)}
							options={housingTypeOptions}
							disabled={disabled}
							error={
								inputError && (housingType === undefined || housingType === "")
							}
						/>
					</Stack>

					{/* housing event seasoning */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor="select-housing-seasoning">
							<Heading6
								text="Housing Event Seasoning"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<SecondarySelect
							id="select-housing-seasoning"
							displayEmpty={true}
							value={housingSeasoning}
							handleChange={(event) => setHousingSeasoning(event.target.value)}
							options={housingSeasoningOptions}
							disabled={disabled}
							error={
								inputError &&
								(housingSeasoning === undefined || housingSeasoning === "")
							}
						/>
					</Stack>

					{/* bankruptcy Type */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor="select-bankruptcy-type">
							<Heading6
								text="Bankruptcy Type"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<SecondarySelect
							id="select-bankruptcy-type"
							displayEmpty={true}
							value={bankruptcyType}
							handleChange={(event) => setBankruptcyType(event.target.value)}
							options={bankruptcyTypeOptions}
							disabled={disabled}
							error={
								inputError &&
								(bankruptcyType === undefined || bankruptcyType === "")
							}
						/>
					</Stack>

					{/* bankruptcy outcome */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor="select-bankruptcy-outcome">
							<Heading6
								text="Bankruptcy Outcome"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<SecondarySelect
							id="select-bankruptcy-outcome"
							displayEmpty={true}
							value={bankruptcyOutcome}
							handleChange={(event) => setBankruptcyOutcome(event.target.value)}
							options={bankruptcyOutcomeOptions}
							disabled={disabled}
							error={
								inputError &&
								(bankruptcyOutcome === undefined || bankruptcyOutcome === "")
							}
						/>
					</Stack>

					{/* Bankruptcy Seasoning */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor="select-bankruptcy-seasoning">
							<Heading6
								text="Bankruptcy Seasoning"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<SecondarySelect
							id="select-bankruptcy-seasoning"
							displayEmpty={true}
							value={bankruptcySeasoning}
							handleChange={(event) =>
								setBankruptcySeasoning(event.target.value)
							}
							options={bankruptcySeasoningOptions}
							disabled={disabled}
							error={
								inputError &&
								(bankruptcySeasoning === undefined ||
									bankruptcySeasoning === "")
							}
						/>
					</Stack>
				</Stack>

				<CustomDivider />

				<Stack direction="column" className={styles.cardHeaderContainer}>
					<Heading5 text="Mortgage Lates" fontType="semibold" />
					<Body3 text="Provide details about the loan" />
				</Stack>

				<Stack direction="column" className={styles.formContainer}>
					{/* mortgage lates */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor="select-mortgage-lates">
							<Heading6
								text="Mortgage Lates"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<SecondarySelect
							id="select-mortgage-lates"
							displayEmpty={true}
							value={mortgageLates}
							handleChange={(event) => setMortgageLates(event.target.value)}
							options={YNOptions}
							disabled={disabled}
							error={
								inputError &&
								(mortgageLates === null ||
									mortgageLates === undefined ||
									mortgageLates === "")
							}
						/>
					</Stack>

					{mortgageLates === "Y" && (
						<Stack
							direction="row"
							className={styles.formContainer + " " + styles.mortgageContainer}
						>
							{/* mtg Lates x30 (0-6 Mos) */}
							<Stack direction="column" className={styles.inputContainer}>
								<InputLabel htmlFor="select-mtg-lates-30-6">
									<Heading6
										text="Mtg Lates x30 (0-6 Mos)"
										fontType="semibold"
										extraClass={styles.inputHeader}
									/>
								</InputLabel>

								<OutlinedTextInput
									id="select-mtg-lates-30-6"
									type="number"
									min={0}
									max={120}
									maxLength={2}
									value={mortgageLates1}
									handleChange={(event) =>
										setMortgageLates1(event.target.value)
									}
									disabled={disabled}
								/>
							</Stack>

							{/* mtg Lates x30 (12 Mos) */}
							<Stack direction="column" className={styles.inputContainer}>
								<InputLabel htmlFor="select-mtg-lates-30-12">
									<Heading6
										text="Mtg Lates x30 (12 Mos)"
										fontType="semibold"
										extraClass={styles.inputHeader}
									/>
								</InputLabel>

								<OutlinedTextInput
									id="select-mtg-lates-30-12"
									type="number"
									min={0}
									max={120}
									maxLength={2}
									value={mortgageLates2}
									handleChange={(event) =>
										setMortgageLates2(event.target.value)
									}
									disabled={disabled}
								/>
							</Stack>

							{/* mtg Lates x60 (12 Mos) */}
							<Stack direction="column" className={styles.inputContainer}>
								<InputLabel htmlFor="select-mtg-lates-60-12">
									<Heading6
										text="Mtg Lates x60 (12 Mos)"
										fontType="semibold"
										extraClass={styles.inputHeader}
									/>
								</InputLabel>

								<OutlinedTextInput
									id="select-mtg-lates-60-12"
									type="number"
									min={0}
									max={120}
									maxLength={2}
									value={mortgageLates3}
									handleChange={(event) =>
										setMortgageLates3(event.target.value)
									}
									disabled={disabled}
								/>
							</Stack>

							{/* mtg Lates x90 (12 Mos) */}
							<Stack direction="column" className={styles.inputContainer}>
								<InputLabel htmlFor="select-mtg-lates-90-12">
									<Heading6
										text="Mtg Lates x90 (12 Mos)"
										fontType="semibold"
										extraClass={styles.inputHeader}
									/>
								</InputLabel>

								<OutlinedTextInput
									id="select-mtg-lates-90-12"
									type="number"
									min={0}
									max={120}
									maxLength={2}
									value={mortgageLates4}
									handleChange={(event) =>
										setMortgageLates4(event.target.value)
									}
									disabled={disabled}
								/>
							</Stack>

							{/* mtg Lates x120 (12 Mos) */}
							<Stack direction="column" className={styles.inputContainer}>
								<InputLabel htmlFor="select-mtg-lates-120-12">
									<Heading6
										text="Mtg Lates x120 (12 Mos)"
										fontType="semibold"
										extraClass={styles.inputHeader}
									/>
								</InputLabel>

								<OutlinedTextInput
									id="select-mtg-lates-120-12"
									type="number"
									min={0}
									max={120}
									maxLength={2}
									value={mortgageLates5}
									handleChange={(event) =>
										setMortgageLates5(event.target.value)
									}
									disabled={disabled}
								/>
							</Stack>

							{/* mtg Lates x30 (13-24 Mos) */}
							<Stack direction="column" className={styles.inputContainer}>
								<InputLabel htmlFor="select-mtg-lates-30-24">
									<Heading6
										text="Mtg Lates x30 (13-24 Mos)"
										fontType="semibold"
										extraClass={styles.inputHeader}
									/>
								</InputLabel>

								<OutlinedTextInput
									id="select-mtg-lates-30-24"
									type="number"
									min={0}
									max={120}
									maxLength={2}
									value={mortgageLates6}
									handleChange={(event) =>
										setMortgageLates6(event.target.value)
									}
									disabled={disabled}
								/>
							</Stack>

							{/* mtg Lates x60 (13-24 Mos) */}
							<Stack direction="column" className={styles.inputContainer}>
								<InputLabel htmlFor="select-mtg-lates-60-24">
									<Heading6
										text="Mtg Lates x60 (13-24 Mos)"
										fontType="semibold"
										extraClass={styles.inputHeader}
									/>
								</InputLabel>

								<OutlinedTextInput
									id="select-mtg-lates-60-24"
									type="number"
									min={0}
									max={120}
									maxLength={2}
									value={mortgageLates7}
									handleChange={(event) =>
										setMortgageLates7(event.target.value)
									}
									disabled={disabled}
								/>
							</Stack>

							{/* mtg Lates x90 (13-24 Mos) */}
							<Stack direction="column" className={styles.inputContainer}>
								<InputLabel htmlFor="select-mtg-lates-90-24">
									<Heading6
										text="Mtg Lates x90 (13-24 Mos)"
										fontType="semibold"
										extraClass={styles.inputHeader}
									/>
								</InputLabel>

								<OutlinedTextInput
									id="select-mtg-lates-90-24"
									type="number"
									min={0}
									max={120}
									maxLength={2}
									value={mortgageLates8}
									handleChange={(event) =>
										setMortgageLates8(event.target.value)
									}
									disabled={disabled}
								/>
							</Stack>

							{/* mtg Lates x120 (13-24 Mos) */}
							<Stack direction="column" className={styles.inputContainer}>
								<InputLabel htmlFor="select-mtg-lates-120-24">
									<Heading6
										text="Mtg Lates x120 (13-24 Mos)"
										fontType="semibold"
										extraClass={styles.inputHeader}
									/>
								</InputLabel>

								<OutlinedTextInput
									id="select-mtg-lates-120-24"
									type="number"
									min={0}
									max={120}
									maxLength={2}
									value={mortgageLates9}
									handleChange={(event) =>
										setMortgageLates9(event.target.value)
									}
									disabled={disabled}
								/>
							</Stack>
						</Stack>
					)}
				</Stack>

				<CustomDivider />

				<Stack direction="column" className={styles.cardHeaderContainer}>
					<Heading5 text="Property Information" fontType="semibold" />
					<Body3 text="Provide details about the loan" />
				</Stack>

				{/* Property Type */}
				<Stack direction="row" className={styles.formContainer}>
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor="select-property-type">
							<Heading6
								text="Property Type"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<SecondarySelect
							id="select-property-type"
							displayEmpty={true}
							value={propertyType}
							handleChange={(event) => setPropertyType(event.target.value)}
							options={propertyInfoTypeOptions}
							disabled={disabled}
							error={
								inputError &&
								(propertyType === undefined || propertyType === "")
							}
						/>
					</Stack>

					{/* number of units */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor="input-unit-count">
							<Heading6
								text="Number of units"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<OutlinedTextInput
							id="input-unit-count"
							placeholder="Units owned"
							type="number"
							min={0}
							value={unitCount}
							handleChange={handleChangeUnitCount}
							disabled={disabled}
							error={
								inputError &&
								(unitCount === undefined ||
									unitCount === "" ||
									unitCount === "0" ||
									unitCount === 0)
							}
						/>
					</Stack>

					{/* occupancy Rate */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor="input-occupancy-rate">
							<Heading6
								text="Occupancy Rate"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<OutlinedTextInput
							id="input-occupancy-rate"
							placeholder="%"
							type="number"
							min={0}
							value={occupancyRate}
							handleChange={(event) => setOccupancyRate(event.target.value)}
							disabled={disabled}
						/>
					</Stack>

					{/* state */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor="select-state">
							<Heading6
								text="State"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<SecondarySelect
							id="select-state"
							displayEmpty={true}
							value={state}
							handleChange={(event) => setState(event.target.value)}
							options={stateOptions}
							disabled={disabled}
							error={inputError && (state === undefined || state === "")}
						/>
					</Stack>

					{/* county */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor="select-county">
							<Heading6
								text="County"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<SecondarySelect
							id="select-county"
							displayEmpty={true}
							value={county}
							handleChange={(event) => setCounty(event.target.value)}
							options={countyListData || []}
							disabled={disabled}
							error={inputError && (county === undefined || county === "")}
						/>
					</Stack>

					{/* zipcode */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor="input-zipcode">
							<Heading6
								text="Zipcode"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<OutlinedTextInput
							id="input-zipcode"
							placeholder="Zipcode"
							type="number"
							min={0}
							value={zipcode}
							handleChange={(event) => setZipcode(event.target.value)}
							disabled={disabled}
						/>
					</Stack>
				</Stack>

				{/* footer buttons */}
			</Stack>
			<Stack direction="row" className={styles.footerContainer}>
				<SecondaryButton
					text="Previous"
					startIcon={<ArrowBackOutlined className={styles.prevIcon} />}
					onClick={handlePrev}
				/>
				<PrimaryButton
					// TODO loader isUpdatingFields
					text="Save and Continue"
					endIcon={<ArrowForwardOutlined className={styles.nextIcon} />}
					onClick={handleSubmitApplication}
				/>
			</Stack>
		</Stack>
	);
};

export default LoanPricingApplication;
