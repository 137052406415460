import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DateTime } from "luxon";

// mui components
import { Box, IconButton, Stack, Tab, Tabs } from "@mui/material";

// custom components
import { TabPanel } from "../../tab-panel";
import RequiredError from "../../required-error";
import BorrowerInformation from "../../loan-form-card/borrower-information";
import PropertyDetails from "../../loan-form-card/property-details";
import LoanOriginator from "../../loan-form-card/loan-originator";
import LoanProcessor from "../../loan-form-card/loan-processor";
import PrimaryButton from "../../buttons/primary-button";
import Heading6 from "../../typography/heading-06";

// mui icons
import {
	AddOutlined,
	ArrowForwardOutlined,
	CloseOutlined,
} from "@mui/icons-material";

// api slice
import { createLoanReducer } from "../../../features/createLoan/createLoanSlice";
import { createAdminLoanReducer } from "../../../features/admin/create-loan/createAdminLoanSlice";
import { useCreateLoanMutation } from "../../../features/createLoan/createLoanApiSlice";
import { useCreateAdminLoanMutation } from "../../../features/admin/create-loan/createAdminLoanApiSlice";
import { useUpdateFieldsMutation } from "../../../features/update-fields/updateFieldsApiSlice";
import { useGetBrokerListByTitleMutation } from "../../../features/broker-search-title/brokerSearchTitleApiSlice";
import { useGetOrganizationDetailsMutation } from "../../../features/organization-detail/organizationDetailApiSlice";
import { useUpdateLoanMutation } from "../../../features/update-loan/updateLoanApiSlice";
import { useCreateBorrowerPairMutation } from "../../../features/create-borrower-pair/createBorrowerPairApiSlice";
import { useUpdateBorrowerPairMutation } from "../../../features/update-borrower-pair/updateBorrowerPairApiSlice";

// reducer slice
import { selectImpersonatedUser } from "../../../features/admin/impersonated-user/impersonatedUserSlice";
import {
	selectUserDetails,
	setLoEmail,
} from "../../../features/auth/authSlice";
import { setBrokerListReducer } from "../../../features/broker-list/brokerListSlice";
import { selectOrganizationName } from "../../../features/organization-detail/organizationDetailsUserSlice";
import { selectImpersonatedOrgName } from "../../../features/admin/impersonated-organization-name/impersonatedOrganizationNameSlice";

// custom modal
import LoaderModal from "../../modal/loader-modal";
import ConfirmationModal from "../../modal/confirmation-modal";

// helper utils
import { requiredFields } from "../../../utils/requiredFields";

// custom styles
import styles from "./index.module.scss";

export const CreateLoanApplication = ({
	setLoanApplicationStage,
	applicationData,
	setApplicationData,
	loanGuid,
	setLoanGuid,
}) => {
	console.log("24 applicationData:", applicationData);
	console.log("45 loan id:", loanGuid);

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [createLoan, { isLoading: creatingLoan }] = useCreateLoanMutation();

	const [createAdminLoan, { isLoading: creatingAdminLoan }] =
		useCreateAdminLoanMutation();

	const [updateFields, { isLoading: isUpdatingFields }] =
		useUpdateFieldsMutation();

	const [updateLoan, { isLoading: updatingLoan }] = useUpdateLoanMutation();

	const [createBorrowerPair, { isLoading: creatingBorrowerPair }] =
		useCreateBorrowerPairMutation();

	const [updateBorrowerPair, { isLoading: updatingBorrowerPair }] =
		useUpdateBorrowerPairMutation();

	const impersonatedUser = useSelector(selectImpersonatedUser);

	const [loanApplicationData, setLoanApplicationData] = useState([
		{
			mailingAddressSameAsPresentIndicator: false,
		},
		{
			mailingAddressSameAsPresentIndicator: false,
		},
	]);

	const [borrowerDataExist, setBorrowerDataExist] = useState(false);

	const [tabValue, setTabValue] = useState(0);

	const [propertyInfo, setPropertyInfo] = useState({});

	// loan originator
	const userDetails = useSelector(selectUserDetails);
	const userOrganizationName = useSelector(selectOrganizationName);
	const impersonatedOrganizationName = useSelector(selectImpersonatedOrgName);

	const [organizationName, setOrganizationName] = useState("");

	const [getBrokerListByTitle, { isLoading: brokerListLoading }] =
		useGetBrokerListByTitleMutation();

	const [getOrganizationDetails, { isLoading: organizationDetailsLoading }] =
		useGetOrganizationDetailsMutation();
	const [organizationDetails, setOrganizationDetails] = useState({});

	const [loanOriginatorEmail, setLoanOriginatorEmail] = useState("");
	const [organizationUsers, setOrganizationUsers] = useState([]);
	const [loanOriginator, setLoanOriginator] = useState({});

	const [organizationLicense, setOrganizationLicense] = useState("");

	const [confirmModalText, setConfirmModalText] = useState("");
	const [confirmModalVisible, setConfirmModalVisible] = useState(false);
	const handleOpenConfirmModal = () => {
		setConfirmModalVisible(true);
	};
	const handleCloseConfirmModal = () => {
		setConfirmModalVisible(false);
	};

	const handleSubmitConfirmModal = () => {
		navigate("/admin");
	};

	useEffect(() => {
		console.log("130 userOrganizationName:", userOrganizationName);
		console.log(
			"130 impersonatedOrganizationName:",
			impersonatedOrganizationName
		);

		if (userDetails?.user_roles?.includes("admin")) {
			setOrganizationName(impersonatedOrganizationName);
		} else {
			setOrganizationName(userOrganizationName);
		}
	}, [userOrganizationName, impersonatedOrganizationName]);

	useEffect(() => {
		// check if admin signed in as another user has an organization
		console.log("150 user roles:", userDetails?.user_roles);
		console.log("150 organization name:", organizationName);

		if (
			userDetails?.user_roles?.includes("admin") &&
			(impersonatedOrganizationName === undefined ||
				impersonatedOrganizationName === null ||
				impersonatedOrganizationName === "")
		) {
			console.log("140 No organization");
			setConfirmModalText("Sign in as a broker to gain access.");
			handleOpenConfirmModal();
		}
	}, [impersonatedOrganizationName, userDetails]);

	const [loanProcessorEmail, setLoanProcessorEmail] = useState("");
	const [organizationProcessor, setOrganizationProcessor] = useState([]);
	const [loanProcessor, setLoanProcessor] = useState({});

	useEffect(() => {
		const getBrokerListFunc = async () => {
			const searchResults = await getBrokerListByTitle({
				orgName: organizationName,
				title: "Loan Officer",
			}).unwrap();
			dispatch(setBrokerListReducer(searchResults));

			console.log("150 loan origin:", searchResults);

			let updatedArr = searchResults.map((item) => ({
				...item,
				label: item?.user?.email || "",
				value: item?.user?.email || "",
			}));

			console.log("150 loan origin updated:", updatedArr);
			setOrganizationUsers(updatedArr);
		};

		const getProcessorListFunc = async () => {
			const searchResults = await getBrokerListByTitle({
				orgName: organizationName,
				title: "Loan Processor",
			}).unwrap();

			let updatedArr = searchResults.map((item) => ({
				...item,
				label: item?.user?.email || "",
				value: item?.user?.email || "",
			}));

			console.log("150 loan origin updated:", updatedArr);
			setOrganizationProcessor(updatedArr);
		};

		const getOrganizationDetailsFunc = async () => {
			const searchResults = await getOrganizationDetails({
				organizationName,
			}).unwrap();
			setOrganizationDetails(searchResults);
		};

		if (organizationName !== undefined && organizationName !== "") {
			getBrokerListFunc();
			getProcessorListFunc();
			getOrganizationDetailsFunc();
		}
	}, [organizationName]);

	useEffect(() => {
		if (loanOriginatorEmail !== {} || loanOriginatorEmail !== "") {
			const originatorDetails = organizationUsers?.find(
				(item) => item?.user?.email === loanOriginatorEmail
			);

			setLoanOriginator(originatorDetails);
		}
	}, [loanOriginatorEmail]);

	useEffect(() => {
		if (loanProcessorEmail !== {} || loanProcessorEmail !== "") {
			const processorDetails = organizationProcessor?.find(
				(item) => item?.user?.email === loanProcessorEmail
			);
			setLoanProcessor(processorDetails);
		}
	}, [loanProcessorEmail]);

	const [error, setError] = useState("");

	useEffect(() => {
		// when user comes back from next screen
		console.log("210 loan guid:", loanGuid);
		console.log("210 application data:", applicationData);
		if (
			loanGuid !== undefined &&
			loanGuid !== "" &&
			applicationData !== undefined &&
			applicationData?.applications !== undefined &&
			applicationData?.applications?.length >= 0
		) {
			console.log(
				"66 applicationData?.applications:",
				applicationData?.applications
			);

			const borrowerPairs = applicationData?.applications
				?.flat()
				?.reduce((acc, curr) => {
					acc.push(curr?.borrower);
					acc.push(curr?.coborrower || curr?.coBorrower);
					return acc;
				}, []);

			console.log("225 borrower pair:", borrowerPairs);

			setLoanApplicationData(borrowerPairs);
			setBorrowerDataExist(true);

			setLoanOriginatorEmail(applicationData?.tPO?.lOEmail || "");
			setLoanProcessorEmail(applicationData?.tPO?.lPEmail || "");

			let data = applicationData?.applications[0];
			console.log("68 data:", data);

			setPropertyInfo(applicationData?.property);
		} else {
			setBorrowerDataExist(false);
		}
	}, [applicationData]);

	const updateField = async (loanGuid, apiObj) => {
		let valuesArr = [
			{ id: "TPO.X3", value: DateTime.now().toFormat("MM-dd-yyyy") },
			{ id: "3629", value: organizationLicense },
			{
				id: "CX.PROPERTY.COMMERCIAL.UNITS",
				value: apiObj?.property?.financedNumberOfUnits || "",
			},
		];

		if (
			apiObj?.applications[0]?.borrower?.urla2020CitizenshipResidencyType ===
			"Foreign National"
		) {
			valuesArr.push({
				id: "CX.FOREIGNNATLBORR1",
				value: "Yes",
			});
			valuesArr.push({
				id: "URLA.X1",
				value: "NonPermanentResidentAlien",
			});
			valuesArr.push({
				id: "URLA.X263",
				value: apiObj?.applications[0]?.borrower?.urla2020CountryOfCitizenship,
			});
		} else {
			valuesArr.push({
				id: "CX.FOREIGNNATLBORR1",
				value: "No",
			});
		}

		if (
			apiObj?.applications[0]?.coBorrower?.urla2020CitizenshipResidencyType ===
			"Foreign National"
		) {
			valuesArr.push({
				id: "CX.FOREIGNNATLBORR2",
				value: "Yes",
			});
			valuesArr.push({
				id: "URLA.X2",
				value: "NonPermanentResidentAlien",
			});
			valuesArr.push({
				id: "URLA.X264",
				value:
					apiObj?.applications[0]?.coBorrower?.urla2020CountryOfCitizenship,
			});
		} else {
			valuesArr.push({
				id: "CX.FOREIGNNATLBORR2",
				value: "No",
			});
		}

		if (loanOriginatorEmail !== undefined && loanOriginatorEmail !== "") {
			valuesArr.push({
				id: "TPO.X61",
				value:
					loanOriginator?.user?.first_name +
					" " +
					loanOriginator?.user?.last_name,
			});

			valuesArr.push({
				id: "TPO.X63",
				value: loanOriginator?.user?.email,
			});

			valuesArr.push({
				id: "TPO.X65",
				value: loanOriginator?.phone,
			});

			valuesArr.push({
				id: "TPO.X67",
				value: loanOriginator?.cell_phone,
			});

			valuesArr.push({
				id: "2306",
				value: loanOriginator?.state_license || "",
			});
		}

		console.log("360 loanProcessor email:", loanProcessorEmail);
		console.log("360 loanProcessor:", loanProcessor);

		if (loanProcessorEmail !== undefined && loanProcessorEmail !== "") {
			valuesArr.push({
				id: "TPO.X74",
				value:
					loanProcessor?.user?.first_name +
					" " +
					loanProcessor?.user?.last_name,
			});

			valuesArr.push({
				id: "TPO.X76",
				value: loanProcessor?.user?.email,
			});

			valuesArr.push({
				id: "TPO.X78",
				value: loanProcessor?.phone,
			});

			valuesArr.push({
				id: "TPO.X80",
				value: loanProcessor?.cell_phone,
			});
		}

		console.log("340 loan update values:", valuesArr);

		const updateFieldValues = await updateFields({
			loan_guid: loanGuid,
			field_values: valuesArr,
		}).unwrap();
		console.log("90 file res:", updateFieldValues);

		if (updateFieldValues?.response === "Field values updated successfully.") {
			setError("");
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: "smooth",
			});
			setLoanApplicationStage(2);
		} else {
			setError(updateFieldValues?.response);
		}
	};

	const handleNext = async () => {
		setError("");

		if (
			!requiredFields(
				loanApplicationData,
				propertyInfo,
				loanOriginatorEmail,
				loanProcessorEmail,
				setError
			)
		) {
			return;
		}

		let borrowerPairData = [];

		console.log("445 loan application data:", loanApplicationData);

		for (let index = 2; index < loanApplicationData.length; index++) {
			if (index % 2 === 0) {
				borrowerPairData.push([
					{
						borrower: loanApplicationData[index],
						coborrower: loanApplicationData[index + 1],
					},
				]);
			}
		}

		console.log("285 borrower pair data;", borrowerPairData);

		let applications = [];

		for (let index = 0; index < loanApplicationData.length; index += 2) {
			applications.push([
				{
					borrower: loanApplicationData[index],
					coBorrower: loanApplicationData[index + 1],
				},
			]);
		}

		console.log("247 application data:", applicationData);

		setApplicationData({
			...applicationData,
			applications,
			property: propertyInfo,
			tPO: {
				lOEmail: loanOriginatorEmail,
				lPEmail: loanProcessorEmail,
			},
		});

		console.log("305 loan guid:", loanGuid);

		if (loanGuid === undefined || loanGuid === "") {
			let apiObj;
			if (loanOriginatorEmail !== undefined && loanOriginatorEmail !== "") {
				apiObj = {
					user_email: loanOriginatorEmail,
					loan_data: {
						...applicationData,
						applications: applications[0],
						property: propertyInfo,
					},
				};
			} else {
				if (
					impersonatedUser !== null &&
					impersonatedUser !== undefined &&
					impersonatedUser !== ""
				) {
					apiObj = {
						user_email: impersonatedUser,
						loan_data: {
							...applicationData,
							applications: applications[0],
							property: propertyInfo,
						},
					};
				} else {
					apiObj = {
						...applicationData,
						applications: applications[0],
						property: propertyInfo,
					};
				}
			}

			console.log("265 upload supporting docs:", apiObj);
			// dispatch API and open modal if successful
			try {
				console.log("385 loan originator email:", loanOriginatorEmail);
				if (loanOriginatorEmail !== undefined && loanOriginatorEmail !== "") {
					const fileUpload = await createAdminLoan({ ...apiObj }).unwrap();
					console.log("90 create impersonated loan:", fileUpload);
					dispatch(setLoEmail(loanOriginatorEmail));
					dispatch(createAdminLoanReducer(fileUpload));
					setLoanGuid(fileUpload?.GUID);

					// create additional borrower pair
					if (loanApplicationData?.length >= 3) {
						let borrowerPair;
						for (let index = 0; index < borrowerPairData.length; index++) {
							borrowerPair = await createBorrowerPair({
								loanGuid: fileUpload?.GUID,
								data: { ...borrowerPairData[index][0] },
							}).unwrap();
						}
						console.log("550 impersonated borrower pair data:", borrowerPair);
					}

					updateField(fileUpload?.GUID, apiObj?.loan_data);
				} else {
					if (
						impersonatedUser !== null &&
						impersonatedUser !== undefined &&
						impersonatedUser !== ""
					) {
						const fileUpload = await createAdminLoan({ ...apiObj }).unwrap();
						console.log("90 create admin loan:", fileUpload);
						dispatch(setLoEmail(impersonatedUser));
						dispatch(createAdminLoanReducer(fileUpload));
						setLoanGuid(fileUpload?.GUID);
						// create borrower pair
						if (loanApplicationData?.length >= 3) {
							let borrowerPair;
							for (let index = 0; index < borrowerPairData.length; index++) {
								borrowerPair = await createBorrowerPair({
									loanGuid: fileUpload?.GUID,
									data: { ...borrowerPairData[index][0] },
								}).unwrap();
							}
							console.log("550 admin borrower pair data:", borrowerPair);
						}

						updateField(fileUpload?.GUID, apiObj?.loan_data);
					} else {
						const fileUpload = await createLoan({ ...apiObj }).unwrap();
						console.log("90 create broker loan:", fileUpload);
						dispatch(setLoEmail(""));
						dispatch(createLoanReducer(fileUpload));
						setLoanGuid(fileUpload?.GUID);

						// create borrower pair
						if (loanApplicationData?.length >= 3) {
							console.log(
								"400 loanApplicationData length:",
								loanApplicationData?.length
							);
							let borrowerPair;
							for (let index = 0; index < borrowerPairData.length; index++) {
								borrowerPair = await createBorrowerPair({
									loanGuid: fileUpload?.GUID,
									data: { ...borrowerPairData[index][0] },
								}).unwrap();
							}
							console.log("550 broker borrower pair data:", borrowerPair);
						}

						updateField(fileUpload?.GUID, apiObj);
					}
				}
			} catch (err) {
				console.log("550 err:", err);
				setError(err?.data?.details);
			}
		} else {
			// update loan api
			console.log("510 loan id:", loanGuid);

			let apiObj = {
				...applicationData,
				applications,
				property: propertyInfo,
			};

			console.log("510 loan data:", apiObj);

			const fileUpload = await updateLoan({
				loanGuid,
				loanData: apiObj,
			}).unwrap();
			console.log("450 loan update:", fileUpload);

			// create borrower pair
			if (loanApplicationData?.length >= 3) {
				let borrowerPair;
				for (let index = 0; index < borrowerPairData.length; index++) {
					borrowerPair = await updateBorrowerPair({
						loanGuid: fileUpload?.GUID,
						data: { ...borrowerPairData[index][0] },
					}).unwrap();
				}
				console.log("550 update borrower pair data:", borrowerPair);
			}

			if (
				fileUpload?.response?.errorCode === undefined ||
				fileUpload?.response?.errorCode === ""
			) {
				window.scrollTo({
					top: 0,
					left: 0,
					behavior: "smooth",
				});
				setLoanApplicationStage(2);
			} else {
				setError(fileUpload?.response?.details);
			}
		}
	};

	const handleNextSectionClick = () => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});
		setLoanApplicationStage(2);
	};

	const handleAddBorrowerPair = () => {
		setLoanApplicationData([
			...loanApplicationData,
			{ mailingAddressSameAsPresentIndicator: false },
			{ mailingAddressSameAsPresentIndicator: false },
		]);
	};

	const handleRemoveBorrowerPair = () => {
		console.log("650 tab value:", tabValue);
		console.log("650 pair count:", loanApplicationData?.length / 2 - 1);

		if (tabValue === loanApplicationData?.length / 2 - 1) {
			setTabValue(tabValue - 1);
		}

		let newArr = loanApplicationData;

		// remove current selected borrower pair with following logic
		// tabvalue ~ loanApplicationData
		// 0 ~ 0, 1
		// 1 ~ 2, 3
		// 2 ~ 4, 5

		if (tabValue === 0) {
			newArr?.splice(tabValue, 2);
		} else {
			newArr?.splice(tabValue * 2, 2);
		}

		setLoanApplicationData([...newArr]);
	};

	function a11yProps(index) {
		return {
			id: `simple-tab-${index}`,
			"aria-controls": `simple-tabpanel-${index}`,
		};
	}

	const handleChangeTab = (event, newValue) => {
		setTabValue(newValue);
	};

	return (
		<Stack direction="column" className={styles.stackContainer}>
			<Stack className={styles.bodyContainer}>
				{/* tabs section */}
				<Tabs
					value={tabValue}
					onChange={handleChangeTab}
					aria-label="basic tabs example"
					TabIndicatorProps={{
						style: {
							backgroundColor: "#76D99E",
						},
					}}
				>
					<Tab
						className={styles.tabBtnContainer}
						label={
							<Stack direction="row" className={styles.tabLabelContainer}>
								<Heading6
									text="Borrower Pair 1"
									fontType="semibold"
									extraClass={
										tabValue === 0 ? styles.activeTabText : styles.tabText
									}
								/>

								{tabValue === 0 && (
									<Stack
										className={styles.closeIconContainer}
										onClick={() =>
											loanApplicationData?.length > 2 &&
											borrowerDataExist === false &&
											handleRemoveBorrowerPair()
										}
									>
										<CloseOutlined className={styles.closeIcon} />
									</Stack>
								)}
							</Stack>
						}
						{...a11yProps(0)}
					/>
					{loanApplicationData?.length > 2 && (
						<Tab
							className={styles.tabBtnContainer}
							label={
								<Stack direction="row" className={styles.tabLabelContainer}>
									<Heading6
										text="Borrower Pair 2"
										fontType="semibold"
										extraClass={
											tabValue === 1 ? styles.activeTabText : styles.tabText
										}
									/>

									{tabValue === 1 && (
										<Stack
											className={styles.closeIconContainer}
											onClick={() =>
												borrowerDataExist === false &&
												handleRemoveBorrowerPair()
											}
										>
											<CloseOutlined className={styles.closeIcon} />
										</Stack>
									)}
								</Stack>
							}
							{...a11yProps(1)}
						/>
					)}
					{loanApplicationData?.length > 4 && (
						<Tab
							className={styles.tabBtnContainer}
							label={
								<Stack direction="row" className={styles.tabLabelContainer}>
									<Heading6
										text="Borrower Pair 3"
										fontType="semibold"
										extraClass={
											tabValue === 2 ? styles.activeTabText : styles.tabText
										}
									/>

									{tabValue === 2 && (
										<Stack
											className={styles.closeIconContainer}
											onClick={() =>
												borrowerDataExist === false &&
												handleRemoveBorrowerPair()
											}
										>
											<CloseOutlined className={styles.closeIcon} />
										</Stack>
									)}
								</Stack>
							}
							{...a11yProps(2)}
						/>
					)}

					<Box>
						<IconButton
							className={styles.addPairContainer}
							onClick={() =>
								loanApplicationData?.length < 6 &&
								borrowerDataExist === false &&
								handleAddBorrowerPair()
							}
							disabled={
								loanApplicationData?.length > 4 && borrowerDataExist === false
							}
						>
							<AddOutlined className={styles.addPairIcon} />
						</IconButton>
					</Box>
				</Tabs>

				{/* borrower pair 1 */}
				{loanApplicationData?.length > 0 && (
					<TabPanel
						value={tabValue}
						index={0}
						className={tabValue === 0 ? styles.tabPanelContainer : ""}
					>
						{/* loan flow error */}
						{error !== "" && (
							<RequiredError
								text={
									error.includes("Something went wrong")
										? "Updates Not Received by LOS. Please Try Again."
										: error.includes("provide a value")
										? "Please provide a value for all fields highlighted below before you continue."
										: error.includes("borrower.dependentCount") &&
										  "Please check your dependent count."
								}
							/>
						)}

						{loanApplicationData?.slice(0, 2)?.map((borrower, index) => (
							<BorrowerInformation
								key={index}
								borrowerIndex={index}
								coBorrower={index % 2 !== 0}
								borrowerData={borrower}
								data={loanApplicationData}
								setData={setLoanApplicationData}
								error={error.includes("provide a value")}
								disabled={borrowerDataExist}
							/>
						))}

						<PropertyDetails
							data={propertyInfo}
							setData={setPropertyInfo}
							loanData={applicationData}
							setLoanData={setApplicationData}
							error={error.includes("provide a value")}
							disabled={borrowerDataExist}
						/>

						<LoanOriginator
							organizationName={organizationName}
							organizationDetails={organizationDetails}
							loanOriginatorEmail={loanOriginatorEmail}
							setLoanOriginatorEmail={setLoanOriginatorEmail}
							organizationUsers={organizationUsers}
							loanOriginator={loanOriginator}
							setLoanOriginator={setLoanOriginator}
							setOrganizationLicense={setOrganizationLicense}
							propertyState={propertyInfo?.state}
							error={error.includes("provide a value")}
							disabled={borrowerDataExist}
						/>

						<LoanProcessor
							organizationName={organizationName}
							organizationDetails={organizationDetails}
							loanProcessorEmail={loanProcessorEmail}
							setLoanProcessorEmail={setLoanProcessorEmail}
							organizationProcessor={organizationProcessor}
							loanProcessor={loanProcessor}
							propertyState={propertyInfo?.state}
							error={error.includes("provide a value")}
							disabled={borrowerDataExist}
						/>
					</TabPanel>
				)}

				{/* borrower pair 2 */}
				{loanApplicationData?.length > 2 && (
					<TabPanel
						value={tabValue}
						index={1}
						className={tabValue === 1 ? styles.tabPanelContainer : ""}
					>
						{/* loan flow error */}
						{error !== "" && (
							<RequiredError
								text={
									error.includes("Something went wrong")
										? "Updates Not Received by LOS. Please Try Again."
										: error.includes("provide a value")
										? "Please provide a value for all fields highlighted below before you continue."
										: error.includes("borrower.dependentCount") &&
										  "Please check your dependent count."
								}
							/>
						)}
						{loanApplicationData?.slice(2, 4)?.map((borrower, index) => (
							<BorrowerInformation
								key={index}
								borrowerIndex={index + 2}
								coBorrower={index % 2 !== 0}
								borrowerData={borrower}
								data={loanApplicationData}
								setData={setLoanApplicationData}
								error={error.includes("provide a value")}
								disabled={borrowerDataExist}
							/>
						))}

						<PropertyDetails
							data={propertyInfo}
							setData={setPropertyInfo}
							loanData={applicationData}
							setLoanData={setApplicationData}
							error={error.includes("provide a value")}
							disabled={borrowerDataExist}
						/>

						<LoanOriginator
							organizationName={organizationName}
							organizationDetails={organizationDetails}
							loanOriginatorEmail={loanOriginatorEmail}
							setLoanOriginatorEmail={setLoanOriginatorEmail}
							organizationUsers={organizationUsers}
							loanOriginator={loanOriginator}
							setLoanOriginator={setLoanOriginator}
							setOrganizationLicense={setOrganizationLicense}
							propertyState={propertyInfo?.state}
							error={error.includes("provide a value")}
							disabled={borrowerDataExist}
						/>

						<LoanProcessor
							organizationName={organizationName}
							organizationDetails={organizationDetails}
							loanProcessorEmail={loanProcessorEmail}
							setLoanProcessorEmail={setLoanProcessorEmail}
							organizationProcessor={organizationProcessor}
							loanProcessor={loanProcessor}
							propertyState={propertyInfo?.state}
							error={error.includes("provide a value")}
							disabled={borrowerDataExist}
						/>
					</TabPanel>
				)}

				{/* borrower pair 3 */}
				{loanApplicationData?.length > 4 && (
					<TabPanel
						value={tabValue}
						index={2}
						className={tabValue === 2 ? styles.tabPanelContainer : ""}
					>
						{/* loan flow error */}
						{error !== "" && (
							<RequiredError
								text={
									error.includes("Something went wrong")
										? "Updates Not Received by LOS. Please Try Again."
										: error.includes("provide a value")
										? "Please provide a value for all fields highlighted below before you continue."
										: error.includes("borrower.dependentCount") &&
										  "Please check your dependent count."
								}
							/>
						)}
						{loanApplicationData?.slice(4, 6)?.map((borrower, index = 4) => (
							<BorrowerInformation
								key={index}
								borrowerIndex={index + 4}
								coBorrower={index % 2 !== 0}
								borrowerData={borrower}
								data={loanApplicationData}
								setData={setLoanApplicationData}
								error={error.includes("provide a value")}
								disabled={borrowerDataExist}
							/>
						))}
						<PropertyDetails
							data={propertyInfo}
							setData={setPropertyInfo}
							loanData={applicationData}
							setLoanData={setApplicationData}
							error={error.includes("provide a value")}
							disabled={borrowerDataExist}
						/>
						<LoanOriginator
							organizationName={organizationName}
							organizationDetails={organizationDetails}
							loanOriginatorEmail={loanOriginatorEmail}
							setLoanOriginatorEmail={setLoanOriginatorEmail}
							organizationUsers={organizationUsers}
							loanOriginator={loanOriginator}
							setLoanOriginator={setLoanOriginator}
							setOrganizationLicense={setOrganizationLicense}
							propertyState={propertyInfo?.state}
							error={error.includes("provide a value")}
							disabled={borrowerDataExist}
						/>
						<LoanProcessor
							organizationName={organizationName}
							organizationDetails={organizationDetails}
							loanProcessorEmail={loanProcessorEmail}
							setLoanProcessorEmail={setLoanProcessorEmail}
							organizationProcessor={organizationProcessor}
							loanProcessor={loanProcessor}
							propertyState={propertyInfo?.state}
							error={error.includes("provide a value")}
							disabled={borrowerDataExist}
						/>
					</TabPanel>
				)}
			</Stack>

			<Stack direction="row" className={styles.footerContainer}>
				<PrimaryButton
					text="Save and Continue"
					endIcon={<ArrowForwardOutlined className={styles.nextIcon} />}
					onClick={() =>
						borrowerDataExist ? handleNextSectionClick() : handleNext()
					}
				/>
			</Stack>

			{/* modal */}
			<LoaderModal
				open={
					creatingLoan ||
					creatingAdminLoan ||
					isUpdatingFields ||
					updatingLoan ||
					creatingBorrowerPair ||
					updatingBorrowerPair
				}
			/>
			<ConfirmationModal
				open={confirmModalVisible}
				text={confirmModalText}
				confirmText="Sign In"
				handleConfirm={handleSubmitConfirmModal}
				closeText="Close"
				handleClose={handleCloseConfirmModal}
			/>
		</Stack>
	);
};

export default CreateLoanApplication;
