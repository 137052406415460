import React from "react";

// mui components
import { Box, Stack } from "@mui/material";

// custom components
import CustomDivider from "../../../../components/custom-divider";
import Heading6 from "../../../../components/typography/heading-06";
import Body2 from "../../../../components/typography/body-02";

// custom styles
import styles from "./index.module.scss";

const ViewLoanInfoCard = ({
	loanNumber,
	borrower,
	coBorrower,
	propertyInfo,
}) => {
	return (
		<Stack direction="row" className={styles.cardContainer}>
			{/* loan number */}
			<Stack direction="column">
				<Heading6 text="Loan Number" fontType="semibold" />
				<Body2 text={loanNumber || ""} fontType="semibold" />
			</Stack>

			<Box className={styles.dividerContainer}>
				<CustomDivider extraClass={styles.divider} />
			</Box>

			{/* borrower */}
			<Stack direction="column">
				<Heading6 text="Borrower " fontType="semibold" />
				<Body2
					text={`${borrower?.firstName || ""} ${borrower?.middleName || ""} ${
						borrower?.lastName || ""
					}`}
					fontType="semibold"
				/>
			</Stack>

			<Box className={styles.dividerContainer}>
				<CustomDivider extraClass={styles.divider} />
			</Box>
			{/* co-borrower */}
			<Stack direction="column">
				<Heading6 text="Co Borrower " fontType="semibold" />
				<Body2
					text={`${coBorrower?.firstName || ""} ${
						coBorrower?.middleName || ""
					} ${coBorrower?.lastName || ""}`}
					fontType="semibold"
				/>
			</Stack>

			<Box className={styles.dividerContainer}>
				<CustomDivider extraClass={styles.divider} />
			</Box>

			{/* property address */}
			<Stack direction="column">
				<Heading6 text="Property Address " fontType="semibold" />
				<Body2
					text={`${propertyInfo?.addressLineText || ""} ${
						propertyInfo?.city || ""
					} ${propertyInfo?.state || ""} ${propertyInfo?.postalCode || ""}`}
					fontType="semibold"
				/>
			</Stack>
		</Stack>
	);
};

export default ViewLoanInfoCard;
