import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// mui icons
import CloseIcon from "@mui/icons-material/Close";
import UploadFileIcon from "@mui/icons-material/UploadFile";

// mui components
import { Box, Stack } from "@mui/material";

// custom components
import CustomTable from "../../../custom-table";
import SupportingDocs from "../../../supporting-docs";
import PrimaryButton from "../../../buttons/primary-button";
import SecondaryButton from "../../../buttons/secondary-button";
import Heading3 from "../../../typography/heading-03";
import Body2 from "../../../typography/body-02";
import Body3 from "../../../typography/body-03";

// api slice
import { useUploadConditionRemedyMutation } from "../../../../features/upload-condition-remedy/uploadConditionRemedyApiSlice";
import { useUnderwritingConditionMutation } from "../../../../features/underwriting-conditions/underwritingApiSlice";
import { usePreliminaryConditionMutation } from "../../../../features/preliminary-conditions/preliminaryApiSlice";
import { usePostClosingConditionMutation } from "../../../../features/post-closing-conditions/postClosingApiSlice";

// redux slice
import { getUnderwritingConditions } from "../../../../app/services/underwriting-conditions";
import { getPreliminaryConditions } from "../../../../app/services/preliminary-conditions";
import { getPostClosingConditions } from "../../../../app/services/post-closing-conditions";

// reducer slice
import { selectUnderwritingConditions } from "../../../../features/underwriting-conditions/underwritingSlice";
import { selectPreliminaryConditions } from "../../../../features/preliminary-conditions/preliminarySlice";
import { selectPostClosingConditions } from "../../../../features/post-closing-conditions/postClosingSlice";
import { selectCategoryCondition } from "../../../../features/category-condition/categoryConditonSlice";

// helper utils
import { formatBytes } from "../../../../utils/format-bytes";

// custom styles
import styles from "./index.module.scss";

const ConditionTrackingDrawer = ({
	handleCloseDrawer,
	handleOpenProcessingModal,
	handleCloseProcessingModal,
	handleOpenSuccessModal,
	setProcessingText,
	setSuccessText,
	handleOpenErrorModal,
	setErrorText,
	// custom values
	selectedCondition,
	setSelectedCondition,
}) => {
	const fileTypes = ["PDF", "PNG", "JPEG", "JPG", "HTML", "TXT"];
	const params = useParams();

	const [uploadConditionRemedy] = useUploadConditionRemedyMutation();

	const [underwritingCondition] = useUnderwritingConditionMutation();
	const [preliminaryCondition] = usePreliminaryConditionMutation();
	const [postClosingCondition] = usePostClosingConditionMutation();

	const brokerUploadColumns = [
		{ id: "upload-date", label: "Date", minWidth: 165 },
		{ id: "name", label: "Name", minWidth: 180 },
		{ id: "description", label: "Description", minWidth: 320 },
		{ id: "category", label: "Category", minWidth: 165 },
		{ id: "status", label: "Status", minWidth: 165 },
		{ id: "sub-status", label: "Sub Status", minWidth: 165 },
	];

	const [selectedRow, setSelectedRow] = useState([]);

	const [documentsList, setDocumentsList] = useState([]);

	useEffect(() => {
		console.log("75 selected cond:", selectedCondition);

		if (selectedCondition?.length > 0) {
			setSelectedRow(selectedCondition);
		}
	}, [selectedCondition]);

	const categoryCondition = useSelector(selectCategoryCondition);
	console.log("64 categoryCondition:", categoryCondition);

	// get underwriting conditions data from redux store
	const underwritingConditionData = useSelector(selectUnderwritingConditions);
	console.log("75 underwriting:", underwritingConditionData);

	// get preliminary conditions data from redux store
	const preliminaryConditionData = useSelector(selectPreliminaryConditions);
	console.log("80 preliminary:", preliminaryConditionData);

	// get post closing conditions data from redux store
	const postClosingConditionData = useSelector(selectPostClosingConditions);
	console.log("85 post closing:", postClosingConditionData);

	// update state underwriting conditions data from redux store
	useEffect(() => {
		console.log("80 underwriting:", underwritingConditionData);

		if (
			categoryCondition === "underwriting" &&
			underwritingConditionData !== undefined
		) {
			setDocumentsList(underwritingConditionData);
			handleCloseProcessingModal();
		}
	}, [underwritingConditionData]);

	// update state preliminary conditions data from redux store
	useEffect(() => {
		console.log("80 preliminary:", preliminaryConditionData);

		if (
			categoryCondition === "preliminary" &&
			preliminaryConditionData !== undefined
		) {
			setDocumentsList(preliminaryConditionData);
			handleCloseProcessingModal();
		}
	}, [preliminaryConditionData]);

	// update state post closing conditions data from redux store
	useEffect(() => {
		console.log("80 post closing:", postClosingConditionData);

		if (
			categoryCondition === "post-closing" &&
			postClosingConditionData !== undefined
		) {
			setDocumentsList(postClosingConditionData);
			handleCloseProcessingModal();
		}
	}, [postClosingConditionData]);

	const [attachedFiles, setAttachedFiles] = useState(null);

	const deleteFile = () => {
		setAttachedFiles(null);
	};

	const handleFileAttach = async (file) => {
		console.log("135 file:", file);
		setAttachedFiles(file);
	};

	const handleCancel = () => {
		console.log("50 cancel clicked.");
		setSelectedRow([]);
		setAttachedFiles(null);
	};

	const handleErrorModal = (data) => {
		console.log("60 data:", data);

		handleCloseProcessingModal();
		handleOpenErrorModal();
		setErrorText("There was an error updating conditions");
	};

	const updateConditionData = () => {
		if (categoryCondition === "underwriting") {
			getUnderwritingConditions({
				loanGuid: params?.id,
				apiRequest: underwritingCondition,
				handleErrorModal: handleErrorModal,
			});

			handleOpenProcessingModal();
			setProcessingText("Setting up the lock info");
		} else if (categoryCondition === "preliminary") {
			getPreliminaryConditions({
				loanGuid: params?.id,
				apiRequest: preliminaryCondition,
				handleErrorModal: handleErrorModal,
			});

			handleOpenProcessingModal();
			setProcessingText("Setting up the lock info");
		} else if (categoryCondition === "post-closing") {
			getPostClosingConditions({
				loanGuid: params?.id,
				apiRequest: postClosingCondition,
				handleErrorModal: handleErrorModal,
			});

			handleOpenProcessingModal();
			setProcessingText("Setting up the lock info");
		}
	};

	// upload documents
	const handleSubmitDocuments = async () => {
		console.log("130 submit click!!!");
		console.log("130 attached files:", attachedFiles);
		console.log("130 selected row:", selectedRow);

		if (selectedRow?.length === 0) return;
		if (attachedFiles === null) return;

		console.log("315 running ");

		let selectedConditions = selectedRow?.toString();
		console.log("130 selected conditions:", selectedConditions);

		handleCloseDrawer();
		handleOpenProcessingModal();
		setProcessingText("Your Remedy is being updated");

		var formData = new FormData();
		formData.append("loan_guid", params?.id);
		formData.append("condition_ids", selectedConditions);
		formData.append("file", attachedFiles);

		// dispatch API and open modal if successful
		try {
			const fileUpload = await uploadConditionRemedy({ formData }).unwrap();
			console.log("99 file data:", fileUpload);

			// get updated conditions data
			updateConditionData();

			// uploaded successfully
			setSelectedCondition("");
			handleCloseProcessingModal();
			handleOpenSuccessModal();
			setSuccessText("Remedy has been added");

			console.log("70 all files attached");
		} catch (err) {
			console.log("95 err:", err);
			// TODO aqib open error modal
			setSelectedCondition("");
			handleCloseProcessingModal();
		}
	};

	return (
		<Stack direction="column" className={styles.stackContainer}>
			<Stack
				direction="row"
				alignItems="center"
				justifyContent="space-between"
				className={styles.drawerHeaderContainer}
			>
				<Heading3
					text="Add Remedy"
					extraClass={styles.headerText}
					fontType="semibold"
				/>
				<SecondaryButton
					startIcon={<CloseIcon />}
					text="Close"
					onClick={() => {
						setSelectedCondition("");
						handleCloseDrawer();
					}}
				/>
			</Stack>

			<Stack direction="column" className={styles.conditionsContainer}>
				<Heading3
					text="Choose all conditions being satisfied"
					extraClass={styles.labelText}
					fontType="semibold"
				/>

				<CustomTable
					enableSort={true}
					enableSelect={true}
					enableSearch={true}
					tableType="satisfying-conditions"
					squaredBorder={true}
					columnData={brokerUploadColumns}
					rowData={documentsList}
					setSortedData={setDocumentsList}
					selectedRow={selectedRow}
					setSelectedRow={setSelectedRow}
				/>
			</Stack>

			<Stack direction="column" className={styles.docsContainer}>
				<Heading3
					text="Upload Supporting Documentation:"
					extraClass={styles.labelText}
					fontType="semibold"
				/>

				<SupportingDocs
					fileCount={attachedFiles?.length}
					fileTypes={fileTypes}
					text="or drag to upload supporting files"
					handleFileAttach={handleFileAttach}
					multiple={false}
				/>
			</Stack>

			{attachedFiles !== undefined && attachedFiles !== null && (
				<Stack direction="column" className={styles.uploadedFileContainer}>
					<Stack direction="row" className={styles.fileListContainer}>
						<Box className={styles.fileIconContainer}>
							<UploadFileIcon className={styles.fileIcon} />
						</Box>
						<Stack direction="column" className={styles.fileNameContainer}>
							<Body2 text={attachedFiles?.name} />
							<Body3 text={formatBytes(attachedFiles?.size)} />
						</Stack>

						<Box
							sx={{ cursor: "pointer" }}
							onClick={(event) => deleteFile(event)}
							className={styles.deleteIconContainer}
						>
							<CloseIcon className={styles.deleteIcon} />
						</Box>
					</Stack>
				</Stack>
			)}

			<Stack
				direction="row"
				alignItems="center"
				justifyContent="flex-end"
				className={styles.drawerFooterContainer}
			>
				<SecondaryButton
					text="Reset"
					extraClass={styles.cancelBtn}
					extraTextClass={styles.cancelText}
					onClick={handleCancel}
					disabled={selectedRow?.length === 0 || attachedFiles === null}
				/>
				<PrimaryButton
					text="Upload"
					onClick={handleSubmitDocuments}
					disabled={selectedRow?.length === 0 || attachedFiles === null}
				/>
			</Stack>
		</Stack>
	);
};

export default ConditionTrackingDrawer;
